import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import useConfig from 'hooks/useConfig';
import requestSetRunState from 'api/requests/admin/Req-SetRunState';
import styles from './RunStateSwitch.module.scss';

const RunStateSwitch = () => {
	const config = useConfig();
	const auctionDetail = useSelector(state => state.adminpages?.auctionDetail);
	const [loading, setLoading] = useState(false);

	const { auctionID, state } = auctionDetail || {};

	useEffect(() => {
		console.log(state);
	}, [state]);

	const setRunState = code => {
		if (code === state) return;
		setLoading(true);
		requestSetRunState(code, auctionID)
			.then(() => setLoading(false))
			.catch(() => setLoading(false));

		setTimeout(() => setLoading(false), 5000);
	};

	if (loading)
		return (
			<div className={styles.container}>
				<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
			</div>
		);

	return (
		<div className={styles.container}>
			<button
				className={classNames(styles.button, {
					[styles.closed]: state === 'closed',
				})}
				onClick={() => setRunState('closed')}
			>
				{config.archive}
			</button>
			<button
				className={classNames(styles.button, {
					[styles.draft]: state === 'draft',
				})}
				onClick={() => setRunState('draft')}
			>
				{config.draft}
			</button>
			<button
				className={classNames(styles.button, {
					[styles.published]: state === 'published',
				})}
				onClick={() => setRunState('published')}
			>
				{config.published}
			</button>
			<button
				className={classNames(styles.button, {
					[styles.live]: state === 'live',
				})}
				onClick={() => setRunState('live')}
			>
				{config.live}
			</button>
		</div>
	);
};

export default RunStateSwitch;
