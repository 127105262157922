import { sendFile } from '..';
import { getLotUploadEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

export default function lotUpload(auctionID, file) {
	const userTokenID = window.API?.user?.userToken;
	const url = `${getLotUploadEndpoint()}?userTokenID=${userTokenID}&auctionID=${auctionID}`;

	return sendFile(url, file)
		.then(response => {
			const { data } = response;
			if (!data.length) {
				handleFailure(data);
			} else {
				handleSuccess(data);
			}
			return data;
		})
		.catch(error => {
			console.debug(error);
			return error;
		});
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.updateCSVUpload(data));
	console.debug('lotUpload success', data);
};

const handleFailure = data => {
	console.debug('lotUpload failure', data);
};
