import 'components/bidderinterface/catalogue/search/search.scss';

const Search = props => {
	const renderCategory = (category, index) => {
		const active = props.activeCategories.indexOf(category) !== -1;
		return (
			<div className="category" key={index}>
				<input
					type="checkbox"
					checked={active}
					onChange={() => toggleCategory(category)}
				/>
				<label>{category}</label>
			</div>
		);
	};

	const toggleCategory = category => {
		let activeCategories = [...props.activeCategories];
		const active = activeCategories.indexOf(category) !== -1;

		if (active)
			activeCategories = activeCategories.filter(
				element => element !== category
			);
		else activeCategories.push(category);

		props.setActiveCategories(activeCategories);
	};

	return (
		<div
			className={`catalogue__search ${
				props.showPageForIndex ? 'displaynone' : ''
			}`}
		>
			<div className="refine">
				<div className="subtitle large-24-font-size">
					{props?.config?.refineResults}
				</div>
				<div className="inputs">
					<input
						className="regular-16-font-size"
						placeholder="Search by keyword or text"
						value={props.keywordSearch}
						onChange={event =>
							props.setKeywordSearch(
								event.target.value.toLowerCase()
							)
						}
					/>
				</div>
			</div>
			<div className="price">
				<div className="subtitle large-24-font-size">
					{props?.config?.price}
				</div>
				<div className="inputs">
					<input
						className="regular-16-font-size"
						placeholder="Price from"
						type="number"
						value={props.priceBoundLow}
						onChange={event =>
							props.setPriceBoundLow(event.target.value)
						}
					/>
					<input
						className="regular-16-font-size"
						placeholder="Price to"
						type="number"
						value={props.priceBoundHigh}
						onChange={event =>
							props.setPriceBoundHigh(event.target.value)
						}
					/>
				</div>
			</div>
			<div className="categories">
				<div className="subtitle large-24-font-size">
					{props?.config?.category}
				</div>
				{props.availableCategories.map((category, index) =>
					renderCategory(category, index)
				)}
			</div>
		</div>
	);
};

export default Search;
