import React, { Component } from 'react';
import { connect } from 'react-redux';
import requestAuth from 'api/requests/common/Req-Auth';
import requestAuctionsBasic from 'api/requests/common/Req-AuctionsBasic';
import 'styles/login/login.scss';
import cookieHandler from 'api/cookieHandler';

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			auctionHouses: null,
			currentAuctionHouse: null,
			currentAuction: null,
			username: '',
			password: '',
			emptyFieldWarning: false,
		};
	}

	componentDidMount() {
		if (!window.API) return;
		requestAuctionsBasic();
	}

	componentDidUpdate(prevProps) {
		if (this.props.login.auctions !== prevProps.login.auctions) {
			this.setState({
				liveAuctions: this.props.login.auctions,
				currentAuction: this.props.login.auctions[0],
			});
		}
	}

	onUsernameChange = event => {
		this.setState({ username: event.currentTarget.value });
	};

	onPasswordChange = event => {
		this.setState({ password: event.currentTarget.value });
	};

	onAuctionHouseChange = event => {
		let index = Number(event.currentTarget.value);
		this.setState({
			currentAuctionHouse: this.state.auctionHouses[index],
			currentAuction: this.state.auctionHouses[index].auctions[0],
		});
	};

	onAuctionChange = event => {
		let index = Number(event.currentTarget.value);

		this.setState({
			currentAuction: this.state.liveAuctions[index],
		});
	};

	onAuthoriseSubmit = event => {
		event.preventDefault();
		if (!this.state.username || !this.state.password) {
			this.setState({ emptyFieldWarning: true });
			return;
		}
		let loginName = this.state.username;
		let password = this.state.password;
		let auctionIDOpt = this.state?.currentAuction?.auctionID || null;

		if (auctionIDOpt) cookieHandler.setAuctionID(auctionIDOpt);
		requestAuth(loginName, password, auctionIDOpt);
	};

	onAdminSubmit = event => {
		event.preventDefault();
		if (!this.state.username || !this.state.password) {
			this.setState({ emptyFieldWarning: true });
			return;
		}
		let loginName = this.state.username;
		let password = this.state.password;

		requestAuth(loginName, password, null);
	};

	getCurrentHouseIndex() {
		if (!this.state.currentAuctionHouse) return;
		let result = 0;
		this.state.auctionHouses.forEach((ah, index) => {
			if (ah._index === this.state.currentAuctionHouse._index)
				result = index;
		});
		return result;
	}

	getCurrentAuctionIndex() {
		if (!this.state.currentAuctionHouse || !this.state.currentAuction)
			return;
		let result = 0;
		this.state.liveAuctions.forEach((auction, index) => {
			if (auction._index === this.state.currentAuction._index)
				result = index;
		});
		return result;
	}

	render() {
		return (
			<div className="login-container primary-color-dark bg-primary-color-dark">
				<form
					className="login bg-primary-color-light"
					// onSubmit={this.onAuthoriseSubmit}
				>
					<div className="content">
						<img
							className="login__title"
							src={`https://${window.location.hostname}/styling/ahlogo.png`}
							alt=""
						/>
						<div className="login__subtitle">
							Welcome to this online auction. Log in to join us!
						</div>

						<label className="login__group">
							<p>User Name</p>
							<input
								className="login__username__input border-secondary-color-dark"
								placeholder="name@domain.com"
								onChange={this.onUsernameChange}
								value={this.state.username}
							/>
						</label>

						<label className="login__group">
							<p>Password</p>
							<input
								className="login__password__input border-secondary-color-dark"
								onChange={this.onPasswordChange}
								type="password"
								value={this.state.password}
							/>
						</label>

						{this.state?.auctionHouses?.length > 1 && (
							<label className="login__group">
								<p>Auction House</p>
								<select
									className="login__house__select border-secondary-color-dark"
									onChange={this.onAuctionHouseChange}
									value={this.getCurrentHouseIndex()}
								>
									{this.state.auctionHouses &&
										this.state.auctionHouses.map(
											(house, index) => {
												return (
													<option
														className="login__house__select__item"
														value={index}
														key={index}
													>
														{house.auctionHouseName}
													</option>
												);
											}
										)}
								</select>
							</label>
						)}

						<label className="login__group">
							<p>Auction</p>
							<select
								className="login__auction__select border-secondary-color-dark"
								onChange={this.onAuctionChange}
								value={this.getCurrentAuctionIndex()}
							>
								{this.state.liveAuctions?.length &&
									this.state.liveAuctions.map(
										(auction, index) => {
											return (
												<option
													className="login__auction__select__item"
													value={index}
													key={index}
												>
													{auction.title}
												</option>
											);
										}
									)}
							</select>
						</label>

						<button
							className="login__buttons__authorise bg-primary-color-dark primary-color-light"
							onClick={this.onAuthoriseSubmit}
						>
							CLERK LOGIN
						</button>

						<button
							className="login__buttons__authorise admin bg-primary-color-dark primary-color-light"
							onClick={this.onAdminSubmit}
						>
							ADMIN LOGIN
						</button>
						<div className="login__info">
							{this.props.login.showLoginDetailError && (
								<div className="login__emptyfieldwarning warning-color">
									{this.props.login.showLoginDetailError}
								</div>
							)}
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		login: state.login,
	};
};

export default connect(mapStateToProps)(Login);
