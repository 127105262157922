import sendRequest from '..';
import { getCommonEndpoint } from '../utils';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';

//REQ_AUTOBID: 'Req-AutoBid'
export default function requestAutobid(lotID, bidLimit) {
	return sendRequest(getCommonEndpoint(), {
		Action: 'Req-AutoBid',
		userTokenID: window.API?.user?.userToken,
		lotID,
		bidLimit,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure();
				return response;
			}
			handleSuccess(data);
			return response;
		})
		.catch(error => {
			console.debug(error);
			return error;
		});
}

const handleSuccess = data => {
	store.dispatch(AuctionActions.updateCat(data));
	console.debug('Req-AutoBid success', data);
};

const handleFailure = data => {
	console.debug('Req-AutoBid failure', data);
};
