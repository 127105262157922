import { connect } from 'react-redux';
import AuctionRow from './row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-regular-svg-icons';

const Table = props => {
	const renderRows = () => {
		if (props?.auctionsList.length === 0) {
			return (
				<tr>
					<td>{props?.config?.noMatches}</td>
				</tr>
			);
		}

		return props?.auctionsList.map((auction, index) => {
			return (
				<AuctionRow
					{...auction}
					auctionHouseID={props?.auctionHouseID}
					key={index}
				/>
			);
		});
	};

	return (
		<div className="auctionlist__row__3">
			<div className="table__wrapper">
				<table className="auctionlist__table" cellSpacing="0">
					<thead className="bg-secondary-color-dark">
						<tr>
							<th onClick={() => props.onSortColumn('saleNumOpt')}>
								Sale Number
								<FontAwesomeIcon className="icon" icon={faSort} />
							</th>
							<th onClick={() => props.onSortColumn('auctionTitle')}>
								{props?.config?.title}{' '}
								<FontAwesomeIcon className="icon" icon={faSort} />
							</th>
							<th onClick={() => props.onSortColumn('currency')}>
								{props?.config?.currency}{' '}
								<FontAwesomeIcon className="icon" icon={faSort} />
							</th>
							<th onClick={() => props.onSortColumn('auctionDate')}>
								{props?.config?.date}{' '}
								<FontAwesomeIcon className="icon" icon={faSort} />
							</th>
							<th onClick={() => props.onSortColumn('lotCount')}>
								{props?.config?.totalLots}{' '}
								<FontAwesomeIcon className="icon" icon={faSort} />
							</th>
							<th onClick={() => props.onSortColumn('specialistEmailOpt')}>
								{props?.config?.email}{' '}
								<FontAwesomeIcon className="icon" icon={faSort} />
							</th>
							<th>{props?.config?.goID}</th>
							<th>{props?.config?.archive}</th>
							<th>{props?.config?.settings}</th>
							<th>{props?.config?.statistics}</th>
						</tr>
					</thead>
					<tbody>{renderRows()}</tbody>
				</table>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Table);
