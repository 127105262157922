import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Routes, Route } from 'react-router-dom';
// import requestAuctions from 'api/requests/common/Req-Auctions';
import Sidebar from 'components/rootadmin/sidebar/sidebar';
// import AddAuctionHouse from 'components/rootadmin/addauctionhouse/addauctionhouse';
// import AuctionHouseList from 'components/rootadmin/auctionhouselist/auctionhouselist';
// import TestingArea from 'components/rootadmin/testingarea/testingarea';
import 'styles/rootadmin/rootadmin.scss';

class RootAdmin extends Component {
	// componentDidMount() {
	// 	if (window.API.user) requestAuctions();
	// }

	render() {
		// const ahID =
		// 	this.props.rootadmin?.loginDetails?.auctionHouse?.auctionHouseID;
		// const houses = this.props.rootadmin?.auctionHouses;

		// const ah =
		// 	houses && houses.filter(house => house.auctionHouseID === ahID)[0];

		return (
			<div className="rootadmin">
				<Sidebar {...this.props} />
				<div className="rootadmin__main">
					Root Admin
					{/* <Routes>
						<Route exact path="/rootadmin">
							<div className="rootadmin__home">
								<div className="rootadmin__title">
									<h2>Root Admin Home Page</h2>
									<p>
										Name:{' '}
										{ah?.auctionHouseName ||
											'No auction house selected'}
									</p>
									<p>
										ID:{' '}
										{ah?.auctionHouseID ||
											'No auction house selected'}
									</p>
								</div>
							</div>
						</Route>
						<Route path="/rootadmin/addauctionhouse">
							<AddAuctionHouse {...this.props} editMode={false} />
						</Route>
						<Route path="/rootadmin/editauctionhouse">
							<AddAuctionHouse {...this.props} editMode={true} />
						</Route>
						<Route path="/rootadmin/auctionhouselist">
							<AuctionHouseList {...this.props} />
						</Route>
						<Route path="/rootadmin/testingarea">
							<TestingArea {...this.props} />
						</Route>
					</Routes> */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		rootadmin: { ...state.login, ...state.rootadmin },
	};
};

export default connect(mapStateToProps)(RootAdmin);
