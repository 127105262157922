import { useEffect } from 'react';
import { connect } from 'react-redux';
import requestDefaultsProtocol from 'api/requests/common/Req-DefaultsProtocol';
import Protocols from 'components/adminpages/settings/protocols';
import TandC from 'components/adminpages/settings/tandc';
import RegToBidMail from 'components/adminpages/settings/regtobidmail';
import OpenPriceMode from './openPriceMode';
import AutoAccept from './autoAccept';
import SaleNote from './saleNote';
import IntroVideo from './introVideo';

import 'styles/adminpages/settings/settings.scss';

const Settings = props => {
	useEffect(() => {
		if (!window?.API?.user?.userToken) return;
		if (props?.defaultsProtocol?.userTokenID) return;
		requestDefaultsProtocol();
	}, [props?.defaultsProtocol]);

	useEffect(() => {
		if (!props?.auctionID) return;

		let reissueOpt = {
			auctionHouseIDOpt: props?.auctionHouseID,
			auctionIDOpt: null,
		};

		window.API.validationCallBack = function () {
			window.API.validationCallBack = null;
			this.pushHistory('/adminpages');
		};

		if (!window?.API?.user?.userToken) return;
		window.API.tokenValidation(window?.API?.user?.userToken, reissueOpt);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (props.houseSettings) {
			let reissueOpt = {
				auctionHouseIDOpt: props?.auctionHouseID,
				auctionIDOpt: null,
			};

			window.API.validationCallBack = function () {
				window.API.validationCallBack = null;
				this.pushHistory('/adminpages/housesettings');
			};

			if (!window?.API?.user?.userToken) return;
			window.API.tokenValidation(
				window?.API?.user?.userToken,
				reissueOpt
			);
		}
		// eslint-disable-next-line
	}, [props?.houseSettings]);

	return (
		<div className="adminpages__settings">
			<div className="row row-1 bg-primary-color-light">
				<div className="title large-24-font-size">
					{`${props?.auctionHouseName} ${props?.config?.houseSettings}`}
				</div>
				<div className="subtitle">
					{props?.config?.changeHouseSettings}
				</div>
			</div>
			<SaleNote />
			<OpenPriceMode />
			<AutoAccept />
			<IntroVideo />
			<Protocols className="row row-2 bg-primary-color-light" />
			<TandC className="row row-3 bg-primary-color-light" />
			<RegToBidMail className="row row-4 bg-primary-color-light" />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		...state.adminpages,
		auctionID: state.login?.loginDetails?.auction?.auctionID,
		auctionHouseID: state.login?.loginDetails?.auctionHouse?.auctionHouseID,
		auctionHouseName: state.login?.loginDetails?.auctionHouse?.name,
		config: state?.config,
	};
};
export default connect(mapStateToProps)(Settings);
