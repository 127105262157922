import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import { getAllClerks } from 'api/clerk/getClerks';
import { getAllAdmins } from 'api/admin/getAdmins';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_DELETESTAFF: 'Req-DeleteStaff'
export default function requestDeleteStaff(userID) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-DeleteStaff',
		userTokenID: window.API?.user?.userToken,
		userID,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.resetStaff());
	getAllClerks();
	getAllAdmins();
	console.debug('Req-DeleteStaff success', data);
};

const handleFailure = data => {
	console.debug('Req-DeleteStaff failure', data);
};
