import "./header.scss";

const Header = props => {
	return (
		<div className='catalogue__header'>
			<div className='catalogue__spacer'></div>
			<div className='catalogue__title large-24-font-size'>
				{props?.title}
			</div>
			<div className='catalogue__spacer'></div>
		</div>
	);
};

export default Header;
