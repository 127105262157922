import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import { storeLatency, getLatency } from '../latency';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';

//REQ_RESPONDTOBID: 'Req-RespondToBid'
export default function respondToBid(
	offerID,
	platformSN,
	priceOpt,
	incTypeOpt,
	custIncOpt
) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-RespondToBid',
		userTokenID: window.API?.user?.userToken,
		offerID,
		platformSN,
		priceOpt,
		incTypeOpt,
		custIncOpt,
		tS: Date.now(),
		lastLatency: getLatency(),
	})
		.then(response => {
			const { data } = response;
			if (data.tS) storeLatency(data.tS);
			if (data.result > 100) {
				handleFailure(data.result);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AuctionActions.updateAuctionState(data?.state));
	console.debug('Req-RespondToBid success', data);
};

const handleFailure = result => {
	console.debug('Req-RespondToBid failure', result);
};
