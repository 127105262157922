import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { capitaliseFirst } from 'utils/utils';
import requestDefaultsProtocol from 'api/requests/common/Req-DefaultsProtocol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faSpinner,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const OpenPriceMode = props => {
	const openPriceMode = useSelector(
		state => state.adminpages?.defaultsProtocol?.openPriceMode
	);
	const config = useSelector(state => state?.config);
	const loadingIcon = (
		<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
	);
	const successIcon = <FontAwesomeIcon className="icon" icon={faCheck} />;
	const failureIcon = <FontAwesomeIcon className="icon" icon={faTimes} />;
	const valueOpt = openPriceMode?.valueOpt || '0';
	const [mode, setMode] = useState(valueOpt);
	const [info, setInfo] = useState(null);

	useEffect(() => setMode(valueOpt), [valueOpt]);

	const onChange = event => {
		event.preventDefault();
		const valueOpt = Number(event.target.value);
		showLoading();

		const data = {
			openPriceMode: { valueOpt, update: true },
		};

		requestDefaultsProtocol(data)
			.then(data => {
				if (data.result > 100 || data?.isAxiosError) {
					showFailure();
					return;
				}
				showSuccess();
			})
			.catch(() => showFailure());
	};

	const showLoading = () => {
		setInfo(
			<div className="openpricemode-info loading">{loadingIcon}</div>
		);
	};

	const showSuccess = () => {
		setInfo(
			<div className="openpricemode-info success">{successIcon}</div>
		);
		reset();
	};

	const showFailure = () => {
		setInfo(
			<div className="openpricemode-info failure">{failureIcon}</div>
		);
		reset();
	};

	const reset = () => {
		setTimeout(() => setInfo(null), 750);
	};

	return (
		<div className="row">
			<div className="openpricemode">
				<div className="large-20-font-size">
					{config?.setOpenPriceMode}
				</div>
				{!info && (
					<select value={mode} onChange={event => onChange(event)}>
						<option value="0">
							{capitaliseFirst(config?.default)}
						</option>
						<option value="10">
							{capitaliseFirst(config?.autobid)}
						</option>
					</select>
				)}
				{info && info}
			</div>
		</div>
	);
};

export default OpenPriceMode;
