import { connect, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import sanitiseCurrency from 'utils/sanitiseCurrency';
import 'styles/adminpages/users/users.scss';
import UsersModalTable from './usersmodaltable';

const UsersModal = props => {
	const user = useSelector(state => state.adminpages?.userModalData);
	const userAuctions = user.auctions;
	const [auctions, setAuctions] = useState(null);
	const [showAll, setShowAll] = useState(
		props.filterAuction === 'all' && props?.dateFilter === 'all'
	);

	useEffect(() => {
		setAuctions(prepareAuctions(userAuctions));
		// eslint-disable-next-line
	}, [userAuctions]);

	if (!user) return;

	const prepareAuctions = userAuctions => {
		return [...props?.auctions]
			.sort((a, b) => {
				if (a.auctionDate > b.auctionDate) return -1;
				if (a.auctionDate < b.auctionDate) return 1;
				return 0;
			})
			.filter(auction => {
				if (showAll) return auction;

				if (props?.filterAuction !== 'all') {
					return auction.auctionID === props.filterAuction;
				}

				if (props?.dateFilter !== 'all') {
					return auction.simpleDate === props.dateFilter;
				}

				return false;
			})
			.map(auction => {
				const registered = userAuctions.find(
					userAuction => userAuction.auctionID === auction.auctionID
				);
				if (!registered) return { ...auction };
				return {
					...auction,
					paddleNum: registered?.paddleNum,
					paddleLimitOpt: registered?.paddleLimitOpt,
					manualLimit: registered?.paddleLimitOpt,
				};
			});
	};

	const onManualLimitChange = (event, auctionID, currencySymbol) => {
		event.preventDefault();

		let sanitisedValue = sanitiseCurrency(event.currentTarget.value);
		let value = Number(sanitisedValue);
		if (isNaN(Number(value))) return;

		let newAuctions = [...auctions].map(auction => {
			if (auction.auctionID !== auctionID) return auction;
			auction.manualLimit = value;
			return auction;
		});

		setAuctions(newAuctions);
	};

	return (
		<div className="adminpages__users__modal" data-testid="users-modal">
			<div
				className="adminpages__users__modal__shadow"
				onClick={event => props.onShadowClick(event)}
			/>
			<div className="adminpages__users__modal__inner">
				<div className="adminpages__users__modal__title large-24-font-size">
					{props?.config?.paddleStatus}
				</div>
				{!showAll && (
					<button
						className="adminpages__users__modal__showall bg-admin-accent-2 primary-color-light border-secondary-color-dark hover-bg-admin-accent-2"
						onClick={() => setShowAll(true)}
					>
						{props?.config?.showAll}
					</button>
				)}
				<div className="adminpages__users__modal__user large-20-font-size">
					{user?.loginName}
				</div>
				<UsersModalTable
					auctions={auctions}
					user={user}
					onManualLimitChange={onManualLimitChange}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(UsersModal);
