import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const useBidsFeed = () => {
	const currentLotID = useSelector(
		state => state.auction?.currentLotDetailOpt?.currentLotID
	);
	const offersWithBids = useSelector(state => state.auction?.offersWithBids);
	const messages = useSelector(state => state.auction?.messages);
	const [displayList, setDisplayList] = useState([]);
	const [animateSlide, setAnimateSlide] = useState(null);
	const voids = offersWithBids?.filter(offer => offer.isVoid);

	useEffect(() => {
		if (displayList?.length) return;
		setMergedList();
		// eslint-disable-next-line
	}, [currentLotID, offersWithBids?.length, messages?.length]);

	useEffect(
		() => setMergedList(),
		// eslint-disable-next-line
		[currentLotID]
	);

	useEffect(() => {
		if (!displayList?.length) return;
		if (window.API.user.userPerm !== 'bidder') {
			setMergedList();
			return;
		}
		setAnimateSlide(true);
		setTimeout(() => {
			setMergedList();
			setAnimateSlide(false);
		}, 500);
		// eslint-disable-next-line
	}, [currentLotID, offersWithBids?.length, messages?.length, voids?.length]);

	const setMergedList = () => {
		if (!offersWithBids?.length && !messages?.length) {
			setDisplayList([]);
			return;
		}
		setDisplayList(
			[...offersWithBids, ...messages].sort((a, b) => {
				let aTimestamp = a.offerTimeStamp || a.createTime;
				let bTimestamp = b.offerTimeStamp || b.createTime;
				return bTimestamp - aTimestamp;
			})
		);
	};

	return { displayList, animateSlide };
};

export default useBidsFeed;
