import cookieHandler from 'api/cookieHandler';
import User from 'api/User';
import WssConn from './wss/wssConn';
import store from 'store/store';
import LoginActions from 'store/actions/login';
import AdminPageActions from 'store/actions/adminpages';
import AuctionActions from 'store/actions/auction';
import BidderActions from 'store/actions/bidder';
import ClerkActions from 'store/actions/clerk';
import requestAuctionsBasic from 'api/requests/common/Req-AuctionsBasic';
import requestAuctions from './requests/common/Req-Auctions';
import requestAuctionDetail from './requests/common/Req-AuctionDetail/Req-AuctionDetail';
import requestAuctionStaticDetail from './requests/common/Req-AuctionStaticDetail/Req-AuctionStaticDetail';
import requestState from './requests/common/Req-State';
import requestValidate from './requests/common/Req-Validate';
import requestDefaultsProtocol from './requests/common/Req-DefaultsProtocol';
import requestSetClient from './requests/common/Req-SetClient';
import { requestTestClerkPort } from './requests/common/Req-State';
// import getConfig from './requests/configs/getConfig';
import getConfigs from './requests/configs/getConfigs';
import getWelcomeConfig from './requests/configs/getWelcomeConfig';

class API {
	constructor(pushHistory) {
		if (this.wssConn) {
			this.wssConn.close();
		}
		this.user = null;
		this.wssConn = null;
		this.pushHistory = pushHistory;
		this.gaSet = false;
		this.clerkPort = 4443;
		this.validationAttempted = false;
		this.initialRouter();
	}

	initialRouter() {
		let urlParams = new URLSearchParams(window.location.search);
		let userTokenIDParam = urlParams.get('userTokenID');
		let userIDParam = urlParams.get('userID');
		let auctionIDParam = urlParams.get('auctionID');
		let pageParam = urlParams.get('topage');
		let modeParam = urlParams.get('mode');
		let resetParams = urlParams.get('resetToken');
		let cachedUserToken = cookieHandler.getUserToken();

		this.tabSetup();
		// console.debug('PARAMS', { userIDParam, pageParam, modeParam });

		if (resetParams) {
			this.gotoLoginInterface();
			return;
		}

		if (userTokenIDParam) {
			userTokenIDParam = userTokenIDParam.replace(/ /g, '+');
			console.debug(
				'Attempting validation for userTokenID: ',
				userTokenIDParam
			);
			this.tokenValidation(userTokenIDParam);
			return;
		}

		if (auctionIDParam) {
			requestAuctionDetail(false, auctionIDParam);
			this.pushHistory('/holdingpage');
			return;
		}

		if (cachedUserToken) {
			console.debug(
				'Attempting validation for cached userTokenID: ',
				cachedUserToken
			);
			this.tokenValidation(cachedUserToken);
			return;
		}

		this.onAuthenticationFail();
	}

	tokenValidation(userToken, reissueOpt) {
		if (this.validationTimestamp) {
			let newTimestamp = Date.now();
			if (newTimestamp - this.validationTimestamp < 1000) {
				console.debug(
					'tokenValidation attempted less than 1000ms ago. Aborting.'
				);
				this.validationTimestamp = newTimestamp;
				return;
			}
		}

		this.validationTimestamp = Date.now();
		requestValidate(userToken, reissueOpt);
	}

	onAuthenticationFail(failReason = 'Authentication has failed') {
		console.debug(
			'[ERROR] ',
			failReason,
			', running onAuthenticationFail()'
		);
		cookieHandler.removeUserToken();

		this.gotoLoginInterface();
		this.validationAttempted = true;
	}

	setGA = gaID => {
		if (!gaID || this.gaSet) return;
		if (window.API?.user?.userPerm !== 'bidder') return;

		let gaScript = document.createElement('script');
		let dataScript = document.createElement('script');

		gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaID}`;
		gaScript.async = true;

		dataScript.innerHTML = `window.dataLayer = window.dataLayer || [];
		    function gtag(){
		        dataLayer.push(arguments);
		    }
		    gtag('js', new Date());
		    gtag('config', '${gaID}');`;

		document.head.appendChild(gaScript);
		document.head.appendChild(dataScript);
		this.gaSet = true;
	};

	onAuthenticationSuccess(data) {
		requestAuctionsBasic();
		console.debug('Authentication success', data);
		this.validationAttempted = true;
		store.dispatch(LoginActions.showLoginDetailError(false));
		store.dispatch(LoginActions.onLoginSuccess(data));

		requestSetClient(
			data.userTokenID,
			navigator.userAgent,
			window.innerWidth <= 768
		);

		if (this.wssConn) this.wssConn.close();

		this.user = new User(data);
		this.wssConn = new WssConn(data);
		let userPerm = this.user?.userPerm;

		if (userPerm === 'admin') {
			requestAuctions();
			requestDefaultsProtocol();
			if (data?.auction?.auctionID) {
				requestAuctionStaticDetail(
					true,
					data?.auction?.auctionID,
					true
				);
				requestState();
			}
		}

		if (userPerm === 'clerk') requestDefaultsProtocol();

		if (userPerm === 'bidder' || userPerm === 'viewer') {
			getWelcomeConfig();
		}

		cookieHandler.setUserToken(data.userTokenID);
		cookieHandler.setAuctionID(data?.auction?.auctionID || null);

		if (window.API.validationCallBack)
			window.API.validationCallBack(data?.auctionHouse?.auctionHouseID);

		let urlParams = new URLSearchParams(window.location.search);
		let pageParams = urlParams.get('page');
		let permissionsIncludes = name => data.permissions.includes(name);

		if (permissionsIncludes('root')) {
			if (pageParams && pageParams.indexOf('rootadmin') !== -1)
				this.pushHistory(pageParams);
			else this.gotoRootInterface();
		} else if (permissionsIncludes('admin')) {
			if (pageParams && pageParams.indexOf('adminpages') !== -1)
				this.pushHistory(pageParams);
			else this.gotoAdminInterface();
		} else if (permissionsIncludes('clerk')) {
			if (pageParams && pageParams.indexOf('clerkconsole') !== -1)
				this.pushHistory(pageParams);
			else this.gotoClerkInterface();
		} else if (permissionsIncludes('bidder')) {
			this.gotoBidderInterface(data);
		} else this.gotoViewerInterface(data);

		if (!data?.auction?.auctionID) {
			if (this.wssConn) this.wssConn?.close();
			console.debug('[INFO] Logging in with no auction');
			return;
		}

		let pathIncludes = name => window.location.pathname.includes(name);

		if (pathIncludes('clerkconsole')) {
			requestTestClerkPort();
			requestAuctionStaticDetail(true, data?.auction?.auctionID, true);
		}
		if (
			pathIncludes('bidderinterface') ||
			pathIncludes('viewerinterface')
		) {
			requestState();
			requestAuctionStaticDetail(true, data?.auction?.auctionID, true);
		}

		if (pathIncludes('holdingpage'))
			requestAuctionStaticDetail(false, data?.auction?.auctionID, true);
	}

	tabSetup() {
		let link = document.querySelector("link[rel~='icon']");
		link.href = `https://${window.location.hostname}/styling/favicon.ico`;
	}

	gotoLoginInterface() {
		let urlParams = new URLSearchParams(window.location.search);
		let resetParams = urlParams.get('resetToken');
		let resetPath =
			window.location.pathname.indexOf('/passwordReset') !== -1;

		if (resetParams || resetPath) {
			let resetToken = encodeURIComponent(
				new URLSearchParams(window.location.search)
					.get('resetToken')
					.replace(/ /g, '+')
			);

			this.pushHistory(`/passwordReset?resetToken=${resetToken}`);
			return;
		}

		this.pushHistory('/');
	}

	gotoBidderInterface(data) {
		getConfigs();
		if (!data?.auction?.isAuctionLive) {
			this.pushHistory('/holdingpage');
			return;
		}
		if (window.location.pathname === '/bidderinterface') return;
		this.pushHistory('/bidderinterface');
	}

	gotoViewerInterface(data) {
		getConfigs();
		store.dispatch(BidderActions.setViewerInterface(true));
		if (!data?.auction?.isAuctionLive) {
			this.pushHistory('/holdingpage');
			return;
		}
		if (window.location.pathname === '/viewerinterface') return;
		this.pushHistory('/viewerinterface');
	}

	gotoClerkInterface() {
		getConfigs();
		let urlParams = new URLSearchParams(window.location.search);
		let fromPageParams = urlParams.get('frompage');
		let goToPage = fromPageParams
			? fromPageParams
			: window.location.pathname;

		switch (goToPage) {
			case '/clerkconsole/results':
				this.pushHistory('/clerkconsole/results');
				return;
			case '/clerkconsole/broadcast':
				this.pushHistory('/clerkconsole/broadcast');
				return;
			case '/clerkconsole/fullscreen':
				this.pushHistory('/clerkconsole/fullscreen');
				return;
			case '/clerkconsole/auctioneer':
				this.pushHistory('/clerkconsole/auctioneer');
				return;
			case '/clerkconsole/detailedauctioneer':
				this.pushHistory('/clerkconsole/detailedauctioneer');
				return;
			default:
				this.pushHistory('/clerkconsole');
		}
	}

	gotoAdminInterface() {
		getConfigs();
		if (
			window.location.pathname.indexOf('/adminpages') !== -1 ||
			window.location.pathname.indexOf('/clerkconsole') !== -1
		)
			return;
		this.pushHistory('/adminpages');
	}

	gotoRootInterface() {
		if (
			window.location.pathname.indexOf('/rootadmin') !== -1 ||
			window.location.pathname.indexOf('/adminpages') !== -1 ||
			window.location.pathname.indexOf('/clerkconsole') !== -1
		)
			return;
		this.pushHistory('/rootadmin');
	}

	resetReduxStore() {
		store.dispatch(AdminPageActions.reset());
		store.dispatch(AuctionActions.reset());
		store.dispatch(BidderActions.reset());
		store.dispatch(ClerkActions.reset());
	}

	onLogout() {
		const userToken = this.user?.userToken;
		const userPerm = this.user?.userPerm;
		cookieHandler.removeUserToken();
		cookieHandler.setAuctionID(null);

		if (this.wssConn) this.wssConn.close();
		if (this.checkWSSConnTimeout) clearTimeout(this.checkWSSConnTimeout);

		const permissionChangeEvent = new CustomEvent('permissionChangeEvent', {
			detail: null,
		});
		window.dispatchEvent(permissionChangeEvent);
		this.user = null;
		this.wssConn = null;

		this.resetReduxStore();

		if (userPerm === 'bidder' && userToken)
			window.location.replace(
				`${window.location.origin}/logout?userTokenID=${userToken}`
			);
		else this.pushHistory('/');
	}
}

export default API;
