import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons';

class UploadProgress extends Component {
	renderMessage() {
		let importState = this.props?.importState;

		if (!importState) {
			return (
				<span>
					<FontAwesomeIcon
						className="upload__icon spinner fa-spin"
						icon={faSpinner}
					/>{' '}
					{this.props?.config?.loading}
				</span>
			);
		}

		if (importState.detail.indexOf('Complete') !== -1) {
			return (
				<span>
					<FontAwesomeIcon
						className="upload__icon check"
						icon={faCheck}
					/>{' '}
					{this.props.importState.detail}
				</span>
			);
		}

		return (
			<span>
				<FontAwesomeIcon
					className="upload__icon spinner fa-spin"
					icon={faSpinner}
				/>{' '}
				{this.props.importState.detail}
			</span>
		);
	}

	render() {
		let warnings =
			this.props?.csvUpload?.length &&
			this.props?.csvUpload?.filter(status => status.result !== 10);
		let completed =
			this.props?.csvUpload?.length &&
			this.props?.csvUpload?.filter(status => status.result === 10);

		return (
			<div className="addauction__uploadprogress">
				<div className="addauction__uploadprogress-row1">
					<div className="addauction__uploadprogress__info">
						<p className="addauction__uploadprogress__title regular-16-font-size">
							{completed?.length &&
								`${this.props?.config?.success} - ${completed.length} lots.`}
						</p>
					</div>
				</div>
				{warnings && warnings.length !== 0 && (
					<div className="addauction__uploadprogress-row2">
						<div className="addauction__warningstitle">
							{this.props?.config?.itemsNeedAttention}
						</div>
						<table>
							<thead>
								<tr>
									<th>{this.props?.config?.row}</th>
									<th>{this.props?.config?.detail}</th>
								</tr>
							</thead>
							<tbody>
								{warnings.map((warning, index) => {
									return (
										<tr key={index}>
											<td>{warning.name}</td>
											<td>{warning.detail}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
				{warnings && warnings.length === 0 && (
					<p className="regular-16-font-size">
						{this.props?.config?.noErrors}
					</p>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		lotList: state.adminpages?.auctionDetail?.lotList,
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		csvUpload: state.adminpages?.csvUpload,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(UploadProgress);
