export function getResolutions() {
	return [
		{ width: 640, height: 360 },
		{ width: 1280, height: 720 },
		{ width: 1920, height: 1080 },
	];
}

export function getResFromWidth(width) {
	switch (width) {
		case 1920:
			return { width: 1920, height: 1080 };
		case 1280:
			return { width: 1280, height: 720 };
		case 640:
			return { width: 640, height: 360 };
		default:
			return null;
	}
}

export function getAdaptorProps(videoStreamWSS, callback, callbackError) {
	return {
		websocket_url: videoStreamWSS,
		mediaConstraints: {
			video: {
				width: '1280',
				height: '720',
			},
			audio: {
				noiseSuppression: false,
				echoCancellation: false,
			},
		},
		peerconnection_config: {
			iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
		},
		sdp_constraints: {
			OfferToReceiveAudio: false,
			OfferToReceiveVideo: false,
		},
		localVideoId: 'localVideo',
		remoteVideo: 'remoteVideo',
		bandwidth: 900,
		dataChannelEnabled: true,
		callback,
		callbackError,
	};
}

export function getDefaultDeviceId(devices) {
	const target = devices.find(
		device => device.label.toLocaleLowerCase().indexOf('streambid') !== -1
	);
	if (target) return target.deviceId;
	return devices[0].deviceId;
}
