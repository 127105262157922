import * as Yup from 'yup';

export const getFormSchema = (existingUser, showTrustLevel, config) => {
	let formData = [
		{
			label: config?.loginName,
			id: 'loginName',
			value: existingUser?.loginName || '',
			type: 'text',
			inputType: 'input',
		},
		{
			label: config?.firstName,
			id: 'firstName',
			value: existingUser?.firstName || '',
			type: 'text',
			inputType: 'input',
		},
		{
			label: config?.lastName,
			id: 'secondName',
			value: existingUser?.lastName || '',
			type: 'text',
			inputType: 'input',
		},
		{
			label: config?.salutation,
			id: 'salutation',
			value: existingUser?.salutation || '',
			type: 'text',
			inputType: 'input',
		},
		{
			label: config?.email,
			id: 'primaryEmail',
			value: existingUser?.email || '',
			type: 'text',
			inputType: 'input',
		},
	];

	if (existingUser)
		formData.push({
			label: config?.permission,
			id: 'userType',
			value: existingUser?.userType,
			type: 'text',
			inputType: 'select',
			options: [config?.bidder, config?.clerk, config?.admin],
		});

	if (!existingUser)
		formData.push({
			label: config?.password,
			id: 'passwordOpt',
			value: existingUser?.passwordOpt || '',
			type: 'text',
			inputType: 'input',
		});

	if (showTrustLevel)
		formData.push({
			label: config?.trustLevel,
			id: 'trustLevel',
			value: existingUser?.trustLevel,
			type: 'text',
			inputType: 'select',
			options: [0, 1, 2, 3, 4],
		});

	return formData;
};

export const getValidationSchema = (existingUser, showTrustLevel) => {
	let schema = {
		loginName: Yup.string().required('Required'),
		firstName: Yup.string().required('Required'),
		secondName: Yup.string().required('Required'),
		salutation: Yup.string().required('Required'),
		primaryEmail: Yup.string()
			.email('Must be a valid email')
			.required('Required'),
	};

	if (existingUser) schema['userType'] = Yup.string().required('Required');

	if (!existingUser) schema['passwordOpt'] = Yup.string().required('Required');
	if (showTrustLevel) schema['trustLevel'] = Yup.string().required('Required');

	let validationSchema = Yup.object().shape(schema);

	return validationSchema;
};
