export function getClerkEndpoint() {
	if (window?.API?.clerkPort) {
		return `${document.location.protocol}//${window.location.hostname}:${window.API.clerkPort}/query2`;
	}
	return `${document.location.protocol}//${window.location.hostname}/query2`;
}

export function getClerkEndpointStrict() {
	return `${document.location.protocol}//${window.location.hostname}:${window.API.clerkPort}/query2`;
}

export function getCommonEndpoint() {
	return `${document.location.protocol}//${window.location.hostname}/query2`;
}

export function getLotUploadEndpoint() {
	return `${document.location.protocol}//${window.location.hostname}/lotupload`;
}

export function getBidderUploadEndpoint() {
	return `${document.location.protocol}//${window.location.hostname}/bidderupload`;
}

export function getConfigEndpoint() {
	return `https://${window.location.hostname}/config`;
}

export function getConfigFolderEndpoint() {
	return `https://${window.location.hostname}/styling/`;
}

export function getWelcomeConfigEndpoint() {
	const userTokenID = window.API?.user?.userToken;
	return `https://${window.location.hostname}/tandc?userTokenID=${userTokenID}`;
}
