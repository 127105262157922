import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import requestPause from 'api/requests/clerk/Req-Pause';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import ClerkActions from 'store/actions/clerk';
import 'styles/clerkconsole/header/header.scss';
import StreamInfo from 'components/clerkconsole/header/streaminfo';
import Connection from 'components/clerkconsole/header/connection/connection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSignalAlt3,
	faFileContract,
	faBroadcastTower,
	faPause,
	faPlay,
	faUserHeadset,
	faDesktop,
	faGavel,
	faSignOut,
	faTimes,
	faExclamationTriangle,
	faBookOpenCover,
} from '@fortawesome/pro-solid-svg-icons';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isStreamInfoOpen: false,
			isConnectionInfoOpen: false,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', () =>
			this.setState({
				isStreamInfoOpen: false,
				isConnectionInfoOpen: false,
			})
		);
	}

	logout = () => {
		window.API.onLogout();
	};

	onStreamInfoClick = () => {
		this.setState({
			isStreamInfoOpen: !this.state.isStreamInfoOpen,
			isConnectionInfoOpen: false,
		});
	};

	onConnectionInfoClick = () => {
		this.setState({
			isConnectionInfoOpen: !this.state.isConnectionInfoOpen,
			isStreamInfoOpen: false,
		});
	};

	setAuctionPause = () => requestPause(!this.props.clerk.isPaused);

	onPingClick = () => {
		store.dispatch(AuctionActions.debugModalOpen());
	};

	pauseButtonRender() {
		if (this.props.clerk.isPaused) {
			return (
				<button
					className="header__pause primary-color-dark bg-primary-color-light"
					onClick={this.setAuctionPause}
				>
					<FontAwesomeIcon className="header__pause__icon" icon={faPlay} />
					<div className="header__pause__text regular-16-font-size">
						{this.props?.config?.startAuction}
					</div>
				</button>
			);
		} else {
			return (
				<button
					className="header__pause primary-color-dark bg-primary-color-light"
					onClick={this.setAuctionPause}
				>
					<FontAwesomeIcon className="header__pause__icon" icon={faPause} />
					<div className="header__pause__text regular-16-font-size">
						{this.props?.config?.pauseAuction}
					</div>
				</button>
			);
		}
	}

	render() {
		const wssStatus = this.props.wssLive;
		const portStatus = window.API?.clerkPort === 4443;
		const connError = !wssStatus || !portStatus;
		const { showDetailedAuctioneer } = this.props?.showFlags || {};

		return (
			<div className="header bg-primary-color-dark">
				<div className={'header-container'}>
					<div className="header__title large-20-font-size">
						{this.props.clerk.auctionTitle}
					</div>

					{this.props?.portMessage?.showMessage && (
						<div className="header__portmessage">
							{!this.props.portMessage.success && (
								<span>
									<FontAwesomeIcon
										className="warning-icon"
										icon={faExclamationTriangle}
									/>
									Unable to connect to port {this.props?.portMessage?.clerkPort}
									. Reverting to slower connection. Please check your firewall.
									<button
										className="header__portmessage__close"
										onClick={() =>
											store.dispatch(
												ClerkActions.setPortMessage({
													showMessage: false,
													success: false,
													clerkPort: null,
												})
											)
										}
									>
										<FontAwesomeIcon className="icon" icon={faTimes} />
									</button>
								</span>
							)}
						</div>
					)}

					<div className="header__controls">
						<div className="header__controls__inner">
							<button
								className={`header__controls__connection regular-18-font-size ${
									this.state.isConnectionInfoOpen
										? 'primary-color-dark bg-primary-color-light'
										: 'bg-primary-color-dark primary-color-light'
								}`}
								onClick={this.onConnectionInfoClick}
							>
								<div className="header__controls__tooltip">
									{this.props?.config?.connectionPanel}
								</div>
								{connError && (
									<FontAwesomeIcon
										className={`conn_error ${
											this.state.isConnectionInfoOpen ? 'invert' : ''
										}`}
										icon={faExclamationTriangle}
									/>
								)}
								<FontAwesomeIcon
									className="header__controls__contact__icon"
									icon={faSignalAlt3}
								/>
							</button>
							{this.state.isConnectionInfoOpen && <Connection />}

							<Link to="/clerkconsole/auctioneer" target="_blank">
								<button className="header__controls__auctioneer bg-primary-color-dark regular-18-font-size primary-color-light">
									<div className="header__controls__tooltip">
										{this.props?.config?.auctioneerScreen}
									</div>
									<FontAwesomeIcon
										className="header__controls__fullscreen__icon"
										icon={faGavel}
									/>
								</button>
							</Link>

							<Link to="/clerkconsole/fullscreen" target="_blank">
								<button className="header__controls__fullscreen bg-primary-color-dark regular-18-font-size primary-color-light">
									<div className="header__controls__tooltip">
										{this.props?.config?.clerkFullscreen}
									</div>
									<FontAwesomeIcon
										className="header__controls__fullscreen__icon"
										icon={faDesktop}
									/>
								</button>
							</Link>
							{showDetailedAuctioneer && (
								<Link to="/clerkconsole/detailedauctioneer" target="_blank">
									<button className="header__controls__contact bg-primary-color-dark regular-18-font-size primary-color-light">
										<div className="header__controls__tooltip">
											{this.props?.config?.detailedAuctioneer}
										</div>
										<FontAwesomeIcon
											className="header__controls__contact__icon"
											icon={faBookOpenCover}
										/>
									</button>
								</Link>
							)}
							<Link to="/clerkconsole/broadcast" target="_blank">
								<button className="header__controls__contact bg-primary-color-dark regular-18-font-size primary-color-light">
									<div className="header__controls__tooltip">
										{this.props?.config?.broadcastScreen}
									</div>
									<FontAwesomeIcon
										className="header__controls__contact__icon"
										icon={faBroadcastTower}
									/>
								</button>
							</Link>

							<button
								className={`header__controls__contact regular-18-font-size ${
									this.state.isStreamInfoOpen
										? 'primary-color-dark bg-primary-color-light'
										: 'bg-primary-color-dark primary-color-light'
								}`}
								onClick={this.onStreamInfoClick}
							>
								<div className="header__controls__tooltip">
									{this.props?.config?.streamPanel}
								</div>
								<FontAwesomeIcon
									className="header__controls__contact__icon"
									icon={faUserHeadset}
								/>
							</button>
							{this.state.isStreamInfoOpen && <StreamInfo />}
							{/* <button className="header__controls__ping bg-primary-color-dark regular-18-font-size primary-color-light" onClick={this.onPingClick}>
                                <FontAwesomeIcon className="header__controls__ping__icon" icon={faSignalAlt3} />
                            </button> */}

							<Link to="/clerkconsole/results" target="_blank">
								<button className="header__controls__file bg-primary-color-dark regular-18-font-size primary-color-light">
									<div className="header__controls__tooltip">
										{this.props?.config?.auctionResults}
									</div>
									<FontAwesomeIcon
										className="header__controls__file__icon"
										icon={faFileContract}
									/>
								</button>
							</Link>

							<button
								className="header__controls__settings bg-primary-color-dark regular-18-font-size primary-color-light"
								onClick={this.logout}
							>
								<div className="header__controls__tooltip">
									{this.props?.config?.logout}
								</div>
								<FontAwesomeIcon
									className="header__controls__settings__icon"
									icon={faSignOut}
								/>
							</button>
						</div>

						{this.pauseButtonRender()}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		portMessage: state?.clerk?.portMessage,
		config: state?.config,
		showFlags: state?.showFlags,
		wssLive: state?.clerk?.wssLive,
	};
};

export default connect(mapStateToProps)(Header);
