import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import styles from './AutobidButton.module.scss';

const AutobidButton = ({
	onSubmit,
	onSubmitIncrement,
	autobidState,
	currentIncrement,
	info,
}) => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();

	if (autobidState === 'confirming')
		return (
			<div className={styles.container}>
				<div className={styles.confirming}>
					<div className={styles.slider}></div>
					<div className={styles.title}>{config?.confirming}</div>
				</div>
			</div>
		);

	if (autobidState === 'closed') {
		const increment = getFormattedCurrency(currentIncrement);
		return (
			<div className={styles.container}>
				<button
					className={styles.button}
					onClick={event => onSubmitIncrement(event)}
				>
					{config?.add} {increment}
				</button>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<button
				className={styles.button}
				onClick={event => onSubmit(event)}
			>
				{info ? info : config?.setAutobid}
			</button>
		</div>
	);
};

export default AutobidButton;
