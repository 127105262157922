import { connect } from 'react-redux';
import CurrentItemImage from '../ImageSlider/currentItemImage';

const Image = props => {
	const { showMainVideo } = props;

	if (showMainVideo)
		return (
			<div
				className={`mobilemainpanel__image ${
					showMainVideo ? 'mobilemainpanel__thumbnail' : ''
				}`}
			>
				<div
					className="mobilemainpanel__thumbnail__overlay"
					onClick={event =>
						showMainVideo ? props.onThumbnailClick(event) : null
					}
				></div>
				<img src={props?.absImg[0]} alt="Current item" />
			</div>
		);

	return (
		<div className={`mobilemainpanel__image`}>
			<CurrentItemImage />
		</div>
	);
};

const mapStateToProps = state => {
	const currentLotID = state.auction?.currentLotDetailOpt?.currentLotID;
	const currentLot = state.auction?.lotList?.find(
		lot => lot?.catalogueLot?.lotID === currentLotID
	);

	return {
		absImg: currentLot?.catalogueLot?.absImg || [],
		showMainVideo: state.bidder?.showMainVideo,
	};
};

export default connect(mapStateToProps)(Image);
