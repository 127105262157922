export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const RESET_CONFIG = 'RESET_CONFIG';

export default class ConfigActions {
	static updateConfig(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_CONFIG,
				data,
			});
		};
	}

	static reset() {
		return function (dispatch) {
			return dispatch({
				type: RESET_CONFIG,
			});
		};
	}
}
