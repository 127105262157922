import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import { getAllBidders } from 'api/bidder/getBidders';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_DELETEBIDDER: 'Req-DeleteBidder'
export default function requestDeleteBidder(userID) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-DeleteBidder',
		userTokenID: window.API?.user?.userToken,
		userID,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.resetBidders());
	getAllBidders();
	console.debug('Req-DeleteBidder success', data);
};

const handleFailure = data => {
	console.debug('Req-DeleteBidder failure', data);
};
