import { useEffect, useState } from 'react';
import LotCard from 'components/common/lotcard/lotcard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import 'components/bidderinterface/catalogue/lots/lots.scss';

const Lots = props => {
	const [currentLotRef, setCurrentLotRef] = useState(null);
	const [initialScrollDone, setInitialScrollDone] = useState(false);

	useEffect(() => {
		if (!initialScrollDone && currentLotRef) scrollToCurrentLot();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLotRef]);

	const setRef = element => {
		setCurrentLotRef(element);
	};

	const scrollToCurrentLot = () => {
		setInitialScrollDone(true);
		currentLotRef.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	};

	const renderLot = (lot, index) => {
		if (!lot) return;

		if (lot.dummyCard) {
			let props = { ...lot, key: index, classes: '' };
			return (
				<div className="lotcard__container">
					<LotCard {...props} />
				</div>
			);
		}

		let cardProps = {
			// ...lot,
			lotID: lot?.catalogueLot?.lotID,
			key: index,
			classes: '',
			customClickCallback: props.onLotCardClick,
		};
		let cardRef = null;

		if (lot.catalogueLot.lotIndex === props.currentLotIndex) {
			cardProps.isCurrentLot = true;
			cardRef = setRef;
		}

		return (
			<div className="lotcard__container" key={index}>
				<LotCard {...cardProps} setRef={cardRef} />
			</div>
		);
	};

	if (!props.allLotsLoaded) {
		return (
			<div className={`catalogue__lots__loading`}>
				<FontAwesomeIcon
					className="icon fa-spin extra-large-34-font-size "
					icon={faSpinner}
				/>
			</div>
		);
	}
	return (
		<div
			className={`catalogue__lots ${
				props.showPageForIndex ? 'displaynone' : ''
			}`}
		>
			{props?.paginatedLotList?.map((lot, index) =>
				renderLot(lot, index)
			)}
		</div>
	);
};

export default Lots;
