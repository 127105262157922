let cookieHandler = {
    getUserToken: ()=>cookieHandler.getCookie("user-tok"),
    setUserToken: value=>cookieHandler.setCookie("user-tok", value),
    removeUserToken: ()=>cookieHandler.setCookie("user-tok", undefined, -1),
    setAuctionHouseName: value=>cookieHandler.setCookie("auction-house-name", value),
    getAuctionHouseName: ()=>cookieHandler.getCookie("auction-house-name"),
    setAuctionID: value=>cookieHandler.setCookie("auction-id", value),
    getAuctionID: ()=>cookieHandler.getCookie("auction-id"),
    setHasInteracted: value=>cookieHandler.setCookie("hasInteracted", value),
    getHasInteracted: ()=>cookieHandler.getCookie("hasInteracted"),
    getAuctionHouseSelectIndex: ()=>cookieHandler.getCookie("auction-house-selection-index"),
    setAuctionHouseSelectIndex: value=>cookieHandler.setCookie("auction-house-selection-index", value),
    // getAuctionInstanceSelectIndex: ()=>cookieHandler.getCookie("auction-instance-selection-index"),
    // setAuctionInstanceSelectIndex: value=>cookieHandler.setCookie("auction-instance-selection-index", value),

    setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    
    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

};

export default cookieHandler;

