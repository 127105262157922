import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';

//REQ_CAT_UPDATE: 'Req-CatUpdate'
export default function requestCatUpdate(lotIndexOpt, countOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-CatUpdate',
		userTokenID: window.API?.user?.userToken,
		lotIndexOpt,
		countOpt,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	// TODO
	// temp data faking for development

	// data.lotUpdates = data.lotUpdates.map(update => {
	// 	update.clerkDataOpt.commissionBids = [
	// 		{
	// 			bidder: 'Bidder 1',
	// 			maxBid: 14000,
	// 			paddle: 'Comm001',
	// 			timeStamp: Date.now(),
	// 		},
	// 		{
	// 			bidder: 'Bidder 2',
	// 			maxBid: 15000,
	// 			paddle: 'Comm002',
	// 			timeStamp: Date.now(),
	// 		},
	// 		{
	// 			bidder: 'Bidder 3',
	// 			maxBid: 16000,
	// 			paddle: 'Comm003',
	// 			timeStamp: Date.now(),
	// 		},
	// 	];
	// 	update.clerkDataOpt.phoneBids = [
	// 		{
	// 			bidder: 'Bidder 4',
	// 			maxBid: 17000,
	// 			paddle: 'Phone004',
	// 			timeStamp: Date.now(),
	// 		},
	// 		{
	// 			bidder: 'Bidder 5',
	// 			maxBid: 18000,
	// 			paddle: 'Phone005',
	// 			timeStamp: Date.now(),
	// 		},
	// 		{
	// 			bidder: 'Bidder 6',
	// 			maxBid: 19000,
	// 			paddle: 'Phone006',
	// 			timeStamp: Date.now(),
	// 		},
	// 	];

	// 	return update;
	// });

	store.dispatch(AuctionActions.updateCat(data));
	console.debug('Req-CatUpdate success', data);
};

const handleFailure = result => {
	console.debug('Req-CatUpdate failure', result);
};
