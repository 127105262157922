import React, { useState, useEffect } from 'react';

import API from 'api/API';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import AudioFX from 'components/common/audiofx';
import 'styles/app.scss';
import 'styles/scrollbar.scss';
import 'styles/variables.scss';
import 'defaults/fallback/customcss.css';
import ClerkConsole from 'components/clerkconsole/clerkconsole';
import { BidderLayout } from 'components/bidderinterface/BidderLayout';
import BidderMobile from 'components/bidderinterface/mobile/mobile';
import BidderInterface from 'components/bidderinterface/bidderinterface';
import AdminPages from 'components/adminpages/adminpages';
import HoldingPage from 'components/holdingpage/holdingpage';
import ResetPW from 'components/login/resetpassword';
import Login from 'components/login/login';
import RootAdmin from 'components/rootadmin/rootadmin';
import FixedNotify from 'components/common/fixednotify/fixednotify';
import LanguagePicker from 'components/common/LanguagePicker/LanguagePicker';

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

const App = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const initialIsMobile = window.innerWidth <= 768;
	const [useMobileLayout, setMobileLayout] = useState(initialIsMobile);
	const [locationClass, seLocationClass] = useState('');
	const [fixedNotify, setFixedNotify] = useState(null);
	const [notifyTimeout, setNotifyTimeout] = useState(null);

	const pushHistory = route => {
		if (!route) return;
		navigate(route);
	};

	useEffect(() => {
		store.dispatch(BidderActions.setUseMobileLayout(initialIsMobile));
		window.addEventListener('resize', checkWindowSize);
		window.addEventListener('permissionChangeEvent', onPermissionChange);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (
			location.pathname === '/bidderinterface' &&
			window?.API?.validationAttempted &&
			!window.API.user
		)
			pushHistory('/');
		// eslint-disable-next-line
	}, [window?.API?.validationAttempted]);

	if (!window.API) window.API = new API(pushHistory);
	window.API.navBack = () => navigate(-1);

	const checkWindowSize = () => {
		const userPerm = window?.API?.user?.userPerm;

		if (userPerm?.includes('clerk') || userPerm?.includes('admin')) {
			setMobileLayout(false);
			store.dispatch(BidderActions.setUseMobileLayout(false));
			return;
		}

		const useMobileLayout = window.innerWidth <= 768;
		setMobileLayout(useMobileLayout);
		store.dispatch(BidderActions.setUseMobileLayout(useMobileLayout));
	};

	const onPermissionChange = event => {
		if (!event.detail) {
			seLocationClass('');
			return;
		}
		checkWindowSize();
		seLocationClass(`app-${event.detail}`);
	};

	// window.addEventListener('resize', checkWindowSize);
	// window.addEventListener('permissionChangeEvent', onPermissionChange);

	window.API.checkWindowSize = checkWindowSize;
	window.API.setFixedNotify = notifyProps => {
		if (notifyTimeout) clearTimeout(notifyTimeout);

		setFixedNotify(notifyProps);
		setNotifyTimeout(setTimeout(() => setFixedNotify(null), 2000));
	};

	useEffect(() => {
		return () => {
			window.removeEventListener('resize', checkWindowSize);
			window.removeEventListener('permissionChangeEvent', onPermissionChange);
		};
		// eslint-disable-next-line
	}, []);

	window.API.pushHistory = pushHistory;

	return (
		<div
			className={`App background-color ${
				useMobileLayout ? 'mobile' : 'desktop'
			} ${locationClass}`}
		>
			{fixedNotify && <FixedNotify fixedNotify={fixedNotify} />}
			<link
				rel="stylesheet"
				type="text/css"
				href={`https://${window.location.hostname}/styling/customcss.css`}
			/>
			<AudioFX />
			<LanguagePicker />
			<Routes>
				<Route path="/" element={<Login pushHistory={pushHistory} />} />
				<Route
					exact
					path="/passwordReset"
					element={<ResetPW pushHistory={pushHistory} />}
				/>
				<Route
					path="/clerkconsole/*"
					element={<ClerkConsole pushHistory={pushHistory} />}
				/>
				<Route
					path="/bidderinterface/*"
					element={useMobileLayout ? <BidderMobile /> : <BidderLayout />}
				/>
				<Route
					path="/viewerinterface"
					element={<BidderInterface pushHistory={pushHistory} />}
				/>
				<Route
					path="/holdingpage"
					element={<HoldingPage pushHistory={pushHistory} />}
				/>
				<Route
					path="/adminpages/*"
					element={<AdminPages pushHistory={pushHistory} />}
				/>
				<Route
					path="/rootadmin/*"
					element={<RootAdmin pushHistory={pushHistory} />}
				/>
			</Routes>
		</div>
	);
};

export default App;
