import { REQ_SENT, REQ_REPLY, REQ_REMOVE } from 'store/actions/requests';
import store from 'store/store';
import RequestActions from 'store/actions/requests';

const initialState = {
	requests: [],
};

export default function requests(state = initialState, action) {
	let returnState = Object.assign({}, state);

	switch (action.type) {
		case REQ_SENT:
			const requestExists = state.requests.find(
				req => req.requestID === action.requestID
			);
			if (requestExists) return returnState;

			returnState.requests = [
				...returnState.requests,
				{
					protocol: action.protocol,
					requestID: action.requestID,
					sent: true,
					reply: false,
					success: null,
					resetTimeout: action.resetTimeout,
				},
			];

			return returnState;

		case REQ_REPLY:
			let targetID = null;
			let resetTimeout = null;
			returnState.requests = [...returnState.requests].map(req => {
				if (req.protocol === action.protocol) {
					req.reply = true;
					req.success = action.success;
					targetID = req.requestID;
					resetTimeout = req.resetTimeout;
				}
				return req;
			});

			setTimeout(() => {
				store.dispatch(RequestActions.requestRemove(targetID));
			}, resetTimeout);
			return returnState;

		case REQ_REMOVE:
			returnState.requests = [...returnState.requests].filter(
				req => req.requestID !== action.requestID
			);

			return returnState;

		default:
			return state;
	}
}
