import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Bid.module.scss';
import {
	faWifi,
	faGavel,
	faMapMarkerAlt,
	faPhone,
} from '@fortawesome/pro-light-svg-icons';

export function getIcon(data) {
	if (data?.isAutoBid)
		return (
			<i
				className={
					'fak fa-autobid mobilebidsfeed__feeditemicon platform-icon-number-' +
					data?.platformIconNumber
				}
			></i>
		);

	let icon = faGavel;
	switch (data?.platformIconNumber) {
		case 0:
			icon = faGavel;
			break;
		case 10:
			icon = faMapMarkerAlt;
			break;
		case 50:
			icon = faWifi;
			break;
		case 60:
			icon = faPhone;
			break;
		default:
	}

	return (
		<FontAwesomeIcon
			className={
				'mobilebidsfeed__feeditemicon platform-icon-number-' +
				data?.platformIconNumber
			}
			icon={icon}
		/>
	);
}

export function getName(data, config) {
	const paddleNum = data?.paddleNum ? `(${data?.paddleNum})` : '';

	if (data?.isVoid)
		return <span className={styles.name}>{config?.voided}</span>;

	if (data?.isYou)
		return (
			<span className={styles.name}>
				{paddleNum} {config?.you}
			</span>
		);

	let name = '';

	switch (data?.platformName) {
		case 'Stream Bid':
			name = config?.platformName;
			break;
		case 'Floor Bid':
			name = config?.floorBid;
			break;
		case 'Phone Bid':
			name = config?.phoneBid;
			break;
		case 'Other Online':
			name = config?.onlineBid;
			break;
		default:
			name = data?.platformName;
	}

	return (
		<span className={styles.name}>
			{paddleNum} {name}
		</span>
	);
}
