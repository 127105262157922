import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLotIndex from 'hooks/useLotIndex';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import classNames from 'classnames';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import requestInteraction from 'api/requests/bidder/Req-Interaction';
import { Interweave } from 'interweave';
import AutobidFuture from 'components/common/AutobidFuture/AutobidFuture';
import PassedOffer from 'components/common/PassedOffer/PassedOffer';
import LazyImage from 'components/common/lazyimage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTimes, faBookmark } from '@fortawesome/pro-light-svg-icons';
import styles from './lotModal.module.scss';

const LotModal = () => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const lotIndex = useSelector(state => state.bidder?.modalLotIndex);
	const { catalogueLot, lotSaleDetail, interaction, isCurrentLot } =
		useLotIndex(lotIndex);
	const {
		lotNumber,
		title,
		artist,
		description,
		lowEst,
		highEst,
		lotID,
		absImg,
	} = catalogueLot || {};
	const { lotState, displayAs, saleResultOpt } = lotSaleDetail || {};
	const { salePrice } = saleResultOpt || {};
	const { like, likeCount } = interaction || {};

	const [mainImage, setMainImage] = useState(0);
	const itemWon = displayAs === 4200;
	const itemSold = lotState === 200;
	const itemPassed = lotState === 300;
	const itemOpen = !itemWon && !itemSold && !itemPassed && !isCurrentLot;
	const hideFooter = itemWon || itemSold || isCurrentLot;

	useEffect(() => setMainImage(0), [lotID]);

	const closeModal = () => store.dispatch(BidderActions.bidderCloseModal());
	const getImageURL = index => absImg && absImg[index];
	const onThumbnailClick = index => setMainImage(index);

	const renderInfo = () => {
		if (!displayAs && !lotState) return null;

		if (displayAs === 4200) {
			return (
				<div className={styles.itemWon}>
					{config?.itemWon} -- {getFormattedCurrency(salePrice)}
				</div>
			);
		}

		if (lotState === 200) {
			return (
				<div className={styles.itemSold}>
					{config?.itemSold} -- {getFormattedCurrency(salePrice)}
				</div>
			);
		}

		if (lotState === 300) {
			return <div className={styles.Passed}>{config?.itemPassed}</div>;
		}
	};

	const onLikesClick = () => {
		if (!lotID) return;
		requestInteraction(lotID, !like);
	};

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<button className={styles.closeButton} onClick={closeModal}>
					<FontAwesomeIcon className={styles.icon} icon={faTimes} />
				</button>
			</div>
			<div className={styles.body}>
				<LazyImage className={styles.mainImage} url={getImageURL(mainImage)} />
				<div className={styles.thumbnails}>
					{absImg?.map((img, index) => {
						return (
							<button
								className={styles.thumbnailButton}
								key={index}
								onClick={() => onThumbnailClick(index)}
							>
								<LazyImage
									className={styles.thumbnail}
									url={getImageURL(index)}
									key={index}
								/>
							</button>
						);
					})}
				</div>
				<div className={styles.details}>
					<div className={styles.info}>
						<div>
							<div className={styles.lotNumber}>
								<div>
									{config?.lot} {lotNumber}
								</div>
								{!itemOpen && renderInfo()}
							</div>
							{artist && (
								<div className={styles.itemTitle}>
									<Interweave content={artist} />
								</div>
							)}
							<div className={styles.itemTitle}>
								<Interweave content={title} />
							</div>
						</div>

						<div className={styles.likes} onClick={onLikesClick}>
							<FontAwesomeIcon className={styles.bookmark} icon={faBookmark} />
							<FontAwesomeIcon
								className={classNames(styles.star, {
									[styles.starActive]: like,
								})}
								icon={faStar}
							/>
							<div className={styles.likeCount}>{likeCount}</div>
						</div>
					</div>

					<div className={styles.estimate}>
						Estimate {getFormattedCurrency(lowEst)} -{' '}
						{getFormattedCurrency(highEst)}
					</div>
					<div className={styles.description}>
						<Interweave content={description} />
					</div>
				</div>
			</div>

			<div
				className={classNames(styles.footer, {
					[styles.hidden]: hideFooter,
				})}
			>
				{itemOpen && <AutobidFuture lotID={lotID} />}
				{itemPassed && <PassedOffer lotID={lotID} />}
			</div>
		</div>
	);
};

export default LotModal;
