import { connect } from 'react-redux';
import ReactPlayerSB from 'components/common/ReactPlayerSB';
import 'styles/holdingpage/holdingpage.scss';

const HoldingPage = props => {
	const { config } = props;
	const bidder = {
		videoStream: {
			sourceOpt: 'vimeo',
			videoURLOpt: [
				'https://vimeo.com/677305167',
				'width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen controls',
			],
		},
	};

	return (
		<div className="holdingpage">
			<div className="holdingpage__auctionhouse">
				<img
					src={`https://${window.location.hostname}/styling/ahlogo.png`}
					alt=""
				/>
			</div>

			<ReactPlayerSB classes={'holdingpage__video'} bidder={bidder} />

			<div className="holdingpage__title">
				{config?.technicalDifficulties}
			</div>

			<div className="holdingpage__body">{config?.message}</div>

			<div className="holdingpage__footer">{config?.thanks}</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auction: state.auction,
		loginDetails: state.login.loginDetails,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(HoldingPage);
