import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_USERREGMAIL: 'Req-UserRegMail
export default function requestUserRegMail(userID, auctionID) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-UserRegMail',
		userTokenID: window.API?.user?.userToken,
		userID,
		auctionID,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return data;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => {
			console.debug(error);
			return error;
		});
}

const handleSuccess = data => {
	console.debug('Req-UserRegMail success', data);
};

const handleFailure = data => {
	console.debug('Req-UserRegMail failure', data);
};
