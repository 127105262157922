import useCurrency from 'hooks/useCurrency';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import styles from './Inputs.module.scss';

const Inputs = ({ limit, onInputChange, onIncrementClick, resetLimit }) => {
	const { getFormattedCurrency } = useCurrency();
	const value = getFormattedCurrency(limit);

	useEffect(() => {
		if (!limit) resetLimit();
		// eslint-disable-next-line
	}, []);

	const onKeyPress = event => {
		if (event.charCode !== 13) return;
		event.preventDefault();
		event.target.blur();
		this.onSubmit();
	};

	return (
		<div className={styles.container}>
			<button
				className={styles.increment}
				onClick={event => onIncrementClick(event, false)}
			>
				<FontAwesomeIcon className={styles.icon} icon={faMinus} />
			</button>
			<input
				className={styles.input}
				value={value}
				onClick={event => event.stopPropagation()}
				onChange={onInputChange}
				onKeyPress={onKeyPress}
				inputMode="numeric"
			/>
			<button
				className={styles.increment}
				onClick={event => onIncrementClick(event, true)}
			>
				<FontAwesomeIcon className={styles.icon} icon={faPlus} />
			</button>
		</div>
	);
};

export default Inputs;
