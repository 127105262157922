import useCurrentLot from 'hooks/useCurrentLot';
import useConfig from 'hooks/useConfig';
import { Interweave } from 'interweave';
import styles from './Description.module.scss';

const Description = () => {
	const { lot } = useConfig();
	const { catalogueLot } = useCurrentLot();
	const { lotNumber, title, artist, description } = catalogueLot || {};

	if (!title) return <div className={styles.description} />;

	return (
		<div className={styles.description}>
			<div className={styles.title}>
				<div>
					<strong>
						<span className={styles.upper}>{lot}</span> {lotNumber}
					</strong>
				</div>
				<div>
					<strong>
						<Interweave content={artist} noHtml />
					</strong>
				</div>
				<div>
					<strong>
						<Interweave content={title} noHtml />
					</strong>
				</div>
			</div>
			<div className={styles.body}>
				<Interweave content={description} noHtml />
			</div>
		</div>
	);
};

export default Description;
