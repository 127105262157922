import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { generateDummyLot } from 'utils/utils';
import 'styles/bidderinterface/lotsfeed/lotsfeed.scss';
import 'styles/bidderinterface/lotsfeed/lot.scss';
import 'styles/common/lotcard.scss';
import LotCard from 'components/common/lotcard/lotcard';
import Filter from 'components/bidderinterface/lotsfeed/filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';

export class LotsFeed extends Component {
	constructor(props) {
		super(props);

		this.state = {
			layout: 'layout_0',
			activeFilter: 'all',
			searchedList: [],
			searchInput: null,
			numberOfSlides: 7,
			currentSlide: 0,
			lotChangeInProgress: false,
			initialCatRequestDone: false,
			initialLotsLoaded: false,
			showMask: false,
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.onSearchInputChange = this.onSearchInputChange.bind(this);

		window.addEventListener('resize', this.setNumberOfSlides.bind(this));
	}

	componentDidMount() {
		this.setNumberOfSlides();
		if (!this.props?.currentLotIndex) return;
		const targetOffest = this.state.numberOfSlides === 1 ? 1 : 2;
		let targetSlide = this.props?.currentLotIndex - targetOffest;
		if (targetSlide < 0) targetSlide = 0;

		this?.slider?.slickGoTo(targetSlide);
		this.setState({ currentSlide: targetSlide });
	}

	componentWillUnmount() {
		if (this.renderTimeout) clearTimeout(this.renderTimeout);
		if (this.lotChangeTimeout) clearTimeout(this.lotChangeTimeout);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps?.lotCount !== this.props?.lotCount) return true;
		if (nextProps?.lotList?.length !== this.props?.lotList?.length)
			return true;

		if (nextProps?.layout !== this.props?.layout) return true;

		if (this.checkForLotPropUpdates(nextProps)) {
			return true;
		}

		if (this.checkForLotStateUpdates(nextState)) {
			return true;
		}

		if (nextProps?.currentLotIndex !== this.props?.currentLotIndex)
			return true;

		if (nextState.searchedList?.length !== this.state.searchedList?.length)
			return true;
		if (nextState.activeFilter !== this.state.activeFilter) return true;
		if (nextState.searchInput !== this.state.searchInput) return true;
		if (nextState.currentSlide !== this.state.currentSlide) return true;
		if (nextState.layout !== this.state.layout) return true;

		return false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props?.lotCount && !prevProps?.lotCount) {
			this.setInitialLotList();
		}

		if (!this.state.searchedList?.length && this.props?.lotList?.length) {
			this.updateLotList();
		}

		if (this.props?.lotList?.length !== prevProps?.lotList?.length) {
			this.updateLotList();
		}
		if (this.props?.currentLotIndex !== prevProps?.currentLotIndex) {
			this.setLotChangeTimeout();
			if (this.props?.currentLotIndex && this.slider) {
				const targetOffest = this.state.numberOfSlides === 1 ? 1 : 2;
				let currentSlide = this.props?.currentLotIndex - targetOffest;

				this.setState({ currentSlide });
			}
		}

		if (this.props?.lotList !== prevProps?.lotList) {
			this.setFilteredLotList();
		}

		if (this.props?.layout !== prevProps?.layout) {
			this.setState({ layout: `layout_${this.props?.layout}` });
			this.setFilteredLotList();
		}

		if (this.props?.currentLotIndex && !prevProps?.currentLotIndex) {
			const targetOffest = this.state.numberOfSlides === 1 ? 1 : 2;
			let currentSlide = this.props?.currentLotIndex - targetOffest;
			if (currentSlide < 0) currentSlide = 0;

			this.setState({
				currentSlide,
			});
		}

		if (prevState.activeFilter !== this.state.activeFilter) {
			this.setFilteredLotList();
		}

		if (prevState.searchInput !== this.state.searchInput) {
			this.setFilteredLotList();
		}

		if (
			prevState.activeFilter !== this.state.activeFilter &&
			this.state.activeFilter === 'all' &&
			this.slider
		) {
			this.slider?.slickGoTo(this.props?.currentLotIndex - 2);
		}

		if (this.state.currentSlide !== prevState.currentSlide) {
			this?.slider?.slickGoTo(this.state.currentSlide);
		}
	}

	getLot(lotList, index) {
		if (index < 1) return null;
		return lotList.find(lot => lot.catalogueLot.lotIndex === index);
	}

	checkForLotPropUpdates(nextProps) {
		if (!this.props?.lotLiist?.length || nextProps?.lotLiist?.length)
			return false;
		const noPropsUpdates = this.props?.lotList?.every((lot, index) => {
			const nextTarget = nextProps?.lotLiist[index];
			return (
				lot?.lotSaleDetail?.displayAs ===
				nextTarget?.lotSaleDetail?.displayAs
			);
		});

		return !noPropsUpdates;
	}

	checkForLotStateUpdates(nextState) {
		const noPropsUpdates = this.state?.searchedList?.every((lot, index) => {
			const nextTarget = nextState?.searchedList[index];
			return (
				lot?.lotSaleDetail?.displayAs ===
				nextTarget?.lotSaleDetail?.displayAs
			);
		});

		return !noPropsUpdates;
	}

	setInitialLotList() {
		let loadingArray = new Array(this.props?.lotCount);
		loadingArray.fill({ loading: true });
		this.setState({
			searchedList: loadingArray,
		});
	}

	updateLotList() {
		if (!this.props?.lotList?.length) return;
		if (!this.state.searchedList) return;
		let newSearchedList = [...this.state.searchedList];

		this.props?.lotList.forEach(lot => {
			let index = lot.catalogueLot.lotIndex;
			newSearchedList[index - 1] = lot;
		});

		this.setState({
			searchedList: newSearchedList,
		});
	}

	setLotChangeTimeout() {
		if (this.state.lotChangeInProgress) return;
		this.setState({ lotChangeInProgress: true });
		this.lotChangeTimeout = setTimeout(
			() => this.setState({ lotChangeInProgress: false }),
			2000
		);
	}

	setNumberOfSlides() {
		let number = 7;
		switch (this.props?.layout) {
			case 1:
				number = 4;
				break;
			case 2:
				number = 2;
				break;
			default:
		}

		if (window.innerWidth <= 1200) number = 5;
		if (window.innerWidth <= 1000) number = 4;
		if (window.innerWidth <= 800) number = 2;
		if (window.innerWidth <= 600) number = 1;

		this.setState({ numberOfSlides: number });
	}

	onFilterChange(newFilter) {
		this.setState({ activeFilter: newFilter });
	}

	onSearchInputChange(value) {
		this.setState({ searchInput: value });
	}

	setFilteredLotList() {
		if (!this.props?.lotList?.length) return;
		if (
			this.props?.lotList?.length &&
			this.props?.lotList?.length < this.props?.lotCount
		)
			return;
		let lotList = [...this.props?.lotList];
		if (!lotList) return [];

		let filteredLotList = [];

		switch (this.state.activeFilter) {
			case 'wishlist':
				filteredLotList = lotList.filter(lot => lot?.interaction?.like);
				break;

			case 'autobid':
				filteredLotList = lotList.filter(lot => lot?.autoBidOpt);
				break;

			default:
				filteredLotList = lotList;
		}

		let searchedList = [];

		if (this.state.searchInput) {
			searchedList = filteredLotList.filter(lot => {
				let target = this.state.searchInput;
				let title = lot.catalogueLot.title.toLowerCase();
				let lotNo = lot.catalogueLot.lotNumber;

				if (
					title.indexOf(target) !== -1 ||
					lotNo.indexOf(target) !== -1
				)
					return lot;
				return false;
			});
		} else {
			searchedList = filteredLotList;
		}

		let actualSlides = searchedList?.length;
		let difference = this.state.numberOfSlides - actualSlides;

		if (this.state.numberOfSlides > actualSlides) {
			for (let i = 0; i < difference; i++) {
				searchedList.push(generateDummyLot());
			}
		}

		this.setState({ searchedList });
	}

	afterSlideChange = index => {
		this.setState({ currentSlide: index });
	};

	renderLot(lot, index) {
		if (!lot) return null;

		if (lot.dummyCard) {
			let props = {
				...lot,
				key: index,
				classes: 'lotsfeed',
				borderBottom: true,
			};
			return <LotCard {...props} />;
		}

		if (lot.loading) {
			let props = {
				...lot,
				key: index,
				classes: 'lotsfeed',
				borderBottom: true,
			};
			return <LotCard {...props} key={index} />;
		}

		let props = {
			key: index,
			classes: 'lotsfeed',
			lotID: lot?.catalogueLot?.lotID,
			borderBottom: true,
		};
		// if (lot.catalogueLot.lotIndex === this.props?.currentLotIndex)
		// 	props.isCurrentLot = true;
		return <LotCard {...props} />;
	}

	render() {
		let currentLotIndex = this.props?.currentLotIndex;
		const isBidder = window.API?.user?.userPerm?.includes('bidder');
		if (!currentLotIndex) {
			return (
				<div className={`lotsfeed ${this.state.layout}`}>
					<div
						className={`lotsfeed__browser ${this.state.layout} placeholder`}
					></div>
				</div>
			);
		}

		const slidesToScroll =
			this.state.numberOfSlides - 1 > 0
				? this.state.numberOfSlides - 1
				: 1;

		const settings = {
			dots: false,
			lazyLoad: true,
			infinite: false,
			speed: 500,
			slidesToShow: this.state.numberOfSlides,
			slidesToScroll,
			rows: 1,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />,
			afterChange: this.afterSlideChange,
		};

		return (
			<div
				className={`lotsfeed ${this.state.layout}`}
				data-testid="lotsfeed"
			>
				{this.state.showMask && (
					<div className={`transition-mask`}></div>
				)}
				<div
					className={`lotsfeed__browser__border bg-primary-color-light ${this.state.layout} top border-top-secondary-color-dark`}
				></div>
				<div
					className={`lotsfeed__browser__border bg-primary-color-light ${this.state.layout} right border-top-secondary-color-dark border-right-secondary-color-dark border-bottom-secondary-color-dark`}
				></div>
				<div
					className={`lotsfeed__browser__mask bg-primary-color-light ${this.state.layout} right`}
				></div>
				<div
					className={`lotsfeed__browser__border bg-primary-color-light ${this.state.layout} left border-top-secondary-color-dark border-left-secondary-color-dark border-bottom-secondary-color-dark`}
				></div>
				<div
					className={`lotsfeed__browser__mask bg-primary-color-light ${this.state.layout} left`}
				></div>

				{isBidder && (
					<Filter
						onFilterChange={this.onFilterChange}
						onSearchInputChange={this.onSearchInputChange}
					/>
				)}

				<Slider
					className={`lotsfeed__browser ${this.state.layout} ${
						this.state.showMask ? 'transitioning' : ''
					}`}
					ref={slider => (this.slider = slider)}
					{...settings}
				>
					{this.state.searchedList?.map((lot, index) =>
						this.renderLot(lot, index)
					)}
				</Slider>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentLotIndex: state.auction?.currentLot?.lotIndex,
		lotCount: state.auction?.lotCount,
		lotList: state.auction?.lotList,
		layout: state.bidder?.layout,
	};
};

export default connect(mapStateToProps)(LotsFeed);

const NextArrow = ({ currentSlide, slideCount, ...props }) => {
	props.className = props.className +=
		' bg-primary-color-light border-secondary-color-dark';
	return (
		<div {...props} style={{ display: 'flex' }}>
			<FontAwesomeIcon icon={faAngleRight} />
		</div>
	);
};

const PrevArrow = ({ currentSlide, slideCount, ...props }) => {
	props.className = props.className +=
		' bg-primary-color-light border-secondary-color-dark';
	return (
		<div {...props} style={{ display: 'flex' }} id="rewardId">
			<FontAwesomeIcon icon={faAngleLeft} />
		</div>
	);
};
