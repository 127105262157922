import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHomeAlt,
	faArrowLeft,
	faArrowRight,
} from '@fortawesome/pro-light-svg-icons';

import { useSearchFilterLots } from '../useSearchFilterLots';
import { useVirtualList } from '../useVirtualList';
import { Filter } from './Filter';
import LotCard from 'components/common/lotcard/lotcard';
import styles from './Timeline.module.scss';

const transitionTime = 350;

export const Timeline = ({ layout }) => {
	const {
		searchedFilteredList,
		searchValue,
		setSearchValue,
		filterValue,
		setFilterValue,
	} = useSearchFilterLots();

	const {
		setRange,
		currentFrame,
		nextFrame,
		prevFrame,
		transitionNext,
		transitionPrev,
		transitionOne,
		prevDisabled,
		nextDisabled,
		navBack,
		navForward,
		goToCurrentLot,
		showHome,
	} = useVirtualList(searchedFilteredList);

	useEffect(() => {
		setLayoutChanging(true);
		if (layout === 1) setRange(7);
		else if (layout === 2) setRange(4);
		else setRange(2);
		setTimeout(() => setLayoutChanging(false), transitionTime);
		// eslint-disable-next-line
	}, [layout]);

	const [layoutChanging, setLayoutChanging] = useState(false);
	const transitioning = transitionNext || transitionPrev;
	const renderNext = transitionNext || transitionOne;

	return (
		<div className={classNames(styles.timeline, styles[`layout${layout}`])}>
			{showHome && (
				<button className={styles.home} onClick={() => goToCurrentLot()}>
					<FontAwesomeIcon icon={faHomeAlt} />
				</button>
			)}
			<Filter
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
			/>
			<button
				className={styles.leftnav}
				disabled={prevDisabled}
				onClick={() => navBack()}
			>
				<FontAwesomeIcon icon={faArrowLeft} />
			</button>
			{!layoutChanging && (
				<div
					className={classNames(styles.track, {
						[styles.transitionNext]: transitionNext,
						[styles.transitionPrev]: transitionPrev,
						[styles.transitionOne]: transitionOne,
					})}
				>
					{transitionPrev && (
						<div
							className={classNames(
								styles.frame,
								styles[`framelayout${layout}`]
							)}
						>
							{prevFrame.map(lot => (
								<div
									key={lot.catalogueLot.lotID}
									className={classNames(styles.item)}
								>
									<LotCard
										lotID={lot.catalogueLot.lotID}
										transitioning={transitioning}
									/>
								</div>
							))}
						</div>
					)}
					<div
						className={classNames(styles.frame, styles[`framelayout${layout}`])}
					>
						{currentFrame.map(lot => (
							<div
								key={lot.catalogueLot.lotID}
								className={classNames(styles.item)}
							>
								<LotCard
									lotID={lot.catalogueLot.lotID}
									transitioning={transitioning}
								/>
							</div>
						))}
					</div>
					{renderNext && (
						<div
							className={classNames(
								styles.frame,
								styles[`framelayout${layout}`]
							)}
						>
							{nextFrame.map(lot => (
								<div
									key={lot.catalogueLot.lotID}
									className={classNames(styles.item)}
								>
									<LotCard
										lotID={lot.catalogueLot.lotID}
										transitioning={transitioning}
									/>
								</div>
							))}
						</div>
					)}
				</div>
			)}
			<button
				className={styles.rightnav}
				disabled={nextDisabled}
				onClick={() => navForward()}
			>
				<FontAwesomeIcon icon={faArrowRight} />
			</button>
		</div>
	);
};
