import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useIncrements from './useIncrements';
import sanitiseCurrency from 'utils/sanitiseCurrency';
import requestCatUpdate from 'api/requests/common/Req-CatUpdate';
import requestAutobid from 'api/requests/bidder/Req-Autobid';

const useAutobid = lotID => {
	const lotList = useSelector(state => state.auction?.lotList);
	const currencySymbol = useSelector(
		state => state.auction?.localisedData?.currencySymbol
	);
	const remainingSpendOpt = useSelector(
		state => state.auction?.remainingSpendOpt
	);
	const isCurrentLot =
		useSelector(state => state.auction?.currentLotDetailOpt?.currentLotID) ===
		lotID;
	const offerPrice = useSelector(
		state => state.auction?.currentOfferOpt?.offerPrice
	);
	const target = lotList.find(lot => lot.catalogueLot.lotID === lotID);
	const autoBidOpt = target?.autoBidOpt;
	const lowEst = target?.catalogueLot?.lowEst;
	const updated = target?.updated;
	const [limit, setLimit] = useState('');
	const lotState = target?.lotSaleDetail?.lotState;
	const lotLocked = lotState === 200 || lotState === 300;

	let initialState = 'open';
	if (lotLocked) initialState = 'lotClosed';
	else initialState = autoBidOpt ? 'closed' : 'open';

	const [autobidState, setAutobidState] = useState(initialState);
	const [currentIncrement, onIncrementClick] = useIncrements(limit, setLimit);
	const [info, setInfo] = useState(null);

	// TODO
	// Timeout - have a handler for timeouts to prevent button locking

	useEffect(() => {
		setAutobidState(autoBidOpt ? 'closed' : 'open');
	}, [autoBidOpt]);

	useEffect(() => {
		if (target) requestCatUpdate(target?.catalogueLot?.lotIndex, 1);
		// eslint-disable-next-line
	}, [lotID]);

	useEffect(() => {
		if (lotLocked) setAutobidState('lotClosed');
	}, [lotLocked]);

	useEffect(() => {
		updateStatus();
		// eslint-disable-next-line
	}, [lotID, autoBidOpt, updated]);

	useEffect(() => {
		if (!isCurrentLot) return;
		if (autoBidOpt && offerPrice > autoBidOpt) deleteAutobid();
		// eslint-disable-next-line
	}, [offerPrice]);

	const updateStatus = () => {
		if (lotLocked) {
			setLimit('');
			setAutobidState('lotClosed');
		} else if (autoBidOpt) {
			if (limit && limit !== autoBidOpt) showInfo('Adjusted to increments');
			setLimit(autoBidOpt);
			setAutobidState('closed');
		} else if (isCurrentLot) {
			setLimit(offerPrice + currentIncrement);
			setAutobidState('open');
		} else {
			// setLimit(target?.catalogueLot?.lowEst);
			setAutobidState('open');
		}
	};

	const resetLimit = () => {
		if (limit === autoBidOpt) return;
		setLimit(autoBidOpt || lowEst);
	};

	const onInputChange = event => {
		event.preventDefault();
		event.stopPropagation();
		const value = sanitiseCurrency(event.target.value, currencySymbol);
		// console.log('**** ONINPUTCHANGE', value);
		setLimit(value);
	};

	const showInfo = msg => {
		setInfo(msg);
		setTimeout(() => setInfo(null), 1200);
	};

	const onSubmit = event => {
		event.preventDefault();
		event.stopPropagation();
		if (!limit || limit <= 0) {
			showInfo('PLEASE SUBMIT A VALUE');
			return;
		}

		if (autoBidOpt && autoBidOpt === limit) return;

		if (!isCurrentLot && limit < target.catalogueLot.lowEst) {
			showInfo('LESS THAN LOW ESTIMATE');
			return;
		}

		if (isCurrentLot && limit < offerPrice) {
			showInfo('LESS THAN ASKING PRICE');
			return;
		}

		if (limit > remainingSpendOpt) {
			showInfo('AUTOBID EXCEEDS PADDLE LIMIT');
			return;
		}

		return sendAutobid();
	};

	const onSubmitIncrement = event => {
		event.preventDefault();
		event.stopPropagation();
		setConfirming();
		requestAutobid(target.catalogueLot.lotID, limit + currentIncrement);
	};

	const setConfirming = () => {
		setAutobidState('confirming');
		setTimeout(() => {
			if (autobidState === 'confirming') updateStatus();
		}, 5000);
	};

	const sendAutobid = () => {
		setConfirming();
		return requestAutobid(target.catalogueLot.lotID, limit)
			.then(response => {
				console.debug(response);
				return response;
			})
			.catch(error => {
				console.debug(error);
				return error;
			});
	};

	const onDelete = event => {
		event.preventDefault();
		event.stopPropagation();
		setAutobidState('confirming');
		return deleteAutobid();
	};

	const deleteAutobid = () => {
		return requestAutobid(target.catalogueLot.lotID, null)
			.then(response => {
				if (response?.data?.result > 0) setAutobidState('closed');
				return response;
			})
			.catch(error => {
				setAutobidState('closed');
				return error;
			});
	};

	if (!lotID) return {};
	// console.log('*****', lotID, limit);
	return {
		limit,
		autoBidOpt,
		onInputChange,
		currentIncrement,
		onIncrementClick,
		onSubmit,
		onSubmitIncrement,
		onDelete,
		autobidState,
		info,
		offerPrice,
		resetLimit,
		lowEst,
	};
};

export default useAutobid;
