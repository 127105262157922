// import { useSelector } from 'react-redux';
import useBidsFeed from 'hooks/useBidsFeed';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import useCurrentLot from 'hooks/useCurrentLot';
import Bid from './bid';
import Message from './message';
import 'styles/clerkconsole/bidhistory/bidhistory.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSignalStream } from '@fortawesome/pro-light-svg-icons';

const BidHistory = () => {
	const { getFormattedCurrency } = useCurrency();
	const config = useConfig();
	const { catalogueLot } = useCurrentLot();
	const { displayList } = useBidsFeed();
	const { lotID, lowEst, highEst } = catalogueLot || {};
	// const curLotViewersOpt = useSelector(
	// 	state => state.auction?.clerkDataOpt?.curLotViewersOpt
	// );

	const renderItem = (data, index) => {
		if (data.offerID) {
			return <Bid {...data} key={index} />;
		}
		return <Message {...data} key={index} />;
	};

	return (
		<div className="bidhistory">
			<div className="bidhistory__header">
				<div className="title large-24-font-size">
					{config?.bidHistory}
				</div>
				{/* <div className="connected">
					<FontAwesomeIcon
						className="connected__icon accent-color-1"
						icon={faSignalStream}
					/>
					{`${config?.connected}: ${curLotViewersOpt || 0}`}
				</div> */}
			</div>
			<div className="bidhistory__estimated bg-primary-color-light regular-18-font-size">
				{lotID ? (
					<span>
						{config?.estimate} {getFormattedCurrency(lowEst)} -{' '}
						{getFormattedCurrency(highEst)}
					</span>
				) : (
					<span>{config?.noCurrentLotSet}</span>
				)}
			</div>
			{displayList && (
				<div className="bids">
					{displayList.map((data, index) => renderItem(data, index))}
				</div>
			)}
		</div>
	);
};

export default BidHistory;
