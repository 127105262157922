import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const transitionTime = 550;

export const useVirtualList = (
	searchedFilteredList,
	initialRange = 7,
	autoChange = true
) => {
	const auction = useSelector(state => state.auction);
	const { currentLotDetailOpt } = auction || {};
	const { currentLotID } = currentLotDetailOpt || {};
	const [focusID, setFocusID] = useState(currentLotID || '');
	const [navToCurrentLot, setNavToCurrentLot] = useState(false);
	const [navToStart, setNavToStart] = useState(false);
	const [navToEnd, setNavToEnd] = useState(false);

	const getIndexOfLotID = lotID =>
		searchedFilteredList.findIndex(lot => lot.catalogueLot.lotID === lotID);

	const [range, setRange] = useState(initialRange);
	const [transitionPrev, setTransitionPrev] = useState(false);
	const [transitionNext, setTransitionNext] = useState(false);
	const [transitionOne, setTransitionNextOne] = useState(false);

	const targetIndex = getIndexOfLotID(focusID);
	const currentIndex = targetIndex === -1 ? 0 : targetIndex;

	const prevDisabled = currentIndex <= 0 || transitionPrev || transitionNext;

	const nextDisabled =
		currentIndex + range >= searchedFilteredList.length ||
		transitionPrev ||
		transitionNext;

	const navBack = () => {
		let newIndex = currentIndex - range;
		if (newIndex < 0) newIndex = 0;
		changeFocus(newIndex);
	};

	const navForward = () => {
		let newIndex = currentIndex + range;
		if (newIndex > searchedFilteredList.length - 1)
			newIndex = searchedFilteredList.length - 1;
		changeFocus(newIndex);
	};

	const navStart = () => {
		setNavToStart(true);
		changeFocus(0);
	};
	const navEnd = () => {
		setNavToEnd(true);
		changeFocus(searchedFilteredList.length - range);
	};

	const goToNextLot = newIndex => {
		setTransitionNextOne(true);
		setTimeout(() => {
			setFocusID(searchedFilteredList[newIndex].catalogueLot.lotID);
			setTransitionNextOne(false);
		}, transitionTime);
	};

	const goToCurrentLot = () => {
		setNavToCurrentLot(true);
		changeFocus(getIndexOfLotID(currentLotID));
	};

	const changeFocus = newIndex => {
		let transitionType;
		if (newIndex > currentIndex) transitionType = setTransitionNext;
		else transitionType = setTransitionPrev;

		transitionType(true);
		setTimeout(() => {
			setNavToCurrentLot(false);
			setNavToStart(false);
			setNavToEnd(false);
			setFocusID(searchedFilteredList[newIndex].catalogueLot.lotID);
			transitionType(false);
		}, transitionTime);
	};

	const getCurrentFrame = () => {
		if (!searchedFilteredList.length) return [];
		return searchedFilteredList.slice(currentIndex, currentIndex + range);
	};

	const getPrevFrame = () => {
		if (!searchedFilteredList.length) return [];
		if (navToCurrentLot) return getCurrentLotFrame();
		if (navToStart) return getFirstFrame();
		if (currentIndex - range <= 0) return searchedFilteredList.slice(0, range);
		else
			return searchedFilteredList.slice(
				currentIndex - range,
				currentIndex - range + range
			);
	};

	const getNextFrame = () => {
		if (!searchedFilteredList.length) return [];
		if (navToCurrentLot) return getCurrentLotFrame();
		if (navToEnd) return getLastFrame();
		return searchedFilteredList.slice(
			currentIndex + range,
			currentIndex + range + range
		);
	};

	const getCurrentLotFrame = () => {
		if (!searchedFilteredList.length) return [];
		const target = getIndexOfLotID(currentLotID);
		return searchedFilteredList.slice(target, target + range);
	};

	const getFirstFrame = () => searchedFilteredList.slice(0, range);

	const getLastFrame = () => {
		const startIndex = searchedFilteredList.length - range;
		console.log(startIndex);
		return searchedFilteredList.slice(startIndex, startIndex + range);
	};

	const currentFrame = getCurrentFrame();
	const prevFrame = getPrevFrame();
	const nextFrame = getNextFrame();

	const showHome =
		searchedFilteredList.some(lot => lot.catalogueLot.lotID === currentLotID) &&
		!currentFrame.some(lot => lot.catalogueLot.lotID === currentLotID);

	useEffect(() => {
		if (!searchedFilteredList.length) {
			setFocusID(currentLotID);
			return;
		}
		if (autoChange && !showHome) goToNextLot(getIndexOfLotID(currentLotID));
		// eslint-disable-next-line
	}, [currentLotID]);

	return {
		currentIndex,
		range,
		setRange,
		currentFrame,
		prevFrame,
		nextFrame,
		transitionPrev,
		transitionNext,
		transitionOne,
		prevDisabled,
		nextDisabled,
		navBack,
		navForward,
		goToCurrentLot,
		showHome,
		navStart,
		navEnd,
	};
};
