import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_SETCLIENT: 'Req-SetClient'
export default function requestSetClient(userTokenID, browserOpt, isMobileOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-SetClient',
		userTokenID,
		browserOpt,
		isMobileOpt,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	// console.debug('Req-SetClient success', data);
};

const handleFailure = data => {
	console.debug('Req-SetClient failure', data);
};
