export const UPDATE_AUCTION_DETAILS = 'UPDATE_AUCTION_DETAILS';
export const UPDATE_SINGLE_AUCTION_DETAILS = 'UPDATE_SINGLE_AUCTION_DETAILS';
export const UPDATE_AUCTION_STATE = 'UPDATE_AUCTION_STATE';
export const UPDATE_BID = 'UPDATE_BID';
export const BID_NOTIFY = 'BID_NOTIFY';
export const UPDATE_CAT = 'UPDATE_CAT';
export const UPDATE_CLERK_ASKING_PRICE = 'UPDATE_CLERK_ASKING_PRICE';
export const RESET_CLERK_ASKING_PRICE = 'RESET_CLERK_ASKING_PRICE';
export const SET_BID_INCREMENT = 'SET_BID_INCREMENT';
export const DEBUG_MODAL_OPEN = 'DEBUG_MODAL_OPEN';
export const DEBUG_MODAL_CLOSE = 'DEBUG_MODAL_CLOSE';
export const INFO_MESSAGE = 'INFO_MESSAGE';
export const UPDATE_INTERACTIONS = 'UPDATE_INTERACTIONS';
export const RESET = 'RESET';
export const UPDATE_STREAMPIN = 'UPDATE_STREAMPIN';
export const SET_PING_RESPONSE = 'SET_PING_RESPONSE';

export default class AuctionActions {
	static reset() {
		return function (dispatch) {
			return dispatch({
				type: RESET,
			});
		};
	}

	static updateAuctionDetail(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTION_DETAILS,
				data,
			});
		};
	}

	static updateSingleAuctionDetail(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_SINGLE_AUCTION_DETAILS,
				data,
			});
		};
	}

	static updateAuctionState(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTION_STATE,
				data,
			});
		};
	}

	static updateBid(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_BID,
				data,
			});
		};
	}

	static bidNotify(data) {
		return function (dispatch) {
			return dispatch({
				type: BID_NOTIFY,
				data,
			});
		};
	}

	static updateCat(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_CAT,
				data,
			});
		};
	}

	static updateClerkAskingPrice(value) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_CLERK_ASKING_PRICE,
				value,
			});
		};
	}

	static resetClerkAskingPrice() {
		return function (dispatch) {
			return dispatch({
				type: RESET_CLERK_ASKING_PRICE,
			});
		};
	}

	static setBidIncrement(data) {
		return function (dispatch) {
			return dispatch({
				type: SET_BID_INCREMENT,
				data,
			});
		};
	}

	static debugModalOpen() {
		return function (dispatch) {
			return dispatch({
				type: DEBUG_MODAL_OPEN,
			});
		};
	}

	static debugModalClose() {
		return function (dispatch) {
			return dispatch({
				type: DEBUG_MODAL_CLOSE,
			});
		};
	}

	static infoMessage(data) {
		return function (dispatch) {
			return dispatch({
				type: INFO_MESSAGE,
				data,
			});
		};
	}

	static updateInteractions(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_INTERACTIONS,
				data,
			});
		};
	}

	static updateStreamPin(pin) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_STREAMPIN,
				pin,
			});
		};
	}

	static setPingResponse(timestamp) {
		return function (dispatch) {
			return dispatch({
				type: SET_PING_RESPONSE,
				timestamp,
			});
		};
	}
}
