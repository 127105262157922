export const UPDATE_CLERK_ASKING_PRICE = 'UPDATE_CLERK_ASKING_PRICE';
export const UPDATE_SALEOUTCOME = 'UPDATE_SALEOUTCOME';
export const UPDATE_STREAM_URLS = 'UPDATE_STREAM_URLS';
export const RESET = 'RESET';
export const UPDATE_CUST_ASKINGPRICE_SUCCESS =
	'UPDATE_CUST_ASKINGPRICE_SUCCESS';
export const ON_KEYDOWN_EVENT = 'ON_KEYDOWN_EVENT';
export const BID_SELECTOR_REQ = 'BID_SELECTOR_REQ';
export const SALE_CONTROL_REQ_INFLIGHT = 'SALE_CONTROL_REQ_INFLIGHT';
export const UPDATE_BID_ACTIONS = 'UPDATE_BID_ACTIONS';
export const SHOW_ASKING_PRICE_ERROR = 'SHOW_ASKING_PRICE_ERROR';
export const SET_PORT_MESSAGE = 'SET_PORT_MESSAGE';
export const SET_SALENOTE_INPUT = 'SET_SALENOTE_INPUT';
export const SET_SALENOTE_WARNING = 'SET_SALENOTE_WARNING';
export const WSS_LIVE = 'WSS_LIVE';

export default class ClerkActions {
	static reset() {
		return function (dispatch) {
			return dispatch({
				type: RESET,
			});
		};
	}

	static updateSaleOutcome(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_SALEOUTCOME,
				data,
			});
		};
	}

	static updateStreamURLs(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_STREAM_URLS,
				data,
			});
		};
	}

	static onKeyDownEvent(event) {
		return function (dispatch) {
			return dispatch({
				type: ON_KEYDOWN_EVENT,
				event,
			});
		};
	}

	static setBidSelectorReq(data) {
		return function (dispatch) {
			return dispatch({
				type: BID_SELECTOR_REQ,
				data,
			});
		};
	}

	static setSaleControlReqInFlight(bool) {
		return function (dispatch) {
			return dispatch({
				type: SALE_CONTROL_REQ_INFLIGHT,
				bool,
			});
		};
	}

	static updateBidActions(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_BID_ACTIONS,
				data,
			});
		};
	}

	static setShowAskingPriceError(bool) {
		return function (dispatch) {
			return dispatch({
				type: SHOW_ASKING_PRICE_ERROR,
				bool,
			});
		};
	}

	static setPortMessage(data) {
		return function (dispatch) {
			return dispatch({
				type: SET_PORT_MESSAGE,
				data,
			});
		};
	}

	static setSaleNoteInput(data) {
		return function (dispatch) {
			return dispatch({
				type: SET_SALENOTE_INPUT,
				data,
			});
		};
	}

	static setSaleNoteWarning(bool) {
		return function (dispatch) {
			return dispatch({
				type: SET_SALENOTE_WARNING,
				bool,
			});
		};
	}

	static setWssLive(bool) {
		return function (dispatch) {
			return dispatch({
				type: WSS_LIVE,
				bool,
			});
		};
	}
}
