import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useButtons from './useButtons';
import useSetButton from './useSetButton';
import useCurrency from 'hooks/useCurrency';
import useCurrentLot from 'hooks/useCurrentLot';
import IncrementButton from './IncrementButtons/IncrementButton';
import Input from './Input/Input';
import SetButton from './SetButton/SetButton';
import 'styles/clerkconsole/managementpanel/setincrements.scss';

const SetIncrements = () => {
	const increments = useSelector(state => {
		const { lowIncrementOpt, incrementOpt, highIncrementOpt } =
			state?.auction?.clerkDataOpt || {};
		return { lowIncrementOpt, incrementOpt, highIncrementOpt };
	});

	const [input, setInput] = useState(0);
	const { buttons, setButtonIncrements, setActiveButton } = useButtons({
		...increments,
	});
	const setButtonHook = useSetButton(input, setActiveButton);
	const { sanitiseCurrency } = useCurrency();
	const { lotID } = useCurrentLot()?.catalogueLot || {};
	const { offerID } =
		useSelector(state => state.auction?.currentOfferOpt) || {};

	// eslint-disable-next-line
	useEffect(() => setButtonIncrements({ ...increments }), [offerID]);

	useEffect(() => {
		setButtonIncrements({ ...increments });
		setInput(0);
		// eslint-disable-next-line
	}, [lotID]);

	const onKeyPress = event => {
		event.stopPropagation();
		if (event.charCode !== 13) return;
		setButtonHook.onSetClick();
	};

	const onInputChange = event => {
		event.preventDefault();
		let sanitisedValue = sanitiseCurrency(event.currentTarget.value);
		let input = Number(sanitisedValue);
		if (isNaN(Number(input))) return;

		setInput(input);
	};

	return (
		<div className="setincrements bg-secondary-color-light">
			<div className="setincrements__controls">
				<div className="setincrements__controls__buttons">
					{buttons &&
						buttons?.map((button, index) => (
							<IncrementButton
								{...button}
								key={index}
								setActiveButton={setActiveButton}
							/>
						))}
				</div>
				<Input
					input={input}
					onInputChange={onInputChange}
					onKeyPress={onKeyPress}
				/>
				<SetButton
					input={input}
					setActiveButton={setActiveButton}
					{...setButtonHook}
				/>
			</div>
		</div>
	);
};

export default SetIncrements;
