import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import requestAuctionStaticDetail from '../common/Req-AuctionStaticDetail/Req-AuctionStaticDetail';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import cookieHandler from 'api/cookieHandler';

//REQ_UPDATEAUCTION: 'Req-UpdateAuction'
export default function requestUpdateAuction(data) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-UpdateAuction',
		userTokenID: window.API?.user?.userToken,
		...data,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}

			handleSuccess(data);
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	console.debug('Req-UpdateAuction success', data);
	store.dispatch(AdminPageActions.reset());
	store.dispatch(AdminPageActions.updateAuctionDetail(data));
	let currentAuctionID = cookieHandler.getAuctionID();
	if (currentAuctionID !== 'null') {
		store.dispatch(AdminPageActions.updateAddAuctionLocation(2));
		requestAuctionStaticDetail(true, currentAuctionID, false);
		return;
	}

	let reissueOpt = {
		auctionHouseIDOpt: data.auctionHouseID,
		auctionIDOpt: data.auctionID,
	};

	window.API.validationCallBack = function () {
		window.API.validationCallBack = null;
		this.pushHistory('/adminpages/edit');
		store.dispatch(AdminPageActions.updateAddAuctionLocation(2));
	};

	window.API.tokenValidation(window.API.user.userToken, reissueOpt);
};

const handleFailure = data => {
	store.dispatch(AdminPageActions.setConfirmationError(data.detail));
	console.debug('Req-UpdateAuction failure', data);
};
