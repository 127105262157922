import { connect } from 'react-redux';
import ImageSlider from './imageSlider';

const CatalogueImage = props => <ImageSlider {...props} />;

const mapStateToProps = state => {
	const catIndex = state.bidder?.catIndex;
	const lotList = state.auction?.lotList;
	const displayLot = lotList?.find(
		lot => lot.catalogueLot.lotIndex === catIndex
	);

	return {
		lotID: displayLot.catalogueLot.lotID,
		absImg: displayLot?.catalogueLot?.absImg || [],
	};
};

export default connect(mapStateToProps)(CatalogueImage);
