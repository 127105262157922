import { useSelector } from 'react-redux';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import useCurrentLot from 'hooks/useCurrentLot';
import { Link } from 'react-router-dom';
import { Interweave } from 'interweave';
import LazyImage from 'components/common/lazyimage';
import styles from './fullscreen.module.scss';

const Fullscreen = () => {
	const config = useConfig();
	const { getFormattedCurrency, currencySymbol } = useCurrency();

	const { catalogueLot, lotSaleDetail } = useCurrentLot();
	const { absImg, lotNumber, artist, title, lowEst, highEst } =
		catalogueLot || {};
	const { lotState } = lotSaleDetail || {};
	const lotPassed = lotState === 200;
	const lotSold = lotState === 300;
	const lotLocked = lotPassed || lotSold;
	const currentBid = useSelector(
		state => state.auction?.offersWithBids?.filter(bid => !bid.isVoid)[0]
	);
	const clerkAskingPrice = useSelector(
		state => state.auction?.clerkAskingPrice
	);
	const { offerPrice, platformName } = currentBid || {};
	const auctionHouseName = useSelector(
		state => state?.auction?.auctionHouseName
	);

	return (
		<div className={styles.fullscreen}>
			<Link to="/clerkconsole" style={{ textDecoration: 'none' }}>
				<div className={styles.header}>
					<div className={styles.ahLogo}>{auctionHouseName}</div>
				</div>
			</Link>

			<div className={styles.body}>
				<div className={styles.col_1}>
					{lotNumber && (
						<div className={styles.lotNumber}>
							{config?.lot} {lotNumber}
						</div>
					)}
					<LazyImage
						className={styles.mainImage}
						url={absImg && absImg[0]}
					/>
				</div>
				<div className={styles.col_2}>
					{artist && (
						<div className={styles.lotTitle}>
							<Interweave content={artist} noHtml />
						</div>
					)}
					{title && (
						<div className={styles.lotTitle}>
							<Interweave content={title} noHtml />
						</div>
					)}

					<div className={styles.estimate}>
						{lotNumber && (
							<span>
								{config?.estimate}{' '}
								{getFormattedCurrency(lowEst)} -{' '}
								{getFormattedCurrency(highEst)}
							</span>
						)}
					</div>

					<div className={styles.value}>
						<div className={styles.hammerWrapper}>
							<div className={styles.hammer}>
								{config?.currentBid}:
							</div>
							{platformName && (
								<div className={styles.bidType}>
									({platformName})
								</div>
							)}
						</div>
						<div className={styles.currentBid}>
							{getFormattedCurrency(offerPrice)}
						</div>
					</div>

					{clerkAskingPrice && (
						<div className={styles.value}>
							<div className={styles.hammer}>
								{config?.askingPrice}:
							</div>
							<div className={styles.askingPrice}>
								{getFormattedCurrency(clerkAskingPrice)}
							</div>
						</div>
					)}

					{!clerkAskingPrice && lotLocked && (
						<div className={styles.placeholder}>
							{config?.lotClosed}
						</div>
					)}

					{!clerkAskingPrice && !lotLocked && (
						<div className={styles.placeholder}>
							{config?.startSoon}
						</div>
					)}

					<div className={styles.sblogo}>Powered by StreamBid</div>
				</div>
			</div>
		</div>
	);
};

export default Fullscreen;
