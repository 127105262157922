import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleLeft,
	faAngleRight,
	faSearch,
} from '@fortawesome/pro-regular-svg-icons';

const Controls = props => {
	const { firstRowIndex, lastRowIndex, totalAuctions, config } = props;

	const [paginationText, setPaginationText] = useState(null);

	useEffect(() => {
		if (firstRowIndex === 0 && lastRowIndex === 0) {
			setPaginationText(null);
			return;
		}

		setPaginationText(
			`${firstRowIndex + 1} - ${lastRowIndex} ${props?.config?.of} ${
				totalAuctions || '...'
			}`
		);
		// eslint-disable-next-line
	}, [firstRowIndex, lastRowIndex, totalAuctions, config]);

	return (
		<div className="auctionlist__row__2 bg-secondary-color-light">
			<div
				className="auctionlist__nav"
				style={{ visibility: `${paginationText ? '' : 'hidden'}` }}
			>
				<div className="auctionlist__pagination">{paginationText}</div>
				<button
					className="auctionlist__nav__btn left bg-primary-color-light"
					onClick={() => props.onPaginationChange(false)}
				>
					<FontAwesomeIcon
						className="auctionlist__nav__icon"
						icon={faAngleLeft}
					/>
				</button>
				<button
					className="auctionlist__nav__btn right bg-primary-color-light"
					onClick={() => props.onPaginationChange(true)}
				>
					<FontAwesomeIcon
						className="auctionlist__nav__icon"
						icon={faAngleRight}
					/>
				</button>
				<select
					name="display-count"
					id="display-count"
					className="auctionlist__range"
					onChange={event => props.onPerPageChange(event)}
					defaultValue="100"
				>
					<option value="10">{`10 ${props?.config?.perPage}`}</option>
					<option value="20">{`20 ${props?.config?.perPage}`}</option>
					<option value="50">{`50 ${props?.config?.perPage}`}</option>
					<option value="100">
						{`100 ${props?.config?.perPage}`}
					</option>
					<option value="150">{`150 ${props?.config?.perPage}`}</option>
					<option value="200">{`200 ${props?.config?.perPage}`}</option>
				</select>
				<div className="auctionlist__archive">
					<label>{props?.config?.showArchivedAuctions}</label>
					<input
						checked={props?.showArchivedFilter}
						onChange={props?.onArchiveToggle}
						type="checkbox"
					/>
				</div>
			</div>
			<div className="auctionlist__search">
				<input
					className="auctionlist__input"
					placeholder={props?.config?.searchTitles}
					onChange={event => props.onFilterInputChange(event)}
					data-testid="auctions-input"
				/>
				<button className="auctionlist__input__btn bg-primary-color-light">
					<FontAwesomeIcon
						className="auctionlist__input__icon"
						icon={faSearch}
					/>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Controls);
