export const SET_SHOWFLAGS = 'SET_SHOWFLAGS';

export default class ShowFlagsActions {
	static setShowFlags(num) {
		return function (dispatch) {
			return dispatch({
				type: SET_SHOWFLAGS,
				num,
			});
		};
	}
}
