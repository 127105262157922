import { useState, useEffect } from 'react';
import classNames from 'classnames';

import useCurrency from 'hooks/useCurrency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faWifi,
	faGavel,
	faMapMarkerAlt,
	faPhone,
} from '@fortawesome/pro-light-svg-icons';
import styles from './bid.module.scss';

export const Bid = props => {
	const [animateEntry, setAnimateEntry] = useState(false);
	const { getFormattedCurrency } = useCurrency();
	useEffect(() => {
		let animationTimeout = setTimeout(() => {
			setAnimateEntry(false);
		}, 500);

		return () => {
			clearTimeout(animationTimeout);
			animationTimeout = null;
		};
	}, []);

	useEffect(() => {
		if (props.index !== 0 || !props.animate) return;

		setAnimateEntry(true);
		let animationTimeout = setTimeout(() => {
			setAnimateEntry(false);
		}, 1000);

		return () => {
			clearTimeout(animationTimeout);
			animationTimeout = null;
		};
		// eslint-disable-next-line
	}, [props.data.offerID]);

	const getIcon = () => {
		if (props.data.isAutoBid)
			return (
				<i
					className={
						'fak fa-autobid platform-icon-number-' +
						props.data.platformIconNumber
					}
				></i>
			);

		let icon = faGavel;
		switch (props.data.platformIconNumber) {
			case 0:
				icon = faGavel;
				break;
			case 10:
				icon = faMapMarkerAlt;
				break;
			case 50:
				icon = faWifi;
				break;
			case 60:
				icon = faPhone;
				break;
			default:
		}

		return <FontAwesomeIcon icon={icon} />;
	};

	const getName = () => {
		const paddleNum = props.data?.paddleNum ? `(${props.data?.paddleNum})` : '';
		if (props.data.isVoid) return props?.config?.voided;

		if (props.data.isYou)
			return (
				<span>
					<span className={styles.paddleNum}>{paddleNum}</span>{' '}
					{props?.config?.you}
				</span>
			);

		let name = '';

		switch (props?.data?.platformName) {
			case 'Stream Bid':
				name = props?.config?.platformName;
				break;
			case 'Floor Bid':
				name = props?.config?.floorBid;
				break;
			case 'Phone Bid':
				name = props?.config?.phoneBid;
				break;
			case 'Other Online':
				name = props?.config?.onlineBid;
				break;
			default:
				name = props?.data?.platformName;
		}

		return (
			<span>
				<span className={styles.paddleNum}>{paddleNum}</span> {name}
			</span>
		);
	};

	const { isYou, isVoid, isStreamBid } = props?.data || {};

	return (
		<div
			className={classNames(styles.container, {
				[styles.streambid]: isStreamBid,
			})}
			data-testid="bid"
		>
			<div
				className={classNames(styles.feedItem, {
					[styles.deleted]: isVoid,
					[styles.self]: isYou,
					[styles.animateEntry]: props?.animate && !isYou,
					[styles.animateEntrySelf]: props?.animate && isYou,
				})}
			>
				{getIcon()}
				<div className={styles.description}>
					{getName()} -{' '}
					<span className="value">
						{getFormattedCurrency(props.data.offerPrice)}
					</span>
				</div>
			</div>
		</div>
	);
};

export default Bid;
