export const UPDATE_ADMINPAGES_ADDAUCTION_LOCATION =
	'UPDATE_ADMINPAGES_ADDAUCTION_LOCATION';
export const UPDATE_AUCTION__DETAIL = 'UPDATE_AUCTION__DETAIL';
export const UPDATE_AUCTION_INCREMENTS = 'UPDATE_AUCTION_INCREMENTS';
export const RESET_USERS = 'RESET_USERS';
export const UPDATE_BIDDERS = 'UPDATE_BIDDERS';
export const RESET_BIDDERS = 'RESET_BIDDERS';
export const UPDATE_CLERKS = 'UPDATE_CLERKS';
export const UPDATE_ADMINS = 'UPDATE_ADMINS';
export const RESET_STAFF = 'RESET_STAFF';
export const UPDATE_USER = 'UPDATE_USER';
export const EDITING_USER = 'EDITING_USER';
export const UPDATE_CSV_UPLOAD = 'UPDATE_CSV_UPLOAD';
export const UPDATE_SALEOUTCOME = 'UPDATE_SALEOUTCOME';
export const RESET = 'RESET';
export const UPDATE_DEFAULTSPROTOCOL = 'UPDATE_DEFAULTSPROTOCOL';
export const UPDATE_WITHDRAWN_LOT = 'UPDATE_WITHDRAWN_LOT';
export const WITHDRAW_LOT_COMPLETE = 'WITHDRAW_LOT_COMPLETE';
export const UPDATE_AUCTION_RUNSTATE = 'UPDATE_AUCTION_RUNSTATE';
export const SET_CONFIRMATION_ERROR = 'SET_CONFIRMATION_ERROR';
export const UPDATE_LOT_STATUS = 'UPDATE_LOT_STATUS';
export const SET_PASSBIDS = 'SET_PASSBIDS';
export const SET_USER_MODAL = 'SET_USER_MODAL';

export default class AdminPageActions {
	static reset() {
		return function (dispatch) {
			return dispatch({
				type: RESET,
			});
		};
	}

	static updateAddAuctionLocation(index) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_ADMINPAGES_ADDAUCTION_LOCATION,
				index,
			});
		};
	}

	static updateAuctionDetail(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTION__DETAIL,
				data,
			});
		};
	}

	static updateAuctionIncrements(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTION_INCREMENTS,
				data,
			});
		};
	}

	static resetUsers(data) {
		return function (dispatch) {
			return dispatch({
				type: RESET_USERS,
			});
		};
	}

	static updateBidders(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_BIDDERS,
				data,
			});
		};
	}

	static resetBidders() {
		return function (dispatch) {
			return dispatch({
				type: RESET_BIDDERS,
			});
		};
	}

	static updateClerks(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_CLERKS,
				data,
			});
		};
	}

	static updateAdmins(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_ADMINS,
				data,
			});
		};
	}

	static resetStaff() {
		return function (dispatch) {
			return dispatch({
				type: RESET_STAFF,
			});
		};
	}

	static updateUser(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_USER,
				data,
			});
		};
	}

	static editingUser(userTokenID) {
		return function (dispatch) {
			return dispatch({
				type: EDITING_USER,
				userTokenID,
			});
		};
	}

	static updateCSVUpload(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_CSV_UPLOAD,
				data,
			});
		};
	}

	static updateSaleOutcome(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_SALEOUTCOME,
				data,
			});
		};
	}

	static updateDefaultsProtocol(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_DEFAULTSPROTOCOL,
				data,
			});
		};
	}

	static updateWithdrawnLot(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_WITHDRAWN_LOT,
				data,
			});
		};
	}

	static updateWithdrawLotComplete(bool) {
		return function (dispatch) {
			return dispatch({
				type: WITHDRAW_LOT_COMPLETE,
				bool,
			});
		};
	}

	static updateAuctionRunState(code) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTION_RUNSTATE,
				code,
			});
		};
	}

	static setConfirmationError(data) {
		return function (dispatch) {
			return dispatch({
				type: SET_CONFIRMATION_ERROR,
				data,
			});
		};
	}

	static updateLotStatus(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_LOT_STATUS,
				data,
			});
		};
	}

	static setPassBids(data) {
		return function (dispatch) {
			return dispatch({
				type: SET_PASSBIDS,
				data,
			});
		};
	}

	static setUserModal(data) {
		return function (dispatch) {
			return dispatch({
				type: SET_USER_MODAL,
				data,
			});
		};
	}
}
