import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import App from 'app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from 'store/store';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,

  document.getElementById('root')
);


