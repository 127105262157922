import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLotIndex from 'hooks/useLotIndex';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import CatalogueImage from '../ImageSlider/catalogueImage';
import PassedOffer from './PassedOffer/passedOffer';
import CurrentLot from './CurrentLot/CurrentLot';
import AutobidFuture from './autobidFuture/AutobidFuture';
import { Interweave } from 'interweave';
import 'styles/bidderinterface/biddermobile/mobilelotdetail/mobilelotdetail.scss';

const LotDetail = () => {
	const { getFormattedCurrency } = useCurrency();
	const config = useConfig();
	const useViewerInterface = useSelector(
		state => state.bidder?.useViewerInterface
	);
	const lotIndex = useSelector(state => state.bidder?.catIndex);
	const { catalogueLot, lotSaleDetail, isCurrentLot } = useLotIndex(lotIndex);
	const { lotNumber, title, artist, description, lowEst, highEst } =
		catalogueLot || {};
	const { lotState, displayAs, saleResultOpt } = lotSaleDetail || {};
	const { salePrice } = saleResultOpt || {};

	const itemWon = displayAs === 4200;
	const itemSold = lotState === 200;
	const itemPassed = lotState === 300;
	const itemOpen = !itemWon && !itemSold && !itemPassed && !isCurrentLot;
	const noSwipePanel =
		itemWon || itemSold || isCurrentLot || useViewerInterface;

	useEffect(() => {
		store.dispatch(BidderActions.setAutobidTarget(lotIndex));
	}, [lotIndex]);

	const getValue = () => {
		if (itemWon) {
			return (
				<span className="admin-accent-1">{`${
					config?.itemWon
				} - ${getFormattedCurrency(salePrice)}`}</span>
			);
		}

		if (itemSold) {
			return (
				<span className="wishlist-color">{`${
					config?.sold
				} - ${getFormattedCurrency(salePrice || 0)}`}</span>
			);
		}

		if (itemPassed) {
			return (
				<span>
					{`${config?.estimate}. ${getFormattedCurrency(
						lowEst
					)} - ${getFormattedCurrency(highEst)} | ${config?.passed}`}
				</span>
			);
		}

		return (
			<span>{`${config?.estimate}. ${getFormattedCurrency(
				lowEst
			)} - ${getFormattedCurrency(highEst)}`}</span>
		);
	};

	const renderSwipePanel = () => {
		if (itemPassed) return <PassedOffer />;
		if (isCurrentLot) return <CurrentLot />;
		if (itemOpen) return <AutobidFuture />;
	};

	const classes = `mobilelotdetail ${noSwipePanel ? 'full' : ''}`;
	if (!lotNumber) return <div className={classes} />;

	return (
		<div className={classes}>
			<CatalogueImage />

			<div className="mobilelotdetail__info">
				<div className="mobilelotdetail__estimate">
					{`${config?.lot} ${lotNumber} | `}
					{getValue()}
				</div>
				{artist && (
					<div className="mobilelotdetail__title">
						<Interweave content={artist} />
					</div>
				)}
				<div className="mobilelotdetail__title">
					<Interweave content={title} />
				</div>
				<div className="mobilelotdetail__description">
					<Interweave content={description} />
				</div>
			</div>
			{renderSwipePanel()}
		</div>
	);
};

export default LotDetail;
