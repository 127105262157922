import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'styles/bidderinterface/lotsfeed/lotsfeed.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSlidersH,
	faTimes,
	faSearch,
} from '@fortawesome/pro-light-svg-icons';

class Filter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false,
			searchActive: false,
			activeFilter: 'all',
			searchInput: '',
		};
	}

	onFilterChange(newFilter) {
		this.setState({ activeFilter: newFilter });
		this.props.onFilterChange(newFilter);
	}

	onCloseClick() {
		if (this.state.searchActive) {
			this.setState({ searchActive: false });
			return;
		}
		this.setState({ menuOpen: false });
	}

	onsearchInputChange = event => {
		event.preventDefault();
		this.setState({ searchInput: event.target.value });
		this.props.onSearchInputChange(event.target.value);
	};

	render() {
		if (!this.state.menuOpen) {
			return (
				<button
					className="lotsfeed__browser__filter bg-primary-color-light border-secondary-color-dark large-20-font-size closed "
					onClick={() => this.setState({ menuOpen: true })}
				>
					<FontAwesomeIcon icon={faSlidersH} />
				</button>
			);
		}

		return (
			<div className="lotsfeed__browser__filter bg-primary-color-light border-secondary-color-dark large-20-font-size open">
				<button
					className="filter__close__button"
					onClick={() => this.onCloseClick()}
				>
					<FontAwesomeIcon icon={faTimes} />
				</button>

				{!this.state.searchActive && (
					<div className="filter__instruction small-14-font-size">
						{this.props?.config?.filterBy}:{' '}
					</div>
				)}

				{!this.state.searchActive && (
					<button
						className={`filter__button small-14-font-size ${
							this.state.activeFilter === 'all' ? 'active' : ''
						}`}
						onClick={() => this.onFilterChange('all')}
					>
						{this.props?.config?.all}
					</button>
				)}

				{!this.state.searchActive && (
					<button
						className={`filter__button ${
							this.state.activeFilter === 'wishlist'
								? 'active'
								: ''
						}`}
						onClick={() => this.onFilterChange('wishlist')}
					>
						{this.props?.config?.wishlist}
					</button>
				)}

				{!this.state.searchActive && (
					<button
						className={`filter__button ${
							this.state.activeFilter === 'autobid'
								? 'active'
								: ''
						}`}
						onClick={() => this.onFilterChange('autobid')}
					>
						{this.props?.config?.autobidLeft}
					</button>
				)}

				{this.state.searchActive && (
					<input
						className="filter__search__input regular-18-font-size"
						value={this.state.searchInput}
						onChange={this.onsearchInputChange}
					/>
				)}

				{!this.state.searchActive && (
					<button
						className="filter__search__button"
						onClick={() => this.setState({ searchActive: true })}
					>
						<FontAwesomeIcon icon={faSearch} />
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Filter);
