import { useSelector } from 'react-redux';

export default function useLotIndex(lotIndex) {
	const lot = useSelector(state => {
		const lotList =
			(state?.auction?.lotList?.length && state?.auction?.lotList) ||
			(state?.adminpages?.auctionDetail?.lotList?.length &&
				state?.adminpages?.auctionDetail?.lotList);

		if (!lotIndex || !lotList?.length) return null;

		const targetLot = lotList?.find(
			lot => lot.catalogueLot.lotIndex === lotIndex
		);
		if (!targetLot) return null;
		targetLot.isCurrentLot =
			targetLot?.catalogueLot?.lotID ===
			state?.auction?.currentLotDetailOpt.currentLotID;

		return targetLot;
	});

	return lot || {};
}
