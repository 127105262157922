import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';

import useConfig from 'hooks/useConfig';
import { AccordionContext } from './Wrapper';

export function Controls() {
	const {
		firstRowIndex,
		lastRowIndex,
		total,
		onPaginationChange,
		onPerPageChange,
		onFilterInputChange,
	} = useContext(AccordionContext);
	const config = useConfig();

	const [paginationText, setPaginationText] = useState(null);

	useEffect(() => {
		if (firstRowIndex === 0 && lastRowIndex === 0) {
			setPaginationText(null);
			return;
		}

		setPaginationText(
			`${firstRowIndex + 1} - ${lastRowIndex} ${config?.of} ${total || '...'}`
		);
		// eslint-disable-next-line
	}, [firstRowIndex, lastRowIndex, total, config]);

	return (
		<div className="auctionlist__row__2 bg-secondary-color-light">
			<div
				className="auctionlist__nav"
				style={{ visibility: `${paginationText ? '' : 'hidden'}` }}
			>
				<div className="auctionlist__pagination">{paginationText}</div>
				<button
					className="auctionlist__nav__btn left bg-primary-color-light"
					onClick={() => onPaginationChange(false)}
				>
					<FontAwesomeIcon
						className="auctionlist__nav__icon"
						icon={faAngleLeft}
					/>
				</button>
				<button
					className="auctionlist__nav__btn right bg-primary-color-light"
					onClick={() => onPaginationChange(true)}
				>
					<FontAwesomeIcon
						className="auctionlist__nav__icon"
						icon={faAngleRight}
					/>
				</button>
				<select
					name="display-count"
					id="display-count"
					className="auctionlist__range"
					onChange={event => onPerPageChange(event)}
					defaultValue="100"
				>
					<option value="10">{`10 ${config?.perPage}`}</option>
					<option value="20">{`20 ${config?.perPage}`}</option>
					<option value="50">{`50 ${config?.perPage}`}</option>
					<option value="100">{`100 ${config?.perPage}`}</option>
					<option value="150">{`150 ${config?.perPage}`}</option>
					<option value="200">{`200 ${config?.perPage}`}</option>
				</select>
			</div>
			<div className="auctionlist__search">
				<input
					className="auctionlist__input"
					placeholder="Search entries"
					onChange={event => onFilterInputChange(event)}
					data-testid="auctions-input"
				/>
			</div>
		</div>
	);
}
