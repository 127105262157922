import LotsFeed from 'components/bidderinterface/lotsfeed/lotsfeed';
import styles from './Timeline.module.scss';

const Timeline = () => {
	return (
		<div className={styles.timeline}>
			<LotsFeed hideFilter={true} />
		</div>
	);
};

export default Timeline;
