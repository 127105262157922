import { getEndPointAPI2 } from 'api/getEndpoint';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

export default async function getBidder(userID) {
	if (!window.API?.user?.userToken) return;
	if (!userID) return;

	const url = new URL(`${getEndPointAPI2()}/bidder/${userID}`);
	// if (from !== undefined) url.searchParams.append('from', from);
	// if (count) url.searchParams.append('count', count);
	// if (name) url.searchParams.append('name', encodeURIComponent(name));
	// if (email) url.searchParams.append('email', encodeURIComponent(email));

	const response = await fetch(url, {
		method: 'GET',
		mode: 'cors',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'text/plain',
			Authorization: `Bearer ${window.API?.user?.userToken}`,
		},
	});

	try {
		const result = await response.json();
		console.log(`*** ${url} response`);
		console.log('***', result);
		result.userUUID = userID;
		store.dispatch(AdminPageActions.setUserModal(result));
		return result;
	} catch (error) {
		console.log(error);
		return {
			result: 400,
			detail: 'not valid json',
		};
	}
}
