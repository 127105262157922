import useCurrentLot from 'hooks/useCurrentLot';
import useConfig from 'hooks/useConfig';
import { capitaliseFirst } from 'utils/utils';
import Bid from './Bid';
import styles from './Phone.module.scss';

const Phone = () => {
	const { phoneBids, amount, time, bidNumber, relation, noBidsYet } =
		useConfig();
	const { clerkDataOpt } = useCurrentLot();
	const bids = clerkDataOpt?.phoneBids;

	return (
		<div className={styles.phone}>
			<div className={styles.title}>{phoneBids}</div>
			<div className={styles.tableContainer}>
				<table className={styles.table}>
					<thead>
						<tr>
							<th>{amount}</th>
							<th>{time}</th>
							<th>{bidNumber}</th>
							<th>{relation}</th>
						</tr>
					</thead>
					<tbody>
						{bids?.map((bid, index) => (
							<Bid {...bid} key={index} />
						))}
					</tbody>
				</table>
				{!bids?.length && (
					<div className={styles.noData}>
						{capitaliseFirst(noBidsYet)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Phone;
