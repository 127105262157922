import requestCatUpdate from 'api/requests/common/Req-CatUpdate';
import { isValidOfferPrice } from 'utils/utils';
import {
	UPDATE_AUCTION_DETAILS,
	UPDATE_SINGLE_AUCTION_DETAILS,
	UPDATE_AUCTION_STATE,
	BID_NOTIFY,
	UPDATE_CAT,
	UPDATE_CLERK_ASKING_PRICE,
	RESET_CLERK_ASKING_PRICE,
	DEBUG_MODAL_OPEN,
	DEBUG_MODAL_CLOSE,
	INFO_MESSAGE,
	SET_BID_INCREMENT,
	UPDATE_INTERACTIONS,
	RESET,
	UPDATE_STREAMPIN,
	SET_PING_RESPONSE,
} from 'store/actions/auction';

const initialState = {
	debugModalOpen: false,
	clerkAskingPrice: null,
	changingLots: false,
	infoMessage: null,
	currency: '',
	currencySymbol: '',
	lotList: [],
	singleAuctionDetail: [],
	ping: {
		current: null,
		list: [],
	},
	lastLot: null,
};

export default function auction(state = initialState, action) {
	let returnState = {};
	switch (action.type) {
		case RESET:
			returnState = initialState;
			return returnState;

		case UPDATE_AUCTION_DETAILS:
			returnState = { ...state };
			let dataKeys = Object.keys(action.data);
			const existingLotIndexes = state.lotList.map(
				lot => lot?.catalogueLot?.lotIndex
			);

			dataKeys.forEach(key => {
				if (key === 'lotList') {
					let newLotList = [...returnState.lotList];
					action.data.lotList.forEach(lot => {
						const lotIndex = lot?.catalogueLot?.lotIndex;
						lot.lotSaleDetail = { ...lot.saleOutcomeOpt };
						if (existingLotIndexes.indexOf(lotIndex) === -1)
							newLotList.push(lot);
					});
					returnState.lotList = newLotList.sort(
						(a, b) => a.catalogueLot.lotIndex - b.catalogueLot.lotIndex
					);
				} else {
					returnState[key] = action.data[key];
				}
			});

			if (
				state.currentLotDetailOpt &&
				state.currentLotDetailOpt.currentLotIndex !== null &&
				action.data.lotList?.length > 0
			) {
				let target = action.data?.lotList?.filter(
					lot =>
						lot?.catalogueLot?.lotIndex ===
						state.currentLotDetailOpt.currentLotIndex
				)[0];
				if (target && target.catalogueLot)
					returnState.currentLot = target.catalogueLot;
			}

			return returnState;

		case UPDATE_SINGLE_AUCTION_DETAILS:
			returnState = { ...state };
			returnState.singleAuctionDetail = action.data.lotList;
			return returnState;

		case UPDATE_AUCTION_STATE:
			returnState = { ...state, ...action.data };

			if (
				action.data.currentLotDetailOpt &&
				state.currentLotDetailOpt &&
				action.data.currentLotDetailOpt.currentLotID !==
					state.currentLotDetailOpt.currentLotID
			) {
				returnState.lastLot = {
					lotID: state?.currentLotDetailOpt?.currentLotID,
					lotNumber: state?.currentLot?.lotNumber,
				};
				returnState.bidUpdate = null;
				returnState.bidIncrement = {
					incTypeOpt: 0,
					custIncOpt: null,
				};
			}

			if (
				action.data.currentOfferOpt?.offerID !== state.currentOfferOpt?.offerID
			) {
				returnState.clerkAskingPrice = isValidOfferPrice(
					action.data.currentOfferOpt?.offerPrice
				)
					? action.data.currentOfferOpt?.offerPrice
					: null;
			}

			if (
				action.data.currentLotDetailOpt &&
				action.data.currentLotDetailOpt.currentLotIndex !== null &&
				state?.lotList?.length > 0
			) {
				let target = state?.lotList?.filter(
					lot =>
						lot?.catalogueLot?.lotIndex ===
						action.data?.currentLotDetailOpt.currentLotIndex
				)[0];
				if (target && target.catalogueLot) {
					returnState.currentLot = target.catalogueLot;
					if (target.lotSaleDetail)
						returnState.currentLot.lotSaleDetail = target.lotSaleDetail;
				}
			}

			if (action.data.videoStreamOpt !== null) {
				returnState.videoStreamWSS = returnState.videoStreamWSS =
					'wss://streampub.stream.bid:5443/WebRTCAppEE/websocket';
			}

			action.data?.lotUpdates?.forEach(newUpdate => {
				const target = state?.lotUpdates?.find(
					storedUpdate => storedUpdate.lI === newUpdate.lI
				);
				if (!target || !target.tS) return;
				if (newUpdate.tS !== target.tS) {
					requestCatUpdate(target.lI, 1);
				}
			});

			return returnState;

		case BID_NOTIFY:
			returnState = { ...state };
			returnState.bidNotify = action.data;
			return returnState;

		case UPDATE_CAT:
			if (action?.data?.auctionID !== state.auctionID) return state;

			returnState = { ...state };
			const currentLotID = state?.currentLotDetailOpt?.currentLotID;
			const currentLotUpdate = action.data.lotUpdates.find(
				ele => ele.lotID === currentLotID
			);

			if (currentLotUpdate) {
				let newCurrentLotDetailOpt = {
					...returnState.currentLotDetailOpt,
				};
				newCurrentLotDetailOpt.myAutoBidOpt = currentLotUpdate.autoBidOpt;
				returnState.currentLotDetailOpt = newCurrentLotDetailOpt;
			}

			if (state.lastLot) {
				const lastLotUpdate = action.data.lotUpdates.find(
					ele => ele.lotID === state.lastLot.lotID
				);

				if (lastLotUpdate && lastLotUpdate?.lotSaleDetail) {
					if (lastLotUpdate?.lotSaleDetail?.displayAs === 4200) {
						if (window?.gtag) {
							const gtagProps = {
								salePrice:
									lastLotUpdate?.lotSaleDetail?.saleResultOpt?.salePrice,
								lotNumber: state?.lastLot?.lotNumber,
							};
							if (state?.extended?.saleNumOpt)
								gtagProps.saleNum = state?.extended?.saleNumOpt;
							window?.gtag('event', 'user_won_lot', gtagProps);
						}
					}
					returnState.lastLot = null;
				}
			}

			const targetLotIds = action.data.lotUpdates.map(lot => lot.lotID);

			let updatedLotList = state.lotList.map(lot => {
				if (targetLotIds.indexOf(lot?.catalogueLot?.lotID) === -1) return lot;

				let newLot = Object.assign({}, lot);

				let targetData = action.data.lotUpdates.find(
					ele => ele.lotID === lot.catalogueLot.lotID
				);

				if (targetData.lotSaleDetail)
					newLot.lotSaleDetail = Object.assign({}, targetData.lotSaleDetail);
				if (targetData.interaction)
					newLot.interaction = Object.assign({}, targetData.interaction);
				if (targetData.clerkDataOpt)
					newLot.clerkDataOpt = Object.assign({}, targetData.clerkDataOpt);
				newLot.autoBidOpt = targetData.autoBidOpt;
				newLot.updated = targetData.updated;
				return newLot;
			});

			returnState.lotList = updatedLotList.sort(
				(a, b) => a.catalogueLot.lotIndex - b.catalogueLot.lotIndex
			);
			return returnState;

		case UPDATE_INTERACTIONS:
			returnState = { ...state };
			let updatedInteractions = state.lotList.map(lot => {
				if (!lot?.catalogueLot) return lot;
				if (lot.catalogueLot.lotIndex !== action.data.lotIndex) return lot;
				const newLot = Object.assign({}, lot);
				newLot.interaction = Object.assign({}, action.data);
				return newLot;
			});

			returnState.lotList = updatedInteractions;
			return returnState;

		case UPDATE_CLERK_ASKING_PRICE:
			returnState = { ...state };
			returnState.clerkAskingPrice = action.value;
			return returnState;

		case RESET_CLERK_ASKING_PRICE:
			returnState = { ...state };
			returnState.clerkAskingPrice = state.currentOfferOpt?.offerPrice;
			return returnState;

		case DEBUG_MODAL_OPEN:
			returnState = { ...state };
			returnState.debugModalOpen = true;
			return returnState;

		case DEBUG_MODAL_CLOSE:
			returnState = { ...state };
			returnState.debugModalOpen = false;
			return returnState;

		case INFO_MESSAGE:
			returnState = { ...state };
			returnState.infoMessage = action.data;
			return returnState;

		case SET_BID_INCREMENT:
			returnState = { ...state };
			returnState.bidIncrement = action.data;
			return returnState;

		case UPDATE_STREAMPIN:
			returnState = { ...state };
			let newClerkDataOpt = { ...returnState.clerkDataOpt };
			newClerkDataOpt.streamPin = action.pin;
			returnState.clerkDataOpt = newClerkDataOpt;
			return returnState;

		case SET_PING_RESPONSE:
			returnState = { ...state };
			let newPing = Object.assign({}, returnState.ping);
			let newList = [...newPing.list];
			let pingReading = Date.now() - action.timestamp;

			if (!newList.length) {
				// first reading
				newList = new Array(5).fill(pingReading);
			} else {
				newList.shift();
				newList.push(pingReading);
			}
			newPing.current = Math.round(newList.reduce((a, b) => a + b) / 5, 2);
			newPing.list = newList;
			returnState.ping = newPing;
			return returnState;

		default:
			return state;
	}
}
