import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import requestOffBid from 'api/requests/clerk/Req-OffBid';
import ClerkActions from 'store/actions/clerk';
import RequestActions from 'store/actions/requests';
import SBButton from './sbbutton';
import 'styles/clerkconsole/managementpanel/bidselector.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faWifi,
	faLandmark,
	faPhone,
	faCheck,
	faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { isValidOfferPrice } from 'utils/utils';

export class BidSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			requestInFlight: null,
			bidSentTimestamp: null,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState?.requestInFlight !== this.state?.requestInFlight)
			return true;

		if (nextProps?.requests?.length !== this.props?.requests?.length)
			return true;
		if (nextProps?.offerID !== this.props?.offerID) return true;

		if (nextProps?.offersWithBidsLen !== this.props?.offersWithBidsLen)
			return true;
		if (nextProps?.showFlags !== this.props?.showFlags) return true;
		if (nextProps?.sbBidCount !== this.props?.sbBidCount) return true;
		if (nextProps?.sbLeadBidderOpt !== this.props?.sbLeadBidderOpt)
			return true;
		if (
			nextProps?.bidActions?.curWiggle !==
			this.props?.bidActions?.curWiggle
		)
			return true;

		if (this.state.requestInFlight) return true;

		return false;
	}

	componentDidUpdate(prevProps) {
		if (this.state.requestInFlight) {
			const request = this.props.requests.find(
				req => req.requestID === this.state.requestInFlight
			);
			if (request && request.reply)
				this.setState({
					requestInFlight: false,
				});
		}

		if (this.props?.offersWithBidsLen > prevProps?.offersWithBidsLen) {
			if (!this.state.bidSentTimestamp || !this.state.bidType) return;
		}
	}

	componentWillUnmount() {
		if (this.showCheckTimeout) clearTimeout(this.showCheckTimeout);
		if (this.inputErrorTimeout) clearTimeout(this.inputErrorTimeout); //temporarily removed due to bug ie i can't offbid
	}

	onOffBidClick(platformSN) {
		let offerPrice = this.props?.offerPrice;
		if (!isValidOfferPrice(offerPrice)) return;

		let clerkAskingPrice = Number(this.props?.clerkAskingPrice);
		let incTypeOpt = this.props?.bidIncrement?.incTypeOpt;
		let custIncOpt = this.props?.bidIncrement?.custIncOpt;
		let isManual = false;

		let value =
			clerkAskingPrice === offerPrice ? offerPrice : clerkAskingPrice;

		if (!isValidOfferPrice(value)) {
			this.showInputError();
			return;
		}

		if (!this.state.requestInFlight) {
			this.setState({ requestInFlight: platformSN });
			store.dispatch(
				RequestActions.requestSent('REQ_OFFBID', platformSN)
			);
		}

		this.setState({
			requestInFlight: platformSN,
		});

		requestOffBid(
			this.props?.offerID,
			platformSN,
			value,
			incTypeOpt,
			custIncOpt,
			isManual
		);

		this.setState({
			bidSentTimestamp: Date.now(),
			bidType: 'OFF BID',
		});
	}

	renderIcon(platformSN) {
		let icon = null;
		let classes = 'bidselector__floor__container__icon';
		switch (platformSN) {
			case 'FB':
				icon = faLandmark;
				classes = classes += ' large-20-font-size';
				break;
			case 'OO':
				icon = faWifi;
				classes = classes += ' large-20-font-size';
				break;
			case 'PB':
				icon = faPhone;
				classes = classes += ' large-20-font-size';
				break;
			default:
		}

		const request = this.props.requests.find(
			req => req.requestID === platformSN
		);
		if (!request || !request.reply)
			return <FontAwesomeIcon className={classes} icon={icon} />;

		if (request.success) {
			icon = faCheck;
			classes =
				'bidselector__floor__container__icon large-20-font-size admin-accent-1';
		} else {
			icon = faTimes;
			classes =
				'bidselector__floor__container__icon large-20-font-size warning-color';
		}

		setTimeout(() => {
			store.dispatch(RequestActions.requestRemove(request.requestID));
		}, 500);

		return <FontAwesomeIcon className={classes} icon={icon} />;
	}

	showInputError = () => {
		store.dispatch(ClerkActions.setShowAskingPriceError(true));

		this.inputErrorTimeout = setTimeout(() => {
			//temporarily removed due to bug ie i can't offbid
			store.dispatch(ClerkActions.setShowAskingPriceError(false));
		}, 800);
	};

	render() {
		const { showBidButton1, showBidButton2, showBidButton3 } =
			this.props?.showFlags || {};
		return (
			<div className="bidselector">
				<div className="bidselector__col-1">
					{showBidButton1 && (
						<button
							className="bidselector__floor border-secondary-color-dark bg-primary-color-light"
							onClick={() => this.onOffBidClick('FB')}
							data-testid="offbid-fb"
						>
							<div className="bidselector__floor__container background-color">
								{this.renderIcon('FB')}
							</div>
							<div className="bidselector__floor__title large-20-font-size">
								{this.props?.config?.floorBid}
							</div>
						</button>
					)}

					{showBidButton2 && (
						<button
							className="bidselector__online border-secondary-color-dark bg-primary-color-light"
							onClick={() => this.onOffBidClick('OO')}
							data-testid="offbid-oo"
						>
							<div className="bidselector__online__container background-color">
								{this.renderIcon('OO')}
							</div>
							<div className="bidselector__online__title large-20-font-size">
								{this.props?.config?.onlineBid}
							</div>
						</button>
					)}

					{showBidButton3 && (
						<button
							className="bidselector__phone border-secondary-color-dark bg-primary-color-light"
							onClick={() => this.onOffBidClick('PB')}
							data-testid="offbid-pb"
						>
							<div className="bidselector__phone__container background-color">
								{this.renderIcon('PB')}
							</div>
							<div className="bidselector__phone__title large-20-font-size">
								{this.props?.config?.phoneBid}
							</div>
						</button>
					)}
				</div>

				<div className="bidselector__col-2">
					<SBButton />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		config: state?.config,
		bidActions: state.clerk?.bidActions,
		...state.requests,
		offerID: state.auction?.currentOfferOpt?.offerID,
		offerPrice: state.auction?.currentOfferOpt?.offerPrice,
		offersWithBidsLen: state.auction?.offersWithBids?.length,
		clerkAskingPrice: state.auction?.clerkAskingPrice,
		sbBidCount: state.auction?.clerkDataOpt?.sbBidCount,
		sbLeadBidderOpt: state.auction?.clerkDataOpt?.sbLeadBidderOpt,
		bidIncrement: state.auction?.bidIncrement,
		packetSizeKB: state.auction?.packetSizeKB,
		serverTS: state.auction?.serverTS,
		receivedTimestamp: state.auction?.receivedTimestamp,
		showFlags: state.showFlags,
	};
};

export default connect(mapStateToProps)(BidSelector);
