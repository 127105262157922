import { Component } from 'react';
import { connect } from 'react-redux';
import InfoIcon from 'components/bidderinterface/biddingpanel/infoicon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

import './infoPanel.scss';

class InfoPanel extends Component {
	render() {
		const biddingStates = this.props?.config?.biddingStates;
		const config = this.props?.config;
		let message = '';
		let messageClasses = 'biddingpanel__info__message regular-16-font-size';
		let iconClasses =
			'biddingpanel__info__container border-secondary-color-dark';
		let icon = (
			<FontAwesomeIcon
				className="biddingpanel__info__container__icon accent-color-1"
				icon={faMoneyBill}
			/>
		);
		let pulseColor = null;

		if (this.props.infoMessage || this.props.autobidMessage) {
			message = this.props.infoMessage || this.props.autobidMessage;
			icon = (
				<FontAwesomeIcon
					className="biddingpanel__info__container__icon admin-accent-2"
					icon={faInfoCircle}
				/>
			);
			messageClasses = messageClasses + ' admin-accent-2';
			iconClasses = iconClasses + ' admin-accent-2';

			return (
				<div className="biddingpanel__info">
					<div className={messageClasses}>{message}</div>
					<div className={iconClasses}>{icon}</div>
				</div>
			);
		}

		switch (this.props.bidState) {
			case biddingStates?.displayAsNotReady:
				message = config?.pleaseWait;
				break;

			case biddingStates?.displayAsPendingBid:
				message = config?.clerkHasSeenBid;
				icon = (
					<FontAwesomeIcon
						className="biddingpanel__info__container__icon fa-spin accent-color-1"
						icon={faCircleNotch}
					/>
				);
				break;

			case biddingStates?.displayAsLeadBid:
				message = config?.yourBidTookLead;
				icon = (
					<i className="biddingpanel__info__container__icon iClass wonitem-highlight fa-regular fa-arrow-trend-up"></i>
				);
				messageClasses = messageClasses + ' wonitem-highlight';
				iconClasses = iconClasses + ' wonitem-highlight';
				pulseColor = 'bg-wonitem-highlight';
				break;

			case biddingStates?.displayAsLateBid:
				message = config?.tooLateBidAgain;
				icon = (
					<i className="biddingpanel__info__container__icon iClass warning-color fa-regular fa-arrow-trend-down"></i>
				);
				messageClasses = messageClasses + ' warning-color';
				iconClasses = iconClasses + ' warning-color';
				pulseColor = 'bg-warning-color';
				break;

			case biddingStates?.displayAsWinningBid:
				message = config?.winningBid;
				icon = (
					<i className="biddingpanel__info__container__icon iClass wonitem-highlight fa-regular fa-arrow-trend-up"></i>
				);
				messageClasses = messageClasses + ' wonitem-highlight';
				iconClasses = iconClasses + ' wonitem-highlight';
				pulseColor = 'bg-wonitem-highlight';
				break;

			case biddingStates?.displayAsRejectedBid:
				message = config?.rejectedBid;
				icon = (
					<i className="biddingpanel__info__container__icon iClass warning-color fa-regular fa-arrow-trend-down"></i>
				);
				messageClasses = messageClasses + ' warning-color';
				iconClasses = iconClasses + ' warning-color';
				pulseColor = 'bg-warning-color';
				break;

			case biddingStates?.displayAsBidError:
				message = config?.bidError;
				icon = (
					<i className="biddingpanel__info__container__icon iClass warning-color fa-regular fa-arrow-trend-down"></i>
				);
				messageClasses = messageClasses + ' warning-color';
				iconClasses = iconClasses + ' warning-color';
				pulseColor = 'bg-warning-color';
				break;

			case biddingStates?.displayAsOutbid:
				message = config?.youWereOutbid;
				icon = (
					<i className="biddingpanel__info__container__icon iClass warning-color fa-regular fa-arrow-trend-down"></i>
				);
				messageClasses = messageClasses + ' warning-color';
				iconClasses = iconClasses + ' warning-color';
				pulseColor = 'bg-warning-color';
				break;

			case biddingStates?.displayAsHasBidNowBidAgain:
				message = config?.bidAgain;
				break;

			case biddingStates?.displayAsPassedBid:
				message = config?.bidPassed;
				break;

			case biddingStates?.displayAsSoldBid:
				message = config?.bidSold;
				break;

			default:
				message = config?.placeFirstBid;
		}

		if (this.props.isPaused) {
			message = 'Please wait';
			icon = (
				<FontAwesomeIcon
					className="biddingpanel__info__container__icon admin-accent-2"
					icon={faInfoCircle}
				/>
			);
			messageClasses = messageClasses + ' admin-accent-2';
			iconClasses = iconClasses + ' admin-accent-2';
		}

		return (
			<div className="biddingpanel__info border-top-secondary-color-dark">
				<div className={messageClasses}>{message}</div>
				<InfoIcon classes={iconClasses} icon={icon} pulseColor={pulseColor} />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.bidder,
		...state.auction,
		config: state.config,
	};
};
export default connect(mapStateToProps)(InfoPanel);
