import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_APPROVEBIDDER: 'Req-ApproveBidder'
export default function requestApproveBidder(
	userID,
	auctionID,
	approvedOpt,
	paddleNum
) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-ApproveBidder',
		userTokenID: window.API?.user?.userToken,
		userID,
		auctionID,
		approvedOpt,
		paddleNum,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return data;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => {
			console.debug(error);
			return error;
		});
}

const handleSuccess = data => {
	console.debug('Req-ApproveBidder success', data);
};

const handleFailure = data => {
	console.debug('Req-ApproveBidder failure', data);
};
