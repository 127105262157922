import { useSelector } from 'react-redux';
import requestDefaultsProtocol from 'api/requests/common/Req-DefaultsProtocol';
import Toggle from 'components/common/Toggle/Toggle';
import styles from './OpenPriceMode.module.scss';

const OpenPriceMode = () => {
	const openPriceMode = useSelector(
		state => state.adminpages?.defaultsProtocol?.openPriceMode
	);
	const showAutoOpen = useSelector(state => state.showFlags?.showAutoOpen);
	const config = useSelector(state => state?.config);
	const valueOpt = openPriceMode?.valueOpt;
	const useAutobid = valueOpt === 10;

	const onToggle = event => {
		event.preventDefault();

		const newValue = valueOpt === 0 ? 10 : 0;

		const data = {
			openPriceMode: { valueOpt: newValue, update: true },
		};

		requestDefaultsProtocol(data);
	};

	if (!showAutoOpen) return null;

	return (
		<div className={styles.container}>
			<div className={styles.title}>{config?.openAtAutobid}</div>
			<Toggle isActive={useAutobid} onClick={event => onToggle(event)} />
		</div>
	);
};

export default OpenPriceMode;
