import useCurrency from 'hooks/useCurrency';

const Bid = ({ maxBid, timeStamp, paddle, detail }) => {
	const { getFormattedCurrency } = useCurrency();
	const value = getFormattedCurrency(maxBid);
	const date = new Date(timeStamp);
	const dateTime = `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;

	return (
		<tr>
			<td>{value}</td>
			<td>{dateTime}</td>
			<td>{paddle}</td>
			<td>{detail}</td>
		</tr>
	);
};

export default Bid;
