import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import requestAuctions from 'api/requests/common/Req-Auctions';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import Breadcrumbs from 'components/adminpages/addauction/breadcrumbs/breadcrumbs';
import Details from 'components/adminpages/addauction/details/details';
import Csv from 'components/adminpages/addauction/csv/csv';
import Review from 'components/adminpages/addauction/review/review';
import Complete from 'components/adminpages/addauction/complete/complete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import 'styles/adminpages/addauction/addauction.scss';

const AddAuction = props => {
	const initialBreadcrumbState = props?.editMode
		? [1, 1, 1, 1, 2]
		: [2, 0, 0, 0, 0];
	const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbState);

	useEffect(() => {
		if (window.API.user) requestAuctions();
		if (!props.editMode) {
			store.dispatch(AdminPageActions.updateAuctionDetail(null));
		} else {
			store.dispatch(AdminPageActions.updateAddAuctionLocation(4));
		}
		// eslint-disable-next-line
	}, []);

	const markAsComplete = index => {
		let array = Array(5)
			.fill(props.editMode ? 0 : -1)
			.fill(1, 0, index);
		array[index] = 2;
		setBreadcrumbs(array);
		store.dispatch(AdminPageActions.updateAddAuctionLocation(index));
	};

	const renderSwitch = () => {
		switch (props.addAuctionLocation) {
			case 0:
				return (
					<Details
						markAsComplete={() => markAsComplete(2)}
						editMode={props.editMode}
					/>
				);
			case 2:
				return <Csv markAsComplete={() => markAsComplete(3)} />;
			case 3:
				return <Review markAsComplete={() => markAsComplete(4)} />;
			case 4:
				return <Complete markAsComplete={() => markAsComplete(5)} />;
			default:
		}
	};

	const addingAuction = !props.editMode;
	const auctionTitle = props?.auctionTitle || (
		<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
	);
	return (
		<div className="addauction">
			<div className="addauction__row-1">
				<div className="addauction__row-1__title large-24-font-size">
					{props.editMode ? (
						<span>
							{props?.config?.editingAuction} - {auctionTitle}
						</span>
					) : (
						props?.config?.createAuction
					)}
				</div>
			</div>
			<div className="addauction__row-2">
				<Breadcrumbs
					addingAuction={addingAuction}
					setBreadcrumbState={state => setBreadcrumbs(state)}
					arrangement={breadcrumbs}
				/>
			</div>
			<div className="addauction__row-3">{renderSwitch()}</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auctionID: state.login?.loginDetails?.auction?.auctionID,
		auctionTitle: state.adminpages?.auctionDetail?.auctionTitle,
		addAuctionLocation: state.adminpages?.addAuctionLocation,
		userTokenID: state.login?.loginDetails?.userTokenID,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(AddAuction);
