import { getEndPointAPI2 } from 'api/getEndpoint';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

export default async function getClerks(from, count, name, email) {
	if (!window.API?.user?.userToken) return;

	const url = new URL(`${getEndPointAPI2()}/clerk`);
	if (from !== undefined) url.searchParams.append('from', from);
	if (count) url.searchParams.append('count', count);
	if (name) url.searchParams.append('name', encodeURIComponent(name));
	if (email) url.searchParams.append('email', encodeURIComponent(email));

	try {
		const response = await fetch(url, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'text/plain',
				Authorization: `Bearer ${window.API?.user?.userToken}`,
			},
		});
		const result = await response.json();
		store.dispatch(AdminPageActions.updateClerks(result));
		return result;
	} catch (error) {
		console.log(error);
		return {
			result: 400,
			detail: error,
		};
	}
}

export async function getAllClerks(from = 0, count = -1) {
	const response = await getClerks(from, count);
	if (!response || response?.result === 400) return;
	if (!response.length) return;
	if (count > 0 && response.length < count) return;

	return getAllClerks(from + response.length, response.length);
}
