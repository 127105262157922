import useCurrentLot from 'hooks/useCurrentLot';
import LazyImage from 'components/common/lazyimage';
import styles from './Image.module.scss';

const Image = () => {
	const currentLot = useCurrentLot();
	const absImg = currentLot?.catalogueLot?.absImg;
	const imgUrl = absImg && absImg[0];

	return (
		<div className={styles.container}>
			<LazyImage className={styles.image} url={imgUrl} />
		</div>
	);
};

export default Image;
