import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import useLotIndex from 'hooks/useLotIndex';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import AutobidFuture from 'components/common/AutobidFuture/AutobidFuture';
import PassedOffer from 'components/common/PassedOffer/PassedOffer';
import { Interweave } from 'interweave';
import './itemdetail.scss';

const ItemDetail = ({ showPageForIndex }) => {
	const [mainImageIndex, setMainImageIndex] = useState(0);
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const { catalogueLot, lotSaleDetail } = useLotIndex(showPageForIndex);
	const {
		lotNumber,
		lotID,
		title,
		artist,
		description,
		lowEst,
		highEst,
		absImg,
	} = catalogueLot || {};
	const { lotState, displayAs } = lotSaleDetail || {};
	const isCurrentLot = useSelector(
		state => state.auction?.currentLotDetailOpt?.currentLotID === lotID
	);

	useEffect(() => {
		setMainImageIndex(0);
		store.dispatch(BidderActions.setAutobidTarget(showPageForIndex));
	}, [showPageForIndex]);

	const getImgUrl = index => {
		if (!absImg?.length) return null;
		return absImg[index];
	};

	const lowEstFormatted = getFormattedCurrency(lowEst);
	const highEstFormatted = getFormattedCurrency(highEst);
	const itemWon = displayAs === 4200;
	const itemSold = lotState === 200;
	const itemPassed = lotState === 300;
	const itemOpen = !itemWon && !itemSold && !itemPassed && !isCurrentLot;
	const hideFooter = itemWon || itemSold || isCurrentLot;

	if (!lotID) return null;

	return (
		<div className={`itemdetail ${lotID ? '' : 'displaynone'}`}>
			<div className="itemdetail__main">
				<div className="mainimage">
					<img src={getImgUrl(mainImageIndex)} alt="" />
				</div>
				<div className="thumbnails">
					{absImg?.map((image, index) => {
						return (
							<div className="thumbnail" key={index}>
								<img
									src={getImgUrl(index)}
									alt=""
									onClick={() => setMainImageIndex(index)}
								/>
							</div>
						);
					})}
				</div>
				<div className="details">
					<div className="itemdetail__lotnumber">
						{config?.lot} {lotNumber}
					</div>
					{artist && (
						<div className="itemdetail__title large-20-font-size">
							{artist}
						</div>
					)}
					<div className="itemdetail__title large-20-font-size">
						{title}
					</div>
					<div className="itemdetail__estimate">
						{`${config?.estimate} ${lowEstFormatted} - ${highEstFormatted}`}
					</div>
					<div className="itemdetail__description">
						<Interweave content={description} />
					</div>
				</div>
			</div>
			<div className="itemdetail__footer">
				{itemOpen && !hideFooter && <AutobidFuture lotID={lotID} />}
				{itemPassed && !hideFooter && <PassedOffer lotID={lotID} />}
			</div>
		</div>
	);
};

export default ItemDetail;
