export const UPDATE_ROOTADMIN_AUCTIONHOUSE_FORM = 'UPDATE_ROOTADMIN_AUCTIONHOUSE_FORM';

export default class RootAdminActions {

    static updateRootAdminAuctionHouseForm(data) {
        return function(dispatch) {
            return dispatch({
                type: UPDATE_ROOTADMIN_AUCTIONHOUSE_FORM,
                data
            })
        }
    }

}
