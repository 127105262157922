import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_PLAYVIDEO: 'Req-PlayVideo'
export default function requestPlayVideo(setPlay) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-PlayVideo',
		userTokenID: window.API?.user?.userToken,
		setPlay,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	console.debug('Req-PlayVideo success', data);
};

const handleFailure = data => {
	console.debug('Req-PlayVideo failure', data);
};
