import currencyFormatter from 'currency.js';
import { useSelector } from 'react-redux';
import { isValidOfferPrice } from 'utils/utils';

export default function useCurrency() {
	const currency = useSelector(
		state =>
			state.auction?.localisedData?.currency ||
			state.adminpages?.auctionDetail?.localisedData?.currency
	);

	const getSymbol = () => {
		switch (currency) {
			case 'usd':
				return '$';
			case 'eur':
				return '€';
			case 'gbp':
				return '£';
			default:
				return '$';
		}
	};

	// const currencySymbol = useSelector(
	// 	state =>
	// 		state.auction?.localisedData?.currencySymbol ||
	// 		state.adminpages?.auctionDetail?.localisedData?.currencySymbol
	// );

	const currencySymbol = getSymbol();

	const getAbbreviatedCurrency = value => {
		if (!value) return 0;

		const symbol = currencySymbol || '';

		if (value.toString().length < 5) {
			return getFormattedCurrency(value);
		}

		if (value.toString().length < 7) {
			const abbNum = (value / 1000).toFixed();
			return `${symbol}${abbNum}K`;
		}

		if (value.toString().length < 10) {
			const abbNum = (value / 1000000).toFixed(1);
			return `${symbol}${abbNum}M`;
		}

		return 'NaN';
	};

	const getFormattedCurrency = (value, locale = currency) => {
		switch (locale) {
			case 'usd':
				if (!isValidOfferPrice(value)) return '$';
				return currencyFormatter(value, {
					symbol: '$',
					precision: 0,
				}).format();
			case 'eur':
				if (!isValidOfferPrice(value)) return '€';
				return currencyFormatter(value, {
					symbol: '€',
					separator: '.',
					precision: 0,
					decimal: ',',
				}).format();
			case 'gbp':
				if (!isValidOfferPrice(value)) return '£';
				return currencyFormatter(value, {
					symbol: '£',
					precision: 0,
				}).format();
			default:
				return value;
		}
	};

	const sanitiseCurrency = input => {
		let sanitisedString = input.replace(/\D+/g, '');
		let value = Number(sanitisedString);
		if (isNaN(value)) return '';
		return value;
	};

	return {
		currency,
		currencySymbol,
		getAbbreviatedCurrency,
		getFormattedCurrency,
		sanitiseCurrency,
	};
}
