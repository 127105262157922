import useConfig from 'hooks/useConfig';
import CurrentBid from 'components/clerkconsole/managementpanel/currentbid';
import AskingPrice from 'components/clerkconsole/managementpanel/askingprice/askingprice';
import SetIncrements from 'components/clerkconsole/managementpanel/setincrements/setincrements';
import BidSelector from 'components/clerkconsole/managementpanel/bidselector/bidselector';
import SaleControls from 'components/clerkconsole/managementpanel/salecontrols/salecontrols';
import OpenPriceMode from './OpenPriceMode/OpenPriceMode';
import AutoAccept from './AutoAccept/AutoAccept';
import InfoPanel from './InfoPanel/InfoPanel';
import 'styles/clerkconsole/managementpanel/managementpanel.scss';
import askingPriceStyles from './askingprice/askingPrice.module.scss';

const ManagementPanel = () => {
	const config = useConfig();
	return (
		<div className="managementpanel">
			<div className="managementpanel__header">
				<div className="title large-24-font-size">
					{config?.managementPanel}
				</div>
				<div className="toggles">
					<OpenPriceMode />
					<AutoAccept />
				</div>
			</div>
			<div className="managementpanel__container">
				<CurrentBid />
				<AskingPrice styles={askingPriceStyles} />
				<InfoPanel />
				<BidSelector />
				<SaleControls />
				<SetIncrements />
			</div>
		</div>
	);
};

export default ManagementPanel;
