import { useEffect } from 'react';
import { connect } from 'react-redux';

import requestSaleResult from 'api/requests/common/Req-SaleResult';
import RunStateSwitch from './RunStateSwitch/RunStateSwitch';
import { getLocaleDate } from 'utils/utils';
import headerbg from 'assets/headerbg.png';

const AuctionHeader = props => {
	const { config } = props;

	useEffect(() => {
		if (!props?.auctionID) return;

		const interval = setInterval(() => {
			requestSaleResult(props?.auctionID);
		}, 300000);

		return () => clearInterval(interval);
	}, [props?.auctionID]);

	useEffect(() => {
		if (!props.saleOutcome && props?.auctionID) {
			requestSaleResult(props?.auctionID);
		}
	}, [props?.saleOutcome, props?.auctionID]);

	const date = props?.auctionDate ? new Date(props?.auctionDate) : '';

	return (
		<div className="addauction__complete__row-1">
			<div className="img-container">
				<img src={headerbg} alt="headerbg" />
			</div>
			<div className="overlay primary-color-light">
				<div className="overlay__col-1">
					<h1 className="auction-title">
						{props?.auctionTitle}{' '}
						{props?.saleNumOpt ? ` - ${props?.saleNumOpt}` : null}
					</h1>
					<h2>{getLocaleDate(date, props?.timeZone)}</h2>
				</div>

				<div className="overlay__col-2">
					<RunStateSwitch />
					<div className="addauction__stats">
						<div className="addauction__stats__item">
							<span>{config?.totalLots}: </span>
							<span>{props?.saleOutcome?.totalLots}</span>
						</div>
						<div className="addauction__stats__item">
							<span>{config?.soldLots}: </span>
							<span>{props?.saleOutcome?.sold}</span>
						</div>
						<div className="addauction__stats__item">
							<span>{config?.passedLots}: </span>
							<span>{props?.saleOutcome?.passed}</span>
						</div>
						<div className="addauction__stats__item">
							<span>{config?.upcomingLots}: </span>
							<span>{props?.saleOutcome?.upcoming}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		saleOutcome: state.adminpages?.saleOutcome,
		auctionDate: state.adminpages?.auctionDetail?.auctionDate,
		auctionTitle: state.adminpages?.auctionDetail?.auctionTitle,
		timeZone: state.adminpages?.auctionDetail?.localisedData?.timeZone,
		config: state?.config,
		saleNumOpt: state.adminpages?.auctionDetail?.extended?.saleNumOpt,
	};
};

export default connect(mapStateToProps)(AuctionHeader);
