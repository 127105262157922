import sendRequest from '../..';
import { getClerkEndpoint } from '../../utils';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import AdminPageActions from 'store/actions/adminpages';
import requestCatUpdate from '../Req-CatUpdate';
import notfound from 'assets/notfound.png';

//REQ_AUCTION_DETAIL: 'Req-AuctionLotDetail'
export default function requestAuctionLotDetail(
	sendToken,
	auctionID,
	lotCount,
	maxTime = 3000,
	minTime = 1000
) {
	let lowBound = null;
	let descendingRange = 10;
	let highBound = null;
	let ascendingRange = 10;
	let failCounter = 0;
	let lotsReceived = 0;

	const increaseTimeout = () => {
		const newMax = maxTime * 2;
		const newMin = minTime * 2;

		if (newMax > 30000) {
			maxTime = 40000;
			minTime = 30000;
			return;
		}
		maxTime = newMax;
		minTime = newMin;
	};

	const getRandomTime = () => {
		const randomTime = Math.floor(
			Math.random() * (maxTime - minTime + 1) + minTime
		);
		return randomTime;
	};

	const requestBatch = (fromLotOpt, lotCountOpt, mode) => {
		return sendRequest(getClerkEndpoint(), {
			Action: 'Req-AuctionLotDetail',
			userTokenIDOpt: sendToken ? window.API?.user?.userToken : null,
			auctionID,
			fromLotOpt,
			lotCountOpt,
		})
			.then(response => {
				if (
					!response?.data ||
					!response?.data?.lotList ||
					!response?.data?.lotList?.length
				)
					return;

				handleSuccess(response.data);
				if (lotCount < 1) return response.data;

				lotsReceived += response.data.lotList?.length;

				// lotList sorted check

				if (response.data.lotList?.length > 1) {
					if (
						response.data.lotList[0].catalogueLot.lotIndex >
						response.data.lotList[1].catalogueLot.lotIndex
					) {
						console.warn('[Req-AuctionLotDetail] - LOT LIST IS UNSORTED');
					}
				}

				const lotList = response.data.lotList.sort(
					(a, b) => a.catalogueLot.lotIndex > b.catalogueLot.lotIndex
				);
				const firstIndex = lotList[0]?.catalogueLot?.lotIndex;
				const lastIndex =
					lotList[response.data.lotList.length - 1]?.catalogueLot?.lotIndex;

				console.debug(
					`Loaded Lots ${firstIndex} - ${lastIndex}. Total Lots Received: ${lotsReceived} of ${lotCount}`
				);

				let catUpdateRange = lastIndex - firstIndex + 1;
				if (catUpdateRange < 1) catUpdateRange = 1;
				getCatUpdate(firstIndex, catUpdateRange);

				if (lotsReceived >= lotCount) {
					console.debug(`All ${lotsReceived} Lots Loaded.`);
				}

				// recurse backwards through lots
				if (!mode || mode === 'descending') {
					if (firstIndex !== 1) {
						lowBound = firstIndex - 10;
						if (lowBound < 1) {
							descendingRange = lowBound + descendingRange - 1;
							lowBound = 1;
						}

						setTimeout(() => {
							// CAUTION RECURSION
							const loggedInToAuction = window.API.user?.auctionID;
							if (!loggedInToAuction) return;
							console.debug('[POST] Recursive API call - Req-AuctionLotDetail');
							requestBatch(lowBound, descendingRange, 'descending');
						}, getRandomTime());

						if (mode === 'descending') return;
					}
				}

				// recurse forwards through lots
				if (!mode || mode === 'ascending') {
					if (lastIndex >= lotCount) return;
					highBound = lastIndex + 1;

					if (highBound + ascendingRange > lotCount) {
						ascendingRange = lotCount - highBound + 1;
					}

					setTimeout(() => {
						// CAUTION RECURSION
						const loggedInToAuction = window.API.user?.auctionID;
						if (!loggedInToAuction) return;
						console.debug('[POST] Recursive API call - Req-AuctionLotDetail');
						requestBatch(highBound, ascendingRange, 'ascending');
					}, getRandomTime());

					if (mode === 'ascending') return;
				}
				return response.data;
			})
			.catch(error => {
				if (error?.response?.status === 404) {
					console.error(error);
					return error;
				}
				console.log(
					`[POST] Req-AuctionLotDetail error in ${mode} branch, trying again with increased timeout`
				);
				increaseTimeout();
				const targetBound = mode === 'ascending' ? highBound : lowBound;
				const targetRange =
					mode === 'ascending' ? ascendingRange : descendingRange;

				const randomTime = getRandomTime();
				setTimeout(() => {
					failCounter++;
					if (failCounter >= 10) {
						console.log(
							`[POST][ERROR] Req-AuctionLotDetail failed ${failCounter} times, aborting.`
						);
						return;
						// popup msg please refresh in a few minutes
					}
					console.debug(
						`[POST] Recursive API call - Req-AuctionLotDetail failure x ${failCounter}. Next attempt in ${randomTime}ms.`
					);
					// CAUTION RECURSION
					const loggedInToAuction = window.API.user?.auctionID;
					if (!loggedInToAuction) return;
					requestBatch(targetBound, targetRange, mode);
				}, randomTime);
				return error;
			});
	};
	return requestBatch(null, 10);
}

const handleSuccess = data => {
	if (data?.result > 100) return;
	if (!window.API?.user?.auctionID) return;
	if (window.API?.user?.auctionID !== data.auctionID) return;

	if (!window.API.gaSet) {
		window.API.setGA(data?.extended?.gaID);
	}

	if (data?.lotList?.length) {
		data.lotList = data.lotList.map(lot => {
			if (lot.catalogueLot.absImg.length) {
				lot.catalogueLot.absImg = lot.catalogueLot.absImg.map(img => {
					if (!img) return notfound;
					return img + `?ts=${data.lotUPTS}`;
				});
			} else {
				lot.catalogueLot.absImg = [notfound];
			}
			return lot;
		});
	}

	if (
		window?.API?.user?.userPerm?.indexOf('root') !== -1 ||
		window?.API?.user?.userPerm?.indexOf('admin') !== -1
	) {
		store.dispatch(AdminPageActions.updateAuctionDetail(data));
	} else {
		store.dispatch(AuctionActions.updateAuctionDetail(data));
	}
};

const getCatUpdate = (firstIndex, catUpdateRange) => {
	if (
		window?.API?.user?.userPerm?.indexOf('bidder') !== -1 ||
		window?.API?.user?.userPerm?.indexOf('clerk') !== -1 ||
		window?.API?.user?.userPerm?.indexOf('viewer') !== -1
	) {
		requestCatUpdate(firstIndex, catUpdateRange);
	}
};
