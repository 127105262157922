import * as Yup from 'yup';

export const getFormData = props => {
	const ahLocale = props?.locale;
	const { config } = props;
	// const labels = props?.config?.labels;
	// const placeholders = props?.config?.placeholders;

	const formData = [
		{
			id: 'auctionTitle',
			label: config?.auctionTitle,
			value: props?.auctionTitle || '',
			type: 'text',
			inputType: 'input',
		},
		{
			id: 'auctionDate',
			label: config?.startDate,
			value: props?.auctionDate || '',
			type: 'datetime-local',
			inputType: 'date',
			ahLocale,
		},
		{
			id: 'auctionEndDateOpt',
			label: config?.endDate,
			value: props?.auctionEndDateOpt || '',
			type: 'datetime-local',
			inputType: 'date',
			ahLocale,
		},
		{
			id: 'currency',
			label: config?.currency,
			value: props?.currency || props?.defaultCurrency,
			type: 'text',
			inputType: 'select',
			options: ['gbp', 'usd', 'eur'],
		},
		{
			id: 'descriptionOpt',
			label: config?.description,
			value: props?.descriptionOpt || '',
			type: 'text',
			inputType: 'input',
		},
		{
			id: 'specialistOpt',
			label: config?.specialistName,
			value: props?.specialistOpt || '',
			type: 'text',
			inputType: 'input',
		},
		{
			id: 'specialistEmailOpt',
			label: config?.specialistEmail,
			value: props?.specialistEmailOpt || '',
			type: 'text',
			inputType: 'input',
		},
	];

	if (!props.editMode) {
		formData.push({
			id: 'loginTrustUpdateOpt',
			label: config?.doNotOverrideLimits,
			value: false,
			type: 'boolean',
			inputType: 'checkbox',
		});
	}

	return formData;
};

export const getValidationSchema = editMode => {
	let schema = {
		auctionTitle: Yup.string().required('Required'),
		auctionDate: Yup.string().required('Required').nullable(),
		auctionEndDateOpt: Yup.string().nullable(),
		currency: Yup.string().required('Required'),
		descriptionOpt: Yup.string().required('Required'),
		specialistOpt: Yup.string().required('Required'),
		specialistEmailOpt: Yup.string()
			.email('Must be a valid email')
			.required('Required'),
	};

	if (!editMode) {
		schema.loginTrustUpdateOpt = Yup.boolean().required('Required');
	}

	return Yup.object().shape(schema);
};
