import sendRequest from '../..';
import { getClerkEndpoint } from '../../utils';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import AdminPageActions from 'store/actions/adminpages';
import requestAuctionLotDetail from '../Req-AuctionLotDetail/Req-AuctionLotDetail';

//'Req-AuctionStaticDetail'
export default function requestAuctionStaticDetail(
	sendToken,
	auctionID,
	requestLots
) {
	let userTokenIDOpt = null;
	if (sendToken && typeof sendToken === 'boolean') {
		userTokenIDOpt = window.API?.user?.userToken;
	}
	if (sendToken && typeof sendToken === 'string') {
		userTokenIDOpt = sendToken;
	}

	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-AuctionStaticDetail',
		userTokenIDOpt,
		auctionID,
	})
		.then(response => {
			const { data } = response;
			if (data?.result > 100) {
				handleFailure();
				return;
			}

			if (!window.API.gaSet) window.API.setGA(data?.extended?.gaID);

			if (
				window?.API?.user?.userPerm?.indexOf('root') !== -1 ||
				window?.API?.user?.userPerm?.indexOf('admin') !== -1
			) {
				store.dispatch(AdminPageActions.updateAuctionDetail(data));
			} else {
				store.dispatch(AuctionActions.updateAuctionDetail(data));
			}

			if (!requestLots) return;
			requestAuctionLotDetail(sendToken, auctionID, data.lotCount);
		})
		.catch(error => {
			handleFailure(error);
		});
}

const handleFailure = data => {
	// TODO - what do we do if auction detail fails?
	console.debug('Req-AuctionStaticDetail failure', data);
};
