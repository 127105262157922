import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import b64toUUID from 'utils/b64toUUID';
import AuctionHeader from './auctionHeader';
import AuctionInfo from './auctionInfo';
import LotTable from 'components/adminpages/addauction/lots/lotTable';
import LotEditForm from 'components/adminpages/addauction/lots/lotEditForm';
import LotAutobids from 'components/adminpages/addauction/lots/LotAutobids';
import { AuctionUtilities } from './AuctionUtilities';
import { getAuctionAutobids } from 'api/auction/autobids';
import 'styles/adminpages/addauction/complete/complete.scss';

const Complete = props => {
	const [editingLot, setEditingLot] = useState(null);
	const [autoBidLots, setAutoBidLots] = useState([]);
	const [targetAutoBidLot, setTargetAutoBidLot] = useState([]);
	const { showAutobids, showRegistrations } = useSelector(
		state => state.showFlags
	);
	const onEditClick = lotIndex => {
		if (!isNaN(lotIndex)) {
			const nextLot = props?.lotList.find(
				lot => lot.catalogueLot.lotIndex === lotIndex
			);
			if (!nextLot) return;
		}
		setEditingLot(lotIndex);
	};

	useEffect(() => {
		if (!showRegistrations || !window.API?.user?.userToken || !props?.auctionID)
			return;
		const fetchAutobidLots = async () => {
			const response = await getAuctionAutobids(props.auctionID);
			setAutoBidLots(response.autoBidLots);
		};
		fetchAutobidLots();
	}, [window.API?.user?.userToken, props?.auctionID]);

	useEffect(() => {
		if (!editingLot || !autoBidLots) {
			setTargetAutoBidLot(null);
			return;
		}
		const targetLot = props.lotList.find(
			lot => lot.catalogueLot.lotIndex === editingLot
		);
		const b64ID = targetLot.catalogueLot.lotID;
		const uuID = b64toUUID(b64ID);
		const target = autoBidLots.find(lot => lot.lotID === uuID);
		setTargetAutoBidLot(target);
	}, [editingLot]);

	return (
		<div className="addauction__complete">
			<AuctionHeader />
			<AuctionInfo />
			<div className="addauction__complete__row-3 regular-16-font-size">
				<div className="addauction__description">
					{props?.descriptionOpt ? props?.descriptionOpt : 'No description set'}
				</div>
				<AuctionUtilities />
			</div>
			<div className="addauction__complete__row-4 bg-primary-color-light">
				{editingLot ? (
					<>
						<LotEditForm lotIndex={editingLot} onEditClick={onEditClick} />
						{autoBidLots ? (
							<LotAutobids targetAutoBidLot={targetAutoBidLot} />
						) : null}
					</>
				) : (
					<LotTable onEditClick={onEditClick} />
				)}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		lotList: state.adminpages?.auctionDetail?.lotList,
		descriptionOpt: state.adminpages?.auctionDetail?.descriptionOpt,
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Complete);
