import { useState } from 'react';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faSpinner,
	faCheck,
	faTimes,
	faLink,
	faCopy,
} from '@fortawesome/pro-solid-svg-icons';
import requestApproveBidder from 'api/requests/admin/Req-ApproveBidder';
import getBidder from 'api/bidder/getBidder';
import requestUserRegMail from 'api/requests/admin/Req-UserRegMail';
import getLoginUrl from 'api/auction/state';
import PaddleNum from './paddleNum/paddleNum';

const UsersModalTable = props => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const [showApproveStatus, setShowApproveStatus] = useState(null);
	const [showRegisterStatus, setShowRegisterStatus] = useState(null);
	const [showSendUrlStatus, setShowSendUrlStatus] = useState(null);
	const [showBiddingURL, setShowBiddingURL] = useState(null);
	const [copyAlertTimeout, setCopyAlertTimeout] = useState(null);
	const [showCopyAlert, setShowCopyAlert] = useState(null);
	const [showNotLive, setShowNotLive] = useState(null);

	const onApproveClick = (userID, auction) => {
		setShowApproveStatus({
			auctionID: auction?.auctionID,
			icon: faSpinner,
		});
		let approvedOpt = Number(auction?.manualLimit);
		requestApproveBidder(userID, auction?.auctionID, approvedOpt)
			.then(data => {
				const icon = data?.result > 100 ? faTimes : faCheck;
				setShowApproveStatus({
					auctionID: auction?.auctionID,
					icon,
				});
				resetApproveStatus();
				getBidder(props?.user?.userUUID);
			})
			.catch(error => {
				setShowApproveStatus({
					auctionID: auction?.auctionID,
					icon: faTimes,
				});
				resetApproveStatus();
			});
	};

	const getRegisterIcon = auctionID => {
		if (showRegisterStatus?.auctionID === auctionID)
			return showRegisterStatus?.icon;
		return faEnvelope;
	};

	const getUrlIcon = auctionID => {
		if (showSendUrlStatus?.auctionID === auctionID)
			return showSendUrlStatus?.icon;
		return faLink;
	};

	const resetApproveStatus = () => {
		setTimeout(() => setShowApproveStatus(null), 1200);
	};

	const onResendRegClick = (user, auction) => {
		setShowRegisterStatus({
			auctionID: auction?.auctionID,
			icon: faSpinner,
		});
		requestUserRegMail(user?.userID, auction?.auctionID)
			.then(data => {
				const icon = data.result > 100 ? faTimes : faCheck;
				setShowRegisterStatus({
					auctionID: auction?.auctionID,
					icon,
				});
				resetStatus();
			})
			.catch(error => {
				setShowRegisterStatus({
					auctionID: auction?.auctionID,
					faTimes,
				});
				resetStatus();
			});
	};

	const onSendUrlClick = async (user, auction) => {
		setShowSendUrlStatus({
			auctionID: auction?.auctionID,
			icon: faSpinner,
		});
		const response = await getLoginUrl(user?.userID, auction?.auctionID);
		console.log(response);
		const { isLive, auctionID, biddingURL } = response?.data;

		if (!isLive) {
			setShowNotLive(auction?.auctionID);
			setTimeout(() => setShowNotLive(null), 1200);
		}

		if (!isLive || !biddingURL) {
			setShowSendUrlStatus({
				auctionID: auction?.auctionID,
				icon: faTimes,
			});
			resetStatus();
			return;
		}

		setShowBiddingURL({
			auctionID,
			biddingURL,
		});

		resetStatus();
	};

	const resetStatus = () => {
		setTimeout(() => {
			setShowRegisterStatus(null);
			setShowSendUrlStatus(null);
		}, 1200);
	};

	const renderButton = (auction, approved) => {
		let buttonText = '';
		let classes = `adminpages__users__paddleaction primary-color-light bg-admin-accent-1 hover-bg-admin-accent-offset-1`;
		if (approved && auction?.paddleLimitOpt === 0) {
			buttonText = config?.revokePaddle;
		} else if (approved) {
			buttonText = config?.editPaddle;
		} else {
			classes += '';
			buttonText = config?.approve;
		}

		if (
			showApproveStatus &&
			showApproveStatus?.auctionID === auction?.auctionID
		)
			buttonText = (
				<FontAwesomeIcon
					className={`adminpages__users__row__icon ${
						showApproveStatus?.icon === faSpinner ? 'fa-spin' : ''
					}`}
					icon={showApproveStatus?.icon}
				/>
			);

		return (
			<button
				className={classes}
				onClick={() => onApproveClick(props?.user?.userID, auction)}
			>
				{buttonText}
			</button>
		);
	};

	const copyToClipboard = biddingURL => {
		clearTimeout(copyAlertTimeout);
		setShowCopyAlert(false);
		navigator.clipboard.writeText(biddingURL);
		setShowCopyAlert(true);
		setCopyAlertTimeout(setTimeout(() => setShowCopyAlert(false), 2000));
	};

	return (
		<div className="adminpages__users__modal__table__wrapper">
			<table className="adminpages__users__modal__table">
				<thead>
					<tr>
						<th>{config?.auction}</th>
						<th>{config?.date}</th>
						<th>{config?.status}</th>
						<th>{config?.paddleNumber}</th>
						<th>{config?.paddleLimit}</th>
						<th>{config?.approve}</th>
						<th>{config?.resendRegisterEmail}</th>
						<th>Send URL</th>
					</tr>
				</thead>
				<tbody>
					{props?.auctions?.map((auction, index) => {
						const { auctionID, biddingURL } = showBiddingURL || {};

						if (auctionID === auction.auctionID)
							return (
								<tr key={index}>
									<td colSpan="8" className="modal__biddingurl">
										<div className="modal__biddingurl__container">
											<a href={biddingURL}>{biddingURL}</a>
											{showCopyAlert && (
												<div className="copy-alert small-12-font-size">
													{config?.copiedToClipboard}
												</div>
											)}
											<div className="button-container">
												<button
													title="Copy to clipboard"
													className="copy-button"
													onClick={() => copyToClipboard(biddingURL)}
												>
													<FontAwesomeIcon icon={faCopy} />
												</button>
												<button
													title="Close"
													className="copy-close"
													onClick={() => setShowBiddingURL(null)}
												>
													<FontAwesomeIcon icon={faTimes} />
												</button>
											</div>
										</div>
									</td>
								</tr>
							);

						const approved = auction?.paddleNum && auction?.paddleLimitOpt;
						return (
							<tr key={index}>
								<td>{auction?.auctionTitle}</td>
								<td>{new Date(auction?.auctionDate).toLocaleDateString()}</td>
								<td>{approved ? 'Approved' : 'Not approved'}</td>
								<PaddleNum
									paddleNum={auction?.paddleNum}
									userID={props?.user?.userID}
									userUUID={props?.user?.userUUID}
									auctionID={auction?.auctionID}
									paddleLimitOpt={auction?.paddleLimitOpt}
								/>
								<td>
									<input
										className="adminpages__users__modal__input"
										value={getFormattedCurrency(
											auction?.manualLimit,
											auction?.currency
										)}
										onChange={event =>
											props?.onManualLimitChange(
												event,
												auction?.auctionID,
												auction?.currencySymbol
											)
										}
									/>
								</td>
								<td>{renderButton(auction, approved)}</td>
								<td>
									<button
										className="adminpages__users__row__btn register bg-accent-color-1 primary-color-light border-secondary-color-dark hover-bg-accent-offset-1"
										onClick={() => onResendRegClick(props?.user, auction)}
									>
										<FontAwesomeIcon
											className="adminpages__users__row__icon"
											icon={getRegisterIcon(auction?.auctionID)}
										/>
									</button>
								</td>
								<td className="relative">
									{showNotLive === auction.auctionID && (
										<div className="copy-alert small-12-font-size">
											Auction not live
										</div>
									)}
									<button
										className="adminpages__users__row__btn bg-accent-color-1 primary-color-light border-secondary-color-dark hover-bg-accent-offset-1"
										onClick={() => onSendUrlClick(props?.user, auction)}
									>
										<FontAwesomeIcon
											className="adminpages__users__row__icon"
											icon={getUrlIcon(auction?.auctionID)}
										/>
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default UsersModalTable;
