import {
    UPDATE_ROOTADMIN_AUCTIONHOUSE_FORM
} from 'store/actions/rootadmin';


const initialState = {
    rootAdminAuctionHouseForm: {}
}

export default function rootadmin(state = initialState, action) {
    let returnState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_ROOTADMIN_AUCTIONHOUSE_FORM:
            returnState.rootAdminAuctionHouseForm = action.data;
            return returnState;

        default:
            return state
    }
}
