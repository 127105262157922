import { guid_to_base64 } from 'utils/utils';
import {
	UPDATE_ADMINPAGES_ADDAUCTION_LOCATION,
	UPDATE_AUCTION__DETAIL,
	UPDATE_AUCTION_INCREMENTS,
	RESET_USERS,
	UPDATE_BIDDERS,
	RESET_BIDDERS,
	UPDATE_CLERKS,
	UPDATE_ADMINS,
	RESET_STAFF,
	EDITING_USER,
	UPDATE_CSV_UPLOAD,
	UPDATE_SALEOUTCOME,
	RESET,
	UPDATE_DEFAULTSPROTOCOL,
	UPDATE_WITHDRAWN_LOT,
	WITHDRAW_LOT_COMPLETE,
	UPDATE_AUCTION_RUNSTATE,
	SET_CONFIRMATION_ERROR,
	UPDATE_LOT_STATUS,
	SET_PASSBIDS,
	SET_USER_MODAL,
} from 'store/actions/adminpages';

const initialState = {
	addAuctionLocation: 0,
	auctionDetail: {
		lotList: [],
	},
	auctionIncrements: null,
	reorderModalisOpen: false,
	bidders: [],
	staff: [],
	userProgress: {
		biddersTotal: 0,
		clerksTotal: 0,
		adminsTotal: 0,
	},
	editingUser: null,
	csvUpload: null,
	saleOutcome: null,
	userPWResetReturn: null,
	defaultsProtocol: {},
	showDefaultsProtocolSuccess: false,
	showUserRegSuccess: false,
	withDrawLotComplete: false,
	confirmationError: null,
	updateLotStatus: null,
	passBids: null,
	userModalData: null,
};

export default function adminpages(state = initialState, action) {
	let returnState = Object.assign({}, state);

	switch (action.type) {
		case RESET:
			returnState.auctionDetail = {
				lotList: [],
			};
			returnState.editingUser = null;
			returnState.passBids = null;

			return returnState;

		case UPDATE_ADMINPAGES_ADDAUCTION_LOCATION:
			returnState.addAuctionLocation = action.index;
			return returnState;

		case UPDATE_AUCTION__DETAIL:
			returnState = { ...state };
			if (!action.data || window.API.user.auctionID !== action.data.auctionID) {
				returnState.auctionDetail = initialState.auctionDetail;
				return returnState;
			}

			let existingLotIndexes = state.auctionDetail.lotList.map(
				lot => lot?.catalogueLot?.lotIndex
			);
			let newAuctionDetail = Object.assign({}, returnState.auctionDetail);
			let dataKeys = Object.keys(action.data);

			if (action.data.lotUPTS && action.data.lotUPTS !== state.lotUPTS) {
				returnState.lotUPTS = action.data.lotUPTS;
				console.debug('NEW lotUPTS', action.data.lotUPTS);
				existingLotIndexes = [];
				newAuctionDetail.lotList = [];
			}

			dataKeys.forEach(key => {
				if (key === 'lotList') {
					let newLotList = [...newAuctionDetail.lotList];
					action.data.lotList.forEach(lot => {
						const lotIndex = lot?.catalogueLot?.lotIndex;
						if (existingLotIndexes.indexOf(lotIndex) === -1) {
							newLotList.push(lot);
							return;
						}
						newLotList = newLotList.map(newLot => {
							if (lot.catalogueLot.lotID === newLot.catalogueLot.lotID)
								return lot;
							return newLot;
						});
					});
					newAuctionDetail.lotList = newLotList.sort(
						(a, b) => a.catalogueLot.lotIndex - b.catalogueLot.lotIndex
					);
				} else {
					newAuctionDetail[key] = action.data[key];
				}
			});

			returnState.auctionDetail = newAuctionDetail;
			return returnState;

		case UPDATE_WITHDRAWN_LOT:
			returnState = { ...state };
			let withdrawAuctionDetail = Object.assign({}, returnState.auctionDetail);
			let withdrawLotList = withdrawAuctionDetail.lotList.map(lot => {
				if (lot.catalogueLot.lotID === action.data.saleLotID) {
					lot.catalogueLot.isWithdrawn = action.data.isWithdrawn;
				}
				return lot;
			});

			withdrawAuctionDetail.lotList = withdrawLotList;
			returnState.auctionDetail = withdrawAuctionDetail;
			return returnState;

		case WITHDRAW_LOT_COMPLETE:
			returnState = { ...state };
			returnState.withDrawLotComplete = action.bool;
			return returnState;

		case UPDATE_AUCTION_INCREMENTS:
			returnState.auctionIncrements = action.data?.bidIncOpt;
			return returnState;

		case RESET_USERS:
			returnState.bidders = [];
			returnState.staff = [];
			return returnState;

		case RESET_BIDDERS:
			returnState.bidders = [];
			return returnState;

		case UPDATE_BIDDERS:
			if (!returnState.userProgress.biddersTotal)
				returnState.userProgress.biddersTotal = action.data[0]?.userCount;
			returnState.bidders = [...returnState.bidders, ...action.data].reduce(
				(accumulator, user) => {
					user.userID = guid_to_base64(user.userUUID);
					const existingIndex = accumulator.findIndex(
						existing => existing.userUUID === user.userUUID
					);
					if (existingIndex === -1) accumulator.push(user);
					else accumulator[existingIndex] = user;
					return [...accumulator];
				},
				[]
			);

			return returnState;

		case UPDATE_CLERKS:
			if (!returnState.userProgress.clerksTotal)
				returnState.userProgress.clerksTotal = action.data[0]?.userCount;
			returnState.staff = [...returnState.staff, ...action.data].reduce(
				(accumulator, user) => {
					user.userID = guid_to_base64(user.userUUID);
					const existingIndex = accumulator.findIndex(
						existing => existing.userUUID === user.userUUID
					);
					if (existingIndex === -1) accumulator.push(user);
					else accumulator[existingIndex] = user;
					return [...accumulator];
				},
				[]
			);

			return returnState;

		case UPDATE_ADMINS:
			if (!returnState.userProgress.adminsTotal)
				returnState.userProgress.adminsTotal = action.data[0]?.userCount;
			returnState.staff = [...returnState.staff, ...action.data].reduce(
				(accumulator, user) => {
					user.userID = guid_to_base64(user.userUUID);
					const existingIndex = accumulator.findIndex(
						existing => existing.userUUID === user.userUUID
					);
					if (existingIndex === -1) accumulator.push(user);
					else accumulator[existingIndex] = user;
					return [...accumulator];
				},
				[]
			);

			return returnState;

		case RESET_STAFF:
			returnState.staff = [];
			return returnState;

		case EDITING_USER:
			returnState.editingUser = action.userTokenID;
			return returnState;

		case UPDATE_CSV_UPLOAD:
			returnState.csvUpload = action.data;
			return returnState;

		case UPDATE_SALEOUTCOME:
			returnState.saleOutcome = action.data;
			return returnState;

		case UPDATE_DEFAULTSPROTOCOL:
			let defaultsProtocol = Object.assign(
				{},
				returnState.defaultsProtocol,
				action.data
			);
			returnState.defaultsProtocol = defaultsProtocol;
			return returnState;

		case UPDATE_AUCTION_RUNSTATE:
			let newRunStateDetail = Object.assign({}, returnState.auctionDetail);
			newRunStateDetail.state = action.code;
			returnState.auctionDetail = newRunStateDetail;
			return returnState;

		case SET_CONFIRMATION_ERROR:
			returnState.confirmationError = action.data;
			return returnState;

		case UPDATE_LOT_STATUS:
			returnState.updateLotStatus = action.data;
			return returnState;

		case SET_PASSBIDS:
			returnState.passBids = action.data;
			return returnState;

		case SET_USER_MODAL:
			returnState.userModalData = action.data;
			return returnState;

		default:
			return state;
	}
}
