import {
    UPDATE_IS_PAUSED,
    UPDATE_IS_MUTED
} from 'store/actions/video';

const initialState = {
    isPaused: false,
    isMuted: true
}

export default function video(state = initialState, action) {
    let returnState = Object.assign({}, state);
  
    switch (action.type) {
        case UPDATE_IS_PAUSED:
            returnState.isPaused = action.bool;
            return returnState;

        case UPDATE_IS_MUTED:
            returnState.isMuted = action.bool;
            return returnState;

        default:
            return state;
    }
}
