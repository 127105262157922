import {
	BIDDER_OPEN_MODAL,
	BIDDER_CLOSE_MODAL,
	UPDATE_BIDDER_LAYOUT,
	CATALOGUE_NAV_FORWARD,
	CATALOGUE_NAV_BACK,
	UPDATE_AUCTION_INCREMENTS,
	RESET,
	SET_VIEWERINTERFACE,
	UPDATE_WELCOMEMODAL,
	UPDATE_MOBILE_PAGE,
	UPDATE_MOBILE_CAT_INDEX,
	SET_SHOW_MAIN_VIDEO,
	SET_USEMOBILELAYOUT,
	SET_FUTURE_AUTOBID_PANEL,
	SET_SWIPE_PANEL,
	AUTOBID_MESSAGE,
	SET_AUTOBID_STATE,
	SET_MOBILE_CAT_SCROLL,
	RESET_AUTOBID_STATE,
	SET_AUTOBID_TARGET,
	UPDATE_POST_PASS,
	TRIGGER_WIN_ANIMATION,
} from 'store/actions/bidder';

const initialState = {
	modalOpen: false,
	modalLotIndex: null,
	welcomeModal: null,
	layout: 1,
	activePanel: null,
	catalogueNavLog: [],
	catalogueLocation: null,
	auctionIncrements: null,
	useViewerInterface: false,
	mobilePage: 'bidding',
	catIndex: 0,
	showMainVideo: true,
	useMobileLayout: false,
	futureAutobidPanel: 'closed',
	swipePanel: 'closed',
	autobidMessage: null,
	autobidState: {
		states: {
			open: 0,
			confirming: 1,
			closed: 2,
			lotClosed: 3,
		},
		currentState: 0,
	},
	mobileCatalogueScrollPosition: 0,
	autobidTarget: null,
	latency: {
		outbound: null,
		return: null,
		measurementInProgress: false,
	},
	postPassSuccess: false,
	winAnimation: null,
	winAnimationLotID: null,
};

export default function bidder(state = initialState, action) {
	let returnState = Object.assign({}, state);
	switch (action.type) {
		case RESET:
			returnState = initialState;
			return returnState;

		case BIDDER_OPEN_MODAL:
			returnState.modalOpen = action.modalType;
			returnState.modalLotIndex = action.data;
			returnState.autobidState = initialState.autobidState;
			return returnState;

		case BIDDER_CLOSE_MODAL:
			returnState.modalOpen = false;
			returnState.modalLotIndex = null;
			return returnState;

		case UPDATE_BIDDER_LAYOUT:
			// if (
			// 	state.layout === action.code &&
			// 	state.activePanel === action.activePanel
			// ) {
			// 	returnState.layout = 0;
			// 	returnState.activePanel = null;
			// } else {
			// 	returnState.layout = action.code;
			// 	returnState.activePanel = action.activePanel;
			// }
			returnState.layout = action.code;
			return returnState;

		case CATALOGUE_NAV_FORWARD:
			returnState.catalogueNavLog.push(action.newLocation);
			returnState.catalogueLocation = action.newLocation;
			return returnState;

		case CATALOGUE_NAV_BACK:
			returnState.catalogueNavLog.pop();
			returnState.catalogueLocation =
				returnState.catalogueNavLog[returnState.catalogueNavLog.length - 1];
			return returnState;

		case UPDATE_AUCTION_INCREMENTS:
			returnState.auctionIncrements = action.data?.bidIncOpt;
			return returnState;

		case SET_VIEWERINTERFACE:
			returnState.useViewerInterface = action.bool;
			return returnState;

		case UPDATE_WELCOMEMODAL:
			returnState.welcomeModal = action.data;
			return returnState;

		case UPDATE_MOBILE_PAGE:
			returnState.mobilePage = action.string;
			return returnState;

		case UPDATE_MOBILE_CAT_INDEX:
			returnState.catIndex = action.index;
			return returnState;

		case SET_SHOW_MAIN_VIDEO:
			returnState.showMainVideo = action.bool;
			return returnState;

		case SET_USEMOBILELAYOUT:
			returnState.useMobileLayout = action.bool;
			return returnState;

		case SET_FUTURE_AUTOBID_PANEL:
			if (
				state.autobidState.currentState === state.autobidState.states.closed
			) {
				// autobid is live.
				if (action.string === 'open') returnState.futureAutobidPanel = 'mid';
				else returnState.futureAutobidPanel = 'closed';
				return returnState;
			}
			returnState.futureAutobidPanel = action.string;
			return returnState;

		case SET_SWIPE_PANEL:
			returnState.swipePanel = action.string;
			return returnState;

		case AUTOBID_MESSAGE:
			returnState.autobidMessage = action.string;
			return returnState;

		case SET_AUTOBID_STATE:
			let newAutobidState = { ...returnState.autobidState };
			newAutobidState.currentState = action.num;
			returnState.autobidState = newAutobidState;

			if (
				action.num === state.autobidState.states.open ||
				action.num === state.autobidState.states.confirming
			) {
				returnState.futureAutobidPanel = 'open';
			}
			if (action.num === state.autobidState.states.closed)
				returnState.futureAutobidPanel = 'mid';

			return returnState;

		case SET_MOBILE_CAT_SCROLL:
			returnState.mobileCatalogueScrollPosition = action.num;
			return returnState;

		case RESET_AUTOBID_STATE:
			returnState.autobidState = initialState.autobidState;
			returnState.futureAutobidPanel = initialState.futureAutobidPanel;
			return returnState;

		case SET_AUTOBID_TARGET:
			returnState.autobidTarget = action.target;
			return returnState;

		case UPDATE_POST_PASS:
			returnState.postPassSuccess = action.bool;
			return returnState;

		case TRIGGER_WIN_ANIMATION:
			if (action.lotID === state.winAnimationLotID) return state;
			returnState.winAnimation = action.num;
			returnState.winAnimationLotID = action.lotID;
			return returnState;

		default:
			return state;
	}
}
