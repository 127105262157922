import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHome,
	faGavel,
	faTag,
	faAward,
	faExclamationCircle,
	faSignalStream,
} from '@fortawesome/pro-regular-svg-icons';

import useConfig from 'hooks/useConfig';
import { capitaliseFirst } from 'utils/utils';
import styles from './AuctionSummary.module.scss';

const AuctionSummary = () => {
	const config = useConfig();
	const { auctionDetail, saleOutcome } =
		useSelector(state => state.adminpages) || {};
	const { auctionID, totalLots, sold, passed, upcoming } = saleOutcome || {};
	const { auctionTitle, auctionDate } = auctionDetail || {};
	const localeDate =
		auctionDate && new Date(auctionDate).toLocaleDateString();

	if (!auctionTitle) return <div className={styles.container} />;

	return (
		<div className={styles.container}>
			<div className={classNames(styles.card, styles.bgColor)}>
				<div className={styles.icon}>
					<FontAwesomeIcon icon={faHome} />
				</div>
				<div className={styles.title}>{auctionTitle}</div>
				<div className={styles.value}>{localeDate}</div>
			</div>
			<div className={styles.card}>
				<div className={styles.icon}>
					<FontAwesomeIcon icon={faGavel} />
				</div>
				<div className={styles.title}>
					{capitaliseFirst(config?.auctionID)}
				</div>
				<div className={styles.value}>{auctionID}</div>
			</div>
			<div className={styles.card}>
				<div className={styles.icon}>
					<FontAwesomeIcon icon={faTag} />
				</div>
				<div className={styles.title}>
					{capitaliseFirst(config?.totalLots)}
				</div>
				<div className={styles.value}>{totalLots}</div>
			</div>
			<div className={styles.card}>
				<div className={styles.icon}>
					<FontAwesomeIcon icon={faAward} />
				</div>
				<div className={styles.title}>
					{capitaliseFirst(config?.soldLots)}
				</div>
				<div className={styles.value}>{sold}</div>
			</div>
			<div className={styles.card}>
				<div className={styles.icon}>
					<FontAwesomeIcon icon={faExclamationCircle} />
				</div>
				<div className={styles.title}>
					{capitaliseFirst(config?.passedLots)}
				</div>
				<div className={styles.value}>{passed}</div>
			</div>
			<div className={styles.card}>
				<div className={styles.icon}>
					<FontAwesomeIcon icon={faSignalStream} />
				</div>
				<div className={styles.title}>
					{capitaliseFirst(config?.upcomingLots)}
				</div>
				<div className={styles.value}>{upcoming}</div>
			</div>
		</div>
	);
};

export default AuctionSummary;
