import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import requestDefaultsProtocol from 'api/requests/common/Req-DefaultsProtocol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUndo,
	faCheck,
	faArrowUp,
	faSpinner,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import 'styles/adminpages/settings/settings.scss';

const Protocols = props => {
	const protocols = Object.keys(props.defaultsProtocol)
		.filter(
			propName =>
				propName === 'defaultURL' ||
				propName === 'loginFailURL' ||
				propName === 'postLogoutURL'
		)
		.map((propName, index) => (
			<Protocol
				{...props.defaultsProtocol[propName]}
				{...{ propName }}
				key={index}
			/>
		));

	return (
		<div className={props.className}>
			<div className="row__title large-20-font-size">
				{props?.config?.defaultURLs}
			</div>
			{protocols.map(protocol => protocol)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		defaultsProtocol: state.adminpages?.defaultsProtocol,
		config: state.config,
	};
};
export default connect(mapStateToProps)(Protocols);

const Protocol = props => {
	const uploadIcon = <FontAwesomeIcon className="icon" icon={faArrowUp} />;
	const loadingIcon = (
		<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
	);
	const successIcon = <FontAwesomeIcon className="icon" icon={faCheck} />;
	const failureIcon = <FontAwesomeIcon className="icon" icon={faTimes} />;

	const [buttonIcon, setButtonIcon] = useState(uploadIcon);
	const [buttonClass, setButtonClass] = useState(
		'bg-primary-color-light admin-accent-1'
	);
	const [resetFeedback, setResetFeedback] = useState(false);
	const [protocol, setProtocol] = useState(props.valueOpt || '');

	useEffect(() => {
		setProtocol(props?.valueOpt || '');
	}, [props.valueOpt]);

	const onSubmit = () => {
		setButtonIcon(loadingIcon);
		let data = { [props.propName]: { valueOpt: protocol, update: true } };
		requestDefaultsProtocol(data)
			.then(data => {
				if (data.result > 100 || data?.isAxiosError) {
					showFailure();
					return;
				}
				showSuccess();
			})
			.catch(() => showFailure());
	};

	const onResetClick = () => {
		setProtocol(props.valueOpt);
		setResetFeedback(true);
		setTimeout(() => setResetFeedback(false), 1000);
	};

	const showSuccess = () => {
		setButtonIcon(successIcon);
		setButtonClass('primary-color-light bg-admin-accent-1');
		setTimeout(() => {
			setButtonIcon(uploadIcon);
			setButtonClass('bg-primary-color-light admin-accent-1');
		}, 1000);
	};

	const showFailure = () => {
		setButtonIcon(failureIcon);
		setButtonClass('bg-warning-color primary-color-light');

		setTimeout(() => {
			setButtonIcon(uploadIcon);
			setButtonClass('bg-primary-color-light admin-accent-1');
		}, 1000);
	};

	return (
		<div className="protocol">
			<div className="protocol__title">{props.propName}</div>
			<input
				className="protocol__value border-secondary-color-dark"
				onChange={event => setProtocol(event.target.value)}
				value={protocol}
			/>
			<button
				className={`protocol__reset border-secondary-color-dark ${
					resetFeedback
						? 'primary-color-light bg-admin-accent-2'
						: 'bg-primary-color-light admin-accent-2'
				}`}
				onClick={() => onResetClick()}
			>
				<FontAwesomeIcon className="protocol__icon" icon={faUndo} />
			</button>

			<button
				className={`protocol__submit border-secondary-color-dark ${buttonClass}`}
				onClick={() => {
					onSubmit();
				}}
			>
				{buttonIcon}
			</button>
		</div>
	);
};
