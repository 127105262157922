import { Interweave } from 'interweave';

import LazyImage from 'components/common/lazyimage';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import useCurrentLot from 'hooks/useCurrentLot';
import useBidsFeed from 'hooks/useBidsFeed.js';
import styles from './currentlot.module.scss';
import { isValidOfferPrice } from 'utils/utils';

const CurrentLot = () => {
	const config = useConfig();
	const { catalogueLot } = useCurrentLot();
	const { isWithdrawn, lotNumber, absImg, title, artist } =
		catalogueLot || {};
	const { getFormattedCurrency } = useCurrency();
	const { displayList } = useBidsFeed();

	const bids = displayList.filter(bid => bid?.offerID);
	const latestBid = bids?.length && bids[0];
	const latestBidValue = latestBid?.offerPrice;
	const displayValue = isValidOfferPrice(latestBidValue)
		? getFormattedCurrency(latestBidValue)
		: config?.noBidsYet;

	if (isWithdrawn) return null;

	return (
		<div className={styles.currentLot}>
			<LazyImage
				className={styles.image}
				url={(absImg && absImg[0]) || ''}
			/>
			<div className={styles.details}>
				<div className={styles.lotNumber}>
					{config?.lot} {lotNumber}
				</div>
				<div className={styles.title}>
					<Interweave content={title} />
				</div>
				{artist && (
					<div className="details__main__title">
						<Interweave content={artist} />
					</div>
				)}
				<div>
					<div className={styles.currentBidLabel}>
						{config?.currentBid}
					</div>
					<div className={styles.currentBidValue}>{displayValue}</div>
				</div>
			</div>
		</div>
	);
};

export default CurrentLot;
