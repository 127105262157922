import { connect } from 'react-redux';
import { useState } from 'react';
import requestDeleteBidder from 'api/requests/admin/Req-DeleteBidder';
import requestDeleteStaff from 'api/requests/admin/Req-DeleteStaff';
import { Link } from 'react-router-dom';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrashAlt,
	faPencil,
	faKey,
	faCheck,
	faTimes,
	faUser,
} from '@fortawesome/pro-solid-svg-icons';

const UserRow = props => {
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	const onDeleteConfirm = () => {
		setShowDeleteConfirm(false);
		if (props.userType === 'bidder') {
			requestDeleteBidder(props.userID);
			return;
		}
		requestDeleteStaff(props.userID);
	};

	const onEditClick = () => {
		store.dispatch(AdminPageActions.editingUser(props.userID));
	};

	return (
		<tr data-testid="users-row">
			<td>{props.altID}</td>
			<td>
				<div className="adminpages__users__table__profile">
					<FontAwesomeIcon className="icon" icon={faUser} />
					{props.firstName + ' ' + props.lastName}
				</div>
			</td>
			<td>{props.email}</td>
			<td>{props.userType}</td>

			{props.userType === 'bidder' && (
				<td>
					<button
						className="adminpages__users__detailsaction primary-color-light bg-admin-accent-1 hover-bg-admin-accent-offset-1"
						onClick={() => props.onPaddleDetailsClick(props.userUUID)}
						data-testid="users-details"
					>
						{props?.config?.detail}
					</button>
				</td>
			)}

			{props.userType === 'viewer' && <td>{props?.config?.viewer}</td>}

			<td>
				{!showDeleteConfirm && (
					<div className="adminpages__users__row__controls">
						<button
							className="adminpages__users__row__btn delete"
							onClick={() => setShowDeleteConfirm(true)}
							data-testid="users-delete"
						>
							<FontAwesomeIcon
								className="adminpages__users__row__icon"
								icon={faTrashAlt}
							/>
							<div className="adminpages__users__tooltip">
								{props?.config?.delete}
							</div>
						</button>
						<Link to={`/adminpages/edituser`}>
							<button
								className="adminpages__users__row__btn edit"
								onClick={onEditClick}
								data-testid="users-edit"
							>
								<FontAwesomeIcon
									className="adminpages__users__row_icon"
									icon={faPencil}
								/>
								<div className="adminpages__users__tooltip">
									{props?.config?.edit}
								</div>
							</button>
						</Link>
						<button
							className="adminpages__users__row__btn reset"
							onClick={() => props.onResetPasswordClick(props)}
							data-testid="users-reset"
						>
							<FontAwesomeIcon
								className="adminpages__users__row__icon"
								icon={faKey}
							/>
							<div className="adminpages__users__tooltip">
								{props?.config?.resetPassword}
							</div>
						</button>
					</div>
				)}
				{showDeleteConfirm && (
					<div className="adminpages__users__row__controls">
						<div className="delete__confirm">
							{props?.config?.deleteConfirm}
						</div>
						<button
							className="adminpages__users__row__btn bg-admin-accent-1 primary-color-light border-secondary-color-dark hover-bg-admin-accent-offset-1"
							onClick={onDeleteConfirm}
							data-testid="users-delete-confirm"
						>
							<FontAwesomeIcon
								className="adminpages__users__row__icon"
								icon={faCheck}
							/>
						</button>
						<button
							className="adminpages__users__row__btn bg-warning-color primary-color-light border-secondary-color-dark hover-bg-warning-color-offset"
							onClick={() => setShowDeleteConfirm(false)}
						>
							<FontAwesomeIcon
								className="adminpages__users__row__icon"
								icon={faTimes}
							/>
						</button>
					</div>
				)}
			</td>
		</tr>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(UserRow);
