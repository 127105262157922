import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';

import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import 'styles/bidderinterface/bidderinterface.scss';
import 'styles/bidderinterface/biddertransitions.scss';
import Catalogue from 'components/bidderinterface/catalogue/catalogue';
import { CurrentLot } from 'components/bidderinterface/currentlot/currentlot';
import VideoFeed from 'components/bidderinterface/videofeed/videofeed';
import { BiddingPanel } from 'components/bidderinterface/biddingpanel/biddingpanel';
import BidsFeed from 'components/bidderinterface/bidsfeed/bidsfeed';
import LotsFeed from 'components/bidderinterface/lotsfeed/lotsfeed';
import LotModal from 'components/bidderinterface/modal/lotModal/lotmodal';
import { WelcomeModal } from 'components/bidderinterface/modal/welcomemodal/welcomemodal';
import DebugModal from 'components/debugmodal/debugmodal';
import BidderMobile from 'components/bidderinterface/mobile/mobile';
import ViewerPanel from 'components/viewerinterface/viewerpanel';
import { Desktop, Mobile } from 'components/viewerinterface';
import BidderAudioController from 'components/bidderinterface/bidderaudiocontroller/bidderaudiocontroller';
import requestCatUpdate from 'api/requests/common/Req-CatUpdate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

class BidderInterface extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bidder: this.props.bidder,
			showModalCheckComplete: false,
			layoutTransition: '',
		};
	}

	componentDidMount() {
		window.API.checkWindowSize();
	}

	componentDidUpdate(prevProps) {
		if (this.props.bidder.layout !== prevProps.bidder.layout) {
			this.setState({
				layoutTransition: `layout-${prevProps.bidder.layout}-${this.props.bidder.layout}`,
			});
		}

		if (this.props.bidder !== prevProps.bidder) {
			let newBidderState = this.props.bidder;

			this.setState({
				bidder: newBidderState,
			});
		}

		if (this.props?.login?.loginDetails && !this.state.showModalCheckComplete) {
			this.setState({ showModalCheckComplete: true });
			if (
				this.props?.login?.loginDetails?.showTandCs &&
				window?.API?.user?.userPerm?.includes('bidder')
			)
				store.dispatch(BidderActions.bidderOpenModal('welcome'));
		}

		if (
			this.props.bidder?.currentLotDetailOpt?.isLocked !==
			prevProps.bidder?.currentLotDetailOpt?.isLocked
		) {
			if (
				this.props.bidder?.currentLotDetailOpt?.currentLotID ===
				prevProps.bidder?.currentLotDetailOpt?.currentLotID
			) {
				requestCatUpdate(
					this.props.bidder?.currentLotDetailOpt?.currentLotIndex,
					1
				);
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this.transitionTimeout);
		this.transitionTimeout = null;
		clearTimeout(this.latencyTimeout);
		this.latencyTimeout = null;
		store.dispatch(BidderActions.updateBidderLayout(0, null));
	}

	getSidePanelClass() {
		return `bidderinterface__sidepanel 
        ${this.state.bidder.layout === 1 ? 'small' : ''} 
        ${this.state.bidder.layout === 2 ? 'large' : ''}`;
	}

	render() {
		if (!window?.API?.user)
			return (
				<div className="bidderinterface">
					<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
				</div>
			);

		if (
			this.props.bidder.useViewerInterface ||
			window.location.hostname.includes('viewerinterface')
		) {
			if (this.props.useMobileLayout) {
				return <Mobile />;
			}
			return <Desktop />;
		}

		if (this.props.useMobileLayout) {
			return <BidderMobile {...this.state} useMobileLayout={true} />;
		}

		let layout = `layout_${this.props.bidder.layout} ${this.state.layoutTransition}`;

		return (
			<div className="bidderinterface">
				{this.props.bidder.bidState ? <BidderAudioController /> : null}
				{/* {!this.props.bidder.useViewerInterface && (
					<Sidebar activePanel={this.state.bidder.activePanel} />
				)} */}
				{this.state.bidder.debugModalOpen && <DebugModal {...this.state} />}
				{this.state.bidder.modalOpen &&
					this.state.bidder.modalOpen === 'lot' && <LotModal {...this.state} />}
				{this.state.bidder.modalOpen &&
					this.state.bidder.modalOpen === 'welcome' && (
						<WelcomeModal {...this.state} />
					)}

				<div
					className={`bidderinterface__sidepanel ${this.state.layoutTransition}`}
				>
					<div
						className={`bidderinterface__sidepanel__container ${
							this.state.bidder.activePanel ? '' : 'hidden'
						}`}
					>
						{/* <Wishlist
							bidder={this.state.bidder}
							hidden={this.state.bidder.activePanel !== 'wishlist'}
							layoutTransition={this.state.layoutTransition}
						/> */}
						<Catalogue
							// bidder={this.state.bidder}
							hidden={this.state.bidder.activePanel !== 'catalogue'}
							layoutTransition={this.state.layoutTransition}
						/>
					</div>
				</div>

				<div
					className={`bidderinterface__container ${
						this.props.bidder.useViewerInterface ? 'viewermode' : ''
					} ${this.state.bidder.layout === 1 ? 'small-open' : ''} ${
						this.state.bidder.layout === 2 ? 'large-open' : ''
					} ${this.state.layoutTransition}`}
				>
					<div className={'bidderinterface__container__elastic'}>
						<div className={`bidderinterface__container__row-1`}>
							<CurrentLot />
							<div
								className={`bidderinterface__container__row-1__mainpanel ${layout}`}
							>
								<VideoFeed bidder={this.state.bidder} />
								{this.props.bidder.useViewerInterface ? (
									<ViewerPanel bidder={this.state.bidder} />
								) : (
									<BiddingPanel bidder={this.state.bidder} />
								)}
							</div>
							<BidsFeed />
						</div>
						<div className="bidderinterface__container__row-2">
							<LotsFeed {...this.state} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		bidder: { ...state.bidder, ...state.auction },
		config: state.config,
		login: state.login,
		useMobileLayout: state.bidder?.useMobileLayout,
	};
};

export default connect(mapStateToProps)(BidderInterface);
