export const UPDATE_IS_PAUSED = "UPDATE_IS_PAUSED";
export const UPDATE_IS_MUTED = 'UPDATE_IS_MUTED';

export default class VideoActions {

    static updateIsPaused(bool) {
        return function(dispatch) {
            return dispatch({
                type: UPDATE_IS_PAUSED,
                bool
            })
        }
    }
    
    static updateIsMuted(bool) {
        return function(dispatch) {
            return dispatch({
                type: UPDATE_IS_MUTED,
                bool
            })
        }
    }

}