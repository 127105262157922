import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_UPDATELOT: 'Req-UpdateLot',
export default function requestUpdateLot(data) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-UpdateLot',
		userTokenID: window.API?.user?.userToken,
		...data,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.updateLotStatus(data));
	console.debug('Req-UpdateLot success', data);
};

const handleFailure = data => {
	store.dispatch(AdminPageActions.updateLotStatus(data));
	console.debug('Req-UpdateLot failure', data);
};
