import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import requestUpdateBidderDefaults from 'api/requests/bidder/Req-UpdateBidderDefaults';
import classNames from 'classnames';
import { setConfig } from 'api/requests/configs/getConfigs';
import styles from './LanguagePicker.module.scss';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const LanguagePicker = () => {
	const showLanguagePicker = useSelector(state => state.showFlags?.showLanguagePicker);
	const data = useSelector(state => state.login?.locales);
	const useMobileLayout = useSelector(state => state.bidder?.useMobileLayout);
	const { defConfigURL, locales } = data || {};
	const [active, setActive] = useState([]);

	useEffect(
		() => setActive(locales?.find(locale => locale?.isCurrent)?.code),
		[locales]
	);

	if (!showLanguagePicker || !locales || useMobileLayout) return false;

	const flagPicker = code => {
		switch (code) {
			case 'en-GBR':
				return <span className="fi fi-gb"></span>;
			case 'en-USA':
				return <span className="fi fi-us"></span>;
			case 'nl-NLD':
				return <span className="fi fi-nl"></span>;
			case 'de-AUT':
				return <span className="fi fi-at"></span>;
			case 'de-DEU':
				return <span className="fi fi-de"></span>;

			default:
				return code;
		}
	};

	const changeConfig = code => {
		requestUpdateBidderDefaults(code, null);
		setConfig(defConfigURL, code).then(code =>
			code ? setActive(code) : null
		);
	};

	// if (locales.length < 2) return <div/>

	return (
		<div className={styles.container}>
			{locales
				.sort((a, b) => a.code > b.code)
				.map((locale, index) => {
					const { code } = locale || {};
					return (
						<button
							className={classNames(styles.button, {
								[styles.active]: code === active,
							})}
							onClick={() => changeConfig(code)}
							key={index}
							title={code}
						>
							{flagPicker(code)}
						</button>
					);
				})}
		</div>
	);
};

export default LanguagePicker;
