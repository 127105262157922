import { useSelector } from 'react-redux';

export default function useCurrentLot() {
	const currentLot = useSelector(state => {
		const currentLotID = state?.auction?.currentLotDetailOpt?.currentLotID;
		const lotList = state?.auction?.lotList;

		if (!currentLotID || !lotList?.length) return null;

		const currentLot = lotList?.find(
			lot => lot.catalogueLot.lotID === currentLotID
		);
		if (!currentLot) return null;
		return currentLot;
	});

	return currentLot || {};
}
