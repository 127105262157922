import { useState } from 'react';

const useButtons = ({ lowIncrementOpt, incrementOpt, highIncrementOpt }) => {
	const [buttons, setButtons] = useState([
		{
			value: lowIncrementOpt,
			isActive: false,
			incTypeOpt: 1,
		},
		{
			value: incrementOpt,
			isActive: true,
			incTypeOpt: 0,
		},
		{
			value: highIncrementOpt,
			isActive: false,
			incTypeOpt: 2,
		},
	]);

	const setButtonIncrements = ({
		lowIncrementOpt,
		incrementOpt,
		highIncrementOpt,
	}) => {
		const low = Object.assign(buttons[0], { value: lowIncrementOpt });
		const mid = Object.assign(buttons[1], { value: incrementOpt });
		const high = Object.assign(buttons[2], { value: highIncrementOpt });

		setButtons([low, mid, high]);
	};

	const setActiveButton = incTypeOpt => {
		const newButtons = buttons.map(button => {
			if (incTypeOpt === button?.incTypeOpt) button.isActive = true;
			else button.isActive = false;
			return button;
		});
		setButtons(newButtons);
	};

	return { buttons, setButtonIncrements, setActiveButton };
};

export default useButtons;
