import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import { storeLatency, getLatency } from '../latency';
import store from 'store/store';
import ClerkActions from 'store/actions/clerk';

//REQ_SALE: 'Req-Sale'
export default function requestSale(offerID, sell, saleNote) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-Sale',
		userTokenID: window.API?.user?.userToken,
		offerID,
		sell,
		saleNote,
		tS: Date.now(),
		lastLatency: getLatency(),
	})
		.then(response => {
			const { data } = response;
			if (data.tS) storeLatency(data.tS);
			store.dispatch(ClerkActions.setSaleControlReqInFlight(false));
		})
		.catch(error => console.debug(error));
}
