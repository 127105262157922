import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import requestAuctions from 'api/requests/common/Req-Auctions';
import Controls from './controls';
import Table from './table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import 'styles/adminpages/home/auctionlist.scss';

const AuctionList = props => {
	const [showLoadSpinner, setShowLoadSpinner] = useState(true);
	const [auctionsList, setAuctionsList] = useState([]);
	const [resultsPerPage, setResultsPerPage] = useState(100);
	const [currentPage, setCurrentPage] = useState(0);
	const [firstRowIndex, setFirstRowIndex] = useState(0);
	const [lastRowIndex, setLastRowIndex] = useState(10);
	const [showArchivedFilter, setShowArchivedFilter] = useState(false);
	const [totalFilteredAuctions, setTotalFilteredAuctions] = useState(null);
	const [filterInput, setFilterInput] = useState('');
	const [sort, setSort] = useState({
		prop: 'auctionDate',
		direction: 'ascending',
	});

	useEffect(() => {
		if (window?.API?.user && !props.auctions?.length) requestAuctions();
		if (!props?.auctionID) return;

		let reissueOpt = {
			auctionHouseIDOpt: props?.auctionHouseID,
			auctionIDOpt: null,
		};

		window.API.validationCallBack = function () {
			window.API.validationCallBack = null;
			this.pushHistory('/adminpages');
		};

		if (!window?.API?.user?.userToken) return;
		window.API.tokenValidation(window?.API?.user?.userToken, reissueOpt);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (auctionsList?.length && showLoadSpinner) setShowLoadSpinner(false);
		// eslint-disable-next-line
	}, [auctionsList]);

	useEffect(() => {
		prepareAuctionsList();
		// eslint-disable-next-line
	}, [
		props.auctions,
		lastRowIndex,
		sort,
		resultsPerPage,
		currentPage,
		showArchivedFilter,
	]);

	useEffect(() => {
		setCurrentPage(0);
		prepareAuctionsList();
		// eslint-disable-next-line
	}, [filterInput]);

	const onPerPageChange = event => {
		setResultsPerPage(Number(event.target.value));
		setCurrentPage(0);
	};

	const onPaginationChange = bool => {
		if (bool) {
			let lastPage =
				(currentPage + 1) * resultsPerPage >= props?.auctions?.length;
			if (lastPage) return;
			setCurrentPage(currentPage + 1);
			return;
		}
		if (currentPage < 1) return;
		setCurrentPage(currentPage - 1);
	};

	const onFilterInputChange = event => setFilterInput(event.target.value);

	const onSortColumn = prop => {
		if (!sort || sort?.prop !== prop) {
			setSort({ prop, direction: 'descending' });
			return;
		}

		if (sort?.prop === prop) {
			if (sort.direction === 'descending') {
				setSort({ prop, direction: 'ascending' });
				return;
			}
			setSort({ prop, direction: 'descending' });
			return;
		}
	};

	const prepareAuctionsList = () => {
		if (!props?.auctions?.length) return [];
		let newAuctionsList = [...props?.auctions];

		newAuctionsList = applyArchiveFilter(newAuctionsList);
		newAuctionsList = applySearchFilter(newAuctionsList);
		newAuctionsList = applySort(newAuctionsList);
		newAuctionsList = applyPagination(newAuctionsList);

		setAuctionsList(newAuctionsList);
	};

	const onArchiveToggle = () => {
		setShowArchivedFilter(!showArchivedFilter);
	};

	const applyArchiveFilter = newAuctionsList => {
		if (showArchivedFilter) return newAuctionsList;

		return [...newAuctionsList].filter(auction => !auction.isArchived);
	};

	const applySearchFilter = newAuctionsList => {
		if (!filterInput) {
			setTotalFilteredAuctions(props?.auctions?.length);
			return newAuctionsList;
		}

		const target = filterInput.toLocaleLowerCase();
		const filteredAuctions = [...newAuctionsList].filter(
			auction => auction.auctionTitle.toLocaleLowerCase().indexOf(target) !== -1
		);
		setTotalFilteredAuctions(filteredAuctions.length);
		return filteredAuctions;
	};

	const applySort = newAuctionsList => {
		if (!sort) return newAuctionsList;

		return [...newAuctionsList].sort((a, b) => {
			let aProp;
			let bProp;

			if (sort.prop === 'lotCount') {
				aProp = a[sort?.prop];
				bProp = b[sort?.prop];
			} else {
				aProp = a[sort?.prop]
					? a[sort?.prop].toString().toLocaleLowerCase()
					: 'z';
				bProp = b[sort?.prop]
					? b[sort?.prop].toString().toLocaleLowerCase()
					: 'z';
			}

			if (sort.direction === 'ascending') {
				if (aProp > bProp) return -1;
				if (aProp < bProp) return 1;
			} else {
				if (aProp < bProp) return -1;
				if (aProp > bProp) return 1;
			}
			return 0;
		});
	};

	const applyPagination = newAuctionsList => {
		let firstRowIndex = currentPage * resultsPerPage;
		let lastRowIndex = firstRowIndex + resultsPerPage;
		let totalAuctions = newAuctionsList?.length;

		if (lastRowIndex > totalAuctions) lastRowIndex = totalAuctions;

		setFirstRowIndex(firstRowIndex);
		setLastRowIndex(lastRowIndex);

		return [...newAuctionsList].filter((auction, index) => {
			return index >= firstRowIndex && index < lastRowIndex;
		});
	};

	if (showLoadSpinner)
		return (
			<div className="auctionlist__loading">
				{props?.config?.loading}{' '}
				<FontAwesomeIcon className="fa-spin" icon={faSpinner} />
			</div>
		);

	return (
		<div className="adminpages__home">
			<div className="auctionlist bg-primary-color-light">
				<div className="auctionlist__row__1">
					<div className="auctionlist__header large-24-font-size">
						{props?.config?.auctionsList}
					</div>
				</div>

				<Controls
					firstRowIndex={firstRowIndex}
					lastRowIndex={lastRowIndex}
					totalAuctions={
						totalFilteredAuctions
							? totalFilteredAuctions
							: props?.auctions?.length
					}
					onPaginationChange={onPaginationChange}
					onPerPageChange={onPerPageChange}
					onFilterInputChange={onFilterInputChange}
					showArchivedFilter={showArchivedFilter}
					onArchiveToggle={onArchiveToggle}
				/>

				<Table
					auctionsList={auctionsList}
					auctionHouseID={props?.auctionHouseID}
					onSortColumn={onSortColumn}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	const auctionHouse = state.login.auctionHouses[0];
	const auctions = auctionHouse?.auctions || [];

	return {
		auctions,
		auctionID: state.login?.loginDetails?.auction?.auctionID,
		auctionHouseID: state.login?.loginDetails?.auctionHouse?.auctionHouseID,
		config: state?.config,
	};
};

export { AuctionList };
export default connect(mapStateToProps)(AuctionList);
