import useConfig from 'hooks/useConfig';
import classNames from 'classnames';
import styles from './InfoCard.module.scss';

const InfoCard = ({ isCurrentLot, showAutobid, showLiked }) => {
	const config = useConfig();

	if (isCurrentLot)
		return (
			<div className={classNames(styles.container, styles.currentItem)}>
				<div className={styles.text}>{config?.currentItem}</div>
			</div>
		);

	if (showAutobid)
		return (
			<div className={classNames(styles.container, styles.autoBid)}>
				<div className={styles.text}>{config?.autobid}</div>
			</div>
		);

	if (showLiked && !isCurrentLot)
		return (
			<div className={classNames(styles.container, styles.wishlist)}>
				<div className={styles.text}>{config?.wishlisted}</div>
			</div>
		);
};

export default InfoCard;
