import './focusMessage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

const FocusMessage = ({ isLive }) => {
	const isFocused = document.hasFocus();
	console.log(isFocused, isLive);
	if (isFocused || isLive) return null;
	return (
		<div className="focus-message">
			<div className="body">
				<FontAwesomeIcon className="icon" icon={faInfoCircle} />
				This window requires focus to detect audio & video devices.
				Click anywhere in this browser window to restore focus.
			</div>
		</div>
	);
};

export default FocusMessage;
