import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLatency } from 'api/requests/latency';
import requestState from 'api/requests/common/Req-State';
import { faCheck, faTimes, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import './connection.scss';

const Connection = () => {
	const config = useSelector(state => state?.config);
	const [buttonValue, setButtonValue] = useState(config?.sendTest);
	const [errorMessage, setErrorMessage] = useState(null);

	const getPortStatus = () => {
		const portStatus = window.API.clerkPort === 4443;
		if (portStatus)
			return (
				<span>
					{window.API.clerkPort}{' '}
					<FontAwesomeIcon
						className="icon status-pass"
						icon={faCheck}
					/>
				</span>
			);
		return (
			<span>
				{window.API.clerkPort} {config?.checkFirewall} 4443{' '}
				<FontAwesomeIcon className="icon status-fail" icon={faTimes} />
			</span>
		);
	};

	const getWssStatus = () => {
		const wssStatus = window.API?.wssConn?.has_wss_connection();
		if (wssStatus)
			return (
				<span>
					{config?.ok}
					<FontAwesomeIcon
						className="icon status-pass"
						icon={faCheck}
					/>
				</span>
			);

		return (
			<span>
				{config?.failedReconnect}
				<FontAwesomeIcon className="icon status-fail" icon={faTimes} />
			</span>
		);
	};

	const resetButton = () => {
		setTimeout(() => setButtonValue('Send Test'), 1500);
	};

	const sendTestPacket = () => {
		setButtonValue(
			<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
		);

		requestState()
			.then(response => {
				if (response.auctionID) {
					showTestPass();
					resetButton();
					setErrorMessage(null);
				} else {
					showTestFail();
					setErrorMessage(response.detail);
				}
				resetButton();
			})
			.catch(error => {
				showTestFail();
				setErrorMessage(error.toString());
				resetButton();
			});
	};

	const showTestPass = () => {
		setButtonValue(
			<span>
				{config?.ok}
				<FontAwesomeIcon className="icon status-pass" icon={faCheck} />
			</span>
		);
	};

	const showTestFail = () => {
		setButtonValue(
			<span>
				{config?.fail}
				<FontAwesomeIcon className="icon status-fail" icon={faTimes} />
			</span>
		);
	};

	const ping = getLatency();
	const latency = ping ? (
		`${ping}ms`
	) : (
		<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
	);

	return (
		<div className="connection">
			<div className="title large-20-font-size">
				{config?.connectionPanel}
			</div>
			<div className="report">
				<div className="subtitle">{config?.port}</div>
				<div className="status">{getPortStatus()}</div>
			</div>
			<div className="report">
				<div className="subtitle">{config?.yourLatency}</div>
				<div className="status">{latency}</div>
			</div>
			<div className="report">
				<div className="subtitle">{config?.websocketConnection}</div>
				<div className="status">{getWssStatus()}</div>
			</div>
			<div className="report">
				<div className="subtitle">{config?.testPacket}</div>
				<div className="status">
					<button
						className="test regular-16-font-size"
						onClick={sendTestPacket}
					>
						{buttonValue}
					</button>
				</div>
			</div>
			{errorMessage && (
				<div className="report">
					<div className="subtitle">{config?.testError}</div>
					<div className="status">{errorMessage}</div>
				</div>
			)}
		</div>
	);
};

export default Connection;
