import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import { Link } from 'react-router-dom';
import UsersModal from 'components/adminpages/users/usersmodal/usersmodal';
import UsersTable from 'components/adminpages/users/usersTable';
import { getAllBidders } from 'api/bidder/getBidders';
import { getAllClerks } from 'api/clerk/getClerks';
import { getAllAdmins } from 'api/admin/getAdmins';
import getBidder from 'api/bidder/getBidder';
import 'styles/adminpages/users/users.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

const Users = props => {
	const [activeTab, setActiveTab] = useState('clients');
	const [filterAuction, setFilterAuction] = useState('all');
	const [dateFilter, setDateFilter] = useState('all');
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(true);

	const { biddersTotal, clerksTotal, adminsTotal } = props?.userProgress || {};

	useEffect(() => {
		if (!props?.auctionID) return;

		let reissueOpt = {
			auctionHouseIDOpt: props?.auctionHouseID,
			auctionIDOpt: null,
		};

		window.API.validationCallBack = function () {
			window.API.validationCallBack = null;
			this.pushHistory('/adminpages/users');
		};

		if (!window?.API?.user?.userToken) return;
		window.API.tokenValidation(window?.API?.user?.userToken, reissueOpt);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (props.bidders.length) {
			setLoading(false);
			return;
		}
		Promise.all([getAllBidders(), getAllClerks(), getAllAdmins()]).then(() =>
			setLoading(false)
		);
		// eslint-disable-next-line
	}, [window?.API?.user?.userToken]);

	const onAddClick = () => {
		store.dispatch(AdminPageActions.editingUser(null));
	};

	const onTabChange = () => {
		setFilterAuction('all');
		setDateFilter('all');
	};

	const onPaddleDetailsClick = async userUUID => {
		const response = await getBidder(userUUID);
		if (response.result) return;
		setShowModal(true);
	};

	if (loading) {
		const loadedTotal = props.bidders?.length + props.staff?.length;
		const usersTotal = biddersTotal + clerksTotal + adminsTotal;
		const percentageLoaded = Math.round((loadedTotal / usersTotal) * 100) || 0;

		return (
			<div className={`adminpages__users bg-primary-color-light`}>
				<div className="loading">
					<div className="loadingdescription">
						<FontAwesomeIcon
							className="icon fa-spin large-24-font-size"
							icon={faSpinner}
						/>
						{usersTotal ? (
							<div style={{ marginLeft: '1rem' }}>
								Loading {loadedTotal} of {usersTotal} users
							</div>
						) : (
							<div style={{ marginLeft: '1rem' }}>Loading users</div>
						)}
					</div>
					<div className="loadingbarouter">
						<div
							className="loadingbarinner"
							style={{ transform: `translateX(-${100 - percentageLoaded}%)` }}
						/>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={`adminpages__users bg-primary-color-light`}>
			{showModal && (
				<UsersModal
					{...props}
					filterAuction={filterAuction}
					dateFilter={dateFilter}
					onShadowClick={() => {
						store.dispatch(AdminPageActions.setUserModal(null));
						setShowModal(false);
					}}
				/>
			)}
			<div className="adminpages__users__row row-1">
				<div className="adminpages__users__tabs">
					<div
						className={`adminpages__users__tab extra-large-34-font-size ${
							activeTab === 'clients' ? 'isActive' : ''
						}`}
						onClick={() => setActiveTab('clients')}
					>
						{props?.config?.clients}
					</div>
					<div className="adminpages__users__tab__spacer"></div>
					<div
						className={`adminpages__users__tab extra-large-34-font-size ${
							activeTab === 'staff' ? 'isActive' : ''
						}`}
						onClick={() => setActiveTab('staff')}
					>
						{props?.config?.staff}
					</div>
				</div>
				<Link to={`/adminpages/adduser`}>
					<button
						className="adminpages__users__add bg-admin-accent-1 primary-color-light border-secondary-color-dark large-20-font-size hover-bg-admin-accent-offset-1"
						onClick={onAddClick}
					>
						{props?.config?.createUser}
					</button>
				</Link>
			</div>

			<UsersTable
				{...props}
				activeTab={activeTab}
				filterAuction={filterAuction}
				setFilterAuction={setFilterAuction}
				dateFilter={dateFilter}
				setDateFilter={setDateFilter}
				onTabChange={onTabChange}
				users={activeTab === 'clients' ? props?.bidders : props?.staff}
				onPaddleDetailsClick={onPaddleDetailsClick}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	const auctionHouse = state.login.auctionHouses[0];
	const auctions = auctionHouse?.auctions || [];

	return {
		auctions,
		auctionID: state.login?.loginDetails?.auction?.auctionID,
		auctionHouseID: state.login?.loginDetails?.auctionHouse?.auctionHouseID,
		auctionHouseShortName:
			state.login?.loginDetails?.auctionHouse?.auctionHouseShortName,
		bidders: state.adminpages?.bidders,
		staff: state.adminpages?.staff,
		userProgress: state.adminpages?.userProgress,
		config: state?.config,
	};
};
export { Users };
export default connect(mapStateToProps)(Users);
