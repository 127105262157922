import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import LotCard from 'components/common/lotcard/lotcard';
import 'styles/bidderinterface/biddermobile/mobilecatalogue/mobilecatalogue.scss';

class Catalogue extends Component {
	constructor(props) {
		super(props);

		this.state = {
			debounceScroll: false,
			containerRef: null,
			lots: null,
			initialScrollDone: false,
		};
	}

	onRefChange = node => {
		this.setState({ containerRef: node });
	};

	componentDidMount() {
		if (this.props.lotList.length >= this.props.lotCount) {
			this.setState({ lots: this.props.lotList });
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.lotCount !== this.props.lotCount) return true;
		if (nextProps.lotList.length !== this.props.lotList.length) return true;
		if (nextProps.currentLot?.lotID !== this.props.currentLot?.lotID)
			return true;
		if (nextState.lots !== this.state.lots) return true;
		if (nextState.containerRef !== this.state.containerRef) return true;

		const nextPrevLot = this.getPrevLot(nextProps.lotList);
		const prevLot = this.getPrevLot(this.props.lotList);

		if (
			nextPrevLot?.lotSaleDetail?.displayAs !==
			prevLot?.lotSaleDetail?.displayAs
		)
			return true;

		return false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.containerRef && !prevState.containerRef) {
			if (this.props.mobileCatalogueScrollPosition)
				this.state.containerRef.scrollTo(
					this.props.mobileCatalogueScrollPosition
				);
			else this.scrollToCurrentLot(); //removed scrtoll to function
		}
		if (this.props.lotCount && !this.state.lots) {
			this.setInitialLotList();
		}

		if (this.props.lotList.length !== prevProps.lotList.length) {
			this.updateLotList();
		}

		if (this.props.lotList.length >= this.props.lotCount) {
			this.setState({ lots: this.props.lotList });
		}
	}

	componentWillUnmount() {
		clearTimeout(this.debounceTimeout);
		this.debounceTimeout = null;
	}

	getPrevLot(lotList) {
		const targetIndex = this.props.currentLot?.lotIndex - 1;
		if (targetIndex < 1) return null;

		return lotList.find(lot => lot.catalogueLot.lotIndex === targetIndex);
	}

	setInitialLotList() {
		let loadingArray = new Array(this.props.lotCount);
		loadingArray.fill({ loading: true });
		this.setState({
			lots: loadingArray,
		});
	}

	updateLotList() {
		if (!this.props.lotList.length) return;
		if (!this.state.lots) return;
		let newLots = [...this.state.lots];

		this.props.lotList.forEach(lot => {
			let index = lot.catalogueLot.lotIndex;
			newLots[index - 1] = lot;
		});
		if (!this.state.initialScrollDone) {
			this.scrollToCurrentLot();
		}

		this.setState({
			lots: newLots,
		});
	}

	handleScroll = event => {
		if (this.state.debounceScroll || !event.scrollOffset) return;

		this.setState({ debounceScroll: true });

		this.debounceTimeout = setTimeout(() => {
			this.setState({ debounceScroll: false });
		}, 100);

		store.dispatch(BidderActions.setMobileCatScroll(event.scrollOffset));
	};

	scrollToCurrentLot = () => {
		const list = this.state.containerRef;
		const currentLotID = this.props?.currentLotID;
		if (!list || !currentLotID) return;

		const { itemData, itemSize } = list?.props || {};
		if (!itemData || !itemSize) return;

		let targetRow = null;

		itemData.forEach((row, index) => {
			if (
				row[0]?.catalogueLot?.lotID === currentLotID ||
				row[1]?.catalogueLot?.lotID === currentLotID
			)
				targetRow = index;
		});

		let scrollTarget = targetRow * itemSize;
		if (!scrollTarget) return;

		list.scrollTo(scrollTarget);
		this.setState({ initialScrollDone: true });
	};

	onLotCardClick = lotIndex => {
		store.dispatch(BidderActions.updateMobileCatIndex(lotIndex));
		store.dispatch(BidderActions.updateMobilePage('lotdetail'));
		window.API.pushHistory('/bidderinterface/lotdetail');
	};

	renderLot = (lot, index) => {
		if (lot.loading || lot.dummyCard) {
			let lotProps = {
				...lot,
				key: index,
				classes: 'mobilecatalogue__lot',
			};
			return <LotCard {...lotProps} />;
		}

		let lotProps = {
			// ...lot,
			lotID: lot?.catalogueLot?.lotID,
			key: index,
			classes: 'mobilecatalogue__lot',
			customClickCallback: this.onLotCardClick,
		};
		if (lot.catalogueLot.lotIndex === this.props.currentLot?.lotIndex) {
			lotProps.isCurrentLot = true;
		}

		return <LotCard {...lotProps} />;
	};

	renderRow = ({ index, style, data }) => {
		return (
			<div style={style} key={index} className="mobilecatalogue__row">
				<div className="mobilecatalogue__row__container">
					{data[index][0] && this.renderLot(data[index][0])}
				</div>
				<div className="mobilecatalogue__row__container">
					{data[index][1] && this.renderLot(data[index][1])}
				</div>
			</div>
		);
	};

	getPreparedRows() {
		let rows = [];
		this.state?.lots?.forEach((lot, index) => {
			if (index % 2) rows[rows.length - 1].push(lot);
			else rows.push([lot]);
		});

		return rows;
	}

	render() {
		let rows = this.getPreparedRows();

		return (
			<div
				className="mobilecatalogue bg-primary-color-light"
				ref={this.containerRef}
			>
				{this.state.lots && (
					<AutoSizer>
						{({ height, width }) => (
							<List
								className="mobilecatalogue__lots"
								height={height}
								itemCount={rows.length}
								itemSize={275}
								ref={this.onRefChange}
								width={width}
								itemData={rows}
								onScroll={event => this.handleScroll(event)}
							>
								{this.renderRow}
							</List>
						)}
					</AutoSizer>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		lotCount: state.auction?.lotCount,
		lotList: state.auction?.lotList,
		currentLotID: state.auction?.currentLot?.lotID,
		currentLotIndex: state.auction?.currentLot?.lotIndex,
		mobileCatalogueScrollPosition:
			state.bidder?.mobileCatalogueScrollPosition,
	};
};

export default connect(mapStateToProps)(Catalogue);
