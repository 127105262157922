import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import { capitaliseFirst } from 'utils/utils';
import AdminPageActions from 'store/actions/adminpages';
import DynamicForm from 'components/common/dynamicform';
import Increments from 'components/adminpages/addauction/details/increments';
import { getFormData, getValidationSchema } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import {
	faFilePlus,
	faFileEdit,
	faCheck,
	faTimes,
	faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';
import 'styles/adminpages/addauction/details/details.scss';
import requestUpdateAuction from 'api/requests/admin/Req-UpdateAuction';

const Details = props => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const formRef = useRef();
	const formData = getFormData(props);
	const validationSchema = getValidationSchema(props?.editMode);
	const isExistingAuction = props?.auctionID || false;

	useEffect(() => {
		if (props.confirmationError)
			setTimeout(
				() =>
					store.dispatch(AdminPageActions.setConfirmationError(null)),
				1500
			);
	}, [props.confirmationError]);

	const onActionClick = () => {
		let form = formRef.current;
		form.handleSubmit();

		if (!form.isValid || !form.dirty) return;

		setShowConfirmation(true);
	};

	const sendData = () => {
		let form = formRef.current;

		let data = Object.assign({}, form.values, {
			auctionIDOpt: props?.auctionID,
			isActive: true,
		});

		data.loginTrustUpdateOpt = !data?.loginTrustUpdateOpt;

		let startDate = new Date(form.values.auctionDate);
		let endDate = null;
		if (form.values.auctionEndDateOpt)
			endDate = new Date(form.values.auctionEndDateOpt.getTime());

		data['auctionDate'] = startDate.valueOf();
		data['auctionEndDateOpt'] = endDate ? endDate.valueOf() : null;

		requestUpdateAuction(data);
	};

	return (
		<div className="addauction__details bg-primary-color-light">
			{showConfirmation && (
				<div className="confirmation">
					<div className="confirmation__popup bg-primary-color-light">
						<div
							className={`confirmation__header primary-color-light ${
								isExistingAuction
									? 'bg-admin-accent-2'
									: 'bg-admin-accent-1'
							}`}
						>
							<div className="confirmation__header-title large-24-font-size">
								{isExistingAuction
									? props?.config?.editAuction
									: props?.config?.createAuction}
							</div>
							<div className="confirmation__header-icon">
								<FontAwesomeIcon
									className="icon extra-large-34-font-size"
									icon={
										isExistingAuction
											? faFileEdit
											: faFilePlus
									}
								/>
							</div>
						</div>

						<div className="confirmation__body large-20-font-size">
							{isExistingAuction &&
								`${capitaliseFirst(
									props?.config?.editConfirm
								)} ${props?.auctionTitle}?`}
							{!isExistingAuction &&
								`${capitaliseFirst(
									props?.config?.createConfirm
								)} ${formRef?.current?.values?.auctionTitle}?`}

							<div
								className="confirmation__error warning-color large-20-font-size"
								style={{
									visibility: `${
										props.confirmationError
											? 'visible'
											: 'hidden'
									}`,
								}}
							>
								<FontAwesomeIcon
									className="icon large-24-font-size"
									icon={faExclamationTriangle}
								/>{' '}
								{props.confirmationError}
							</div>
						</div>

						<div className="confirmation__footer">
							{!props.confirmationError && (
								<button
									className="confirmation__control primary-color-light bg-admin-accent-1 border-secondary-color-dark"
									onClick={() => sendData()}
									data-testid="details-submit-confirm"
								>
									<FontAwesomeIcon
										className="icon large-24-font-size"
										icon={faCheck}
									/>{' '}
									{props?.config?.confirm}
								</button>
							)}

							{!props.confirmationError && (
								<button
									className="confirmation__control primary-color-light bg-warning-color border-secondary-color-dark"
									onClick={() => setShowConfirmation(false)}
								>
									<FontAwesomeIcon
										className="icon large-24-font-size"
										icon={faTimes}
									/>{' '}
									{props?.config?.cancel}
								</button>
							)}
						</div>
					</div>
				</div>
			)}
			<div className="addauction__details__row-1 bg-primary-color-light">
				<div className="addauction__details__row-1__header">
					<div className="addauction__details__row-1__header__title">
						{props.editMode
							? props?.config?.editTitle
							: props?.config?.addTitle}
					</div>
					<div className="addauction__details__row-1__header__subtitle">
						{props.editMode
							? props?.config?.editBody
							: props?.config?.addBody}
					</div>
				</div>

				<div className="addauction__details__row-1__action__wrapper">
					<button
						className={`addauction__details__row-1__action primary-color-light bg-accent-color-1 `}
						onClick={() => onActionClick(false)}
						type="submit"
						data-testid="details-submit"
					>
						{props?.config?.next}
						<FontAwesomeIcon
							className="addauction__details__row-1__action-icon"
							icon={faArrowRight}
						/>
					</button>
				</div>
			</div>

			<div className="addauction__details__row-2 bg-primary-color-light">
				<div className="addauction__details__row-2__col-1">
					<div className="addauction__details__form">
						<div className="addauction__details__form__header">
							<h3>{props?.config?.auctionInformation}</h3>
						</div>
						<DynamicForm
							formData={formData}
							validationSchema={validationSchema}
							hideControls={true}
							innerRef={formRef}
						/>
					</div>
				</div>

				<div className="addauction__details__row-2__col-2">
					<h3 className="title">{props?.config?.increments}</h3>
					{props.auctionIncrements ? (
						<Increments {...props} />
					) : (
						<div>{props?.config?.incrementsNotFound}</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	const { auctionDetail, auctionIncrements } = state.adminpages;
	const auctionHouse = state.login?.auctionHouses[0];
	const ahDetail = auctionHouse?.ahDetail;

	return {
		locale: auctionHouse?.locale,
		defaultCurrency: ahDetail?.defaultCurrency,
		auctionID: auctionDetail?.auctionID,
		auctionTitle: auctionDetail?.auctionTitle,
		auctionDate: auctionDetail?.auctionDate,
		auctionEndDateOpt: auctionDetail?.auctionEndDateOpt,
		currency: auctionDetail?.localisedData?.currency,
		descriptionOpt: auctionDetail?.descriptionOpt,
		specialistOpt: auctionDetail?.specialistOpt,
		specialistEmailOpt: auctionDetail?.specialistEmailOpt,
		auctionIncrements,
		confirmationError: state.adminpages?.confirmationError,
		config: state?.config,
	};
};

export { Details };
export default connect(mapStateToProps)(Details);
