import { useState } from 'react';
import { useSelector } from 'react-redux';
import { capitaliseFirst } from 'utils/utils';
import 'styles/clerkconsole/messagepanel/messagepanel.scss';
import { requestMessage } from 'api/requests/clerk/Req-Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

const MessagePanel = () => {
	const config = useSelector(state => state?.config);
	const [customMessage, setCustomMessage] = useState('');
	const messages = config?.clerkMessages;

	const onMessageClick = (messageID, text) => requestMessage(messageID, text);

	const onActionClick = () => {
		const messageID = 1000;
		requestMessage(messageID, customMessage);
		setCustomMessage('');
	};

	const onInputKeyPress = event => {
		if (event.charCode === 13) onActionClick();
	};

	const onInputChange = event => {
		event.preventDefault();
		setCustomMessage(event.currentTarget.value);
	};

	if (!messages) {
		return <div className="messagepanel"></div>;
	}

	return (
		<div className="messagepanel">
			<div className="messagepanel__container bg-primary-color-light">
				{messages
					.filter((message, index) => index < 5)
					.map((message, index) => {
						return (
							<button
								id={message.text.replace(' ', '_')}
								className="buttons__premade border-2-background-color regular-16-font-size"
								onClick={() =>
									onMessageClick(
										message.messageID,
										message.text
									)
								}
								key={index}
							>
								{message.text}
							</button>
						);
					})}
			</div>
			<div className="messagepanel__manualinput border-top-2-background-color bg-primary-color-light">
				<input
					className="messagepanel__manualinput__input"
					onChange={onInputChange}
					value={customMessage}
					onKeyPress={onInputKeyPress}
					placeholder={capitaliseFirst(
						config?.customMessagePlaceholder
					)}
				></input>

				<button
					className="messagepanel__manualinput__action primary-color-light bg-accent-color-1"
					onClick={onActionClick}
				>
					<FontAwesomeIcon className="icon" icon={faArrowRight} />
				</button>
			</div>
		</div>
	);
};

export default MessagePanel;
