import { connect } from 'react-redux';
import { useState } from 'react';
import './autowithdraw.scss';
import { faCheck, faTimes, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import requestAuctionDetail from 'api/requests/common/Req-AuctionDetail/Req-AuctionDetail';
import requestWithdrawnToggle from 'api/requests/admin/Req-WithdrawnToggle';

const AutoWithdraw = props => {
	const buttonDefault = props?.config?.withdraw;
	const placeholder = props?.config?.withdrawn;
	const check = <FontAwesomeIcon icon={faCheck} />;
	const cross = <FontAwesomeIcon icon={faTimes} />;
	const loading = <FontAwesomeIcon className="fa-spin" icon={faSpinner} />;

	const [input, setInput] = useState('');
	const [buttonText, setButtonText] = useState(buttonDefault);

	const onSuccess = () => {
		setButtonText(check);
		requestAuctionDetail(true, props.auctionID);
		setTimeout(() => setButtonText(buttonDefault), 700);
	};

	const onError = () => {
		setButtonText(cross);
		setTimeout(() => setButtonText(buttonDefault), 700);
	};

	const onKeyPress = event => {
		if (event.charCode !== 13) return;
		sendRequest();
	};

	const sendRequest = () => {
		setButtonText(loading);

		requestWithdrawnToggle(props.auctionID, input || placeholder)
			.then(response => {
				onSuccess();
			})
			.catch(error => {
				onError();
			});
	};

	return (
		<div className="autowithdraw">
			<h3>{props?.config?.autoWithdrawTitle}</h3>
			<p>{props?.config?.autoWithdrawSubtitle}</p>
			<div>
				<input
					value={input}
					onChange={event => setInput(event.target.value)}
					placeholder={placeholder}
					onKeyPress={onKeyPress}
				/>
				<button onClick={sendRequest}>{buttonText}</button>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(AutoWithdraw);
