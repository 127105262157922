import React, { Component } from 'react';
import 'styles/common/lazyimage.scss';
import notfound from 'assets/notfound.png';

class LazyImage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			imageLoaded: false,
			imgSrc: this.props.url,
		};

		this.onImageLoaded = this.onImageLoaded.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.url !== prevProps.url) {
			this.setState({
				imgSrc: this.props.url,
			});
		}

		if (
			this.props.uploadComplete &&
			this.props.uploadComplete !== prevProps.uploadComplete
		) {
			this.setState({ imageLoaded: true });
		}
	}

	onImageLoaded() {
		this.setState({ imageLoaded: true });
	}

	render() {
		if (this.props.flipcard)
			return (
				<div
					className={`${this.props.className} ${
						!this.state.imageLoaded ? 'lazyimage' : ''
					}`}
				>
					<div
						className={`lazyimage__inner ${
							this.props.itemWon ? 'itemwon' : ''
						}`}
					>
						<div className="lazyimage__front">
							<img
								src={this.state.imgSrc}
								alt=""
								loading="lazy"
								onLoad={this.onImageLoaded}
								onError={event => (event.target.src = notfound)}
								style={
									!this.state.imageLoaded
										? { visibility: 'hidden' }
										: {}
								}
							/>
						</div>
						<div className="lazyimage__back">
							{this.props.flipcard}
						</div>
					</div>
				</div>
			);

		return (
			<div
				className={`${this.props.className} ${
					!this.state.imageLoaded ? 'lazyimage' : ''
				}`}
			>
				<img
					src={this.state.imgSrc}
					alt=""
					loading="lazy"
					onLoad={this.onImageLoaded}
					onError={event => (event.target.src = notfound)}
					style={
						!this.state.imageLoaded ? { visibility: 'hidden' } : {}
					}
				/>
			</div>
		);
	}
}

export default LazyImage;
