import classNames from 'classnames';
import Header from './Header/Header';
import Image from './CurrentLot/Image/Image';
import Description from './CurrentLot/Description/Description';
import Details from './CurrentLot/Details/Details';
import Commission from './BidInfo/Commission/Commission';
import Phone from './BidInfo/Phone/Phone';
import Timeline from './Timeline/Timeline';
import styles from './DetailedAuctioneer.module.scss';

const DetailedAuctioneer = () => {
	return (
		<div className={styles.container}>
			<Header />
			<div className={classNames(styles.row, styles.top)}>
				<Image />
				<Description />
				<Details />
			</div>
			<div className={classNames(styles.row, styles.middle)}>
				<Phone />
				<Commission />
			</div>
			<div className={classNames(styles.row, styles.bottom)}>
				<Timeline />
			</div>
		</div>
	);
};

export default DetailedAuctioneer;
