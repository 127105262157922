// import React, { useState, useEffect } from "react";
import React, { Component } from 'react';
import 'styles/common/flipdater.scss';

class Flipdater extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentValue: this.props?.value || null,
			newValue: null,
			animateClass: '',
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({
				newValue: this.props.value,
				animateClass: 'animate-flip',
			});

			this.flipTimeout = setTimeout(() => {
				this.setState({
					currentValue: this.props?.value,
					newValue: null,
					animateClass: '',
				});
			}, 300);
		}
	}

	componentWillUnmount() {
		if (this.flipTimeout) clearTimeout(this.flipTimeout);
	}

	render() {
		return (
			<div className="flipdater__container">
				<div
					className={`flipdater__newvalue ${
						this.state.animateClass
					} ${this.props?.right ? 'right' : ''} ${
						this.props?.left ? 'left' : ''
					}`}
				>
					{this.state.newValue}
				</div>
				<div
					className={`flipdater__currentvalue ${
						this.state.animateClass
					} ${this.props?.right ? 'right' : ''} ${
						this.props?.left ? 'left' : ''
					}`}
				>
					{this.state.currentValue}
				</div>
			</div>
		);
	}
}

export default Flipdater;
