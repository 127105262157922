import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import ClerkActions from 'store/actions/clerk';

//REQ_SALERESULT: 'Req-SaleResult'
export default function requestSaleResult(auctionID, lotFilter) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-SaleResult',
		userTokenID: window.API?.user?.userToken,
		auctionID,
		lotFilter,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.updateSaleOutcome(data));
	store.dispatch(ClerkActions.updateSaleOutcome(data));
};

const handleFailure = data => {
	console.debug('Req-SaleResult failure', data);
};
