import { connect } from 'react-redux';
import BreadcrumbButton from './breadcrumbButton';
import {
	faInfo,
	faFileUpload,
	faSearch,
	faCheck,
} from '@fortawesome/pro-solid-svg-icons';
import 'styles/adminpages/addauction/breadcrumbs/breadcrumbs.scss';

const Breadcrumbs = props => {
	return (
		<div className="addauction__breadcrumbs bg-primary-color-light">
			<BreadcrumbButton
				text={
					props.addingAuction
						? props?.config?.createAuctionDetails
						: props?.config?.editAuctionDetails
				}
				icon={faInfo}
				buttonIndex={0}
				addingAuction={props.addingAuction}
				arrangement={props.arrangement}
				setBreadcrumbState={props.setBreadcrumbState}
			/>
			<div className="addauction__breadcrumbs__spacer" />

			<BreadcrumbButton
				text={
					props.addingAuction
						? props?.config?.addCsv
						: props?.config?.editCsv
				}
				icon={faFileUpload}
				buttonIndex={2}
				addingAuction={props.addingAuction}
				arrangement={props.arrangement}
				setBreadcrumbState={props.setBreadcrumbState}
			/>
			<div className="addauction__breadcrumbs__spacer" />

			<BreadcrumbButton
				text={props?.config?.auctionReview}
				icon={faSearch}
				buttonIndex={3}
				addingAuction={props.addingAuction}
				arrangement={props.arrangement}
				setBreadcrumbState={props.setBreadcrumbState}
			/>
			<div className="addauction__breadcrumbs__spacer" />

			<BreadcrumbButton
				text={props?.config?.auctionComplete}
				icon={faCheck}
				buttonIndex={4}
				addingAuction={props.addingAuction}
				arrangement={props.arrangement}
				setBreadcrumbState={props.setBreadcrumbState}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Breadcrumbs);
