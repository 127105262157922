import { connect } from 'react-redux';
import { useState } from 'react';
import useCurrency from 'hooks/useCurrency';
import requestPostPassOffer from 'api/requests/bidder/Req-PostPassOffer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PassedOffer.module.scss';

import {
	faSpinner,
	faCheck,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const PassedOffer = props => {
	const { getFormattedCurrency, sanitiseCurrency } = useCurrency();
	const offerMade = props?.offerMade;
	const lowEst = props?.lowEst;
	const config = props?.config;
	const [offerValue, setOfferValue] = useState(offerMade || '');
	const [requestStatus, setRequestStatus] = useState(null);

	const onActionClick = event => {
		event.stopPropagation();
		const lotID = props?.lotID;
		if (!lotID || !offerValue || offerValue === offerMade) return;

		setRequestStatus('sending');

		requestPostPassOffer(lotID, offerValue)
			.then(response => {
				if (response.data?.result > 100) showError();
				else showSuccess();
			})
			.catch(() => showError());
	};

	const showSuccess = () => {
		setRequestStatus('accepted');
		resetRequestStatus();
	};

	const showError = () => {
		setRequestStatus('error');
		resetRequestStatus();
	};

	const resetRequestStatus = () =>
		setTimeout(() => setRequestStatus(null), 1500);

	const onInputChange = event => {
		event.preventDefault();

		let sanitisedValue = sanitiseCurrency(event.currentTarget.value);
		let value = Number(sanitisedValue);
		if (isNaN(Number(value))) return;

		setOfferValue(value);
	};

	const onKeyPress = event => {
		event.stopPropagation();
		if (event.charCode !== 13) return;
		event.preventDefault();
		onActionClick(event);
	};

	const buttonValue = () => {
		if (requestStatus === 'accepted')
			return <FontAwesomeIcon className="icon" icon={faCheck} />;
		if (requestStatus === 'error')
			return <FontAwesomeIcon className="icon" icon={faTimes} />;
		if (requestStatus === 'sending')
			return (
				<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
			);
		return props?.offerMade
			? config?.makeAnotherOffer
			: config?.makeAnOffer;
	};

	const suggested = getFormattedCurrency(lowEst);

	return (
		<div className={styles.container}>
			<input
				className={styles.input}
				placeholder={config?.leaveOfferHere}
				value={offerValue ? getFormattedCurrency(offerValue) : ''}
				onClick={event => event.stopPropagation()}
				onChange={event => onInputChange(event)}
				inputMode="numeric"
				onKeyPress={event => onKeyPress(event)}
			/>
			<div className="mobile-passedoffer-infomessage warning-color-mid">
				{offerMade
					? `${config?.youMadeOffer}: ${offerMade}`
					: `${config?.suggestedOffer}: ${suggested}`}
			</div>
			<button
				className={styles.action}
				onClick={event => onActionClick(event)}
			>
				{buttonValue()}
			</button>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const lotList = state.auction?.lotList;
	const displayLot = lotList?.find(
		lot => lot.catalogueLot.lotID === ownProps?.lotID
	);

	return {
		lotID: displayLot?.catalogueLot?.lotID,
		offerMade: displayLot?.lotSaleDetail?.offerMade,
		lowEst: displayLot?.catalogueLot?.lowEst,
		currency: state.auction?.localisedData?.currency,
		currencySymbol: state.auction?.localisedData?.currencySymbol,
		swipePanel: state.bidder?.swipePanel,
		config: state.config,
	};
};

export default connect(mapStateToProps)(PassedOffer);
