import { getEndPointAPI2 } from 'api/getEndpoint';
import axios from 'axios';
import b64toUUID from 'utils/b64toUUID';

export async function getAuctionAutobids(auctionID) {
	// const auctionUUID = b64toUUID(auctionID);
	// const userUUID = userID ? b64toUUID(userID) : null;
	if (!auctionID) return;
	const auctionUUID = b64toUUID(auctionID);

	let url = `${getEndPointAPI2()}/auction/autobids/${auctionUUID}`;
	// if (userUUID) url += `&clientID=${userUUID}`;

	console.log('URL:', url);
	return axios({
		headers: {
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
			// Origin: window.location.host,
			// crossdomain: true,
			Authorization: `Bearer ${window.API.user.userToken}`,
		},
		method: 'GET',
		url,
	})
		.then(response => {
			console.log('getAuctionAutobids response', response.data);
			return response.data;
		})
		.catch(error => {
			console.log(error);
			return error;
		});
}

// https://demoreact.stream.bid/api2/auction/registration/wtrVfZ7WRgeU%2BC3O9T%2FGSg%3D%3D
// https://demoreact.stream.bid/api2/auction/state?auctionID=%2BwLEKH9iQQaqewThnh9Tvg%3D%3D&clientID=vT73VeEqQFyamzS36F4RKQ%3D%3D
