import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_LINKGOAUCTION: 'Req-LinkGoAuction'
export default function requestLinkGoAuction(goAuctionID, auctionIDOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-LinkGoAuction',
		userTokenID: window.API?.user?.userToken,
		goAuctionID,
		auctionIDOpt,
	});
}
