import { connect } from 'react-redux';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import 'styles/bidderinterface/biddermobile/mobilewelcomemodal.scss';

const WelcomeModal = props => {
	const onContinue = () => {
		store.dispatch(BidderActions.bidderCloseModal());
	};

	if (!props.ahName && !props?.config?.messagePt1) return false;

	return (
		<div className="mobilewelcomemodal">
			<div className="mobilewelcomemodal__shadow"></div>
			<div className="mobilewelcomemodal__main">
				<div className="mobilewelcomemodal__title large-24-font-size">
					{`${props?.config?.welcomeTo} ${props.ahName} ${props?.config?.liveBiddingPlatform}`}
				</div>

				<button
					className="mobilewelcomemodal__button large-24-font-size"
					onClick={onContinue}
				>
					{props?.config?.continue}
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		ahName: state?.auction?.auctionHouseName,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(WelcomeModal);
