let pingList = [];

export function storeLatency(sentTs) {
	const returnTs = Date.now();
	const ping = returnTs - sentTs;

	if (!pingList.length) {
		pingList = new Array(5).fill(ping);
	} else {
		pingList.shift();
		pingList.push(ping);
	}
}

export function getLatency() {
	if (!pingList.length) return null;

	const currentPing = Math.round(pingList.reduce((a, b) => a + b) / 5, 2);
	return currentPing;
}
