import sendRequest from '..';
import { getCommonEndpoint } from '../utils';
import cookieHandler from 'api/cookieHandler';
import store from 'store/store';
import LoginActions from 'store/actions/login';

//AUTH_REQUEST: 'Req-Auth'
export default function requestAuth(loginName, password, auctionIDOpt) {
	return sendRequest(getCommonEndpoint(), {
		Action: 'Req-Auth',
		loginName,
		password,
		auctionHouseShortNameOpt: null,
		auctionIDOpt,
	})
		.then(response => {
			const { data } = response;
			if (data?.result > 100) {
				handleFailure();
				return;
			}
			setLocale(data);
			handleSuccess(data);
		})
		.catch(error => {
			console.debug(error);
			store.dispatch(LoginActions.showLoginDetailError('Network error'));
		});
}

const handleSuccess = data => {
	cookieHandler.setUserToken(data.userTokenID);
	data.tokExpTime = Date.now() + data.tokenLife * 1000;
	window.API.onAuthenticationSuccess(data);
};

const handleFailure = () => {
	store.dispatch(
		LoginActions.showLoginDetailError('Incorrect username or password')
	);
	window.API.onAuthenticationFail('Manual authentication request failed');
};

function setLocale(data) {
	if (!data.locales) return;
	store.dispatch(LoginActions.setLocales(data));
	const activeLocale = data.locales.find(locale => locale.isCurrent);
	window.API.locale = activeLocale?.code;
}
