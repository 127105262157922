import requestUpdateInc from 'api/requests/clerk/Req-UpdateInc';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import AuctionActions from 'store/actions/auction';
import classNames from 'classnames';
import styles from './IncrementButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSpinner,
	faCheck,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const IncrementButton = ({
	value,
	isActive,
	isHighlighted,
	incTypeOpt,
	setActiveButton,
}) => {
	const { getFormattedCurrency } = useCurrency();
	const config = useConfig();
	const dispatch = useDispatch();
	const { setBidIncrement } = AuctionActions;
	const [buttonIcon, setButtonIcon] = useState(null);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);

	const getLabel = () => {
		switch (incTypeOpt) {
			case 1:
				return config?.half;
			case 2:
				return config?.double;
			default:
				return config?.standard;
		}
	};

	const onSuccess = () => {
		setShowSuccess(true);
		setButtonIcon(<FontAwesomeIcon icon={faCheck} />);
		setActiveButton(incTypeOpt);
		resetButton();
	};

	const onError = () => {
		setShowError(true);
		setButtonIcon(<FontAwesomeIcon icon={faTimes} />);
		resetButton();
	};

	const resetButton = () => {
		setTimeout(() => {
			setShowSuccess(false);
			setShowError(false);
			setButtonIcon(null);
		}, 800);
	};

	const onClick = () => {
		const custIncOpt = null;
		setButtonIcon(<FontAwesomeIcon className="fa-spin" icon={faSpinner} />);
		dispatch(setBidIncrement({ incTypeOpt, custIncOpt }));
		requestUpdateInc(incTypeOpt, custIncOpt, null)
			.then(response => {
				const { data } = response;
				if (data.result > 100) {
					onError();
					return;
				}
				onSuccess();
				return data;
			})
			.catch(() => onError());
	};

	return (
		<div className={styles.container}>
			<button
				className={classNames(styles.button, {
					[styles.active]: isActive,
					[styles.success]: showSuccess,
					[styles.error]: showError,
				})}
				onClick={onClick}
				data-testid="increment"
			>
				{buttonIcon || getFormattedCurrency(value)}
			</button>
			<div className={styles.label}>{getLabel()}</div>
		</div>
	);
};

export default IncrementButton;
