import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/pro-solid-svg-icons';
import styles from './Buttons.module.scss';

const Buttons = ({ autobidState, onSubmit, isEditing }) => {
	const config = useSelector(state => state.config);

	if (autobidState === 'confirming')
		return (
			<div className={styles.container}>
				<button className={styles.action} disabled>
					<FontAwesomeIcon className="fa-spin" icon={faSpinner} />
				</button>
			</div>
		);

	if (isEditing) {
		return (
			<div className={styles.container}>
				<button
					className={styles.action}
					onClick={event => onSubmit(event)}
				>
					{config?.change}
				</button>
			</div>
		);
	}

	if (autobidState === 'closed')
		return (
			<div className={styles.container}>
				<button className={styles.action} disabled>
					<FontAwesomeIcon className={styles.icon} icon={faCheck} />
					{config?.confirmed}
				</button>
			</div>
		);

	return (
		<div className={styles.container}>
			<button
				className={styles.action}
				onClick={event => onSubmit(event)}
			>
				{config?.setAutobid}
			</button>
		</div>
	);
};

export default Buttons;
