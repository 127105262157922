import {
	UPDATE_AUCTION_HOUSES,
	UPDATE_AUCTIONS,
	UPDATE_USERNAME,
	UPDATE_PASSWORD,
	UPDATE_CURRENT_AUCTIONHOUSE,
	UPDATE_CURRENT_AUCTION,
	SHOW_LOGIN_DETAIL_ERROR,
	ON_LOGIN_SUCCESS,
	SET_LOCALES,
} from 'store/actions/login';

const initialState = {
	auctionHouses: [],
	auctions: [],
	userName: '',
	password: '',
	currentAuctionHouse: null,
	currentAuction: null,
	showLoginDetailError: false,
	loginDetails: null,
};

export default function login(state = initialState, action) {
	let returnState = Object.assign({}, state);
	switch (action.type) {
		case UPDATE_AUCTION_HOUSES:
			//returnState.auctionHouses = action.data;
			returnState.auctionHouses = action?.data?.map((ah, index) => {
				ah._index = index;
				ah.auctions = ah.auctions.map((auction, index) => {
					auction._index = index;
					auction.simpleDate = new Date(
						auction.auctionDate
					).toLocaleDateString();
					return auction;
				});
				return ah;
			});
			return returnState;

		case UPDATE_AUCTIONS:
			returnState.auctions = action.data.auctions;
			return returnState;

		case UPDATE_USERNAME:
			returnState.userName = action.data;
			return returnState;

		case UPDATE_PASSWORD:
			returnState.password = action.data;
			return returnState;

		case UPDATE_CURRENT_AUCTIONHOUSE:
			returnState.currentAuctionHouse = state.auctionHouses.filter(
				ah => ah._index === action.data
			)[0];
			return returnState;

		case UPDATE_CURRENT_AUCTION:
			returnState.currentAuction =
				state.currentAuctionHouse.auctions.filter(
					auction => auction._index === action.data
				)[0];
			return returnState;

		case SHOW_LOGIN_DETAIL_ERROR:
			returnState.showLoginDetailError = action.data;
			return returnState;

		case ON_LOGIN_SUCCESS:
			returnState.loginDetails = action.data;
			return returnState;

		case SET_LOCALES:
			returnState = { ...state };
			returnState.locales = { ...returnState.locales, ...action.data };
			return returnState;

		default:
			return state;
	}
}
