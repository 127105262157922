import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import requestGetAuctionStats from 'api/requests/common/Req-AuctionStats';
import useCurrency from 'hooks/useCurrency';
import styles from './AuctionStats.module.scss';

const AuctionStats = () => {
	const token = window?.API?.user?.userToken;
	const { saleOutcome } = useSelector(state => state?.adminpages) || {};
	const [stats, setStats] = useState([]);
	const { getAbbreviatedCurrency } = useCurrency();

	useEffect(() => {
		if (!token) return;
		requestGetAuctionStats()
			.then(response => {
				const { data } = response;
				if (data.result > 100) {
					console.debug('requestGetAuctionStats rejected', data);
					return;
				}
				setStats(data);
			})
			.catch(error => console.debug(error));
	}, [token]);

	const { perPlatform, valueSold, lotsSold } = stats || {};

	if (!perPlatform?.length) return <div className={styles.container} />;

	const streamBid = perPlatform.filter(
		platform => platform.platformSN === 'SB'
	)[0];
	const floor = perPlatform.filter(platform => platform.platformSN === 'FB')[0];
	const otherOnline = perPlatform.filter(
		platform => platform.platformSN === 'OO'
	)[0];

	const streamBidValueSold = streamBid?.valueSold;
	const streamBidPercentSold = (streamBid?.percentSold * 100).toFixed(1);
	const streamBidValuePercent = streamBidValueSold
		? ((streamBidValueSold / valueSold) * 100).toFixed(1)
		: 0;
	const otherValueSold = otherOnline?.valueSold;
	const inRoomValueSold = floor?.valueSold;

	const lotSaleValues =
		saleOutcome?.lots?.map(lot => lot?.salePrice).sort((a, b) => a - b) || [];

	const minLotPrice = lotSaleValues.find(Boolean);
	const avgLotPrice = (valueSold / lotsSold).toFixed();
	const maxLotPrice = lotSaleValues.reverse().find(Boolean);

	return (
		<div className={styles.container}>
			<div className={classNames(styles.item, styles.streamBidValueSold)}>
				<div className={styles.subtitleLarge}>Streambid total</div>
				<div className={classNames(styles.valueLarge, styles.green)}>
					{getAbbreviatedCurrency(streamBidValueSold)}
				</div>
			</div>
			<div className={classNames(styles.item, styles.streamBidPercentSold)}>
				<div className={styles.subtitleLarge}>% Streambid sales</div>
				<div className={classNames(styles.valueLarge, styles.blue)}>
					{streamBidPercentSold}%
				</div>
			</div>
			<div className={classNames(styles.item, styles.streamBidValuePercent)}>
				<div className={styles.subtitleLarge}>% Streambid value</div>
				<div className={classNames(styles.valueLarge, styles.purple)}>
					{streamBidValuePercent}%
				</div>
			</div>
			<div className={classNames(styles.item, styles.valueSold)}>
				<div className={styles.subtitleSmall}>Total sales</div>
				<div className={styles.valueSmall}>
					{getAbbreviatedCurrency(valueSold)}
				</div>
			</div>
			<div className={classNames(styles.item, styles.otherValueSold)}>
				<div className={styles.subtitleSmall}>Other online sales</div>
				<div className={styles.valueSmall}>
					{getAbbreviatedCurrency(otherValueSold)}
				</div>
			</div>
			<div className={classNames(styles.item, styles.inRoomValueSold)}>
				<div className={styles.subtitleSmall}>In room total</div>
				<div className={styles.valueSmall}>
					{getAbbreviatedCurrency(inRoomValueSold)}
				</div>
			</div>
			<div className={classNames(styles.item, styles.minLotPrice)}>
				<div className={styles.subtitleSmall}>Min lot price</div>
				<div className={styles.valueSmall}>
					{getAbbreviatedCurrency(minLotPrice)}
				</div>
			</div>
			<div className={classNames(styles.item, styles.avgLotPrice)}>
				<div className={styles.subtitleSmall}>Avg lot price</div>
				<div className={styles.valueSmall}>
					{getAbbreviatedCurrency(avgLotPrice)}
				</div>
			</div>
			<div className={classNames(styles.item, styles.maxLotPrice)}>
				<div className={styles.subtitleSmall}>Max lot price</div>
				<div className={styles.valueSmall}>
					{getAbbreviatedCurrency(maxLotPrice)}
				</div>
			</div>
		</div>
	);
};

export default AuctionStats;
