import { useSelector } from 'react-redux';
import styles from './Buttons.module.scss';

const Buttons = props => {
	const config = useSelector(state => state.config);

	if (props?.info)
		return (
			<div className={styles.container}>
				<div className={styles.action}>{props?.info}</div>
			</div>
		);

	if (props?.autobidState === 'open')
		return (
			<div className={styles.container}>
				<button
					className={styles.action}
					onClick={event => props.onSubmit(event)}
				>
					{config?.setAutobid}
				</button>
			</div>
		);

	if (props?.autobidState === 'closed')
		return (
			<div className={styles.container}>
				<button
					className={styles.half}
					onClick={event => props.onDelete(event)}
				>
					{config?.delete}
				</button>
				<button
					className={styles.half}
					onClick={event => props.onSubmit(event)}
				>
					{config?.change}
				</button>
			</div>
		);

	if (props?.autobidState === 'confirming')
		return (
			<div className={styles.container}>
				<div className={styles.confirming}>
					<div className={styles.slider}></div>
					<div className={styles.title}>{config?.confirming}</div>
				</div>
			</div>
		);
};

export default Buttons;
