import { useState } from 'react';
import requestPWChange from 'api/requests/admin/Req-PWChange';
import 'styles/login/resetpassword.scss';

const ResetPW = () => {
	const [newPW, setNewPW] = useState('');

	let onActionClick = () => {
		let urlParams = new URLSearchParams(window.location.search);
		let resetToken = urlParams.get('resetToken');
		let decodedToken = decodeURIComponent(resetToken);

		if (!decodedToken) {
			alert('STREAMBID DEBUG: No reset token passed');
			return;
		}

		if (newPW.length < 8) {
			alert(
				'STREAMBID DEBUG: New password must be 8 characters or longer'
			);
			return;
		}

		let userLoginOpt = null;
		let currentPWOpt = null;
		let newPWOpt = newPW;
		let ahSNOpt = null;
		let resetTokenOpt = decodedToken;

		requestPWChange(
			userLoginOpt,
			currentPWOpt,
			newPWOpt,
			ahSNOpt,
			resetTokenOpt
		);
	};

	return (
		<div className="resetpassword">
			<div className="resetpassword__title">
				Please reset your password
			</div>
			<div className="resetpassword__inputcontainer">
				<input
					className="resetpassword__input"
					value={newPW}
					onChange={event => setNewPW(event.target.value)}
				/>
			</div>
			<div className="resetpassword__actioncontainer">
				<button
					className="resetpassword__action"
					onClick={() => onActionClick()}
				>
					Submit
				</button>
			</div>
		</div>
	);
};

export default ResetPW;
