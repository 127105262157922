export default class User {
	constructor(data) {
		this.loginName = data.loginName;
		this.displayAs = data.displayAs;
		this.userToken = data.userTokenID;
		this.userPerm = data.permissions[0];
		this.tokenExpTime = data.tokenExpTime;
		this.auctionID = data?.auction?.auctionID;

		if (this.userPerm) {
			const permissionChangeEvent = new CustomEvent(
				'permissionChangeEvent',
				{ detail: this.userPerm }
			);
			window.dispatchEvent(permissionChangeEvent);
		}
	}
}
