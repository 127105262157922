import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import SwipePanel from '../../swipePanel/swipePanel';
import useAutobid from 'hooks/autobid/useAutobid';
import useCurrency from 'hooks/useCurrency';
import PanelClosed from './PanelClosed/PanelClosed';
import Inputs from './Inputs/Inputs';
import Buttons from './Buttons/Buttons';
import useLotIndex from 'hooks/useLotIndex';
import styles from './AutobidFuture.module.scss';

const AutobidFuture = () => {
	const lotIndex = useSelector(state => state.bidder?.catIndex);
	const displayLot = useLotIndex(lotIndex);
	const lotID = displayLot?.catalogueLot?.lotID;
	const panelState = useSelector(state => state.bidder?.swipePanel);
	const config = useSelector(state => state.config);
	const { currencySymbol } = useCurrency();

	const autobidProps = useAutobid(lotID);

	useEffect(() => {
		if (autobidProps?.autobidState === 'closed')
			store.dispatch(BidderActions.setSwipePanel('closed'));
	}, [autobidProps?.autobidState]);

	if (panelState === 'closed') {
		return (
			<SwipePanel>
				<PanelClosed {...autobidProps} />
			</SwipePanel>
		);
	}

	return (
		<SwipePanel>
			<div className={styles.container}>
				<Inputs {...autobidProps} />
				<div>
					{config?.increments}: {currencySymbol}
					{autobidProps?.currentIncrement}
				</div>
				<Buttons {...autobidProps} />
			</div>
		</SwipePanel>
	);
};

export default AutobidFuture;
