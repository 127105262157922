import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

// draft;
// published;
// live;
// closed;

//REQ_SETRUNSTATE: 'Req-SetRunState'
export default function requestSetRunState(state, auctionID) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-SetRunState',
		userTokenID: window.API?.user?.userToken,
		state,
		auctionID,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.updateAuctionRunState(data.state));
	console.debug('Req-SetRunState success', data.state);
};

const handleFailure = data => {
	console.debug('Req-SetRunState failure', data);
};
