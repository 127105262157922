import { combineReducers } from 'redux';
import clerk from 'store/reducers/clerk';
import bidder from 'store/reducers/bidder';
import login from 'store/reducers/login';
import auction from 'store/reducers/auction';
import adminpages from 'store/reducers/adminpages';
import rootadmin from 'store/reducers/rootadmin';
import video from 'store/reducers/video';
import audio from 'store/reducers/audio';
import config from 'store/reducers/config';
import requests from 'store/reducers/requests';
import showFlags from './showflags';

const rootReducer = combineReducers({
	clerk,
	bidder,
	login,
	auction,
	adminpages,
	rootadmin,
	video,
	audio,
	config,
	requests,
	showFlags,
});

export default rootReducer;
