import { useState } from 'react';
import { connect } from 'react-redux';
import requestLinkGoAuction from 'api/requests/admin/Req-LinkGoAuction';
import requestAuctionDetail from 'api/requests/common/Req-AuctionDetail/Req-AuctionDetail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faArrowUp,
	faCheck,
	faSpinner,
} from '@fortawesome/pro-regular-svg-icons';

const GoLink = props => {
	const [showInput, setShowInput] = useState(false);
	const [goID, setGoID] = useState('');
	const [actionIcon, setActionIcon] = useState(
		<FontAwesomeIcon className="golink__icon" icon={faArrowUp} />
	);
	const [error, setError] = useState(false);

	const errorIcon = (
		<FontAwesomeIcon className="golink__icon" icon={faTimes} />
	);

	const successIcon = (
		<FontAwesomeIcon className="golink__icon" icon={faCheck} />
	);

	const uploadIcon = (
		<FontAwesomeIcon className="golink__icon" icon={faArrowUp} />
	);

	const showError = () => {
		setActionIcon(errorIcon);
		setError(true);
		setTimeout(() => {
			setError(false);
			setActionIcon(uploadIcon);
		}, 1500);
	};

	const showSuccess = () => {
		setActionIcon(successIcon);
		setTimeout(() => setActionIcon(uploadIcon), 1500);
	};

	const onConfirm = () => {
		if (!goID) return;
		setActionIcon(
			<FontAwesomeIcon
				className="golink__icon fa-spin"
				icon={faSpinner}
			/>
		);
		requestLinkGoAuction(goID, props?.auctionID)
			.then(response => {
				const { data } = response;
				if (data.result > 100 || data.error) {
					showError();
				} else showSuccess();
			})
			.catch(() => showError());
		requestAuctionDetail(true, props?.auctionID);
	};

	if (showInput)
		return (
			<section>
				<input
					className="golink__input"
					placeholder={props?.config?.linkIDPlaceholder}
					value={goID}
					onChange={event => setGoID(event.target.value)}
				/>
				<button
					className="golink__cancel bg-primary-color-light warning-color border-secondary-color-dark"
					onClick={() => setShowInput(false)}
				>
					<FontAwesomeIcon className="golink__icon" icon={faTimes} />
				</button>
				<button
					className={`golink__confirm border-secondary-color-dark ${
						error ? 'red' : ''
					}`}
					onClick={() => onConfirm()}
				>
					{actionIcon}
				</button>
			</section>
		);

	return (
		<section>
			<button
				className="golink__start primary-color-light bg-admin-accent-1"
				onClick={() => setShowInput(true)}
			>
				{props?.config?.linkIDButton}
			</button>
		</section>
	);
};

const mapStateToProps = state => {
	return {
		lotList: state.adminpages?.auctionDetail?.lotList,
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		saleOutcome: state.adminpages?.saleOutcome,
		auctionDate: state.adminpages?.auctionDetail?.auctionDate,
		auctionTitle: state.adminpages?.auctionDetail?.auctionTitle,
		timeZone: state.adminpages?.auctionDetail?.localisedData?.timeZone,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(GoLink);
