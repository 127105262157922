import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faCheck } from '@fortawesome/pro-light-svg-icons';
import styles from './PanelClosed.module.scss';

const PanelClosed = props => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();

	if (props?.autobidState === 'open')
		return (
			<div className={styles.open}>
				<FontAwesomeIcon className={styles.icon} icon={faMoneyBill} />
				<div className="title">
					{props?.lotClosed
						? config?.lotClosed
						: config?.swipeToAutobid}
				</div>
			</div>
		);

	return (
		<div className={styles.closed}>
			<FontAwesomeIcon className={styles.icon} icon={faCheck} />
			<div className="">{config?.autobidSet}:</div>
			<div className="value">{getFormattedCurrency(props?.limit)}</div>
		</div>
	);
};

export default PanelClosed;
