import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import VideoActions from 'store/actions/video';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faVolumeMute } from '@fortawesome/pro-solid-svg-icons';
import 'styles/common/reactplayersb.scss';

class ReactPlayerSB extends Component {
	constructor(props) {
		super(props);
		this.player = React.createRef();

		this.state = {
			isPlaying: true,
			showPauseOverlay: false,
		};

		this.playCheckInterval = setInterval(() => {
			if (!this.player?.current?.player) return;
			this.setState({
				showPauseOverlay: !this.player.current.player.isPlaying,
			});
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.playCheckInterval);
	}

	onVolumeChange() {
		if (this.video?.muted !== this.props?.isMuted) {
			store.dispatch(VideoActions.updateIsMuted(this.video?.muted));
		}
	}

	onToggleMute() {
		store.dispatch(VideoActions.updateIsMuted(!this.props?.isMuted));
	}

	unMute() {
		this.setState({ isPlaying: true });
		if (!this.props?.isMuted) return;
		store.dispatch(VideoActions.updateIsMuted(false));
	}

	togglePlayPause() {
		store.dispatch(VideoActions.updateIsPaused(!this.props?.isPaused));
	}

	onPlayPauseEvent() {
		store.dispatch(VideoActions.updateIsPaused(this.video?.paused));
	}

	renderOverlay() {
		if (this.props?.isPaused)
			return (
				<div
					className="reactplayersb__overlay"
					onClick={() => this.togglePlayPause()}
				>
					<FontAwesomeIcon
						className="reactplayersb__overlay-icon"
						icon={faPlayCircle}
					/>
				</div>
			);

		if (this.props?.isMuted)
			return (
				<div
					className="reactplayersb__overlay"
					onClick={() => this.onToggleMute()}
				>
					<FontAwesomeIcon
						className="reactplayersb__overlay-icon"
						icon={faVolumeMute}
					/>
				</div>
			);
	}

	render() {
		if (!this.props?.videoURLOpt) {
			return (
				<div
					className={`${this.props?.classes} reactplayersb__container`}
				>
					<div
						className="video-placeholder"
						onClick={event => this.props?.overlayClick(event)}
					>
						<div className="video-placeholder-msg">
							{this.props?.tickerMessageOpt ||
								'Video will start shortly'}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className={`${this.props?.classes} reactplayersb__container`}>
				{this.props.overlay && (
					<div
						className={this.props.overlay}
						onClick={event => {
							this.props?.showMainVideo
								? this.unMute()
								: this.props.overlayClick(event);
						}}
					>
						{this.state.showPauseOverlay && (
							<FontAwesomeIcon
								className={`${this.props.overlay} icon`}
								icon={faPlayCircle}
							/>
						)}
					</div>
				)}
				{/* {this.renderOverlay()} */}

				<ReactPlayer
					className="reactplayersb"
					controls={true}
					height="100%"
					width="100%"
					url={this.props?.videoURLOpt && this.props?.videoURLOpt[0]}
					autoPlay={true}
					volume={1}
					playing={this.state.isPlaying}
					playsinline={true}
					// muted={this.props?.isMuted} TODO causes crash on overlay click
					onPlay={() => this.onPlayPauseEvent()}
					onPause={() => this.onPlayPauseEvent()}
					onVolumeChange={() => this.onVolumeChange()}
					ref={this.player}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isMuted: state.video?.isMuted,
		isPaused: state.video?.isPaused,
		videoURLOpt: state?.auction?.videoStream?.videoURLOpt,
		tickerMessageOpt: state?.auction?.tickerMessageOpt,
		showMainVideo: state?.bidder?.showMainVideo,
	};
};

export default connect(mapStateToProps)(ReactPlayerSB);
