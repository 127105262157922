import { getFile } from '..';
import { getWelcomeConfigEndpoint } from '../utils';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';

export default function getWelcomeConfig() {
	return getFile(getWelcomeConfigEndpoint())
		.then(response => {
			store.dispatch(BidderActions.updateWelcomeModal(response.data));
			console.debug('getWelcomeConfig success, sending to store');
		})
		.catch(error => {
			console.debug('getWelcomeConfig failure', error);
		});
}
