import CurrentLot from './currentlot';
import VideoFeed from 'components/bidderinterface/videofeed/videofeed';
import BidsFeed from 'components/bidderinterface/bidsfeed/bidsfeed';
import LotsFeed from 'components/bidderinterface/lotsfeed/lotsfeed';
import styles from './desktop.module.scss';

const Desktop = () => {
	return (
		<div className={styles.container}>
			<div className={styles.row1}>
				<CurrentLot />
				<div className={styles.video}>
					<VideoFeed />
				</div>
				<BidsFeed classes={styles.bidsFeed} showFooter={false} />
			</div>
			<div className={styles.lotsFeed}>
				<LotsFeed />
			</div>
		</div>
	);
};

export default Desktop;
