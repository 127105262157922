import { connect } from 'react-redux';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import Video from './video';
import Image from './image';
import 'styles/bidderinterface/biddermobile/mobilebidding/mobilemainpanel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

const MainPanel = props => {
	const thumbnailClick = event => {
		event.stopPropagation();
		store.dispatch(BidderActions.setShowMainVideo(!props.showMainVideo));
	};

	return (
		<div className="mobilemainpanel">
			<div className="mobilemainpanel__live primary-color-light bg-warning-color">
				<FontAwesomeIcon
					className="icon small-12-font-size"
					icon={faCircle}
				/>
				{props?.config?.live}
			</div>
			<div className="mobilemainpanel__lotno primary-color-light bg-primary-color-dark">
				{`${props?.config?.lot} ${props?.lotNumber}`}
			</div>
			<Image onThumbnailClick={thumbnailClick} />
			<Video onThumbnailClick={thumbnailClick} />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
		lotNumber: state.auction?.currentLot?.lotNumber,
		showMainVideo: state.bidder?.showMainVideo,
	};
};

export default connect(mapStateToProps)(MainPanel);
