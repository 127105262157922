import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_WITHDRAWLOT: 'Req-WithdrawLot'
export default function requestWithdrawLot(auctionID, saleLotID, isWithdrawn) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-WithdrawLot',
		userTokenID: window.API?.user?.userToken,
		auctionID,
		saleLotID,
		isWithdrawn,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return data;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.updateWithdrawnLot(data));
	store.dispatch(AdminPageActions.updateWithdrawLotComplete(true));
	console.debug('Req-WithdrawLot success', data);
};

const handleFailure = data => {
	console.debug('Req-WithdrawLot failure', data);
	store.dispatch(AdminPageActions.updateWithdrawLotComplete(true));
};
