import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLotCard from './useLotCard';
import useConfig from 'hooks/useConfig';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import Star from './Star/Star';
import InfoCard from './InfoCard/InfoCard';
import Withdrawn from './Withdrawn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import styles from './lotcard.module.scss';
import notfound from 'assets/notfound.png';

const LotCard = ({
	lotID,
	customClickCallback,
	loading,
	transitioning,
	dummyCard,
	setRef,
	borderBottom,
}) => {
	const [cardHover, setCardHover] = useState(false);
	const [itemWon, setItemWon] = useState(false);
	const [hasAnimationPlayed, setHasAnimationPlayed] = useState(false);
	const { useMobileLayout } = useSelector(state => state.bidder) || {};
	const config = useConfig();
	const isClerk = window?.API?.user?.userPerm?.includes('clerk');

	const {
		autoBidOpt,
		isCurrentLot,
		displayAs,
		title,
		absImg,
		lotIndex,
		lotNumber,
		isWithdrawn,
		like,
		isBidder,
		getTitle,
		getEstimate,
		lotState,
	} = useLotCard(lotID);

	const lotOpen = lotState < 200;

	useEffect(() => setCardHover(false), [like]);

	useEffect(() => {
		if (hasAnimationPlayed || isClerk) return;
		if (displayAs !== 4200) return;
		setHasAnimationPlayed(true);
		setItemWon(true);
		setTimeout(() => setItemWon(false), 3000);
		// eslint-disable-next-line
	}, [displayAs]);

	const onItemClick = () => {
		if (customClickCallback) {
			customClickCallback(lotIndex);
			return;
		}
		store.dispatch(BidderActions.bidderOpenModal('lot', lotIndex));
	};

	const onError = event => (event.target.src = 'assets/notfound.png');

	const getTitleStyles = () => {
		let showLost = false;
		let showWon = false;

		if (displayAs === 4300) showLost = true;
		if (displayAs === 4200) showWon = true;

		if (isClerk) {
			if (lotState === 200) {
				showLost = false;
				showWon = true;
			}
			if (lotState === 300) {
				showLost = true;
				showWon = false;
			}
		}

		return classNames(styles.title, {
			[styles.lost]: showLost,
			[styles.won]: showWon,
		});
	};

	if (loading)
		return (
			<div
				className={classNames(styles.loading, {
					[styles.borderBottom]: borderBottom,
				})}
				data-testid="lotcard"
			>
				<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
			</div>
		);

	if (dummyCard)
		return (
			<div
				data-testid="lotcard"
				className={classNames(styles.loading, {
					[styles.borderBottom]: borderBottom,
				})}
			/>
		);

	const showAutobid = isBidder && autoBidOpt && !isCurrentLot && lotOpen;
	const showLiked = isBidder && like && !isCurrentLot && !showAutobid;

	if (isWithdrawn) return <Withdrawn lotNumber={lotNumber} title={title} />;

	return (
		<div
			className={classNames(styles.container, {
				[styles.borderBottom]: borderBottom,
			})}
			ref={setRef}
			data-testid="lotcard"
		>
			<div
				className={styles.lotCard}
				onClick={onItemClick}
				onMouseEnter={() => setCardHover(true)}
				onMouseLeave={() => setCardHover(false)}
			>
				<div className={classNames(styles.imageContainer)}>
					<div
						className={classNames(styles.flipContainer, {
							[styles.winAnimation]: !loading && itemWon,
						})}
					>
						<div className={classNames(styles.imageFront)}>
							{transitioning ? (
								<div className={styles.placeholder}></div>
							) : (
								<img
									className={styles.image}
									src={absImg[0]}
									onError={event => (event.target.src = notfound)}
									loading="lazy"
									alt=""
								/>
							)}
						</div>
						{!transitioning && (
							<div className={styles.imageBack}>
								<FontAwesomeIcon className={styles.winIcon} icon={faTrophy} />
							</div>
						)}
					</div>
				</div>
				<div className={styles.details}>
					<div className={styles.lotNumber}>
						{config?.lot} {lotNumber}
					</div>
					{isBidder && !transitioning && (
						<Star
							lotID={lotID}
							like={like}
							showLiked={showLiked}
							useMobileLayout={useMobileLayout}
							cardHover={cardHover}
							lotNumber={lotNumber}
						/>
					)}
				</div>

				<div className={getTitleStyles()}>{getTitle()}</div>
				<div className={styles.estimate}>{getEstimate()}</div>

				{!transitioning && (
					<InfoCard
						isCurrentLot={isCurrentLot}
						showAutobid={showAutobid}
						showLiked={showLiked}
					/>
				)}
			</div>
		</div>
	);
};

export default LotCard;
