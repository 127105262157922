import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import requestSetCurrentItem from 'api/requests/clerk/Req-SetCurItem';
import ClerkHeader from 'components/clerkconsole/header/header';
import BidHistory from 'components/clerkconsole/bidhistory/bidhistory';
import CurrentLot from 'components/clerkconsole/currentlot/currentlot';
import ManagementPanel from 'components/clerkconsole/managementpanel/managementpanel';
import Timeline from 'components/clerkconsole/timeline/timeline';
import MessagePanel from 'components/clerkconsole/messagepanel/messagepanel';
import DebugModal from 'components/debugmodal/debugmodal';
// import BroadcastInterface from 'components/clerkconsole/broadcast/broadcast2';
import BroadcastInterface from './broadcast3/broadcast3';
import AuctionResults from 'components/common/auctionresults/auctionresults';
import Fullscreen from 'components/clerkconsole/fullscreen/fullscreen';
import Auctioneer from 'components/clerkconsole/auctioneer/auctioneer';
import DetailedAuctioneer from './DetailedAuctioneer/DetailedAuctioneer';
import 'styles/clerkconsole/clerkconsole.scss';

class ClerkConsole extends Component {
	constructor(props) {
		super(props);

		this.state = {
			clerk: this.props.clerk,
			keyPressEvent: null,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.clerk === prevProps.clerk) return;

		this.setState({
			clerk: this.props.clerk,
		});

		let unPausing = prevProps.clerk.isPaused && !this.props.clerk.isPaused;
		if (unPausing && !this.props.clerk.currentLot) {
			// auction is unpausing with no current item set, i.e. this is the initial startup
			// set the first item here
			let lotList = this.props.clerk.lotList;
			if (lotList.length > 0) {
				let lotIndex = lotList[0].catalogueLot.lotIndex;
				requestSetCurrentItem(lotIndex);
				return;
			}
		}
	}

	onKeyDown(event) {
		// TODO - clerk key capture is enabled here
		// event.preventDefault();
		// store.dispatch(ClerkActions.onKeyDownEvent(event));
	}

	render() {
		return (
			<Routes>
				<Route
					path=""
					element={
						<div
							className="clerkconsole background-color"
							onKeyDown={this.onKeyDown}
							tabIndex={-1}
						>
							<ClerkHeader clerk={this.state.clerk} />
							{this.state.clerk.debugModalOpen && (
								<DebugModal {...this.state} />
							)}

							<div className="clerkconsole-container">
								<div className="clerkconsole-col-container">
									<div className="clerkconsole__col-1">
										<div className="clerkconsole__col-1__row-1">
											<CurrentLot />
											<BidHistory />
										</div>
										<div className="clerkconsole__col-1__row-2">
											<Timeline />
										</div>
										<div className="clerkconsole__col-1__row-3">
											<MessagePanel />
										</div>
									</div>
									<div className="clerkconsole__col-2">
										<ManagementPanel />
									</div>
								</div>
							</div>
						</div>
					}
				/>

				<Route
					path="results"
					element={
						<AuctionResults
							saleOutcome={this.state.clerk?.saleOutcome}
							origin="/clerkconsole"
							{...this.state.clerk}
						/>
					}
				/>

				<Route path="broadcast" element={<BroadcastInterface />} />

				<Route path="fullscreen" element={<Fullscreen />} />

				<Route
					path="auctioneer"
					element={<Auctioneer clerk={this.state.clerk} />}
				/>

				<Route
					path="detailedauctioneer"
					element={<DetailedAuctioneer />}
				/>
			</Routes>
		);
	}
}

const mapStateToProps = state => {
	return {
		clerk: { ...state.clerk, ...state.auction },
	};
};

export default connect(mapStateToProps)(ClerkConsole);
