import { useState, useEffect } from 'react';
import classNames from 'classnames';

import useCurrentLot from 'hooks/useCurrentLot';
import LazyImage from 'components/common/lazyimage';
import styles from './currentlot.module.scss';

const Images = props => {
	const [mainImage, setMainImage] = useState(0);
	const { catalogueLot } = useCurrentLot();
	const { absImg, lotIndex } = catalogueLot || {};

	useEffect(() => {
		setMainImage(0);
	}, [lotIndex]);

	const getImageURL = index => {
		if (!absImg) return '';
		return absImg[index];
	};

	const onThumbnailClick = index => {
		setMainImage(index);
	};

	return (
		<div className={styles.images}>
			<div className={styles.mainimagewrapper}>
				<LazyImage className={styles.mainimage} url={getImageURL(mainImage)} />
			</div>

			<div className={styles.spacer}></div>
			<div className={styles.thumbnails}>
				{absImg?.map((img, index) => {
					return (
						<button
							className={styles.thumbnailbutton}
							key={index}
							onClick={() => onThumbnailClick(index)}
						>
							<LazyImage
								className={classNames(styles.thumbnail, {
									[styles.active]: index === mainImage,
								})}
								url={getImageURL(index)}
								key={index}
							/>
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default Images;
