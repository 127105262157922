import { useSelector } from 'react-redux';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import Thumbnail from './thumbnail';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import useCurrentLot from 'hooks/useCurrentLot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import styles from './ActionBar.module.scss';
import 'styles/bidderinterface/biddermobile/mobileactionbar.scss';

const ActionBar = () => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const mobilePage = useSelector(
		state => state.bidder?.mobilePage || 'bidding'
	);
	const askingPrice = useSelector(
		state => state.auction?.currentOfferOpt?.offerPrice || 0
	);
	const lotList = useSelector(state => state.auction?.lotList || []);
	const currentLotIndex = useCurrentLot()?.catalogueLot?.lotIndex;

	const bidding = mobilePage === 'bidding';
	const catalogue = mobilePage !== 'bidding';
	const nextLot = lotList?.find(
		lot => lot.catalogueLot.lotIndex === currentLotIndex + 1
	);

	const onToggleClick = () => {
		store.dispatch(
			BidderActions.updateMobilePage(bidding ? 'catalogue' : 'bidding')
		);
		window.API.pushHistory(
			bidding ? '/bidderinterface/mobilecatalogue' : '/bidderinterface'
		);
	};

	return (
		<section className={styles.container}>
			<div className={styles.toggle} onClick={onToggleClick}>
				<span
					className={`${styles.toggle_button} ${
						bidding ? `${styles.active}` : `${styles.inactive}`
					}`}
				>
					{config?.auction}
				</span>
				<span
					className={`${styles.toggle_button} ${
						catalogue ? `${styles.active}` : `${styles.inactive}`
					}`}
				>
					{config?.lots}
				</span>
			</div>
			<div className={styles.info}>
				{bidding && config?.next}
				{catalogue && (
					<div className={styles.current}>
						<div className={styles.live}>
							<FontAwesomeIcon
								className={styles.icon}
								icon={faCircle}
							/>
							{config?.live}
						</div>
						<div className={styles.askingPrice}>
							{getFormattedCurrency(askingPrice)}
						</div>
					</div>
				)}
			</div>

			<Thumbnail {...nextLot} />
		</section>
	);
};

export default ActionBar;
