import store from 'store/store';
import ClerkActions from 'store/actions/clerk';
import requestState from 'api/requests/common/Req-State';
import {
	auctionState,
	bidUpdate,
	bidNotify,
	bidActions,
} from 'api/wss/wssRequests';

export default class WssConn {
	constructor(data) {
		if (
			data.permissions.indexOf('admin') !== -1 ||
			data.permissions.indexOf('root') !== -1
		)
			return null;
		const userPerm = window.API.user?.userPerm;
		this.userTokenID = data.userTokenID;
		this.wssConn = data?.auction?.isAuctionLive
			? this.create_wss_connection(data.userTokenID)
			: null;
		this.checkConnTimeout = null;
		this.checkInterval =
			userPerm?.indexOf('clerk') > -1
				? this.getClerkRandomTime()
				: this.getRandomTime();
		this.connClosing = false;
		this.connectAttemptLimit = 5;
		this.connectAttemptCount = 0;
	}

	create_wss_connection() {
		const userPerm = window.API.user?.userPerm;
		if (userPerm?.indexOf('admin') !== -1 || userPerm?.indexOf('root') !== -1)
			return;

		const wwsProtocolHead =
			'https:' === document.location.protocol ? 'wss://' : 'ws://';
		let wwsPort = 'https:' === document.location.protocol ? '443' : '80';
		if (userPerm?.indexOf('clerk') > -1) wwsPort = '4443';
		const hostName = window.location.hostname;
		let wssUrl = wwsProtocolHead + hostName + ':' + wwsPort + '/clientwss';
		let ws = null;

		if (this.has_wss_connection()) return this.wssConn;

		if (this.wssConn && this.wssConn.readyState !== WebSocket.CLOSED) {
			console.debug(
				'[WSS][WARNING] Attempting to create websocket when it is in closing or starting state: ',
				this.wssConn
			);
			return this.wssConn;
		}

		console.debug('[WSS] Attempting to create new Websocket...');

		if (this.userTokenID)
			ws = new WebSocket(wssUrl + '?userTokenID=' + this.userTokenID);
		else ws = new WebSocket(wssUrl);

		ws.onopen = () => {
			console.debug('[WSS] Websocket created: ', ws);
			console.log('[WSS] Websocket Connection established at ' + wssUrl);
			this.connectAttemptCount = 0;
			if (!this.checkConnTimeout) {
				this.setupCheckConnInterval();
			}
			store.dispatch(ClerkActions.setWssLive(true));
			const userPerm = window.API.user?.userPerm;
			if (userPerm?.indexOf('clerk') === -1) return;
			requestState();
		};
		ws.onerror = error => {
			console.debug('[WSS] WebSocket error: ', error);
		};
		ws.onmessage = e => {
			this.onmessage(JSON.parse(e.data));
		};
		ws.onclose = e => {
			console.debug('[WSS] Websocket connection closed: ', e);
			store.dispatch(ClerkActions.setWssLive(false));

			if (this.connClosing) return;

			const userPerm = window.API.user?.userPerm;
			if (userPerm?.indexOf('clerk') === -1) return;

			setTimeout(() => this.checkConn(), 200);
		};

		return ws;
	}

	setupCheckConnInterval() {
		if (this.checkConnTimeout || this.connClosing) return;
		this.checkConnTimeout = setTimeout(() => {
			this.checkConn();
		}, this.checkInterval);
	}

	checkConn() {
		const userPerm = window.API.user?.userPerm;
		if (!this.has_wss_connection()) {
			console.log('[WSS] Websocket connection lost, attempting to reconnect');
			this.connectAttemptCount++;
			if (this.connectAttemptCount > this.connectAttemptLimit) {
				window.API.onLogout();
				return;
			}

			this.wssConn = this.create_wss_connection();

			const multiplier = userPerm?.indexOf('clerk') > -1 ? 1.2 : 1.5;
			this.checkInterval = Math.floor(this.checkInterval * multiplier);
			if (this.checkInterval > 60000) this.checkInterval = 60000;
		} else {
			this.checkInterval = this.getRandomTime();
		}

		if (this.connClosing) return;

		// CAUTION RECURSION
		this.checkConnTimeout = setTimeout(() => {
			if (this.connClosing) return;
			console.debug('[INFO] Recursive API call - WSS checkConn');
			this.checkConn();
		}, this.checkInterval);
	}

	has_wss_connection() {
		return this.wssConn && this.wssConn?.readyState === WebSocket.OPEN;
	}

	send(data) {
		if (this.wssConn?.readyState !== WebSocket.OPEN) return;
		this.wssConn?.send(data);
	}

	break() {
		this.wssConn?.close();
	}

	close() {
		this.connClosing = true;
		clearTimeout(this.checkConnTimeout);
		this.checkConnTimeout = null;
		this.wssConn?.close();
	}

	onmessage(msg) {
		let action = msg.Action;

		switch (action) {
			case 'WSSConnected':
				console.debug('[WSS] Websocket connected', msg);
				break;
			case 'AuctionState':
				auctionState(msg);
				break;
			case 'BidUpdate':
				bidUpdate(msg);
				break;
			case 'BidNotify':
				bidNotify(msg);
				break;
			case 'BidActions':
				bidActions(msg);
				break;
			default:
				console.debug('[WSS] No protocol found for action', action);
		}
	}

	getRandomTime() {
		return Math.floor(Math.random() * 10000) + 5000;
	}
	getClerkRandomTime() {
		return Math.floor(Math.random() * 5000) + 2000;
	}
}
