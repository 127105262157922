import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import AudioActions from 'store/actions/audio';

const useAudio = props => {
	const [audioElement] = useState(new Audio());
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		if (!props.effect) {
			audioElement.pause();
			return;
		}
		const url = `https://${window.location.hostname}/styling/sounds/${props.effect}.m4a`;
		audioElement.src = url;
		audioElement.loop = props.loop;
		audioElement
			.play()
			.then()
			.catch(err => console.debug(err));
	}, [props, audioElement]);

	useEffect(() => {
		audioElement.addEventListener('ended', () => {
			setPlaying(false);
			let stopData = { effect: null, loop: false };
			store.dispatch(AudioActions.playAudio(stopData));
		});
		return () => {
			audioElement.removeEventListener('ended', () => setPlaying(false));
		};
	}, [audioElement]);

	return [playing];
};

const AudioFX = props => {
	useAudio(props.audio);
	return null;
};

const mapStateToProps = state => {
	return {
		audio: state.audio,
	};
};

export default connect(mapStateToProps)(AudioFX);
