import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import requestAuctionDetail from 'api/requests/common/Req-AuctionDetail/Req-AuctionDetail';
import requestUpdateLot from 'api/requests/admin/Req-UpdateLot';
import 'styles/adminpages/addauction/review/loteditform.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import DynamicForm from 'components/common/dynamicform';
import LazyImage from 'components/common/lazyimage';
import { getFormData, getValidationSchema } from './utils';

const LotEditForm = props => {
	const [formData, setFormData] = useState(getFormData(props));

	const [mainImage, setMainImage] = useState(0);
	const lotIndex = props?.lotIndex;
	const [statusMessage, setStatusMessage] = useState(null);

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => (document.body.style.overflow = 'unset');
	}, []);

	useEffect(() => {
		setFormData(getFormData(props));
		// eslint-disable-next-line
	}, [props?.lotID]);

	useEffect(() => {
		if (!props.updateLotStatus?.detail) return;

		if (props.updateLotStatus.result === 0) {
			setStatusMessage('Changes saved successfully');
		} else {
			setStatusMessage(props.updateLotStatus?.detail);
		}

		setTimeout(() => {
			setStatusMessage(null);
			store.dispatch(AdminPageActions.updateLotStatus(null));
		}, 2000);
	}, [props.updateLotStatus]);

	const getImageURL = index => {
		return props?.absImg[index];
	};

	const onSubmit = (data, setSubmitting) => {
		let preparedData = {
			saleLotID: props.lotID,
			title: data.title,
			description: data.description,
			footnote: props.footnote,
			lowEst: Number(data.lowEst),
			highEst: Number(data.highEst),
			startingPrice: Number(data.startingPrice),
			category: props.category,
			resaleRights: props.resaleRights,
		};

		requestUpdateLot(preparedData);
		requestAuctionDetail(true, props?.auctionID);
		setSubmitting(false);
	};

	return (
		<div className="loteditform" data-testid="lots-editform">
			<div className="nav">
				<button
					className="nav__close"
					onClick={() => props.onEditClick(undefined)}
				>
					<FontAwesomeIcon className="icon" icon={faChevronLeft} />
					Back to all lots
				</button>
				{statusMessage && (
					<div className="nav__status large-24-font-size">{statusMessage}</div>
				)}
				<div className="nav__container">
					<button
						className="nav_changelot"
						onClick={() => props.onEditClick(lotIndex - 1)}
					>
						<FontAwesomeIcon className="icon" icon={faChevronLeft} />
					</button>
					<button
						className="nav_changelot"
						onClick={() => props.onEditClick(lotIndex + 1)}
					>
						<FontAwesomeIcon className="icon" icon={faChevronRight} />
					</button>
				</div>
			</div>
			<div className="main">
				<div className="images">
					<LazyImage className="mainimage" url={getImageURL(mainImage)} />

					<div className="thumbnails">
						{props?.absImg.map((imgPath, index) => {
							return (
								<div
									className="thumbnail__container"
									key={index}
									onClick={() => setMainImage(index)}
								>
									<LazyImage className="thumbnail" url={getImageURL(index)} />
								</div>
							);
						})}
					</div>
				</div>
				<div className="form">
					<DynamicForm
						formData={formData}
						validationSchema={getValidationSchema()}
						onSubmit={onSubmit}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const lotProps = state.adminpages?.auctionDetail?.lotList.find(
		lot => lot.catalogueLot.lotIndex === ownProps.lotIndex
	);

	return {
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		updateLotStatus: state.adminpages?.updateLotStatus,
		lotNumber: lotProps?.catalogueLot?.lotNumber,
		title: lotProps?.catalogueLot?.title,
		description: lotProps?.catalogueLot?.description,
		lowEst: lotProps?.catalogueLot?.lowEst,
		highEst: lotProps?.catalogueLot?.highEst,
		startingPrice: lotProps?.catalogueLot?.startingPrice,
		lotID: lotProps?.catalogueLot?.lotID,
		footnote: lotProps?.catalogueLot?.footnote,
		category: lotProps?.catalogueLot?.category,
		resaleRights: lotProps?.catalogueLot?.resaleRights,
		absImg: lotProps?.catalogueLot?.absImg,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(LotEditForm);
