import currency from 'currency.js';

export function numberWithCommas(x) {
	if (x === undefined || x === null) return '';
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function capitaliseFirst(string) {
	if (!string) return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function base64Encode(msgIndexNum) {
	let encoder = new TextEncoder('ascii');
	let decoder = new TextDecoder('ascii');
	let base64Table = encoder.encode(
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
	);
	let timeStamp = Date.now() / 1000;
	let rawArray = new Uint8Array(6);
	let outArray = new Uint8Array(8);
	for (let i = 0; i < 4; i++) rawArray[i] = (timeStamp >> ((3 - i) * 8)) & 255;
	for (let i = 0; i < 2; i++)
		rawArray[i + 4] = (msgIndexNum >> ((1 - i) * 8)) & 255;
	let outIndex = 0; //Only works if there is no padding
	let data1 = (rawArray[0] << 16) | (rawArray[1] << 8) | rawArray[2];
	let data2 = (rawArray[3] << 16) | (rawArray[4] << 8) | rawArray[5];
	outArray[outIndex++] = base64Table[(data1 >> 18) & 0x3f];
	outArray[outIndex++] = base64Table[(data1 >> 12) & 0x3f];
	outArray[outIndex++] = base64Table[(data1 >> 6) & 0x3f];
	outArray[outIndex++] = base64Table[data1 & 0x3f];
	outArray[outIndex++] = base64Table[(data2 >> 18) & 0x3f];
	outArray[outIndex++] = base64Table[(data2 >> 12) & 0x3f];
	outArray[outIndex++] = base64Table[(data2 >> 6) & 0x3f];
	outArray[outIndex++] = base64Table[data2 & 0x3f];
	//console.debug("Time:" + timeStamp + " " + output)
	return decoder.decode(outArray);
}

export function generateDummyLot() {
	return {
		dummyCard: true,
		autoBidOpt: null,
		catalogueLot: {
			category: null,
			description: null,
			footnote: null,
			highEst: null,
			imageURL: null,
			isWithdrawn: null,
			lotID: null,
			lotIndex: null,
			lotNumber: null,
			lowEst: null,
			resaleRights: null,
			absImg: [],
			title: null,
		},
		interaction: null,
		lotSaleDetail: null,
	};
}

export function pretifyDate(input) {
	const date = new Date(input);
	const days = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	let numericDate = date.getDate().toString();
	let splitDate = numericDate.split('');
	let lastDigit = splitDate[splitDate.length - 1];

	switch (lastDigit) {
		case '1':
			numericDate += 'st';
			break;
		case '2':
			numericDate += 'nd';
			break;
		case '3':
			numericDate += 'rd';
			break;
		default:
			numericDate += 'th';
	}

	let mins = `${date.getMinutes()}`.padStart(2, 0);
	let hours = `${date.getHours()}`.padStart(2, 0);
	let day = days[date.getDay()];
	let month = months[date.getMonth()];
	let year = `${date.getFullYear()}`;

	return `${day} ${numericDate} ${month} ${year} ${hours}:${mins}`;
}

export function getFormattedDate(date, locale) {
	switch (locale) {
		case 'us':
			return new Date(date).toLocaleString('en-US', {
				timeZone: 'America/New_York',
			});
		case 'uk':
			return new Date(date).toLocaleString('en-GB');
		default:
	}
}

export function getDateFormatString(locale) {
	switch (locale) {
		case 'us':
			return 'MM-dd-yy hh:mm';
		case 'uk':
			return 'dd-MM-yy hh:mm';
		default:
	}
}

export function getFormattedCurrency(value, locale) {
	if (!value) return '';
	switch (locale) {
		case 'usd':
			return currency(value, { symbol: '$', precision: 0 }).format();
		case 'eur':
			return currency(value, {
				symbol: '€',
				separator: '.',
				precision: 0,
				decimal: ',',
			}).format();
		case 'gbp':
			return currency(value, { symbol: '£', precision: 0 }).format();
		default:
			return '';
	}
}

export function getLocaleDate(date, locale) {
	// BST & EDT need to be added
	switch (locale) {
		case 'EST':
			return date.toLocaleString('en-US', {
				timeZone: 'America/New_York',
			});
		case 'GMT':
			return date.toLocaleString('en-GB');
		default:
			return date.toString();
	}
}

export function isValidOfferPrice(price) {
	if (price === undefined || price === null) return false;
	const numPrice = Number(price);
	if (isNaN(numPrice)) return false;
	if (numPrice < 0) return false;
	return true;
}

export function guid_to_base64(g, le) {
	var hexlist = '0123456789abcdef';
	var b64list =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

	var s = g.replace(/[^0-9a-f]/gi, '').toLowerCase();
	if (s.length != 32) return '';

	if (le)
		s =
			s.slice(6, 8) +
			s.slice(4, 6) +
			s.slice(2, 4) +
			s.slice(0, 2) +
			s.slice(10, 12) +
			s.slice(8, 10) +
			s.slice(14, 16) +
			s.slice(12, 14) +
			s.slice(16);
	s += '0';

	var a, p, q;
	var r = '';
	var i = 0;
	while (i < 33) {
		a =
			(hexlist.indexOf(s.charAt(i++)) << 8) |
			(hexlist.indexOf(s.charAt(i++)) << 4) |
			hexlist.indexOf(s.charAt(i++));

		p = a >> 6;
		q = a & 63;

		r += b64list.charAt(p) + b64list.charAt(q);
	}
	r += '==';

	return r;
}
