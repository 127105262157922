import requestInteraction from 'api/requests/bidder/Req-Interaction';
import { useState } from 'react';
import classNames from 'classnames';
import styles from './Star.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';

const Star = ({ lotID, like, showLiked, useMobileLayout, cardHover }) => {
	const [starHover, setStarHover] = useState(false);
	const starLight = <FontAwesomeIcon className="" icon={faStarLight} />;
	const starSolid = <FontAwesomeIcon className="" icon={faStarSolid} />;

	const onStarClick = event => {
		event.stopPropagation();
		if (!lotID) return;
		requestInteraction(lotID, !like);
	};

	const getStarIcon = () => {
		if (showLiked || starHover) return starSolid;
		return starLight;
	};

	return (
		<div
			className={classNames(styles.star, {
				[styles.hidden]: !cardHover,
				[styles.active]: showLiked,
			})}
			onClick={onStarClick}
			onMouseEnter={() => setStarHover(true)}
			onMouseLeave={() => setStarHover(false)}
		>
			{getStarIcon()}
		</div>
	);
};

export default Star;
