import React, { Component } from 'react';
import { connect } from 'react-redux';
import { capitaliseFirst } from 'utils/utils';
import Lot from 'components/clerkconsole/timeline/lot/lot';
import 'styles/clerkconsole/timeline/timeline.scss';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/pro-regular-svg-icons';

export class Timeline extends Component {
	constructor(props) {
		super(props);
		this.listRef = React.createRef();
		this.state = {
			focusLotIndex: null,
			allLotsLoaded: false,
			lots: null,
			initialScrollDone: false,
			containerRef: null,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps?.lotCount !== this.props?.lotCount) return true;
		if (nextProps?.lotList.length !== this.props?.lotList.length)
			return true;
		if (nextState.lots !== this.state.lots) return true;
		if (nextProps?.currentLot?.lotID !== this.props?.currentLot?.lotID)
			return true;
		if (nextState.lots?.length !== this.state.lots?.length) return true;
		if (nextState.focusLotIndex !== this.state.focusLotIndex) return true;

		const nextPrevLot = this.getPrevLot(nextProps?.lotList);
		const prevLot = this.getPrevLot(this.props?.lotList);

		if (
			nextPrevLot?.lotSaleDetail?.displayAs !==
			prevLot?.lotSaleDetail?.displayAs
		)
			return true;

		const nextLot = this.getCurrentLot(nextProps?.lotList);
		const currentLot = this.getCurrentLot(this.props?.lotList);

		if (
			nextLot?.lotSaleDetail?.displayAs !==
			currentLot?.lotSaleDetail?.displayAs
		)
			return true;

		return false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props?.lotCount && !this.state.lots) {
			this.setInitialLotList();
		}

		if (this.props?.lotList.length !== prevProps?.lotList.length) {
			this.updateLotList();
		}

		if (this.props?.lotList.length >= this.props?.lotCount) {
			this.setState({ lots: this.props?.lotList });
		}

		if (this.props?.currentLot?.lotID !== prevProps?.currentLot?.lotID) {
			this.scrollToCurrentLot();
			this.updateLotList();
		}

		if (this.state.focusLotIndex !== prevState.focusLotIndex) {
			this.listRef.current.scrollToItem(
				this.state.focusLotIndex - 1,
				'start'
			);
		}
	}

	onRefChange = node => {
		this.setState({ containerRef: node });
	};

	getCurrentLot(lotList) {
		const targetIndex = this.props?.currentLot?.lotIndex;
		if (targetIndex < 1) return null;

		return lotList.find(lot => lot.catalogueLot.lotIndex === targetIndex);
	}

	getPrevLot(lotList) {
		const targetIndex = this.props?.currentLot?.lotIndex - 1;
		if (targetIndex < 1) return null;

		return lotList.find(lot => lot.catalogueLot.lotIndex === targetIndex);
	}

	setInitialLotList() {
		let loadingArray = new Array(this.props?.lotCount);
		loadingArray.fill({ loading: true });
		this.setState({
			lots: loadingArray,
		});
	}

	updateLotList() {
		if (!this.props?.lotList.length) return;
		if (!this.state.lots) return;
		let newLots = [...this.state.lots];

		this.props?.lotList.forEach(lot => {
			let index = lot.catalogueLot.lotIndex;
			newLots[index - 1] = lot;
		});
		if (!this.state.initialScrollDone) {
			this.scrollToCurrentLot();
			this.setState({ initialScrollDone: true });
		}

		this.setState({
			lots: newLots,
		});
	}

	scrollToCurrentLot = () => {
		const list = this.state.containerRef;
		const currentLotIndex = this.props?.currentLot?.lotIndex - 2;
		if (!list || !currentLotIndex) return;

		const { itemData, itemSize } = list?.props || {};
		if (!itemData || !itemSize) return;

		let scrollTarget = currentLotIndex * itemSize;
		if (!scrollTarget) return;

		list.scrollTo(scrollTarget);
	};

	isCurrentLot(lot) {
		return lot.catalogueLot?.lotIndex === this.props?.currentLot?.lotIndex;
	}

	isFocusLot(lot) {
		return lot.catalogueLot?.lotIndex === this.state.focusLotIndex;
	}

	onSearchInputChange = event => {
		const targetVal = event.target.value.toLowerCase();
		const result = targetVal
			? this.props?.lotList
					.filter(lot => {
						let lotNo = lot.catalogueLot.lotNumber.toLowerCase();
						return lotNo === targetVal;
					})
					.sort(
						(a, b) =>
							a.catalogueLot.lotIndex < b.catalogueLot.lotIndex
					)[0].catalogueLot.lotIndex
			: this.props?.currentLot?.lotIndex;

		const list = this.state.containerRef;
		const resultLotIndex = result - 2;
		if (!list || !resultLotIndex) return;

		const { itemData, itemSize } = list?.props || {};
		if (!itemData || !itemSize) return;

		let scrollTarget = resultLotIndex * itemSize;
		if (!scrollTarget) return;

		list.scrollTo(scrollTarget);
	};

	renderLot = ({ index, style, data }) => {
		const lot = data[index];

		if (lot.loading) {
			const loadingStyle = {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				...style,
			};

			return (
				<div
					style={loadingStyle}
					className="timeline__list__item loading"
				>
					<FontAwesomeIcon
						className="icon primary-color-dark fa-spin"
						icon={faSpinner}
					/>
				</div>
			);
		}

		let props = {
			lotID: lot.catalogueLot?.lotID,
			key: index,
			isActive: this.isCurrentLot(lot),
			callback: this.lotDidIntersect,
		};

		if (this.isFocusLot(lot)) {
			props.isFocus = true;
		}

		return (
			<div style={style} className="timeline__list__item">
				<Lot {...props} />
			</div>
		);
	};

	render() {
		return (
			<div className="timeline bg-primary-color-light">
				<div className="timeline__controls ">
					<div className="timeline__controls__search">
						<FontAwesomeIcon
							className="icon primary-color-dark"
							icon={faSearch}
						/>
						<input
							className="timeline__controls__search__input primary-color-dark bg-secondary-color-light regular-16-font-size"
							placeholder={capitaliseFirst(
								this.props?.config?.searchTitles
							)}
							onChange={this.onSearchInputChange}
							data-testid="input"
						></input>
					</div>
				</div>

				<div className="timeline-list-wrapper">
					{this.state.lots && (
						<AutoSizer>
							{({ height, width }) => (
								<List
									className="timeline-list"
									height={height}
									itemCount={this.state.lots.length}
									itemSize={65}
									ref={this.onRefChange}
									width={width}
									itemData={this.state.lots}
								>
									{this.renderLot}
								</List>
							)}
						</AutoSizer>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		config: state?.config,
		lotCount: state.auction?.lotCount,
		lotList: state.auction?.lotList,
		currentLot: state.auction?.currentLot,
	};
};

export default connect(mapStateToProps)(Timeline);
