import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import styles from './AutobidInput.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const AutobidInput = ({
	limit,
	onInputChange,
	onSubmit,
	autoBidOpt,
	onDelete,
	offerPrice,
}) => {
	const { getFormattedCurrency } = useCurrency();
	const config = useConfig();
	const value = autoBidOpt
		? getFormattedCurrency(offerPrice)
		: getFormattedCurrency(limit);

	const onKeyPress = event => {
		if (event.charCode !== 13) return;
		event.preventDefault();
		event.target.blur();
		onSubmit();
	};

	return (
		<div className={styles.container}>
			{autoBidOpt && <div className={styles.title}>{config?.bid}:</div>}
			<input
				className={styles.input}
				value={value}
				onClick={event => event.stopPropagation()}
				onChange={onInputChange}
				onKeyPress={onKeyPress}
				inputMode="numeric"
			/>
			{autoBidOpt && (
				<div className={styles.delete}>
					<button
						className={styles.deleteButton}
						onClick={event => onDelete(event)}
					>
						<FontAwesomeIcon
							className={styles.icon}
							icon={faTimes}
						/>
					</button>
				</div>
			)}
		</div>
	);
};

export default AutobidInput;
