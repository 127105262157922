import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import LoginActions from 'store/actions/login';

//AUTH_VALIDATE: 'Req-Validate'
export default function requestValidate(userTokenID, reissueOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-Validate',
		userTokenID,
		reissueOpt,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				store.dispatch(
					LoginActions.showLoginDetailError('Token validation failed')
				);
				window.API.onAuthenticationFail('Token validation failed');
				return;
			}
			setLocale(data);
			data.tokExpTime = Date.now() + data.tokenLife * 1000;
			window.API.onAuthenticationSuccess(data);
		})
		.catch(error => {
			console.log(error);
			store.dispatch(LoginActions.showLoginDetailError('Network error'));
			window.API.onAuthenticationFail('Unable to connect to server');
		});
}

function setLocale(data) {
	if (!data.locales) return;
	store.dispatch(LoginActions.setLocales(data));
	const activeLocale = data.locales.find(locale => locale.isCurrent);
	window.API.locale = activeLocale?.code;
}
