import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import requestPassBids from 'api/requests/admin/Req-PassBids';
import 'styles/common/passbids.scss';

const PassBids = props => {
	useEffect(() => {
		if (!window.API?.user?.userToken) {
			goBack();
			return;
		}
		requestPassBids();
		// eslint-disable-next-line
	}, []);

	const goBack = () => window.API.pushHistory(props.origin);
	const passBids = useSelector(state => state.adminpages?.passBids);
	const config = useConfig();
	const [showAllBids, setShowAllBids] = useState(null);
	const modalProps = {
		...showAllBids,
		setShowAllBids,
		config,
	};

	const currency = props?.currency;

	return (
		<div className="passbids">
			{showAllBids && <Modal {...modalProps} />}
			<div className="passbids__header">
				<button className="passbids__back" onClick={() => goBack()}>
					{config?.back}
				</button>
				<h1>{config?.passedLotOffers}</h1>
			</div>

			<div className="passbids__table__wrapper">
				<table className="passbids__table" cellSpacing="0">
					<thead>
						<tr>
							<th>{config?.lotNumber}</th>
							<th>{config?.title}</th>
							<th>{config?.lowEstimate}</th>
							<th>{config?.bestBid}</th>
							<th>{config?.email}</th>
							<th>{config?.name}</th>
							<th>{config?.showAll}</th>
						</tr>
					</thead>
					<tbody>
						{passBids?.length > 0 &&
							passBids.map((row, index) => {
								return (
									<Row
										{...row}
										currency={currency}
										config={config}
										key={index}
										setShowAllBids={setShowAllBids}
									/>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PassBids;

const Row = props => {
	const { getFormattedCurrency } = useCurrency();
	const topBid = props.bids[0];
	const salutation = topBid?.salutation;
	const firstName = topBid?.firstName;
	const secondName = topBid?.secondName;
	const { config } = props;
	let name = null;

	if (!salutation || !firstName || !secondName) name = 'N/A';
	else name = `${salutation} ${firstName} ${secondName}`;

	return (
		<tr>
			<td>{props?.lotNum}</td>
			<td>{props?.title}</td>
			<td>{getFormattedCurrency(props?.lowEst)}</td>
			<td>{getFormattedCurrency(props?.bestBid)}</td>
			<td>{topBid?.email}</td>
			<td>{name}</td>
			<td>
				<button
					className="passbids__showall"
					onClick={() => props.setShowAllBids(props)}
				>
					{config?.showAll}
				</button>
			</td>
		</tr>
	);
};

const Modal = props => {
	const { config } = props;
	return (
		<div className="passbids__modal">
			<div className="modal__header">
				<button
					className="close"
					onClick={() => props.setShowAllBids(null)}
				>
					{config?.close}
				</button>
				<h2>
					{config?.bidHistoryForLot} {props?.lotNum}
				</h2>
			</div>
			<div className="passbids__table__wrapper">
				<table className="passbids__table" cellSpacing="0">
					<thead>
						<tr>
							<th>{config?.bid}</th>
							<th>{config?.email}</th>
							<th>{config?.name}</th>
							<th>{config?.time}</th>
						</tr>
					</thead>
					<tbody>
						{props.bids?.length > 0 &&
							props.bids.map((row, index) => {
								return (
									<ModalRow
										{...row}
										key={index}
										currency={props?.currency}
									/>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const ModalRow = props => {
	const { getFormattedCurrency } = useCurrency();
	const name = `${props?.salutation} ${props?.firstName} ${props?.secondName}`;
	const date = new Date(props?.timeStamp);
	const dateTime = `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
	return (
		<tr>
			<td>{getFormattedCurrency(props.bid)}</td>
			<td>{props?.email}</td>
			<td>{name}</td>
			<td>{dateTime}</td>
		</tr>
	);
};
