import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faGavel } from '@fortawesome/pro-light-svg-icons';

import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import useCurrentLot from 'hooks/useCurrentLot';
import Images from 'components/bidderinterface/currentlot/images';
import Details from 'components/bidderinterface/currentlot/details';
import styles from './currentlot.module.scss';

export const CurrentLot = () => {
	const config = useConfig();
	const { catalogueLot } = useCurrentLot();
	const { isWithdrawn, lotNumber, lotIndex, lowEst, highEst } =
		catalogueLot || {};
	const { getFormattedCurrency } = useCurrency();
	const { auctionTitle, auctionHouseName, extended } =
		useSelector(state => state?.auction) || {};

	const { saleNumOpt } = extended || {};

	if (!lotIndex)
		return (
			<div className={styles.currentlot}>
				<div className={styles.header} />
			</div>
		);

	if (isWithdrawn)
		return (
			<div className={styles.currentlot}>
				<div className={styles.header}>
					{config?.currentLot} {lotNumber} -- {config?.lot}
					{config?.withdrawn}
				</div>
				<div className="images__main__image">
					{config?.lot}
					{config?.withdrawn}
				</div>
			</div>
		);

	return (
		<div className={styles.currentlot}>
			{highEst && (
				<div className={styles.header}>
					{config?.currentLot} {lotNumber} | {config?.estimate}{' '}
					{getFormattedCurrency(lowEst)} - {getFormattedCurrency(highEst)}
				</div>
			)}

			<Images />
			<Details />

			<div className={styles.discover}>
				<div className={styles.text}>
					<div className="auctionhouse">
						<FontAwesomeIcon className={styles.icon} icon={faMapMarkerAlt} />{' '}
						{auctionHouseName}
					</div>
					<div className={styles.auctiontitle}>
						<FontAwesomeIcon className={styles.icon} icon={faGavel} />{' '}
						{saleNumOpt ? `${saleNumOpt} - ` : null}
						{auctionTitle}
					</div>
				</div>
			</div>
		</div>
	);
};
