import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import requestUpdateBidder from 'api/requests/admin/Req-UpdateBidder';
import requestUpdateClerk from 'api/requests/admin/Req-UpdateClerk';
import requestUpdateAdmin from 'api/requests/admin/Req-UpdateAdmin';
import { getFormSchema, getValidationSchema } from './utils';
import 'styles/adminpages/users/addedituser.scss';
import DynamicForm from 'components/common/dynamicform';

const AddUser = props => {
	const [formData, setFormData] = useState(null);
	const [validationSchema, setValidationSchema] = useState(null);
	const [userType, setUserType] = useState('bidder');
	const [infoMessage, setInfoMessage] = useState('');
	const [existingUser, setExistingUser] = useState(null);

	useEffect(() => {
		const existingUser = props?.users?.find(
			user => user.userID === props?.editingUser
		);

		if (existingUser) {
			setExistingUser(existingUser);
			setUserType(existingUser?.userType);
		}

		setFormData(getFormSchema(existingUser, true, props?.config));
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		onUserTypeChange();
		// eslint-disable-next-line
	}, [userType]);

	const onUserTypeChange = () => {
		const showTrustLevel = userType === 'bidder';
		const existingUser = props?.users?.find(
			user => user.userID === props?.editingUser
		);
		const formData = getFormSchema(existingUser, showTrustLevel, props?.config);
		const validationSchema = getValidationSchema(existingUser, showTrustLevel);

		setFormData(formData);
		setValidationSchema(validationSchema);
	};

	const onSubmit = (data, isSubmitting) => {
		let updatePermOpt = null;
		if (data.userType !== existingUser?.userType) updatePermOpt = data.userType;

		data['userIDOpt'] = existingUser?.userID || null;
		data['locationOpt'] = null;
		data['allowEmail'] = true;
		data['trustLevel'] = data.trustLevel ? Number(data.trustLevel) : 0;
		data['updatePermOpt'] = updatePermOpt;
		if (!data.passwordOpt) data['passwordOpt'] = null;

		isSubmitting();

		switch (userType) {
			case 'bidder':
				sendData(requestUpdateBidder, data);
				return;
			case 'clerk':
				sendData(requestUpdateClerk, data);
				return;
			case 'admin':
				sendData(requestUpdateAdmin, data);
				return;
			default:
		}
	};

	const sendData = (endPoint, data) => {
		endPoint(data)
			.then(response => {
				if (response.result > 100) {
					showInfoMessage(`${props?.config?.error} - ${response.detail}`);
					return;
				}
				const action = props?.editingUser
					? props?.config?.edited
					: props?.config?.created;
				showInfoMessage(`${props?.config?.success}: ${action} ${userType} `);
				returnToList();
			})
			.catch(error => {
				showInfoMessage(`${error}`);
				return;
			});
	};

	const showInfoMessage = msg => {
		setInfoMessage(msg);
		setTimeout(() => setInfoMessage(''), 2000);
	};

	const returnToList = () =>
		setTimeout(() => window?.API?.pushHistory('/adminpages/users'), 2100);

	if (!props.users?.length) {
		return <Navigate to="/adminpages/users" />;
	}

	return (
		<div className="adminpages__adduser background-color">
			<div className="adminpages__adduser__row row-2">
				{infoMessage && (
					<div className="adminpages__adduser__info">
						<div className="adminpages__adduser__info__message bg-secondary-color-light border-primary-color-dark">
							{infoMessage}
						</div>
					</div>
				)}

				<div className="adminpages__adduser__header">
					{props?.editingUser && (
						<span className="large-20-font-size">
							{props?.config?.editUser}
						</span>
					)}
					{!props?.editingUser && (
						<span className="large-20-font-size">
							{props?.config?.createUser}
						</span>
					)}
					<Link to={`/adminpages/users`}>
						<button className="adminpages__adduser__back border-secondary-color-dark bg-admin-accent-2 regular-18-font-size">
							{props?.config?.back}
						</button>
					</Link>
				</div>

				{!props?.editingUser && (
					<div className="adminpages__adduser__controls">
						<button
							className={`adminpages__adduser__usertype border-secondary-color-dark ${
								userType === 'bidder' ? 'active' : ''
							}`}
							onClick={() => setUserType('bidder')}
						>
							{props?.config?.bidder}
						</button>

						<button
							className={`adminpages__adduser__usertype border-secondary-color-dark ${
								userType === 'clerk' ? 'active' : ''
							}`}
							onClick={() => setUserType('clerk')}
						>
							{props?.config?.clerk}
						</button>

						<button
							className={`adminpages__adduser__usertype border-secondary-color-dark ${
								userType === 'admin' ? 'active' : ''
							}`}
							onClick={() => setUserType('admin')}
						>
							{props?.config?.admin}
						</button>
					</div>
				)}

				<div className="adminpages__adduser__formcontainer">
					{formData && (
						<DynamicForm
							formData={formData}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		users: [...state.adminpages?.bidders, ...state.adminpages?.staff],
		editingUser: state.adminpages?.editingUser,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(AddUser);
