import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'styles/rootadmin/sidebar/sidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLandmark,
	faSignOut,
	faUser,
	faList,
	faFlask,
} from '@fortawesome/pro-light-svg-icons';
import { faGavel } from '@fortawesome/pro-solid-svg-icons';

class Sidebar extends Component {
	render() {
		// const currentAuctionHouse =
		// this.props.rootadmin.loginDetails?.auctionHouseIDOpt;

		return (
			<div className="rootadmin__sidebar bg-primary-color-dark primary-color-light">
				<div className="rootadmin__sidebar__title">
					<FontAwesomeIcon
						className="rootadmin__sidebar__title-icon"
						icon={faGavel}
					/>
				</div>
				<div className="rootadmin__sidebar__buttons">
					<div className="rootadmin__sidebar__buttons__1">
						{/* <Link to={`/rootadmin/${currentAuctionHouse ? "editauctionhouse" : "addauctionhouse"}`}>
                            <button className="rootadmin__sidebar__buttons__1__button sidebar__addauctionhouse bg-primary-color-dark primary-color-light">
                                <FontAwesomeIcon className="rootadmin__sidebar__buttons__1__button-icon" icon={currentAuctionHouse ? faPencil : faPlus} />
                            </button>
                        </Link> */}

						<Link to="/rootadmin/auctionhouselist">
							<button className="rootadmin__sidebar__buttons__1__button bg-primary-color-dark primary-color-light">
								<FontAwesomeIcon
									className="rootadmin__sidebar__buttons__1__button-icon"
									icon={faList}
								/>
							</button>
						</Link>

						<Link to="/rootadmin/">
							<button className="rootadmin__sidebar__buttons__1__button bg-primary-color-dark primary-color-light">
								<FontAwesomeIcon
									className="rootadmin__sidebar__buttons__1__button-icon"
									icon={faLandmark}
								/>
							</button>
						</Link>

						<Link to="/rootadmin/testingarea">
							<button className="rootadmin__sidebar__buttons__1__button bg-primary-color-dark primary-color-light">
								<FontAwesomeIcon
									className="rootadmin__sidebar__buttons__1__button-icon"
									icon={faFlask}
								/>
							</button>
						</Link>
					</div>
					{/* <div className="rootadmin__sidebar__spacer"></div> */}
					<div className="rootadmin__sidebar__buttons__2">
						<button className="rootadmin__sidebar__buttons__2__button bg-primary-color-dark primary-color-light">
							<FontAwesomeIcon
								className="rootadmin__sidebar__buttons__2__button-icon"
								icon={faUser}
							/>
						</button>

						<button
							className="rootadmin__sidebar__buttons__2__button bg-primary-color-dark primary-color-light"
							onClick={() => window.API.onLogout()}
						>
							<FontAwesomeIcon
								className="rootadmin__sidebar__buttons__2__button-icon"
								icon={faSignOut}
							/>
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Sidebar;
