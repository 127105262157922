const StopButton = props => {
	const getClass = () => {
		switch (props?.publishingStatus) {
			case 'live':
				return 'stop';
			default:
				return '';
		}
	};

	return (
		<button className={getClass()} onClick={() => props?.stopPublishing()}>
			Stop Publishing
		</button>
	);
};

export default StopButton;
