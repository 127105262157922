import { useSelector } from 'react-redux';

export default function useLotID(lotID) {
	return useSelector(state => {
		const lotList =
			(state?.auction?.lotList?.length && state?.auction?.lotList) ||
			(state?.adminpages?.auctionDetail?.lotList?.length &&
				state?.adminpages?.auctionDetail?.lotList);

		if (!lotID || !lotList?.length) return {};

		const targetLot = lotList?.find(
			lot => lot.catalogueLot.lotID === lotID
		);

		if (!targetLot) return {};

		targetLot.isCurrentLot =
			targetLot?.catalogueLot?.lotID ===
			state?.auction?.currentLotDetailOpt?.currentLotID;

		return targetLot;
	});
}
