export const UPDATE_AUCTION_HOUSES = 'UPDATE_AUCTION_HOUSES';
export const UPDATE_AUCTIONS = 'UPDATE_AUCTIONS';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_CURRENT_AUCTIONHOUSE = 'UPDATE_CURRENT_AUCTIONHOUSE';
export const UPDATE_CURRENT_AUCTION = 'UPDATE_CURRENT_AUCTION';
export const SHOW_LOGIN_DETAIL_ERROR = 'SHOW_LOGIN_DETAIL_ERROR';
export const ON_LOGIN_SUCCESS = 'ON_LOGIN_SUCCESS';
export const SET_LOCALES = 'SET_LOCALES';

export default class LoginActions {
	static updateAuctionHouses(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTION_HOUSES,
				data,
			});
		};
	}

	static updateAuctions(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTIONS,
				data,
			});
		};
	}

	static updateUserName(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_USERNAME,
				data,
			});
		};
	}

	static updatePassword(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_PASSWORD,
				data,
			});
		};
	}

	static updateCurrentAuctionHouse(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_CURRENT_AUCTIONHOUSE,
				data,
			});
		};
	}

	static updateCurrentAuction(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_CURRENT_AUCTION,
				data,
			});
		};
	}

	static showLoginDetailError(data) {
		return function (dispatch) {
			return dispatch({
				type: SHOW_LOGIN_DETAIL_ERROR,
				data,
			});
		};
	}

	static onLoginSuccess(data) {
		return function (dispatch) {
			return dispatch({
				type: ON_LOGIN_SUCCESS,
				data,
			});
		};
	}

	static setLocales(data) {
		return function (dispatch) {
			return dispatch({
				type: SET_LOCALES,
				data,
			});
		};
	}
}
