import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/pro-light-svg-icons';

const Thumbnail = props => {
	if (!props.catalogueLot)
		return (
			<div className="mobileactionbar__nextlot finish">
				<FontAwesomeIcon className="icon " icon={faFlagCheckered} />
			</div>
		);
	return (
		<div className="mobileactionbar__nextlot">
			<div className="nextlot__lotnumber primary-color-light bg-primary-color-dark">
				{props?.catalogueLot?.lotNumber}
			</div>
			<img
				className="nextlot__image"
				src={props?.catalogueLot?.absImg[0]}
				alt="Next lot"
			/>
		</div>
	);
};

export default Thumbnail;
