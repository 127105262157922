import {
	UPDATE_SALEOUTCOME,
	UPDATE_STREAM_URLS,
	RESET,
	UPDATE_CUST_ASKINGPRICE_SUCCESS,
	ON_KEYDOWN_EVENT,
	BID_SELECTOR_REQ,
	SALE_CONTROL_REQ_INFLIGHT,
	UPDATE_BID_ACTIONS,
	SHOW_ASKING_PRICE_ERROR,
	SET_PORT_MESSAGE,
	SET_SALENOTE_INPUT,
	SET_SALENOTE_WARNING,
	WSS_LIVE,
} from 'store/actions/clerk';

const initialState = {
	saleOutcome: null,
	streamURLs: {
		videoPublishURL: null,
	},
	custAskingPriceSuccess: false,
	keyDownEvent: null,
	bidSelectorReq: {
		inFlight: false,
		requestSuccess: false,
	},
	saleControlReqInFlight: false,
	bidActions: null,
	showAskingPriceError: false,
	portMessage: {
		showMessage: false,
		success: false,
		clerkPort: null,
	},
	saleNoteInput: '',
	showSaleNoteWarning: false,
	wssLive: false,
};

export default function clerk(state = initialState, action) {
	let returnState = Object.assign({}, state);

	switch (action.type) {
		case RESET:
			returnState = initialState;
			return returnState;

		case UPDATE_SALEOUTCOME:
			returnState.saleOutcome = action.data;
			return returnState;

		case UPDATE_STREAM_URLS:
			returnState.streamURLs = {
				videoPublishURL: action.data.videoPublishURL,
			};
			return returnState;

		case UPDATE_CUST_ASKINGPRICE_SUCCESS:
			returnState.custAskingPriceSuccess = action.bool;
			return returnState;

		case ON_KEYDOWN_EVENT:
			returnState.keyDownEvent = action.event;
			return returnState;

		case BID_SELECTOR_REQ:
			returnState.bidSelectorReq = action.data;
			return returnState;

		case SALE_CONTROL_REQ_INFLIGHT:
			returnState.saleControlReqInFlight = action.bool;
			return returnState;

		case UPDATE_BID_ACTIONS:
			if (
				state.bidActions?.curCon === action.data?.curCon &&
				state.bidActions?.curHover === action.data?.curHover &&
				state.bidActions?.wsCount === action.data?.wsCount
			)
				return state;
			returnState.bidActions = action.data;
			return returnState;

		case SHOW_ASKING_PRICE_ERROR:
			returnState.showAskingPriceError = action.bool;
			return returnState;

		case SET_PORT_MESSAGE:
			returnState.portMessage = action.data;
			return returnState;

		case SET_SALENOTE_INPUT:
			returnState.saleNoteInput = action.data;
			return returnState;

		case SET_SALENOTE_WARNING:
			console.debug('SET_SALENOTE_WARNING', action.bool);
			returnState.showSaleNoteWarning = action.bool;
			return returnState;
		case WSS_LIVE:
			returnState.wssLive = action.bool;
			return returnState;

		default:
			return state;
	}
}
