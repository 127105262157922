import classNames from 'classnames';
import styles from './Toggle.module.scss';

const Toggle = ({ isActive, onClick }) => {
	return (
		<div
			className={classNames(styles.container, {
				[styles.isActive]: isActive,
			})}
			onClick={onClick}
		>
			<div
				className={classNames(styles.slider, {
					[styles.isActive]: isActive,
				})}
			></div>
		</div>
	);
};

export default Toggle;
