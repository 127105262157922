import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowLeft,
	faArrowLeftToLine,
	faArrowRight,
	faArrowRightToLine,
} from '@fortawesome/pro-light-svg-icons';

import styles from './Nav.module.scss';

export const Nav = ({ virtualList, searchFilterLots }) => {
	const { navBack, navForward, navStart, navEnd, prevDisabled, nextDisabled } =
		virtualList;

	return (
		<div className={styles.nav}>
			<button
				className={styles.pagebutton}
				onClick={() => navStart()}
				disabled={prevDisabled}
				title="Start"
			>
				<FontAwesomeIcon icon={faArrowLeftToLine} />
			</button>
			<button
				className={styles.pagebutton}
				onClick={() => navBack()}
				disabled={prevDisabled}
				title="Previous"
			>
				<FontAwesomeIcon icon={faArrowLeft} />
			</button>
			<button
				className={styles.pagebutton}
				onClick={() => navForward()}
				disabled={nextDisabled}
				title="Next"
			>
				<FontAwesomeIcon icon={faArrowRight} />
			</button>
			<button
				className={styles.pagebutton}
				onClick={() => navEnd()}
				disabled={nextDisabled}
				title="End"
			>
				<FontAwesomeIcon icon={faArrowRightToLine} />
			</button>
		</div>
	);
};
