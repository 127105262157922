import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_GETUSERS: 'Req-AuctionStats'
export default function requestGetAuctionStats() {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-AuctionStats',
		userTokenID: window.API?.user?.userToken,
	});
}
