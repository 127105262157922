import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_DEFAULTSPROTOCOL: 'Req-DefaultsProtocol'
export default function requestDefaultsProtocol(data) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-DefaultsProtocol',
		userTokenID: window.API?.user?.userToken,
		...data,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return data;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => {
			console.debug(error);
			return error;
		});
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.updateDefaultsProtocol(data));
	// store.dispatch(AdminPageActions.updateShowDefaultsProtocolSuccess(true));
	// console.debug('Req-DefaultsProtocol success', data);
};

const handleFailure = data => {
	console.debug('Req-DefaultsProtocol failure', data);
};
