import { useSelector } from 'react-redux';
import useCurrentLot from 'hooks/useCurrentLot';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import styles from './Details.module.scss';

import classNames from 'classnames';

const Details = () => {
	const { catalogueLot, lotSaleDetail } = useCurrentLot();
	const {
		lotDetails,
		estimate,
		reserve,
		bestBid,
		bidNumber,
		askingPrice,
		sold,
		passed,
	} = useConfig();
	const { lotState } = lotSaleDetail || {};
	const { getFormattedCurrency } = useCurrency();
	const offersWithBids = useSelector(state => state?.auction?.offersWithBids);
	const clerkAskingPrice = useSelector(
		state => state.auction?.clerkAskingPrice
	);
	if (!catalogueLot) return <div className={styles.details} />;
	const currentBid = offersWithBids[0];
	const { offerPrice, paddleNum } = currentBid || {};

	const { lowEst, highEst } = catalogueLot || {};
	const { reservePrice } = catalogueLot?.clerkInfo || {};
	const formattedLowEst = getFormattedCurrency(lowEst);
	const formattedHighEst = getFormattedCurrency(highEst);
	const formattedReserve = reservePrice
		? getFormattedCurrency(reservePrice)
		: '';
	const formattedCurrentBid = getFormattedCurrency(offerPrice);

	const getAskingPrice = () => {
		if (lotState === 200) return sold;
		if (lotState === 300) return passed;
		return getFormattedCurrency(clerkAskingPrice);
	};

	return (
		<div className={styles.details}>
			<div className={classNames(styles.row, styles.title)}>
				{lotDetails}
			</div>
			<div className={classNames(styles.row)}>
				<div>{estimate}</div>
				<div>
					{formattedLowEst} - {formattedHighEst}
				</div>
			</div>
			<div className={styles.row}>
				<div>{reserve}</div>
				<div className={styles.warningColor}>{formattedReserve}</div>
			</div>
			<div className={styles.row}>
				<div>{askingPrice}</div>
				<div>{getAskingPrice()}</div>
			</div>
			<div className={classNames(styles.row)}>
				<div>{bestBid}</div>
				<div
					className={classNames(
						styles.winningColor,
						styles.largeFont
					)}
				>
					{formattedCurrentBid}
				</div>
			</div>
			<div className={styles.row}>
				<div>{bidNumber}</div>
				<div>{paddleNum}</div>
			</div>
		</div>
	);
};

export default Details;
