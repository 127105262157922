import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import useBidsFeed from 'hooks/useBidsFeed.js';
import useConfig from 'hooks/useConfig.js';
import useCurrency from 'hooks/useCurrency.js';
import Bid from './bid.jsx';
import Message from './message';
import 'styles/bidderinterface/bidsfeed/bidsfeed.scss';
import styles from './bidsfeed.module.scss';
import { isValidOfferPrice } from 'utils/utils.js';

const BidsFeed = ({ classes, showFooter = true }) => {
	const slideTimeout = 500;
	const config = useConfig();
	const paddleNumOpt = useSelector(state => state.auction?.paddleNumOpt);
	const showPaddleLimit = useSelector(
		state => state.showFlags?.showPaddleLimit
	);
	const remainingSpendOpt = useSelector(
		state => state.auction?.remainingSpendOpt
	);
	const [animateTopBid, setAnimateTopBid] = useState(false);
	const { displayList, animateSlide } = useBidsFeed();
	const { getFormattedCurrency } = useCurrency();

	useEffect(() => {
		setAnimateTopBid(true);
		setTimeout(() => setAnimateTopBid(false), slideTimeout);
	}, [displayList?.length]);

	const renderItem = (data, index) => {
		const itemProps = {
			config,
			data,
			index,
			animate: index === 0 && animateTopBid,
		};
		if (data.offerID) {
			return <Bid {...itemProps} key={index} />;
		}
		return <Message {...itemProps} key={index} />;
	};

	const getNoPaddleMsg = () => {
		const isViewer = window?.API?.user?.userPerm === 'viewer';
		return isViewer ? config?.loginToBid : config?.paddlePendingApproval;
	};

	const getPaddleNumMsg = () => {
		return paddleNumOpt
			? `${config?.paddle}: ${paddleNumOpt}`
			: getNoPaddleMsg();
	};

	const getPaddleLimitMsg = () => {
		return remainingSpendOpt
			? `${config?.limit}: ${getFormattedCurrency(remainingSpendOpt)}`
			: '';
	};

	const getBidUpdate = () => {
		const bids = displayList.filter(bid => bid?.offerID);
		const latestBid = bids?.length && bids[0];
		const latestBidValue = latestBid?.offerPrice;
		return isValidOfferPrice(latestBidValue)
			? `${config?.currentBid} ${getFormattedCurrency(latestBidValue)}`
			: config?.noBidsYet;
	};

	const paddleNumMsg = getPaddleNumMsg();
	const paddleLimitMsg = getPaddleLimitMsg();

	return (
		<div className={classNames(styles.bidsfeed, classes)}>
			<div className={styles.header}>{getBidUpdate()}</div>
			<div
				className={classNames(styles.feed, {
					[styles.animateSlide]: animateSlide,
					[styles.showFooter]: showFooter,
				})}
			>
				{displayList &&
					displayList.map((data, index) => renderItem(data, index))}
			</div>
			{showFooter && (
				<div className={styles.footer}>
					<div className={styles.user}>
						<i className="fa-solid fa-user"></i>
						<div className={styles.usertitle}>
							{window?.API?.user?.displayAs}
						</div>
					</div>
					<div className={styles.paddle}>
						<div>{paddleNumMsg}</div>
						{showPaddleLimit && <div>{paddleLimitMsg}</div>}
					</div>
				</div>
			)}
		</div>
	);
};

export default BidsFeed;
