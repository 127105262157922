import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import styles from './AuctionUtilities.module.scss';

export function AuctionUtilities() {
	const { showAutobids, showRegistrations } = useSelector(
		state => state.showFlags
	);

	return (
		<div className={styles.container}>
			Auction utilities
			<FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
			<div className={styles.dropdown}>
				{showAutobids ? (
					<Link className={styles.item} to="/adminpages/autobids">
						Autobids
					</Link>
				) : null}

				{showRegistrations ? (
					<Link className={styles.item} to="/adminpages/registrations">
						Registrations
					</Link>
				) : null}

				<Link className={styles.item} to="/adminpages/passbids">
					Passbids
				</Link>

				<Link className={styles.item} to="/adminpages/results">
					Results
				</Link>
			</div>
		</div>
	);
}

// {showAutobids ? (
// 	<div className="addauction__autobids__control">
// 		<Link to="/adminpages/autobids">
// 			<button className="addauction__autobids__btn">
// 				Get autobids
// 			</button>
// 		</Link>
// 	</div>
// ) : null}
// {showRegistrations ? (
// 	<div className="addauction__registrations__control">
// 		<Link to="/adminpages/registrations">
// 			<button className="addauction__registrations__btn">
// 				Get registrations
// 			</button>
// 		</Link>
// 	</div>
// ) : null}
// <div className="addauction__passbids__control">
// 	<Link to="/adminpages/passbids">
// 		<button className="addauction__passbids__btn">
// 			{props?.config?.passedLotOffers}
// 		</button>
// 	</Link>
// </div>
// <Link to="/adminpages/results">
// 	<button
// 		className={`addauction__stats__btn bg-accent-color-1 primary-color-light`}
// 	>
// 		{props?.config?.auctionResults}
// 	</button>
// </Link>
