import useConfig from 'hooks/useConfig';
import 'styles/clerkconsole/bidhistory/message.scss';

const Message = ({ text }) => {
	const config = useConfig();
	const { clerkMessages, auctionMessages } = config || {};
	const messages = [...clerkMessages, ...auctionMessages];

	if (!messages.length) return <div />;

	const getMessage = () => {
		if (typeof text === 'string') return text;
		const message = messages?.find(msg => msg.messageID === text)?.text;

		if (!message) {
			console.debug(
				`[Error] - Could not find messageID ${text} in config.json`
			);
			return text;
		}
		return message;
	};

	return (
		<div className="message" data-testid="message">
			<div className="message__spacer bg-secondary-color-dark "></div>
			<div className="message__text">{getMessage()}</div>
			<div className="message__spacer bg-secondary-color-dark "></div>
		</div>
	);
};

export default Message;
