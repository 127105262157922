const DeviceButton = props => {
	return (
		<button
			className={
				props?.device?.deviceId === props?.activeId ? 'active' : ''
			}
			onClick={() => props?.callback(props?.device?.deviceId)}
		>
			{props?.device?.label}
		</button>
	);
};

const ResolutionButton = props => {
	return (
		<button
			className={
				props?.camResolution?.width === props?.resolution?.width
					? 'active'
					: ''
			}
			onClick={() => props?.callback(props?.resolution)}
		>
			{`${props?.resolution.width} x ${props?.resolution.height}`}
		</button>
	);
};

export { DeviceButton, ResolutionButton };
