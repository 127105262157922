import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faGavel as gavelSolid,
	faStar as starSolid,
	faBookmark as bookmarkSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faGavel as gavelLight,
	faStar as starLight,
	faBookmark as bookmarkLight,
	faSignOutAlt,
	faQuestion,
} from '@fortawesome/pro-light-svg-icons';

import BidderActions from 'store/actions/bidder';
import useConfig from 'hooks/useConfig';
import { capitaliseFirst } from 'utils/utils';
import styles from './Sidebar.module.scss';

export const Sidebar = () => {
	const dispatch = useDispatch();
	const { auction, wishlist, catalogue, help, logout } = useConfig();
	const layout = useSelector(state => state.bidder.layout);
	const onLogout = () => window.API.onLogout();
	const setLayout = num => dispatch(BidderActions.updateBidderLayout(num));
	const userTokenID = window?.API?.user?.userToken;
	const helpURL = `https://${window.location.hostname}/intro?userTokenID=${userTokenID}`;

	return (
		<section className={styles.sidebar}>
			<div className={styles.logo}>
				<img
					src={`https://${window.location.hostname}/styling/roundlogo.png`}
					alt=""
				/>
			</div>

			<hr className={styles.spacer} />

			<button
				className={classNames(styles.button, {
					[styles.buttonactive]: layout === 1,
				})}
				onClick={() => setLayout(1)}
			>
				<div className={styles.iconwrapper}>
					<div
						className={classNames(styles.circle, {
							[styles.active]: layout === 1,
						})}
					/>
					<FontAwesomeIcon
						className={styles.icon}
						icon={layout === 1 ? gavelSolid : gavelLight}
					/>
				</div>

				{capitaliseFirst(auction)}
			</button>
			<button
				className={classNames(styles.button, {
					[styles.buttonactive]: layout === 2,
				})}
				onClick={() => (layout === 2 ? setLayout(1) : setLayout(2))}
			>
				<div className={styles.iconwrapper}>
					<div
						className={classNames(styles.circle, {
							[styles.active]: layout === 2,
						})}
					/>
					<FontAwesomeIcon
						className={styles.icon}
						icon={layout === 2 ? starSolid : starLight}
					/>
				</div>
				{capitaliseFirst(wishlist)}
			</button>
			<button
				className={classNames(styles.button, {
					[styles.buttonactive]: layout === 3,
				})}
				onClick={() => (layout === 3 ? setLayout(1) : setLayout(3))}
			>
				<div className={styles.iconwrapper}>
					<div
						className={classNames(styles.circle, {
							[styles.active]: layout === 3,
						})}
					/>
					<FontAwesomeIcon
						className={styles.icon}
						icon={layout === 3 ? bookmarkSolid : bookmarkLight}
					/>
				</div>

				{capitaliseFirst(catalogue)}
			</button>

			<hr className={styles.spacer} />

			<a className={styles.button} href={helpURL}>
				<div className={styles.iconwrapper}>
					<div className={styles.circle} />
					<FontAwesomeIcon className={styles.icon} icon={faQuestion} />
				</div>

				{capitaliseFirst(help)}
			</a>
			<button className={styles.button} onClick={onLogout}>
				<div className={styles.iconwrapper}>
					<div className={styles.circle} />
					<FontAwesomeIcon className={styles.icon} icon={faSignOutAlt} />
				</div>
				{capitaliseFirst(logout)}
			</button>
		</section>
	);
};
