import { stringify } from 'uuid';

export default function b64toUUID(b64string) {
	const byteCharacters = atob(b64string);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}

	const byteArray = new Uint8Array(byteNumbers);
	return stringify(byteArray);
}
