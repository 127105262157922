import { useSelector } from 'react-redux';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import 'styles/clerkconsole/managementpanel/currentbid.scss';

const CurrentBid = () => {
	const config = useConfig();
	const offersWithBids = useSelector(state => state.auction?.offersWithBids);
	const { getFormattedCurrency } = useCurrency();

	const getBid = () => offersWithBids?.filter(bid => !bid.isVoid)[0];
	const currentBid = getBid();

	return (
		<div className={`currentbid bg-secondary-color-light`}>
			<div className="currentbid__description">
				<div className="currentbid__description__title large-20-font-size">
					{config?.currentBid}
				</div>
			</div>
			<div
				className={`currentbid__value extra-large-34-font-size focus-outline-accent-color-1 bg-primary-color-light`}
				data-testid="currentbid"
			>
				{getFormattedCurrency(currentBid?.offerPrice)}
			</div>
		</div>
	);
};

export default CurrentBid;
