import { useState, useEffect, useRef } from 'react';
import { DeviceButton } from './buttons';
import PublishButton from './publishbutton';
import StopButton from './stopButton';
import './controls.scss';

const Controls = props => {
	const [filterVideo, setFilterVideo] = useState(false);
	const [log, setLog] = useState([]);
	const logEndRef = useRef(null);

	const scrollToBottom = () => {
		logEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const getVideoDevices = () => {
		if (!filterVideo) return props?.videoDevices;

		return props?.videoDevices.filter(device => {
			const label = device.label.toLowerCase();
			return label.indexOf('streambid') !== -1;
		});
	};

	useEffect(() => {
		if (!props.infoMsg && !props.errorMsg) return;
		setLog([...log, props.infoMsg || props.errorMsg]);
		// eslint-disable-next-line
	}, [props.infoMsg, props.errorMsg]);

	useEffect(() => scrollToBottom(), [log]);

	return (
		<div className="controls-panel">
			<div className="controls-body">
				<div className="streamid">
					<div className="title">Stream ID</div>
					<input
						value={props?.streamId || ''}
						onChange={event =>
							props?.setStreamId(event.target.value)
						}
					/>
				</div>
				<div className="video-source">
					<div className="title">
						Video Source Picker
						<div className="filter">
							<input
								type="checkbox"
								value={filterVideo}
								onChange={() => setFilterVideo(!filterVideo)}
							/>
							<label>Filter by Streambid</label>
						</div>
					</div>
					<div className="sources">
						{getVideoDevices().map((device, index) => (
							<DeviceButton
								device={device}
								activeId={props?.activeCameraId}
								callback={props?.switchCamera}
								key={index}
							/>
						))}
					</div>
				</div>
				<div className="audio-source">
					<div className="title">Audio Source Picker</div>
					<div className="sources">
						{props?.audioDevices.map((device, index) => (
							<DeviceButton
								device={device}
								activeId={props?.activeMicId}
								callback={props?.switchMic}
								key={index}
							/>
						))}
					</div>
				</div>
				{/* <div className="resolution">
					<div className="title">Camera Resolution</div>
					{getResolutions().map((resolution, index) => (
						<ResolutionButton
							resolution={resolution}
							camResolution={props?.camResolution}
							callback={props?.changeVideoSize}
							key={index}
						/>
					))}
				</div> */}
				<div className="publish">
					<div className="title">Publish</div>
					<div className="start-stop">
						<PublishButton
							publish={props?.publish}
							publishingStatus={props?.publishingStatus}
						/>
						<StopButton
							stopPublishing={props?.stopPublishing}
							publishingStatus={props?.publishingStatus}
						/>
					</div>
				</div>
				<div className="title"></div>
				<div className="log">
					<div className="title">WebRTC Log</div>
					<div className="output">
						{log.map(log => (
							<div className="logitem">
								<code>{log}</code>
							</div>
						))}
						<div ref={logEndRef}></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Controls;
