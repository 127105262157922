import { useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './BidderLayout.module.scss';
import { Layout } from './Layout/Layout';
import { RenderWrapper } from './RenderWrapper/RenderWrapper';
import { ModalLayout } from './ModalLayout/ModalLayout';
import { CurrentLot } from '../currentlot/currentlot';
import VideoFeed from '../videofeed/videofeed';
import { BiddingPanel } from '../biddingpanel/biddingpanel';
import BidsFeed from '../bidsfeed/bidsfeed';
import { Catalogue } from 'components/common/VirtualList/Catalogue';
import { Timeline } from 'components/common/VirtualList/Timeline';
import { Wishlist } from 'components/common/VirtualList/Wishlist';
import LotModal from '../modal/lotModal/lotmodal';
import { WelcomeModal } from '../modal/welcomemodal/welcomemodal';
import BidderAudioController from '../bidderaudiocontroller/bidderaudiocontroller';
import bids from 'components/clerkconsole/bidhistory/bids';

export const BidderLayout = () => {
	const bidder = useSelector(state => state.bidder) || {};
	const { bidState } = useSelector(state => state.auction);
	const showTandCModal = useSelector(state => state.showFlags?.showTandCModal);

	const { layout, modalOpen } = bidder;
	const showWishlist = layout === 2;
	const showCatalogue = layout === 3;
	const showCurrentLot = layout === 1;
	const showBidHistory = layout === 1 || layout === 2;

	return (
		<Layout>
			{bidState ? <BidderAudioController /> : null}
			{modalOpen === 'lot' && (
				<ModalLayout>
					<LotModal />
				</ModalLayout>
			)}
			{showTandCModal && modalOpen === 'welcome' && (
				<ModalLayout>
					<WelcomeModal />
				</ModalLayout>
			)}

			<div
				className={classNames(styles.grid, {
					[styles.layout_1]: layout === 1,
					[styles.layout_2]: layout === 2,
					[styles.layout_3]: layout === 3,
				})}
			>
				<div className={styles.wishlist}>
					<RenderWrapper isVisible={showWishlist}>
						{layout === 2 && <Wishlist />}
					</RenderWrapper>
				</div>
				<div className={styles.catalogue}>
					<RenderWrapper isVisible={showCatalogue}>
						{layout === 3 && <Catalogue />}
					</RenderWrapper>
				</div>
				<div className={styles.currentlot}>
					<RenderWrapper isVisible={showCurrentLot}>
						<CurrentLot />
					</RenderWrapper>
				</div>
				<div className={styles.bidpanel}>
					<VideoFeed />
					<BiddingPanel />
				</div>
				<div className={styles.bidhistory}>
					<RenderWrapper isVisible={showBidHistory}>
						<BidsFeed />
					</RenderWrapper>
				</div>
				<div className={styles.timeline}>
					<Timeline layout={layout} />
				</div>
			</div>
		</Layout>
	);
};
