import { useState } from 'react';
import { useSelector } from 'react-redux';
import useLotIndex from 'hooks/useLotIndex';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import requestPostPassOffer from 'api/requests/bidder/Req-PostPassOffer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwipePanel from '../../swipePanel/swipePanel';
import './PassedOffer.scss';

import {
	faSpinner,
	faCheck,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const PassedOffer = () => {
	const config = useConfig();
	const lotIndex = useSelector(state => state.bidder?.catIndex);
	const { catalogueLot, lotSaleDetail } = useLotIndex(lotIndex);
	const { lotID, lowEst } = catalogueLot || {};
	const { offerMade } = lotSaleDetail || {};
	const swipePanel = useSelector(state => state.bidder?.swipePanel);
	const [offerValue, setOfferValue] = useState(offerMade || '');
	const [requestStatus, setRequestStatus] = useState(null);
	const { getFormattedCurrency, sanitiseCurrency } = useCurrency();

	const onActionClick = event => {
		event.stopPropagation();
		if (!lotID) return;

		setRequestStatus('sending');
		const offerPriceOpt = offerValue || null;

		requestPostPassOffer(lotID, offerPriceOpt)
			.then(response => {
				if (response.data?.result > 100) showError();
				else showSuccess();
			})
			.catch(() => showError());
	};

	const showSuccess = () => {
		setRequestStatus('accepted');
		resetRequestStatus();
	};

	const showError = () => {
		setRequestStatus('error');
		resetRequestStatus();
	};

	const resetRequestStatus = () =>
		setTimeout(() => setRequestStatus(null), 1500);

	const onInputChange = event => {
		event.preventDefault();

		let sanitisedValue = sanitiseCurrency(event.currentTarget.value);
		let value = Number(sanitisedValue);
		if (isNaN(Number(value))) return;

		setOfferValue(value);
	};

	const onKeyPress = event => {
		event.stopPropagation();
		if (event.charCode !== 13) return;
		event.preventDefault();
		onActionClick(event);
	};

	const buttonValue = () => {
		if (requestStatus === 'accepted')
			return <FontAwesomeIcon className="icon" icon={faCheck} />;
		if (requestStatus === 'error')
			return <FontAwesomeIcon className="icon" icon={faTimes} />;
		if (requestStatus === 'sending')
			return (
				<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
			);
		return offerMade ? config?.makeAnotherOffer : config?.makeAnOffer;
	};

	if (swipePanel === 'closed')
		return (
			<SwipePanel>
				<div className="mobile-passedoffer">
					<div className="mobile-passedoffer-closed">
						{offerMade ? config?.offerMade : config?.swipeToOffer}
					</div>
				</div>
			</SwipePanel>
		);

	const suggested = getFormattedCurrency(lowEst);

	return (
		<SwipePanel>
			<div className="mobile-passedoffer open">
				<div className="mobile-passedoffer-infomessage warning-color-mid">
					{offerMade
						? `${config?.youMadeOffer} ${offerMade}`
						: `${config?.suggestedOffer} ${suggested}`}
				</div>
				<input
					className={`mobile-passedoffer-input`}
					placeholder={config?.leaveOfferHere}
					value={offerValue ? getFormattedCurrency(offerValue) : ''}
					onClick={event => event.stopPropagation()}
					onChange={event => onInputChange(event)}
					inputMode="numeric"
					onKeyPress={event => onKeyPress(event)}
				/>

				<button
					className={`mobile-passedoffer-action`}
					onClick={event => onActionClick(event)}
				>
					{buttonValue()}
				</button>
			</div>
		</SwipePanel>
	);
};

export default PassedOffer;
