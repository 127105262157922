import { connect } from 'react-redux';
import { useEffect } from 'react';
import store from 'store/store';
import { useSwipeable } from 'react-swipeable';
import BidderActions from 'store/actions/bidder';
import './swipePanel.scss';

const SwipePanel = ({ children, ...props }) => {
	useEffect(
		() => () => store.dispatch(BidderActions.setSwipePanel('closed')),
		[]
	);

	const panelState = props?.swipePanel || 'closed';

	const onPanelClick = () => {
		if (panelState === 'closed')
			store.dispatch(BidderActions.setSwipePanel('open'));
		else store.dispatch(BidderActions.setSwipePanel('closed'));
	};

	const handlers = useSwipeable({
		onSwipedUp: () => {
			store.dispatch(BidderActions.setSwipePanel('open'));
		},
		onSwipedDown: () => {
			store.dispatch(BidderActions.setSwipePanel('closed'));
		},
		preventDefaultTouchmoveEvent: true,
		trackTouch: true,
	});

	return (
		<div
			className={`swipepanel ${panelState}`}
			{...handlers}
			onClick={() => onPanelClick()}
		>
			{children}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		swipePanel: state.bidder?.swipePanel,
	};
};

export default connect(mapStateToProps)(SwipePanel);
