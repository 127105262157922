import { useContext } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';

import { AccordionContext } from './Wrapper';
import styles from './AccordionTable.module.scss';

const Row = ({ item }) => {
	const { schema, nestedProp, nestedSchema, showNested, setShowNested } =
		useContext(AccordionContext);
	const columnProps = Object.keys(schema);
	const nestedColumnProps = Object.keys(nestedSchema);
	return (
		<>
			<tr
				className={classNames(styles.row, {
					[styles['rowopen']]: showNested === item.lotID,
				})}
				onClick={() =>
					setShowNested(showNested === item.lotID ? null : item.lotID)
				}
			>
				<td>
					{showNested === item.lotID ? (
						<FontAwesomeIcon icon={faChevronDown} />
					) : (
						<FontAwesomeIcon icon={faChevronUp} />
					)}
				</td>
				{columnProps.map((column, index) => (
					<td key={`${item[column]}-${index}`}>{item[column]}</td>
				))}
			</tr>
			<tr className={styles.nestedtr}>
				<td colSpan={columnProps.length + 1}>
					<div
						className={classNames(styles.nestedtableWrapper, {
							[styles.nestedtableWrapperVisible]: showNested === item.lotID,
						})}
					>
						<table className={styles.nestedtable}>
							<thead className={styles.nestedtablehead}>
								<tr>
									{nestedColumnProps.map(column => (
										<th key={column}>{nestedSchema[column]}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{item[nestedProp].map((item, index) => (
									<tr className={styles.nestedrow} key={`${item[0]}-${index}`}>
										{nestedColumnProps.map((column, index) => (
											<td key={`${item[column]}-${index}`}>{item[column]}</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</td>
			</tr>
		</>
	);
};

export default Row;
