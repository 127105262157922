import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_POSTPASSOFFER: 'Req-PostPassOffer'
export default function requestPostPassOffer(lotID, offerPriceOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-PostPassOffer',
		userTokenID: window.API?.user?.userToken,
		lotID,
		offerPriceOpt,
	});
}
