import { Formik, useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { getDateFormatString } from 'utils/utils';

import 'styles/common/dynamicform.scss';
import 'react-datepicker/dist/react-datepicker.css';

const DynamicForm = props => {
	const formData = props.formData;
	const hideControls = props.hideControls;

	const handleSubmit = (data, { setSubmitting }) => {
		if (props?.onSubmit) props.onSubmit(data, setSubmitting);
	};

	let initialValues = {};
	formData?.forEach(field => {
		initialValues[field.id] = field.value;
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={props.validationSchema}
			onSubmit={handleSubmit}
			innerRef={props?.innerRef}
			enableReinitialize
		>
			{formProps => {
				const { dirty, isSubmitting, handleSubmit, handleReset } =
					formProps;
				return (
					<form className="dynamicform" onSubmit={handleSubmit}>
						<div className="dynamicform__items">
							{formData?.map((field, index) => {
								switch (field.inputType) {
									case 'select':
										return (
											<SelectField
												{...formProps}
												{...field}
												key={index}
											/>
										);
									case 'date':
										return (
											<DateField
												name={field.id}
												{...formProps}
												{...field}
												key={index}
											/>
										);
									case 'textarea':
										return (
											<TextArea
												name={field.id}
												{...formProps}
												{...field}
												key={index}
											/>
										);
									case 'checkbox':
										return (
											<CheckBox
												name={field.id}
												{...formProps}
												{...field}
												key={index}
											/>
										);
									default:
										return (
											<InputField
												{...formProps}
												{...field}
												key={index}
											/>
										);
								}
							})}
						</div>

						{!hideControls && (
							<div className="dynamicform__controls">
								<button
									type="button"
									className="outline"
									onClick={handleReset}
									disabled={!dirty || isSubmitting}
								>
									Reset
								</button>
								<button type="submit" disabled={isSubmitting}>
									Submit
								</button>
							</div>
						)}

						{/* <DisplayFormikState {...props} /> */}
					</form>
				);
			}}
		</Formik>
	);
};

export default DynamicForm;

const InputField = ({ ...props }) => {
	return (
		<div className="dynamicform__item">
			<label htmlFor={props.id} style={{ display: 'block' }}>
				{props.label}
			</label>
			<input
				id={props.id}
				placeholder={props.placeholder}
				type={props.inputType}
				value={props.values[props.id]}
				onChange={props.handleChange}
				onBlur={props.handleBlur}
				disabled={props.disabled}
				data-testid="form-input"
				className={
					props.errors[props.id] && props.touched[props.id]
						? 'text-input error'
						: 'text-input'
				}
			/>
			{props.errors[props.id] && props.touched[props.id] && (
				<div className="input-feedback">{props.errors[props.id]}</div>
			)}
		</div>
	);
};

const TextArea = ({ ...props }) => {
	return (
		<div className="dynamicform__item">
			<label htmlFor={props.id} style={{ display: 'block' }}>
				{props.label}
			</label>
			<textarea
				id={props.id}
				placeholder={props.placeholder}
				type={props.inputType}
				value={props.values[props.id]}
				onChange={props.handleChange}
				onBlur={props.handleBlur}
				disabled={props.disabled}
				data-testid="form-textarea"
				className={
					props.errors[props.id] && props.touched[props.id]
						? 'text-area error'
						: 'text-area'
				}
			/>
			{props.errors[props.id] && props.touched[props.id] && (
				<div className="input-feedback">{props.errors[props.id]}</div>
			)}
		</div>
	);
};

const DateField = ({ ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [field] = useField(props);

	return (
		<div className="dynamicform__item">
			<label htmlFor={props.id} style={{ display: 'block' }}>
				{props.label}
			</label>
			<DatePicker
				{...field}
				{...props}
				value={Date}
				showTimeSelect
				dateFormat={getDateFormatString(props.ahLocale)}
				selected={(field.value && new Date(field.value)) || null}
				disabled={props.disabled}
				data-testid="form-datepicker"
				onChange={val => {
					setFieldValue(field.name, val);
				}}
			/>
			{props.errors[props.id] && props.touched[props.id] && (
				<div className="input-feedback">{props.errors[props.id]}</div>
			)}
		</div>
	);
};

const CheckBox = ({ ...props }) => {
	return (
		<div className="dynamicform__item checkbox">
			<label htmlFor={props.id} style={{ display: 'block' }}>
				{props.label}
			</label>
			<input
				id={props.id}
				placeholder={props.placeholder}
				type={props.inputType}
				value={props.values[props.id]}
				checked={props.values[props.id]}
				onChange={props.handleChange}
				onBlur={props.handleBlur}
				disabled={props.disabled}
				data-testid="form-checkbox"
				className={
					props.errors[props.id] && props.touched[props.id]
						? 'text-input error'
						: 'text-input'
				}
			/>
			{props.errors[props.id] && props.touched[props.id] && (
				<div className="input-feedback">{props.errors[props.id]}</div>
			)}
		</div>
	);
};

const SelectField = ({ ...props }) => {
	return (
		<div className="dynamicform__item">
			<label htmlFor={props.id} style={{ display: 'block' }}>
				{props.label}
			</label>
			<select
				id={props.id}
				type={props.inputType}
				value={props.values[props.id]}
				onChange={props.handleChange}
				onBlur={props.handleBlur}
				disabled={props.disabled}
				data-testid="form-select"
				className={
					props.errors[props.id] && props.touched[props.id]
						? 'text-input error'
						: 'text-input'
				}
			>
				<option value="" label={props.placeholder} />
				{props.options.map((option, index) => (
					<option value={option} key={index}>
						{option}
					</option>
				))}
			</select>
			{props.errors[props.id] && props.touched[props.id] && (
				<div className="input-feedback">{props.errors[props.id]}</div>
			)}
		</div>
	);
};

// const DisplayFormikState = props => (
// 	<div className="printout" style={{ margin: '1rem 0' }}>
// 		<h3 style={{ fontFamily: 'monospace' }} />
// 		<pre
// 			style={{
// 				background: '#f6f8fa',
// 				fontSize: '.65rem',
// 				padding: '.5rem',
// 			}}
// 		>
// 			<strong>props</strong> = {JSON.stringify(props, null, 2)}
// 		</pre>
// 	</div>
// );
