import { useEffect, useState, createContext } from 'react';
import { AccordionTable } from './AccordionTable';
import { Controls } from './Controls';
import styles from './AccordionTable.module.scss';

export const AccordionContext = createContext();

export function Wrapper({
	data,
	schema,
	nestedProp,
	nestedSchema,
	defaultSortProp,
}) {
	const [list, setList] = useState([]);
	const [resultsPerPage, setResultsPerPage] = useState(100);
	const [currentPage, setCurrentPage] = useState(0);
	const [firstRowIndex, setFirstRowIndex] = useState(0);
	const [lastRowIndex, setLastRowIndex] = useState(10);
	const [totalFiltered, setTotalFiltered] = useState(null);
	const [filterInput, setFilterInput] = useState('');
	const [showNested, setShowNested] = useState(null);
	const [sort, setSort] = useState({
		prop: defaultSortProp,
		direction: 'ascending',
	});

	useEffect(() => {
		prepareList();
		// eslint-disable-next-line
	}, [data, lastRowIndex, sort, resultsPerPage, currentPage]);

	useEffect(() => {
		setCurrentPage(0);
		prepareList();
		// eslint-disable-next-line
	}, [filterInput]);

	const onPerPageChange = event => {
		setResultsPerPage(Number(event.target.value));
		setCurrentPage(0);
	};

	const onPaginationChange = bool => {
		if (bool) {
			let lastPage = (currentPage + 1) * resultsPerPage >= data?.length;
			if (lastPage) return;
			setCurrentPage(currentPage + 1);
			return;
		}
		if (currentPage < 1) return;
		setCurrentPage(currentPage - 1);
	};

	const onFilterInputChange = event => setFilterInput(event.target.value);

	const onSortColumn = prop => {
		if (!sort || sort?.prop !== prop) {
			setSort({ prop, direction: 'descending' });
			return;
		}

		if (sort?.prop === prop) {
			if (sort.direction === 'descending') {
				setSort({ prop, direction: 'ascending' });
				return;
			}
			setSort({ prop, direction: 'descending' });
			return;
		}
	};

	const prepareList = () => {
		if (!data?.length) return [];
		let newList = [...data];

		newList = applySearchFilter(newList);
		newList = applySort(newList);
		newList = applyPagination(newList);

		setList(newList);
	};

	const applySearchFilter = newList => {
		if (!filterInput) {
			setTotalFiltered(data?.length);
			return newList;
		}

		const target = filterInput.toLocaleLowerCase();
		const filtered = [...newList].filter(item =>
			Object.keys(item).some(
				key =>
					typeof item[key] === 'string' &&
					item[key].toLowerCase().includes(target)
			)
		);
		setTotalFiltered(filtered.length);
		return filtered;
	};

	const applySort = newList => {
		if (!sort) return newList;

		return [...newList].sort((a, b) => {
			let aProp;
			let bProp;

			// if (sort.prop === 'spendLimit' || sort.prop === 'paddleNum') {
			if (!isNaN(Number(a[sort?.prop]))) {
				aProp = Number(a[sort?.prop]);
				bProp = Number(b[sort?.prop]);
			} else {
				aProp = a[sort?.prop]
					? a[sort?.prop].toString().toLocaleLowerCase()
					: 'z';
				bProp = b[sort?.prop]
					? b[sort?.prop].toString().toLocaleLowerCase()
					: 'z';
			}

			if (sort.direction === 'ascending') {
				if (aProp > bProp) return -1;
				if (aProp < bProp) return 1;
			} else {
				if (aProp < bProp) return -1;
				if (aProp > bProp) return 1;
			}
			return 0;
		});
	};

	const applyPagination = newList => {
		let firstRowIndex = currentPage * resultsPerPage;
		let lastRowIndex = firstRowIndex + resultsPerPage;
		let total = data?.length;

		if (lastRowIndex > total) lastRowIndex = total;

		setFirstRowIndex(firstRowIndex);
		setLastRowIndex(lastRowIndex);

		return [...newList].filter((item, index) => {
			return index >= firstRowIndex && index < lastRowIndex;
		});
	};

	const total = totalFiltered ? totalFiltered : data?.length;

	return (
		<AccordionContext.Provider
			value={{
				list,
				onSortColumn,
				schema,
				nestedProp,
				nestedSchema,
				onPaginationChange,
				onPerPageChange,
				onFilterInputChange,
				firstRowIndex,
				lastRowIndex,
				total,
				showNested,
				setShowNested,
			}}
		>
			<div className={styles.outer}>
				<Controls />
				<AccordionTable
					list={list}
					onSortColumn={onSortColumn}
					schema={schema}
				/>
			</div>
		</AccordionContext.Provider>
	);
}
