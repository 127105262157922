import classNames from 'classnames';

import requestSetCurrentItem from 'api/requests/clerk/Req-SetCurItem';
import LazyImage from 'components/common/lazyimage';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import useLotID from 'hooks/useLotID';
import styles from './lot.module.scss';

const Lot = ({ lotID, isFocus }) => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const {
		catalogueLot,
		lotSaleDetail,
		clerkDataOpt,
		interaction,
		isCurrentLot,
	} = useLotID(lotID);
	const { absImg, lotIndex, lotNumber, title, artist, lowEst, isWithdrawn } =
		catalogueLot || {};
	const { lotState, saleResultOpt } = lotSaleDetail || {};
	const { winningPlatformIcon, salePrice } = saleResultOpt || {};
	const { autoBidCount, maxAutoBidOpt, winningBidder } = clerkDataOpt || {};
	const { likeCount, interactionCount } = interaction || {};

	const onActionClick = () => requestSetCurrentItem(lotIndex);

	const getPlatformName = code => {
		let paddle = winningBidder ? `(${winningBidder})` : '';
		switch (code) {
			case 0:
				return `${config?.platformName} ${paddle}`;
			case 10:
				return `${config?.floor} ${paddle}`;
			case 50:
				return `${config?.online} ${paddle}`;
			case 60:
				return `${config?.phone} ${paddle}`;
			default:
		}
	};

	const getEstimate = () => {
		let text = ``;
		let value = ``;

		switch (lotState) {
			case 200:
				text = `${config?.sold}: ${getPlatformName(
					winningPlatformIcon
				)}`;
				value = `${getFormattedCurrency(salePrice)}`;
				break;

			case 300:
				text = config?.passed;
				value = `N/A`;
				break;

			default:
				text = `${config?.estimate}`;
				value = `${getFormattedCurrency(lowEst)}`;
				break;
		}

		return (
			<div className={styles.estimate}>
				<div
					className={classNames({
						[styles.soldColor]: lotState === 200,
						[styles.passedColor]: lotState === 300,
					})}
				>
					{text}
				</div>
				<div className={styles.estimateValue}>{value}</div>
			</div>
		);
	};

	const getAutoBids = () => {
		if (!autoBidCount || autoBidCount < 2) return '';

		return `[AB(${autoBidCount}) -- ${
			config?.startingAt
		}: ${getFormattedCurrency(maxAutoBidOpt)}]`;
	};

	const renderLotNo = () => {
		let autobids = getAutoBids();
		let interactions = interaction
			? `${config?.likes}: ${likeCount}, ${config?.interactions}: ${interactionCount}`
			: '';

		return `${config?.lot} ${lotNumber} ${autobids} ${interactions}`;
	};

	if (isWithdrawn)
		return (
			<div
				className={classNames(styles.lot, styles.withdrawn)}
			>{`${config?.lot} ${lotNumber} ${config?.withdrawn}`}</div>
		);

	return (
		<div
			className={classNames(styles.lot, {
				[styles.isActive]: isCurrentLot,
				[styles.hoverHighlight]: !isCurrentLot,
				[styles.soldColor]: lotState === 200,
				[styles.passedColor]: lotState === 300,
			})}
		>
			<LazyImage
				url={absImg && absImg[0]}
				className={styles.imageWrapper}
			/>

			<div className={styles.description}>
				<div className={styles.lotNo}>{renderLotNo()}</div>
				<div className={styles.title}>
					{artist} {title}
				</div>
			</div>

			{isCurrentLot && (
				<div className={styles.info}>{config?.currentItem}</div>
			)}

			<button className={styles.setItem} onClick={onActionClick}>
				{config?.setAsCurrentItem}
			</button>

			{getEstimate()}
		</div>
	);
};

export default Lot;
