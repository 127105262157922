import { useSelector } from 'react-redux';
import styles from './AutobidToggle.module.scss';
import classNames from 'classnames';

const AutobidToggle = ({ showAutobid, onAutobidToggle }) => {
	const config = useSelector(state => state.config);
	return (
		<div className={styles.container}>
			<div className={styles.title}>
				{config?.autobid}
				<span className={styles.subtitle}>
					({config?.autobidDescription})
				</span>{' '}
			</div>
			<button
				className={classNames(styles.track, {
					[styles.open]: showAutobid,
				})}
				onClick={() => onAutobidToggle()}
			>
				<div
					className={classNames(styles.slider, {
						[styles.open]: showAutobid,
					})}
				/>
			</button>
		</div>
	);
};

export default AutobidToggle;
