import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';

const NextArrow = props => {
	const { onClick } = props;
	const style =
		props.currentSlide === props.slideCount - 1 ? { opacity: '0' } : null;
	return (
		<div
			className="biddermobile__slickarrow next"
			onClick={onClick}
			style={style}
		>
			<FontAwesomeIcon
				className="icon secondary-color-light"
				icon={faArrowRight}
			/>
		</div>
	);
};

const PrevArrow = props => {
	const { onClick } = props;
	const style = props.currentSlide === 0 ? { opacity: '0' } : null;
	return (
		<div
			className="biddermobile__slickarrow prev"
			onClick={onClick}
			style={style}
		>
			<FontAwesomeIcon
				className="icon secondary-color-light"
				icon={faArrowLeft}
			/>
		</div>
	);
};

export { NextArrow, PrevArrow };
