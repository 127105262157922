import sendRequest from '..';
import { getCommonEndpoint } from '../utils';

//REQ_UPDATEBIDDERDEFAULTS: 'Req-UpdateBidderDefaults'
export default function requestUpdateBidderDefaults(
	localeCode,
	secondaryCurrencyCode
) {
	return sendRequest(getCommonEndpoint(), {
		Action: 'Req-UpdateBidderDefaults',
		userTokenID: window.API?.user?.userToken,
		localeCode,
		secondaryCurrencyCode,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				// handleFailure(data);
				console.log(data);
				return;
			}
			// handleSuccess(data);
			console.log(data);
			return data;
		})
		.catch(error => console.debug(error));
}
