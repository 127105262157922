import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHomeAlt,
	faArrowLeft,
	faArrowLeftToLine,
	faArrowRight,
	faArrowRightToLine,
} from '@fortawesome/pro-light-svg-icons';

import useConfig from 'hooks/useConfig';
import { capitaliseFirst } from 'utils/utils';
import styles from './Filters.module.scss';

export const Filters = ({ virtualList, searchFilterLots }) => {
	const config = useConfig();
	const {
		navBack,
		navForward,
		navStart,
		navEnd,
		prevDisabled,
		nextDisabled,
		showHome,
		goToCurrentLot,
	} = virtualList;
	const { filterValue, setFilterValue, sortKey, setSortKey } = searchFilterLots;

	return (
		<div className={styles.filters}>
			<div className={styles.buttons}>
				<button
					className={classNames(styles.button, {
						[styles.active]: filterValue === 'all',
					})}
					onClick={() => setFilterValue('all')}
				>
					All
				</button>
				<button
					className={classNames(styles.button, {
						[styles.active]: filterValue === 'current',
					})}
					onClick={() => setFilterValue('current')}
				>
					Current lots
				</button>
				<button
					className={classNames(styles.button, {
						[styles.active]: filterValue === 'sold',
					})}
					onClick={() => setFilterValue('sold')}
				>
					Sold lots
				</button>
				<button
					className={classNames(styles.button, {
						[styles.active]: filterValue === 'passed',
					})}
					onClick={() => setFilterValue('passed')}
				>
					Passed lots
				</button>
			</div>
			<div className={styles.pagination}>
				{showHome && (
					<button
						className={styles.pagebutton}
						onClick={() => goToCurrentLot()}
						title="Current lot"
					>
						<FontAwesomeIcon icon={faHomeAlt} />
					</button>
				)}
				<select
					className={styles.dropdown}
					value={sortKey}
					onChange={event => setSortKey(event.target.value)}
				>
					<option value={'lotNumber'}>
						{capitaliseFirst(config?.lotNumber)}
					</option>
					<option value={'lowEst'}>
						{capitaliseFirst(config?.lowEstimate)}
					</option>
					<option value={'highEst'}>
						{capitaliseFirst(config?.highEstimate)}
					</option>
					<option value={'title'}>{capitaliseFirst(config?.title)}</option>
				</select>
				<button
					className={styles.pagebutton}
					onClick={() => navStart()}
					disabled={prevDisabled}
					title="Start"
				>
					<FontAwesomeIcon icon={faArrowLeftToLine} />
				</button>
				<button
					className={styles.pagebutton}
					onClick={() => navBack()}
					disabled={prevDisabled}
					title="Previous"
				>
					<FontAwesomeIcon icon={faArrowLeft} />
				</button>
				<button
					className={styles.pagebutton}
					onClick={() => navForward()}
					disabled={nextDisabled}
					title="Next"
				>
					<FontAwesomeIcon icon={faArrowRight} />
				</button>
				<button
					className={styles.pagebutton}
					onClick={() => navEnd()}
					disabled={nextDisabled}
					title="End"
				>
					<FontAwesomeIcon icon={faArrowRightToLine} />
				</button>
			</div>
		</div>
	);
};
