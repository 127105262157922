import classNames from 'classnames';

import styles from './Lots.module.scss';
import LotCard from 'components/common/lotcard/lotcard';

export const Lots = ({ virtualList }) => {
	const { currentFrame, prevFrame, nextFrame, transitionNext, transitionPrev } =
		virtualList;
	return (
		<div className={styles.lots}>
			<div
				className={classNames(styles.track, {
					[styles.transitionNext]: transitionNext,
					[styles.transitionPrev]: transitionPrev,
				})}
			>
				{transitionPrev && (
					<div className={styles.frame}>
						{prevFrame.map(lot => (
							<div
								key={lot.catalogueLot.lotID}
								className={classNames(styles.item)}
							>
								<LotCard lotID={lot.catalogueLot.lotID} />
							</div>
						))}
					</div>
				)}
				<div className={styles.frame}>
					{currentFrame.map(lot => (
						<div key={lot.catalogueLot.lotID} className={styles.item}>
							<LotCard lotID={lot.catalogueLot.lotID} />
						</div>
					))}
				</div>
				{transitionNext && (
					<div className={styles.frame}>
						{nextFrame.map(lot => (
							<div
								key={lot.catalogueLot.lotID}
								className={classNames(styles.item)}
							>
								<LotCard lotID={lot.catalogueLot.lotID} />
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
