import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './nav.scss';

const Nav = props => {
	const onCloseClick = () => {
		store.dispatch(BidderActions.updateBidderLayout(0, null));
	};

	return (
		<div className="catalogue__nav">
			<button
				className={`back regular-18-font-size ${
					props.showPageForIndex ? '' : 'hidden'
				}`}
				onClick={() => props.setShowPageForIndex(null)}
			>
				{props?.config?.back}
			</button>
			<button className="close" onClick={onCloseClick}>
				<FontAwesomeIcon
					className="icon large-24-font-size"
					icon={faTimes}
				/>
			</button>
		</div>
	);
};

export default Nav;
