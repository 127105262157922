import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BreadcrumbButton = props => {
	const onClick = () => {
		if (isDisabled()) return;
		let original = Array(5).fill(0);
		original.fill(1, 0, props.buttonIndex);
		original[props.buttonIndex] = 2;
		props.setBreadcrumbState(original);
		store.dispatch(
			AdminPageActions.updateAddAuctionLocation(props.buttonIndex)
		);
	};

	const getStateId = () => {
		return props.arrangement[props.buttonIndex];
	};

	const isDisabled = () => {
		return (
			getStateId() === -1 || (getStateId() === 0 && props.addingAuction)
		);
	};

	const isHighlighted = () => {
		return getStateId() === 1;
	};

	const hasCompleted = () => {
		return getStateId() === 2;
	};

	const getClass = () => {
		return `addauction__breadcrumbs__item 
        ${isDisabled() ? 'disabled' : ''} 
        ${isHighlighted() ? 'selected' : ''} 
        ${hasCompleted() ? 'completed' : ''}`;
	};

	return (
		<button className={getClass()} onClick={() => onClick()}>
			<div className="addauction__breadcrumbs__item-icon-container border-accent-color-1">
				<FontAwesomeIcon
					className="addauction__breadcrumbs__item-icon accent-color-1"
					icon={props.icon}
				/>
			</div>
			<span>{props.text}</span>
		</button>
	);
};

export default BreadcrumbButton;
