let timeout = null;
let timestamp = Date.now();

const hoverController = isHovering => {
	if (!isHovering) {
		reset();
		return;
	}

	const newTimestamp = Date.now();
	if (newTimestamp - timestamp < 5000) return;

	clearTimeout(timeout);
	sendUserActionWSS(true);
	timeout = setTimeout(() => reset(), 5000);
};

const reset = () => {
	clearTimeout(timeout);
	sendUserActionWSS(false);
};

const sendUserActionWSS = bidHover => {
	const data = JSON.stringify({
		Action: 'UserAction',
		bidHover,
	});
	window?.API?.wssConn?.send(data);
};

export { hoverController };
