import { useSelector } from 'react-redux';

import styles from './message.module.scss';

const Message = props => {
	const config = useSelector(state => state?.config);
	const { clerkMessages, auctionMessages } = config || {};
	const messages = [...clerkMessages, ...auctionMessages];
	const classes = ' message';

	const getMessage = () => {
		if (typeof props.data.text === 'string') {
			return props.data.text;
		}

		const text = messages?.find(
			msg => msg.messageID === props.data.text
		)?.text;

		if (!text) {
			console.debug(
				`[Error] - Could not find messageID ${props?.data?.text} in config.json`
			);
			return props?.data?.text;
		}
		return text;
	};

	return (
		<div className={styles.container} data-testid="message">
			<div className={styles.spacer} />
			<div className={styles.text}>{getMessage()}</div>
			<div className={styles.spacer} />
		</div>
	);
};

export default Message;
