import { connect } from 'react-redux';
import ImageSlider from './imageSlider';

const CurrentItemImage = props => <ImageSlider {...props} />;

const mapStateToProps = state => {
	const currentLotID = state.auction?.currentLotDetailOpt?.currentLotID;
	const currentLot = state.auction?.lotList?.find(
		lot => lot?.catalogueLot?.lotID === currentLotID
	);

	return {
		lotID: currentLotID,
		absImg: currentLot?.catalogueLot?.absImg || [],
	};
};

export default connect(mapStateToProps)(CurrentItemImage);
