import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import requestDeleteAuction from 'api/requests/admin/Req-DeleteAuction';
import requestAuctions from 'api/requests/common/Req-Auctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArchive,
	faUndo,
	faTrash,
	faSpinner,
	faCheck,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const Archive = props => {
	const { auctionID, isArchived } = props;
	const config = useSelector(state => state?.config);
	const [resetTimeout, setResetTimeout] = useState(null);
	const unarchiveIcon = <FontAwesomeIcon className="icon" icon={faUndo} />;
	const deleteIcon = <FontAwesomeIcon className="icon" icon={faTrash} />;
	const archiveIcon = <FontAwesomeIcon className="icon" icon={faArchive} />;
	const loadingIcon = (
		<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
	);
	const successIcon = <FontAwesomeIcon className="icon" icon={faCheck} />;
	const errorIcon = <FontAwesomeIcon className="icon" icon={faTimes} />;

	const [unarchiveButton, setUnarchiveButton] = useState({
		icon: unarchiveIcon,
	});
	const [deleteButton, setDeleteButton] = useState({ icon: deleteIcon });
	const [archiveButton, setArchiveButton] = useState({ icon: archiveIcon });

	useEffect(
		() => () => {
			resetButtons();
			clearTimeout(resetTimeout);
		},
		// eslint-disable-next-line
		[auctionID]
	);

	const onArchiveClick = event => {
		event.stopPropagation();
		setArchiveButton({ icon: loadingIcon });
		requestDeleteAuction(auctionID, true).then(response => {
			if (response.result > 100) {
				setArchiveButton({ icon: errorIcon, classes: 'error' });
				if (response?.detail)
					window.API.setFixedNotify(response.detail);
			} else setArchiveButton({ icon: successIcon, classes: 'success' });
			onRequestComplete();
		});
	};

	const onUnarchiveClick = event => {
		event.stopPropagation();
		setUnarchiveButton({ icon: loadingIcon });
		requestDeleteAuction(auctionID, false).then(response => {
			if (response.result > 100) {
				setUnarchiveButton({ icon: errorIcon, classes: 'error' });
				window.API.setFixedNotify(response.detail);
			} else
				setUnarchiveButton({ icon: successIcon, classes: 'success' });
			onRequestComplete();
		});
	};

	const onDeleteClick = event => {
		event.stopPropagation();
		setDeleteButton({ icon: loadingIcon });
		requestDeleteAuction(auctionID, null).then(response => {
			if (response.result > 100) {
				setDeleteButton({ icon: errorIcon, classes: 'error' });
				window.API.setFixedNotify(response.detail);
			} else setDeleteButton({ icon: successIcon, classes: 'success' });
			onRequestComplete();
		});
	};

	const onRequestComplete = () => {
		requestAuctions();
		setResetTimeout(setTimeout(() => resetButtons(), 2000));
	};

	const resetButtons = () => {
		setArchiveButton({ icon: archiveIcon });
		setUnarchiveButton({ icon: unarchiveIcon });
		setDeleteButton({ icon: deleteIcon });
	};

	if (isArchived) {
		return (
			<div>
				<button
					className={`archive-button unarchive ${unarchiveButton.classes}`}
					title={config?.unarchive}
					onClick={event => onUnarchiveClick(event)}
				>
					{unarchiveButton.icon}
				</button>
				<button
					className={`archive-button delete ${deleteButton.classes}`}
					title={config?.delete}
					onClick={event => onDeleteClick(event)}
				>
					{deleteButton.icon}
				</button>
			</div>
		);
	}

	return (
		<button
			className={`archive-button archive ${archiveButton.classes}`}
			title={config?.archive}
			onClick={event => onArchiveClick(event)}
		>
			{archiveButton.icon}
		</button>
	);
};

export default Archive;
