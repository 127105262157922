import { useDispatch } from 'react-redux';

import BidderActions from 'store/actions/bidder';
import styles from './ModalLayout.module.scss';

export const ModalLayout = ({ children }) => {
	const dispatch = useDispatch();
	return (
		<div className={styles.wrapper}>
			<div
				onClick={() => dispatch(BidderActions.bidderCloseModal())}
				className={styles.shadow}
			/>
			<div className={styles.modal}>
				{children}
			</div>
		</div>
	);
};
