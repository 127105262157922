import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import requestPWChange from 'api/requests/admin/Req-PWChange';
import UserRow from './usersRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import 'styles/adminpages/users/users.scss';

const UsersTable = props => {
	const [users, setUsers] = useState([]);
	const [searchInput, setSearchInput] = useState('');
	const [showResetMessage, setShowResetMessage] = useState(false);

	useEffect(() => {
		prepareUsers();
		// eslint-disable-next-line
	}, [props?.users, props?.filterAuction, searchInput]);

	useEffect(() => {
		if (searchInput === '') return;
		props?.setFilterAuction('all');
		props?.setDateFilter('all');
		// eslint-disable-next-line
	}, [searchInput]);

	const prepareUsers = () => {
		if (!props.users?.length) return;
		let newUsers = [...props?.users];

		newUsers = applySearchFilter(newUsers);
		setUsers(newUsers);
	};

	const applySearchFilter = newUsers => {
		if (searchInput === '') return [...newUsers];
		return [...newUsers].filter(user => {
			const target = searchInput.toLowerCase();
			const searchProps = ['firstName', 'lastName', 'loginName', 'email'];
			return searchProps.some(
				prop => user[prop]?.toString().toLowerCase()?.indexOf(target) > -1
			);
		});
	};

	useEffect(() => {
		setSearchInput('');
		props?.onTabChange();
		// eslint-disable-next-line
	}, [props?.activeTab]);

	const onResetPasswordClick = user => {
		let userLoginOpt = user.loginName;
		let currentPWOpt = null;
		let newPWOpt = null;
		let ahSNOpt = null;
		let resetTokenOpt = null;

		requestPWChange(
			userLoginOpt,
			currentPWOpt,
			newPWOpt,
			ahSNOpt,
			resetTokenOpt
		)
			.then(data => {
				if (data.result > 100) {
					showResetFail(user);
					return;
				}
				showResetSuccess(user);
			})
			.catch(error => {
				showResetFail(user);
			});
	};

	const showResetSuccess = user => {
		setShowResetMessage(
			`Password reset email for ${user.firstName} ${user.lastName} sent successfully`
		);
		clearResetMessage();
	};

	const showResetFail = user => {
		setShowResetMessage(
			`Password reset email for ${user.firstName} ${user.secondName} failed`
		);
		clearResetMessage();
	};

	const clearResetMessage = () => {
		setTimeout(() => {
			setShowResetMessage(null);
		}, 3000);
	};

	const clearAllFilters = () => {
		props?.setFilterAuction('all');
		props?.setDateFilter('all');
		setSearchInput('');
	};

	const showClearAllButton =
		props?.filterAuction !== 'all' ||
		props?.dateFilter !== 'all' ||
		searchInput !== '';

	return (
		<div className="adminpages__users__row row-4">
			<div className="adminpages__users__controls">
				<div
					className={`adminpages__users__filter bg-secondary-color-dark ${
						searchInput ? 'active' : ''
					}`}
				>
					<label>
						{props?.config?.search}{' '}
						<FontAwesomeIcon
							className="adminpages__controls__icon"
							icon={faSearch}
						/>
					</label>
					<input
						value={searchInput}
						onChange={event => setSearchInput(event.target.value)}
						placeholder={props?.config?.searchUsersPlaceholder}
						data-testid="users-input"
					></input>
				</div>
				{showClearAllButton && (
					<button className="clear" onClick={() => clearAllFilters()}>
						<FontAwesomeIcon className="icon" icon={faTimes} />
						{props?.config?.clearFilters}
					</button>
				)}
			</div>
			<div className="adminpages__users__table__wrapper">
				{showResetMessage && (
					<div className="adminpages__users__resetmessage">
						<span>{showResetMessage}</span>
					</div>
				)}

				<table className="adminpages__users__table" cellSpacing="0">
					<thead>
						<tr>
							<th>{props?.config?.goID}</th>
							<th>{props?.config?.name}</th>
							<th>{props?.config?.email}</th>
							<th>{props?.config?.role}</th>
							{props.activeTab === 'clients' && (
								<th>{props?.config?.paddle}</th>
							)}
							<th>{props?.config?.controls}</th>
						</tr>
					</thead>
					<tbody>
						{users &&
							users.map((user, index) => (
								<UserRow
									{...user}
									key={index}
									onPaddleDetailsClick={props.onPaddleDetailsClick}
									onResetPasswordClick={onResetPasswordClick}
								/>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		config: state?.config,
	};
};

export default connect(mapStateToProps)(UsersTable);
