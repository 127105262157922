import { useDispatch, useSelector } from 'react-redux';
import { Interweave } from 'interweave';
import classNames from 'classnames';

import useConfig from 'hooks/useConfig';
import BidderActions from 'store/actions/bidder';
import requestAckTandC from 'api/requests/bidder/Req-AckTandC';
import styles from './welcomemodal.module.scss';

export const WelcomeModal = () => {
	const dispatch = useDispatch();
	const { decline, accept } = useConfig();
	const welcomeModal = useSelector(state => state.bidder?.welcomeModal);

	const onAccept = () => {
		// if (!props.bidder.auctionID) return;
		dispatch(BidderActions.bidderCloseModal());
		requestAckTandC(true);
	};

	const onDecline = () => {
		dispatch(BidderActions.bidderCloseModal());
	};

	return (
		<div className={styles.welcomemodal}>
			<div className={styles.main}>
				<Interweave content={welcomeModal} allowElements={true} />
			</div>

			<div className={styles.footer}>
				<button
					className={classNames(styles.button, styles.decline)}
					onClick={onDecline}
				>
					{decline}
				</button>
				<button
					className={classNames(styles.button, styles.accept)}
					onClick={onAccept}
				>
					{accept}
				</button>
			</div>
		</div>
	);
};
