import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_PWCHANGE: 'Req-PWChange'
export default function requestPWChange(
	userLoginOpt,
	currentPWOpt,
	newPWOpt,
	ahSNOpt,
	resetTokenOpt
) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-PWChange',
		userLoginOpt,
		currentPWOpt,
		newPWOpt,
		// ahSNOpt,
		resetTokenOpt,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => {
			console.debug(error);
			return error;
		});
}

const handleSuccess = data => {
	// if (window.location.pathname.indexOf('/adminpages') !== -1)
	// 	store.dispatch(AdminPageActions.userPWResetReturn(data));
	if (window.location.pathname.indexOf('/passwordReset') !== -1)
		window.API.pushHistory('/');
	console.debug('Req-PWChange success', data);
};

const handleFailure = data => {
	console.debug('Req-PWChange failure', data);
};
