import { Component } from 'react';
import 'styles/bidderinterface/biddingpanel/infoicon.scss';

class InfoIcon extends Component {
	constructor(props) {
		super(props);

		this.state = {
			animatePulse: '',
		};
	}

	componentWillUnmount() {
		if (this.pulseTimeout) clearTimeout(this.pulseTimeout);
		this.pulseTimeout = null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.pulseColor !== this.props.pulseColor) {
			if (this.pulseTimeout) clearTimeout(this.pulseTimeout);
			this.pulseTimeout = null;

			this.setState({ animatePulse: 'animate-pulse' });
			this.pulseTimeout = setTimeout(() => {
				this.setState({ animatePulse: '' });
			}, 4000);
		}
	}

	render() {
		return (
			<div className={`infoicon ${this.props.classes}`}>
				<div
					className={`infoicon__pulse ${this.props.pulseColor} ${this.state.animatePulse}`}
				></div>
				<div className={`infoicon__current`}>{this.props.icon}</div>
			</div>
		);
	}
}

export default InfoIcon;
