import styles from './Layout.module.scss';
import { Sidebar } from 'components/bidderinterface/Sidebar';

export const Layout = ({ children, setLayout }) => {
	return (
		<div className={styles.layout}>
			<div className={styles.sidebarwrapper}>
				<Sidebar />
			</div>
			{children}
		</div>
	);
};
