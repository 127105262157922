import { useSelector } from 'react-redux';
import { useState } from 'react';
import useAutobid from 'hooks/autobid/useAutobid';
import AutobidToggle from './AutobidToggle/AutobidToggle';
import PriceControl from './PriceControl/PriceControl';
import BidsFeed from './BidsFeed/BidsFeed';
import AutobidButton from './AutobidButton/AutobidButton';
import ViewerPanel from 'components/viewerinterface/viewerpanel';
import BidButton from 'components/bidderinterface/biddingpanel/bidbutton/bidbutton';
import 'styles/bidderinterface/biddermobile/mobilebidding/biddermobilebidpanel.scss';
import 'styles/bidderinterface/biddermobile/mobilebidding/biddermobilebidsfeed.scss';
import 'styles/bidderinterface/biddermobile/mobilebidding/biddermobilebidbutton.scss';
import 'styles/bidderinterface/biddermobile/mobilebidding/biddermobileautomode.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

const BiddingPanel = () => {
	const currentLotID = useSelector(
		state => state.auction?.currentLotDetailOpt?.currentLotID
	);
	const bidderProps = useSelector(state => {
		return { ...state.bidder, ...state.auction };
	});
	const useViewerInterface = bidderProps?.useViewerInterface;
	const [showAutobid, setShowAutobid] = useState(false);

	const onAutobidToggle = event => {
		setShowAutobid(!showAutobid);
	};

	const autobidProps = {
		...useAutobid(currentLotID),
		showAutobid,
		onAutobidToggle,
	};

	if (useViewerInterface) return <ViewerPanel bidder={bidderProps} />;

	if (!currentLotID) {
		return (
			<section className="biddermobile__mobilebidpanel open bg-primary-color-light">
				<FontAwesomeIcon
					className="icon fa-spin large-24-font-size"
					icon={faSpinner}
				/>
			</section>
		);
	}

	return (
		<section
			className={`biddermobile__mobilebidpanel bg-primary-color-light open`}
		>
			<AutobidToggle {...autobidProps} />
			<PriceControl {...autobidProps} />
			<BidsFeed {...autobidProps} />
			{showAutobid ? (
				<AutobidButton {...autobidProps} />
			) : (
				<BidButton {...autobidProps} />
			)}
		</section>
	);
};

export default BiddingPanel;
