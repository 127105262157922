import React, { Component } from 'react';
import DragDropInput from 'components/common/dragdropinput';
import Papa from 'papaparse';
import requestBidIncrements from 'api/requests/common/Req-BidIncrements';
import 'styles/adminpages/addauction/details/increments.scss';

class Increments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			increments: this.props.auctionIncrements,
			isEditing: false,
			file: null,
			showMessage: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.auctionIncrements !== prevProps.auctionIncrements) {
			this.setState({
				increments: this.props.auctionIncrements,
			});
		}
	}

	onFileUpload = file => {
		if (!file) {
			this.setState({ file });
			return;
		}
		if (file?.type !== 'text/csv') {
			this.setState({
				showMessage: 'Please upload a .csv file',
			});
			setTimeout(() => this.setState({ showMessage: null }), 1500);
			return;
		}
		this.setState({ file });
	};

	onConfirm = () => {
		Papa.parse(this.state.file, {
			complete: results => {
				if (!results.data?.length) return;

				let preppedData = results.data
					.map(csvRow => {
						let preppedRow = {};
						let rowKeys = Object.keys(csvRow);

						rowKeys.forEach(key => {
							let lowered = key.toLowerCase();
							if (lowered === 'from')
								preppedRow.from = csvRow[key];
							if (lowered === 'increment')
								preppedRow.inc = csvRow[key];
						});

						if (!preppedRow.from || !preppedRow.inc) return null;
						preppedRow.from = this.cleanUpNumber(preppedRow.from);
						preppedRow.inc = this.cleanUpNumber(preppedRow.inc);
						return preppedRow;
					})
					.filter(row => row);

				requestBidIncrements(
					this.props.auctionDetail.auctionID,
					preppedData
				);
				this.setState({ file: null });
			},
			header: true,
		});
	};

	cleanUpNumber = value => {
		if (value.indexOf(',') === -1) return Number(value);
		return Number(value.replace(/[, ]+/g, ''));
	};

	render() {
		return (
			<div className="addauction__details__increments">
				{this.state.showMessage && (
					<div
						className="dragdropinput"
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: 'red',
						}}
					>
						{this.state.showMessage}
					</div>
				)}

				{!this.state.showMessage && (
					<DragDropInput
						onFileUpload={this.onFileUpload}
						confirm={this.onConfirm}
						file={this.state.file}
					/>
				)}

				<table className="addauction__details__increments__table">
					<thead className="bg-secondary-color-light">
						<tr>
							<th>From</th>
							<th>Increment</th>
						</tr>
					</thead>
					<tbody>
						{this.state.increments.map((increment, index) => {
							return (
								<tr key={index}>
									<td>{increment.from}</td>
									<td>{increment.inc}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}

export default Increments;
