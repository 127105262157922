import React, { Component } from 'react';
import { connect } from 'react-redux';
import Bid from 'components/clerkconsole/bidhistory/bid';
import Message from 'components/clerkconsole/bidhistory/message';
import 'styles/clerkconsole/bidhistory/bids.scss';

export class Bids extends Component {
	shouldComponentUpdate(nextProps) {
		if (nextProps?.messages?.length !== this.props?.messages?.length)
			return true;
		if (nextProps?.currentLotState !== this.props?.currentLotState)
			return true;

		if (
			nextProps?.offersWithBids?.length !==
			this.props?.offersWithBids?.length
		)
			return true;

		const currentBids = this.props?.offersWithBids;
		const nextBids = nextProps?.offersWithBids;

		if (!currentBids || !nextBids) return false;

		const bidsInSync = currentBids?.every(
			(bid, index) => bid.isVoid === nextBids[index]?.isVoid
		);
		if (!bidsInSync) return true;

		return false;
	}

	render() {
		let messages = this.props?.messages;
		let lotState = this.props?.currentLotState;
		let lotPassed = lotState === 300;
		let topBidFound = false;
		let offerList =
			this.props?.offersWithBids &&
			this.props?.offersWithBids.map(bid => {
				bid.currentBid = false;
				if (topBidFound) return bid;
				if (!bid.isVoid) {
					bid.currentBid = true;
					topBidFound = true;
				}
				return bid;
			});

		let noOffers = !offerList || offerList.length < 1;
		let noMessages = !messages || messages.length < 1;

		if (noOffers && noMessages) {
			if (lotPassed)
				return (
					<div className="bids bg-primary-color-light">
						<Bid bid={null} auctioneer={this.props.auctioneer} />
					</div>
				);
			else
				return (
					<div className="bids">
						{!lotPassed && (
							<div>{this.props.config?.noBidsYet}</div>
						)}
					</div>
				);
		}

		let mergedList = [...offerList, ...messages].sort((a, b) => {
			let aTimestamp = a.offerTimeStamp || a.createTime;
			let bTimestamp = b.offerTimeStamp || b.createTime;
			return bTimestamp - aTimestamp;
		});

		if (this.props.auctioneer) {
			mergedList = offerList
				.filter((bid, index) => index < 3)
				.sort((a, b) => {
					let aTimestamp = a.offerTimeStamp || a.createTime;
					let bTimestamp = b.offerTimeStamp || b.createTime;
					return bTimestamp - aTimestamp;
				});
		}

		return (
			<div className="bids bg-primary-color-light">
				{mergedList.map((item, index) => {
					if (item.offerID) {
						return (
							<Bid
								bid={item}
								key={index}
								auctioneer={this.props.auctioneer}
							/>
						);
					}

					return <Message message={item} key={index} />;
				})}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		offersWithBids: state.auction?.offersWithBids,
		messages: state.auction?.messages,
		currentLotState: state.auction?.currentLot?.lotSaleDetail?.lotState,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Bids);
