import { connect } from 'react-redux';
import AntMediaSB from 'components/common/AntMediaSB';
import ReactPlayerSB from 'components/common/ReactPlayerSB';

const Video = props => {
	const { showMainVideo, onThumbnailClick } = props;

	if (props?.sourceOpt === 'liveStream') {
		return (
			<AntMediaSB
				classes={`mobilemainpanel__video ${
					showMainVideo ? '' : 'mobilemainpanel__thumbnail'
				}`}
				overlay={'mobilemainpanel__overlay'}
				overlayClick={onThumbnailClick}
			/>
		);
	}
	return (
		<ReactPlayerSB
			classes={`mobilemainpanel__video ${
				showMainVideo ? '' : 'mobilemainpanel__thumbnail'
			}`}
			overlay={'mobilemainpanel__overlay'}
			overlayClick={onThumbnailClick}
		/>
	);
};

const mapStateToProps = state => {
	return {
		sourceOpt: state.auction?.videoStream?.sourceOpt,
		showMainVideo: state.bidder?.showMainVideo,
	};
};

export default connect(mapStateToProps)(Video);
