import useConfig from 'hooks/useConfig';
import styles from './Message.module.scss';

const Message = props => {
	const { clerkMessages, auctionMessages } = useConfig();
	const messages = [...clerkMessages, ...auctionMessages];

	const getMessage = () => {
		if (typeof props.data.text === 'string') {
			return props.data.text;
		}

		const text = messages?.find(
			msg => msg.messageID === props.data.text
		)?.text;

		if (!text) {
			console.debug(
				`[Error] - Could not find messageID ${props?.data?.text} in config.json`
			);
			return props?.data?.text;
		}
		return text;
	};

	return (
		<div className={styles.container}>
			<div className={styles.spacer}></div>
			<div className={styles.body}>{getMessage()}</div>
			<div className={styles.spacer}></div>
		</div>
	);
};

export default Message;
