import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_MESSAGE: 'Req-Message'
export function requestMessage(index, text) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-Message',
		userTokenID: window.API?.user?.userToken,
		index,
		text,
	}).catch(error => console.debug(error));
}
