import classNames from 'classnames';
import styles from './Bid.module.scss';
import useCurrency from 'hooks/useCurrency';
import { getIcon, getName } from './utils';

const Bid = ({
	data,
	config,
	showAutobid,
	animate,
	visibilityHidden,
	displayNone,
}) => {
	const { getFormattedCurrency } = useCurrency();
	if (!data || !config) return <div />;
	const isYou = data?.isYou;
	return (
		<div className={styles.container} data-testid="bid">
			<div
				className={classNames({
					[styles.bidOther]: !isYou,
					[styles.bidIsYou]: isYou,
					[styles.animateOther]: !isYou && animate,
					[styles.animateIsYou]: isYou && animate,
					[styles.isVoid]: data?.isVoid,
					[styles.visibilityHidden]: visibilityHidden,
					[styles.displayNone]: displayNone,
				})}
			>
				{getIcon(data)}
				<div className={'descriptionClass'}>
					{getName(data, config)} -{' '}
					<span>{getFormattedCurrency(data.offerPrice)}</span>
				</div>
			</div>
		</div>
	);
};

export default Bid;
