import { useSelector } from 'react-redux';

import useCurrentLot from 'hooks/useCurrentLot';
import useConfig from 'hooks/useConfig';
import LazyImage from 'components/common/lazyimage';
import 'styles/clerkconsole/currentlot/currentlot.scss';

const CurrentLot = () => {
	const { lot, currentLot, lotWithdrawn, noCurrentLotSet, of } = useConfig();
	const { catalogueLot } = useCurrentLot();
	const { lotNumber, lotIndex, title, artist, absImg, isWithdrawn } =
		catalogueLot || {};
	const { lotCount } = useSelector(state => state.auction) || {};
	const progress = lotNumber ? `${(lotNumber / lotCount) * 100}%` : 0;
	const displayLotNumber = lotNumber ? `${lot} ${lotNumber}` : null;

	if (isWithdrawn)
		return (
			<div className="currentlot">
				<div className="currentlot__header large-24-font-size">
					{currentLot}
				</div>
				<div className="currentlot__image regular-18-font-size withdrawn">
					{lotWithdrawn}
				</div>
			</div>
		);

	return (
		<div className="currentlot">
			<div className="currentlot__header large-24-font-size">
				<div>{currentLot}</div>
				<div>{displayLotNumber}</div>
			</div>
			{artist && (
				<div className="currentlot__title regular-16-font-size">
					{lotNumber ? artist : ''}
				</div>
			)}
			<div className="currentlot__title regular-16-font-size">
				{lotNumber ? title : noCurrentLotSet}
			</div>
			<LazyImage
				className={'currentlot__image'}
				url={absImg?.length && absImg[0]}
			/>
			<div className="currentlot__auctionprogress">
				<div className="currentlot__auctionprogress__progressbar">
					<div
						className="currentlot__auctionprogress__progressbar_inner bg-accent-color-1"
						style={{ width: progress }}
					></div>
				</div>
				<div className="currentlot__auctionprogress__lotcount regular-16-font-size">
					{lotIndex && lotCount && (
						<span>
							{lot} {lotIndex || 0} {of} {lotCount || 0}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default CurrentLot;
