import { connect } from 'react-redux';
import store from 'store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute } from '@fortawesome/pro-solid-svg-icons';

import VideoActions from 'store/actions/video';
import AntMediaSB from 'components/common/AntMediaSB';
import ReactPlayerSB from 'components/common/ReactPlayerSB';
import styles from './videofeed.module.scss';
import classNames from 'classnames';

export const VideoFeed = props => {
	const onOverlayClick = () => {
		store.dispatch(VideoActions.updateIsMuted(false));
	};

	const isViewer = window?.API?.user?.userPerm === 'viewer';

	if (props.bidder?.videoStream?.sourceOpt === 'liveStream')
		return (
			<div
				className={classNames(styles.wrapper, {
					[styles.wrapperviewer]: isViewer,
				})}
			>
				{props.video.isMuted && (
					<div className={styles.overlay} onClick={onOverlayClick}>
						<FontAwesomeIcon className={styles.icon} icon={faVolumeMute} />
					</div>
				)}
				{props.video.isMuted && <div className={styles.shadow}></div>}
				<AntMediaSB classes={styles.videofeed} {...props} />
			</div>
		);

	return (
		<div
			className={classNames(styles.wrapper, {
				[styles.wrapperviewer]: isViewer,
			})}
		>
			{props.video.isMuted && (
				<div className={styles.overlay} onClick={onOverlayClick}>
					<FontAwesomeIcon className={styles.icon} icon={faVolumeMute} />
				</div>
			)}
			{props.video.isMuted && <div className={styles.shadow}></div>}
			<ReactPlayerSB classes={styles.videofeed} {...props} />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		bidder: { ...state.bidder, ...state.auction },
		video: { ...state.video },
	};
};

export default connect(mapStateToProps)(VideoFeed);
