import useConfig from 'hooks/useConfig';
import classNames from 'classnames';
import styles from './SetButton.module.scss';

const SetButton = ({
	input,
	setActiveButton,
	onSetClick,
	buttonSetIcon,
	showSetSuccess,
	showSetError,
}) => {
	const config = useConfig();

	return (
		<div className={styles.container}>
			<button
				className={classNames(styles.button, {
					[styles.success]: showSetSuccess,
					[styles.error]: showSetError,
				})}
				onClick={onSetClick}
				data-testid="set"
			>
				{buttonSetIcon || config?.set}
			</button>
		</div>
	);
};

export default SetButton;
