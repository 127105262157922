import { Interweave } from 'interweave';

import useConfig from 'hooks/useConfig';
import useCurrentLot from 'hooks/useCurrentLot';
import styles from './currentlot.module.scss';

const Details = props => {
	const config = useConfig();
	const { catalogueLot } = useCurrentLot();
	const { title, description, artist } = catalogueLot || {};

	return (
		<div className={styles.details}>
			{/* <div className={styles.tabs}>
				<span className={styles.tabitem}>{config?.overview}</span>
			</div> */}
			<div className={styles.detailbody}>
				{artist && (
					<div className={styles.detailtitle}>
						<Interweave content={artist} />
					</div>
				)}
				<div className={styles.detailtitle}>
					<Interweave content={title} />
				</div>
				<Interweave content={description} />
			</div>
		</div>
	);
};

export default Details;
