import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Header from './Header/Header';
import AuctionStats from './AuctionStats/AuctionStats';
import AuctionSummary from './AuctionSummary/AuctionSummary';
import Row from './row';
import Modal from './modal';
import requestSaleResult from 'api/requests/common/Req-SaleResult';
import dataToCsvDownload from 'utils/dataToCsvDownload';
import 'styles/common/auctionresults.scss';

const AuctionResults = props => {
	const [showModalForLotNumber, setShowModalForLotNumber] = useState(null);
	const { config } = props;

	useEffect(() => {
		if (!window.API?.user) return;
		requestSaleResult(props?.saleOutcome?.auctionID);
		// eslint-disable-next-line
	}, [props?.auctionID]);

	const buildRow = (lot, bid, isTopResult) => {
		const date = bid?.timestamp && new Date(bid.timestamp);
		const formattedDate = date
			? `${`${date.getHours()}`.padStart(
					2,
					0
				)}:${`${date.getMinutes()}`.padStart(
					2,
					0
				)}:${`${date.getSeconds()}`.padStart(2, 0)}`
			: 'N/A';

		return {
			lotNumber: lot.lotNumber,
			bidPrice: bid?.bidPrice || 'N/A',
			bidderID: bid?.bidderID || 'N/A',
			bidderLogin: bid?.bidderLogin || 'N/A',
			isClerk: bid?.isClerk || 'N/A',
			isVoid: bid?.isVoid || 'N/A',
			paddleNum: bid?.paddleNum || 'N/A',
			timestamp: formattedDate,
			saleOutcome: isTopResult ? lot.saleOutcome : 'N/A',
			winningPlatform:
				isTopResult && lot.winningPlatform ? lot.winningPlatform : 'N/A',
		};
	};

	const onExportClick = () => {
		let data = [];

		props.saleOutcome.lots.forEach(lot => {
			if (!lot.sbAllBidders.length) data.push(buildRow(lot, null, true));
			if (lot.sbWinningBidder)
				data.push(buildRow(lot, lot.sbWinningBidder, true));

			lot.sbAllBidders.forEach((bid, index) => {
				let topResultPassedLot = !lot.sbWinningBidder && index === 0;
				data.push(buildRow(lot, bid, topResultPassedLot));
			});
		});

		dataToCsvDownload(data, `${props?.saleOutcome?.auctionID}_results`);
	};

	const showAllBids = lotNumber => {
		setShowModalForLotNumber(lotNumber);
	};

	return (
		<div className="auctionresults">
			{showModalForLotNumber !== null && (
				<Modal
					modalClose={() => setShowModalForLotNumber(null)}
					{...props}
					showModalForLotNumber={showModalForLotNumber}
				/>
			)}

			{/* <div className="auctionresults__row row-2">
				<Link to={props.origin}>
					<button className="auctionresults__back bg-admin-accent-1 primary-color-light">
						{config?.back}
					</button>
				</Link>
				<div className="auctionresults__header">
					{config?.auctionResults}
				</div>
				<button
					className="auctionresults__export bg-accent-color-1 primary-color-light"
					onClick={() => onExportClick()}
				>
					{config?.exportCsv}
				</button>
			</div> */}

			<Header origin={props?.origin} onExportClick={onExportClick} />
			<AuctionSummary />
			<AuctionStats />
			<div className="auctionresults__row row-4">
				<div className="auctionresults__table__wrapper">
					<table className="auctionresults__table" cellSpacing="0">
						<thead>
							<tr>
								<th>{config?.lotNumber}</th>
								<th>{config?.bidder}</th>
								<th>{config?.clerkBid}</th>
								<th>{config?.platform}</th>
								<th>{config?.timestamp}</th>
								<th>{config?.salePrice}</th>
								<th>{config?.status}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{props.saleOutcome?.lots?.length > 0 &&
								props.saleOutcome?.lots.map((row, index) => {
									return (
										<Row
											{...row}
											key={index}
											showAllBids={showAllBids}
											config={config}
										/>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auctionID:
			state.adminpages?.auctionDetail?.auctionID || state.auction?.auctionID,
		saleOutcome: state.adminpages?.saleOutcome || state.clerk?.saleOutcome,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(AuctionResults);
