import sendRequest from '..';
import ShowFlagsActions from 'store/actions/showflags';
import { getCommonEndpoint } from '../utils';
import store from 'store/store';
import LoginActions from 'store/actions/login';

//REQ_AUCTIONSBASIC: 'Req-AuctionsBasic'
export default function requestAuctionsBasic() {
	return sendRequest(getCommonEndpoint(), {
		Action: 'Req-AuctionsBasic',
		userTokenID: '',
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => {
			console.debug(error);
			store.dispatch(LoginActions.showLoginDetailError('Network error'));
		});
}

const handleSuccess = data => {
	store.dispatch(LoginActions.updateAuctions(data));
	store.dispatch(ShowFlagsActions.setShowFlags(data.showFlags));
	// console.debug('Req-AuctionsBasic success', data);
};

const handleFailure = data => {
	console.debug('Req-AuctionsBasic failure', data);
	store.dispatch(LoginActions.showLoginDetailError('Unable to fetch auctions'));
};
