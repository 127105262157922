import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import styles from './Input.module.scss';

const Input = ({ input, onInputChange, onKeyPress }) => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();

	return (
		<div className={styles.container}>
			<input
				className={styles.input}
				placeholder={config?.incrementsPlaceholder}
				onChange={onInputChange}
				onKeyPress={onKeyPress}
				value={input ? getFormattedCurrency(input) : ''}
				data-testid="input"
			/>
			<div className={styles.label}>{config?.manual}</div>
		</div>
	);
};

export default Input;
