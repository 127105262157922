import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import requestBidIncrements from 'api/requests/common/Req-BidIncrements';

const useIncrements = (limit, setLimit) => {
	const auction = useSelector(state => state.auction);
	const increments = useSelector(state => state.bidder?.auctionIncrements);

	const [currentIncrement, setCurrentIncrement] = useState(null);
	const [debounce, setDebounce] = useState(false);

	useEffect(() => {
		if (!window.API?.user?.userToken) return;
		requestBidIncrements(auction?.auctionID, null);
		// eslint-disable-next-line
	}, [auction?.auctionID, window.API?.user?.userToken]);

	useEffect(() => {
		if (!increments) return;
		let current = 0;

		increments?.forEach(inc => {
			if (limit >= inc.from) current = inc.inc;
		});

		setCurrentIncrement(current);
	}, [limit, increments]);

	const onIncrementClick = (event, isIncreasing) => {
		event.preventDefault();
		event.stopPropagation();
		if (debounce) return;
		setDebounce(true);
		setTimeout(() => setDebounce(false), 100);
		let reversed = [...increments].reverse();

		if (!increments || !limit) return;

		if (isIncreasing) {
			/**
			 * get limit + current increment.
			 * If it jumps to the next band, set limit to the first value in that band
			 */
			let currentBand = null;
			let nextBand = null;
			increments.forEach(inc => {
				if (limit >= inc.from) currentBand = inc;
				if (limit + currentIncrement >= inc.from) nextBand = inc;
			});

			if (currentBand.from !== nextBand.from) setLimit(nextBand.from);
			else setLimit(limit + currentBand.inc);
		} else {
			/**
			 * get limit - current increment.
			 * If it drops to the previous band, set limit to the first value in that band
			 */
			let currentBand = null;
			let nextBand = null;
			let breakNext = false;

			increments.forEach(inc => {
				if (limit >= inc.from) currentBand = inc;
			});

			reversed.forEach(inc => {
				if (limit - currentIncrement >= inc.from && !breakNext) {
					nextBand = inc;
					breakNext = true;
				}
			});

			if (currentBand.from !== nextBand.from)
				setLimit(currentBand.from - nextBand.inc);
			else setLimit(limit - currentBand.inc);
		}
	};

	return [currentIncrement, onIncrementClick];
};

export default useIncrements;
