import { useState } from 'react';
import { useSelector } from 'react-redux';
import useLotID from 'hooks/useLotID';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import requestWithdrawLot from 'api/requests/admin/Req-WithdrawLot';
import LazyImage from 'components/common/lazyimage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons';

const LotRow = props => {
	const [showSpinner, setShowSpinner] = useState(false);
	const [errorDetail, setErrorDetail] = useState(null);
	const { getFormattedCurrency } = useCurrency();
	const config = useConfig();
	const { auctionID, lotUPTS } =
		useSelector(state => state?.adminpages?.auctionDetail) || {};
	const { catalogueLot } = useLotID(props?.lotID);
	const {
		lotID,
		lotNumber,
		lotIndex,
		artist,
		title,
		lowEst,
		highEst,
		clerkInfo,
		startingPrice,
		absImg,
		isWithdrawn,
	} = catalogueLot || {};
	const { reservePrice } = clerkInfo || {};

	const getUploadStatus = () => {
		switch (props?.uploadStatus?.result) {
			case 10:
				return (
					<div>
						{config?.uploaded}{' '}
						<FontAwesomeIcon
							className="uploadicon check"
							icon={faCheck}
						/>
					</div>
				);
			default:
				return <div>{props?.uploadStatus?.detail}</div>;
		}
	};

	const onWithdrawChange = event => {
		const checked = event.target.checked;
		setShowSpinner(true);
		requestWithdrawLot(auctionID, lotID, checked)
			.then(data => {
				setShowSpinner(false);
				if (data.result > 100) handleFailure(data.detail);
			})
			.catch(error => {
				setShowSpinner(false);
				handleFailure('Connection error');
			});
	};

	const handleFailure = detail => {
		setErrorDetail(detail);
		setTimeout(() => setErrorDetail(null), 2000);
	};

	return (
		<tr
			className={`review__table__row regular-16-font-size ${
				isWithdrawn ? 'withdrawn' : ''
			}`}
			onClick={() => props?.onEditClick(lotIndex)}
			data-testid="lots-row"
		>
			<td
				className="review__withdraw__container"
				data-testid="lots-withdraw-input"
			>
				{showSpinner && (
					<FontAwesomeIcon
						className="upload__icon spinner fa-spin"
						icon={faSpinner}
					/>
				)}
				{!showSpinner && (
					<input
						className="review__withdraw__input"
						type="checkbox"
						checked={isWithdrawn}
						onChange={event => onWithdrawChange(event)}
						onClick={event => event.stopPropagation()}
					/>
				)}
			</td>
			<td>{lotNumber}</td>
			<td>
				<div className="img-container">
					{!absImg?.length && (
						<FontAwesomeIcon
							className="upload__icon spinner fa-spin"
							icon={faSpinner}
						/>
					)}
					{absImg?.length && (
						<LazyImage
							className={'lazy-img-container-review'}
							url={absImg[0]}
							adminPagesUpload={true}
						/>
					)}
				</div>
			</td>
			<td>{artist}</td>
			<td>
				{errorDetail && <p className="error">{errorDetail}</p>}
				{!errorDetail && <p>{title}</p>}
			</td>
			<td>
				{isWithdrawn
					? config?.withdrawn
					: getFormattedCurrency(startingPrice)}
			</td>
			<td>{getFormattedCurrency(reservePrice)}</td>
			<td>{isWithdrawn ? '' : getFormattedCurrency(lowEst)}</td>
			<td>{isWithdrawn ? '' : getFormattedCurrency(highEst)}</td>
			{props?.uploadStatus && <td>{getUploadStatus()}</td>}
		</tr>
	);
};

export default LotRow;
