import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import useConfig from 'hooks/useConfig';
import CurrentLot from './currentlot';
import VideoFeed from 'components/bidderinterface/videofeed/videofeed';
import LotsFeed from 'components/bidderinterface/lotsfeed/lotsfeed';
import styles from './mobile.module.scss';

const Mobile = () => {
	const config = useConfig();
	const { auctionTitle } = useSelector(state => state?.auction) || {};

	return (
		<div className={styles.container}>
			<div className={styles.video}>
				<div className={styles.liveNow}>
					<FontAwesomeIcon className={styles.icon} icon={faCircle} />
					{config?.live}
				</div>
				<VideoFeed />
				<CurrentLot />
			</div>
			<div className={styles.title}>{auctionTitle}</div>
			<div className={styles.lotsfeed}>
				<LotsFeed />
			</div>
		</div>
	);
};

export default Mobile;
