import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-light-svg-icons';

import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import { capitaliseFirst } from 'utils/utils';
import styles from './SearchPanel.module.scss';

export const SearchPanel = ({ searchFilterLots }) => {
	const { refineResults, price, category } = useConfig();
	const { getFormattedCurrency, sanitiseCurrency } = useCurrency();
	const {
		searchValue,
		setSearchValue,
		priceValue,
		setPriceValue,
		categories,
		categoryValue,
		setCategoryValue,
	} = searchFilterLots;
	const { low, high } = priceValue;
	const showPriceReset = low || high;

	return (
		<div className={styles.searchpanel}>
			<div className={styles.title}>
				<label htmlFor="keyword">{capitaliseFirst(refineResults)}</label>
				{searchValue && (
					<button className={styles.undo} onClick={() => setSearchValue('')}>
						<FontAwesomeIcon icon={faUndo} />
					</button>
				)}
			</div>
			<input
				className={styles.input}
				placeholder="Search by keyword or text"
				name="keyword"
				value={searchValue}
				onChange={event => setSearchValue(event.target.value)}
			/>

			<div className={styles.title}>
				<label htmlFor="pricelow">{capitaliseFirst(price)}</label>
				{showPriceReset && (
					<button
						className={styles.undo}
						onClick={() => setPriceValue({ low: '', high: '' })}
					>
						<FontAwesomeIcon icon={faUndo} />
					</button>
				)}
			</div>

			<input
				className={styles.input}
				placeholder="from"
				name="pricelow"
				value={low ? getFormattedCurrency(low) : ''}
				onChange={event =>
					setPriceValue({
						low: sanitiseCurrency(event.target.value),
						high,
					})
				}
			/>
			<input
				className={styles.input}
				placeholder="to"
				name="pricehigh"
				value={high ? getFormattedCurrency(high) : ''}
				onChange={event =>
					setPriceValue({
						low,
						high: sanitiseCurrency(event.target.value),
					})
				}
			/>

			<div className={styles.title}>
				<label htmlFor="pricelow">{capitaliseFirst(category)}</label>
				{categoryValue && (
					<button className={styles.undo} onClick={() => setCategoryValue('')}>
						<FontAwesomeIcon icon={faUndo} />
					</button>
				)}
			</div>

			{categories.map(category => (
				<div className={styles.category} key={category}>
					<input
						type="checkbox"
						checked={categoryValue === category}
						onChange={() =>
							setCategoryValue(categoryValue === category ? '' : category)
						}
					/>
					<label>{capitaliseFirst(category)}</label>
				</div>
			))}
		</div>
	);
};
