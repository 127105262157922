import React, { Component } from 'react';
import { connect } from 'react-redux';

import requestOnBid from 'api/requests/bidder/Req-OnBid';
import Flipdater from 'components/common/flipdater';
import './bidbutton.scss';
import { hoverController } from './HoverController';

export class BidButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bidState: this.props?.bidState || null,
			offerID: this.props?.currentOfferOpt?.offerID || null,
			showButtonPulse: false,
		};
	}

	componentWillUnmount() {
		clearTimeout(this.buttonPulseTimeout);
		hoverController(false);
		this.fairWarningTimeout = null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props?.currentOfferOpt?.offerID &&
			this.props?.currentOfferOpt?.offerID !== this.state.offerID
		) {
			let bidState = this.isCurrentStateSafe() ? this.state.bidState : 0;
			this.setState({
				offerID: this.props?.currentOfferOpt?.offerID || null,
				bidState,
			});
		}

		if (
			this.props?.messages?.length &&
			this.props?.messages?.length !== prevProps?.messages?.length
		) {
			this.fairWarningAlert();
		}

		if (
			prevProps?.currentLotDetailOpt?.currentLotID !==
			this.props?.currentLotDetailOpt?.currentLotID
		) {
			hoverController(false);
		}

		if (this.props?.currentOfferOpt?.offerID === this.state.offerID) {
			if (this.state.bidState === this.props?.bidState) return;
			this.setState({
				bidState: this.props?.bidState || 0,
			});
		}

		if (
			prevProps?.currentLotDetailOpt?.myAutoBidOpt >=
			prevProps.currentOfferOpt?.offerPrice
		) {
			if (
				this.props?.currentLotDetailOpt?.myAutoBidOpt <
				this.props?.currentOfferOpt?.offerPrice
			) {
				this.autobidFinishPulse();
			}
		}
	}

	autobidFinishPulse() {
		this.setState({ showButtonPulse: true });
		this.buttonPulseTimeout = setTimeout(
			() => this.setState({ showButtonPulse: false }),
			2000
		);
	}

	fairWarningAlert() {
		if (this.props.messages[0]?.text !== this.props?.config?.fairWarningPulseOn)
			return;

		const showWarningStates = [
			this.props.config?.biddingStates?.displayAsOutbid,
			this.props.config?.biddingStates?.displayAsHasBidNowBidAgain,
		];

		const showWarning = showWarningStates.indexOf(this.props.bidState) !== -1;

		if (!showWarning) return;
		if (this.buttonPulseTimeout) return;

		this.setState({ showButtonPulse: true });

		this.buttonPulseTimeout = setTimeout(() => {
			this.setState({ showButtonPulse: false });
			this.buttonPulseTimeout = null;
		}, 2000);
	}

	isCurrentStateSafe() {
		const safeStates = [
			this.props.config?.biddingStates?.displayAsDidNotBid,
			this.props.config?.biddingStates?.displayAsLateBid,
			this.props.config?.biddingStates?.displayAsRejectedBid,
			this.props.config?.biddingStates?.displayAsOutbid,
			this.props.config?.biddingStates?.displayAsHasBidNowBidAgain,
		];

		return safeStates.indexOf(this.state.bidState) !== -1;
	}

	onActionClick = () => {
		if (!this.props.currentOfferOpt?.offerID) return;

		if (window?.gtag) {
			const gtagProps = {
				askingPrice: this.props.currentOfferOpt.offerPrice,
				lotNumber: this.props.lotNumber,
			};
			if (this.props?.saleNumOpt) gtagProps.saleNum = this.props?.saleNumOpt;
			window?.gtag('event', 'user_bid', gtagProps);
		}

		requestOnBid(this.props.currentOfferOpt.offerID);
	};

	onMouseEnter = () => hoverController(true);

	onMouseLeave = () => hoverController(false);

	render() {
		let buttonText = '';
		let classes = this.props.useMobileLayout
			? 'mobilebidbutton__action'
			: 'biddingpanel__action';
		classes = classes + ' regular-18-font-size';
		let disabled = false;
		const isWithdrawn = this.props?.currentLotDetailOpt?.isWithdrawn;
		const isLocked = this.props?.currentLotDetailOpt?.isLocked;

		switch (this.state.bidState) {
			case this.props.config?.biddingStates?.displayAsNotReady:
				buttonText = this.props?.config?.pleaseWait;
				classes = classes + ' deactivated';
				disabled = true;
				break;

			case this.props.config?.biddingStates?.displayAsPendingBid:
				buttonText = this.props.config?.confirming;
				classes = classes + ' deactivated';
				disabled = true;
				break;

			case this.props.config?.biddingStates?.displayAsLeadBid:
				buttonText = this.props.config?.leadBid;
				classes = classes + ' deactivated lead';
				disabled = true;
				break;

			case this.props.config?.biddingStates?.displayAsLateBid:
				buttonText = this.props.config?.tooLateBidAgain;
				break;

			case this.props.config?.biddingStates?.displayAsWinningBid:
				buttonText = this.props.config?.winningBid;
				classes = classes + ' deactivated';
				disabled = true;
				break;

			case this.props.config?.biddingStates?.displayAsRejectedBid:
				buttonText = this.props.config?.rejectedBid;
				break;

			case this.props.config?.biddingStates?.displayAsBidError:
				buttonText = this.props.config?.bidError;
				break;

			case this.props.config?.biddingStates?.displayAsPassedBid:
				buttonText = this.props.config?.bidPassed;
				classes = classes + ' deactivated';
				disabled = true;
				break;

			case this.props.config?.biddingStates?.displayAsSoldBid:
				buttonText = this.props.config?.bidSold;
				classes = classes + ' deactivated';
				disabled = true;
				break;

			case this.props.config?.biddingStates?.displayAsOutbid:
				buttonText = this.props.config?.bidAgain;
				break;

			case this.props.config?.biddingStates?.displayAsHasBidNowBidAgain:
				buttonText = this.props.config?.bidAgain;
				break;

			default:
				buttonText = this.props.config?.placeBid;
		}

		if (this.props.isPaused || isLocked || isWithdrawn) {
			classes = classes + ' deactivated';
			disabled = true;
			buttonText = this.props.config?.pleaseWait;
		}

		if (this.props.useMobileLayout) {
			return (
				<div className="biddermobile__mobilebidbutton">
					{this.state.showButtonPulse && (
						<div className="bidbutton__pulse"></div>
					)}
					<button
						className={classes}
						onClick={disabled ? null : this.onActionClick}
						disabled={disabled}
						data-testid="bidbutton"
					>
						{this.state.bidState ===
							this.props.config?.biddingStates?.displayAsPendingBid && (
							<div className="mobilebidbutton__confirming bg-accent-offset-1"></div>
						)}
						<div
							className={`leadbid ${
								this.state.bidState ===
								this.props.config?.biddingStates.displayAsLeadBid
									? 'live'
									: ''
							}`}
						></div>
						<div className="mobilebidbutton__title">
							<Flipdater value={buttonText} />
						</div>
					</button>
				</div>
			);
		}

		return (
			<div className="bidbutton__container">
				{this.state.showButtonPulse && <div className="bidbutton__pulse"></div>}
				<button
					className={classes}
					onClick={disabled ? null : this.onActionClick}
					disabled={disabled}
					onMouseEnter={this.onMouseEnter}
					onMouseMove={this.onMouseEnter}
					onMouseLeave={this.onMouseLeave}
					data-testid="bidbutton"
				>
					{this.state.bidState ===
						this.props.config?.biddingStates?.displayAsPendingBid && (
						<div
							className={`${
								this.props.useMobileLayout
									? 'mobilebidbutton__confirming'
									: 'biddingpanel__action-confirming'
							} bg-accent-offset-1`}
						></div>
					)}
					<div
						className={`leadbid ${
							this.state.bidState ===
							this.props.config?.biddingStates?.displayAsLeadBid
								? 'live'
								: ''
						}`}
					></div>
					<div
						className={`${
							this.props.useMobileLayout
								? 'mobilebidbutton__title'
								: 'biddingpanel__action-title'
						}`}
					>
						<Flipdater value={buttonText} />
					</div>
				</button>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		config: state?.config,
		useMobileLayout: state.bidder?.useMobileLayout,
		bidState: state.auction?.bidState,
		currentOfferOpt: state.auction?.currentOfferOpt,
		currentLotDetailOpt: state.auction?.currentLotDetailOpt,
		messages: state.auction?.messages,
		isPaused: state.auction?.isPaused,
		lotNumber: state.auction?.currentLot?.lotNumber,
		saleNumOpt: state.auction?.extended?.saleNumOpt,
	};
};

export default connect(mapStateToProps)(BidButton);
