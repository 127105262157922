import React, { Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTimes, faCheck } from '@fortawesome/pro-light-svg-icons';
import 'styles/common/dragdropinput.scss';

class DragDropInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dragOver: false,
			selectedFile: undefined,
		};

		this.inputRef = createRef();

		this.onDragEnter = this.onDragEnter.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onRemoveItem = this.onRemoveItem.bind(this);
		this.onDummyInputClick = this.onDummyInputClick.bind(this);
	}

	onDragEnter(event) {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ dragOver: true });
	}

	onDragLeave(event) {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ dragOver: false });
	}

	onDragOver(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	onDrop(event) {
		event.stopPropagation();
		event.preventDefault();
		// console.log(event.dataTransfer.files[0]);
		this.props.onFileUpload(event.dataTransfer.files[0]);
	}

	onRemoveItem() {
		this.setState({ dragOver: false });
		this.props.onFileUpload(null);
	}

	onSubmit = event => {
		console.debug(event);
	};

	fileChange = event => {
		// console.log(event.target.files[0]);
		this.props.onFileUpload(event.target.files[0]);
	};

	onDummyInputClick() {
		this.inputRef.current.click();
	}

	render() {
		if (this.props.file) {
			return (
				<div className={`dragdropinput ${this.props.id}`}>
					<div className="dragdropinput__file">
						<FontAwesomeIcon
							className="dragdropinput__file__icon"
							icon={faFile}
						/>
						<div className="dragdropinput__file__title">
							{this.props.file.name}
						</div>
						<button
							className="dragdropinput__file__action"
							onClick={this.onRemoveItem}
						>
							<FontAwesomeIcon
								className="dragdropinput__file__action__icon delete"
								icon={faTimes}
							/>
						</button>

						{this.props.confirm && (
							<button
								className="dragdropinput__file__action"
								onClick={this.props.confirm}
							>
								<FontAwesomeIcon
									className="dragdropinput__file__action__icon confirm"
									icon={faCheck}
								/>
							</button>
						)}
					</div>
				</div>
			);
		}

		const instruction =
			this.props?.dragSelectInstruction ||
			'Drag your file or click here to select a file';

		return (
			<div
				className="dragdropinput"
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDragOver={this.onDragOver}
				onDrop={this.onDrop}
			>
				<div
					className={`dragdropinput__input ${
						this.state.dragOver ? 'drag-over' : ''
					}`}
				>
					<div className="dragdropinput__input__instruction">
						{this.props.icon && this.props.icon}
						<span
							className="dragdropinput__input__dummy"
							onClick={this.onDummyInputClick}
						>
							{instruction}
						</span>
						<input
							type="file"
							ref={this.inputRef}
							id="file"
							onChange={event => this.fileChange(event)}
							style={{ display: 'none' }}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default DragDropInput;
