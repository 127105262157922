import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import AudioActions from 'store/actions/audio';

const BidderAudioController = props => {
	const { biddingStates } = props;
	const [initialMute, setInitialMute] = useState(true);

	setTimeout(() => {
		setInitialMute(false);
	}, 3000);

	useEffect(() => {
		if (initialMute) return;
		switch (props.bidder.bidState) {
			case biddingStates.displayAsPendingBid:
				store.dispatch(
					AudioActions.playAudio({ effect: 'waiting', loop: true })
				);
				break;
			case biddingStates.displayAsLeadBid:
				store.dispatch(
					AudioActions.playAudio({ effect: 'positive', loop: false })
				);
				break;
			case biddingStates.displayAsOutbid:
				store.dispatch(
					AudioActions.playAudio({ effect: 'negative', loop: false })
				);
				break;
			case biddingStates.displayAsLateBid:
				store.dispatch(
					AudioActions.playAudio({ effect: 'negative', loop: false })
				);
				break;
			case biddingStates.displayAsRejectedBid:
				store.dispatch(
					AudioActions.playAudio({ effect: 'negative', loop: false })
				);
				break;
			case biddingStates.displayAsHasBidNowBidAgain:
				store.dispatch(
					AudioActions.playAudio({ effect: 'negative', loop: false })
				);
				break;
			default:
				store.dispatch(
					AudioActions.playAudio({ effect: null, loop: false })
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.bidder.bidState]);

	useEffect(() => {
		return () => {
			store.dispatch(
				AudioActions.playAudio({ effect: null, loop: false })
			);
		};
	}, []);

	return null;
};

const mapStateToProps = state => {
	return {
		bidder: { ...state.bidder, ...state.auction },
		biddingStates: state?.config?.biddingStates,
	};
};

export default connect(mapStateToProps)(BidderAudioController);
