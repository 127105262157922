import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const PublishButton = props => {
	const getClass = () => {
		switch (props?.publishingStatus) {
			case 'working':
				return 'working';
			case 'live':
				return 'live';
			default:
				return '';
		}
	};

	const getValue = () => {
		switch (props?.publishingStatus) {
			case 'working':
				return (
					<span>
						Publishing...{' '}
						<FontAwesomeIcon
							className="icon fa-spin"
							icon={faSpinner}
						/>
					</span>
				);
			case 'live':
				return 'Live';
			default:
				return 'Start Publishing';
		}
	};

	const isDisabled = () =>
		props?.publishingStatus === 'live' ||
		props?.publishingStatus === 'working';

	return (
		<button
			disabled={isDisabled()}
			className={getClass()}
			onClick={() => props?.publish()}
		>
			{getValue()}
		</button>
	);
};

export default PublishButton;
