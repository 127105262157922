import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import 'styles/clerkconsole/bidhistory/bid.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faWifi,
	faGavel,
	faLandmark,
	faPhone,
} from '@fortawesome/pro-solid-svg-icons';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { isValidOfferPrice } from 'utils/utils';

const Bid = ({
	platformIconNumber,
	offerPrice,
	displayStatus,
	clerkDataOpt,
	isVoid,
	offerState,
	leadBidTimeStamp,
}) => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const { leadBidderName, bidCount } = clerkDataOpt || {};
	const getIcon = () => {
		let icon = faGavel;
		let classes = 'item__sourcetype__icon regular-16-font-size';
		switch (platformIconNumber) {
			case 0:
				classes = classes += ' streambid';
				break;
			case 10:
				icon = faLandmark;
				break;
			case 50:
				icon = faWifi;
				break;
			case 60:
				icon = faPhone;
				break;
			default:
		}
		return <FontAwesomeIcon className={classes} icon={icon} />;
	};

	const getText = () => {
		let status = '';
		let name = '';
		if (displayStatus === 3000) status = config?.sold;
		if (displayStatus === 2000) status = config?.passed;

		switch (platformIconNumber) {
			case 0:
				name =
					leadBidderName?.substr(
						0,
						(leadBidderName + '@').indexOf('@')
					) || 'Streambid';
				break;
			case 10:
				name = config.floor;
				break;
			case 50:
				name = config.online;
				break;
			case 60:
				name = config.phone;
				break;
			default:
		}

		return `${name}: ${getFormattedCurrency(offerPrice)} ${status}`;
	};

	const getClass = () => {
		if (isVoid) return `item void`;

		//TODO
		// Check these props
		if (!offerPrice) return `item passed`;

		switch (offerState) {
			case 400:
				return `item sold`;

			case 500:
				return `item passed`;

			case -200:
				return `item void`;

			default:
				return `item`;
		}
	};

	const getStatus = () => {
		if (isVoid) {
			return <div className="item__status">{config?.deleted}</div>;
		}

		switch (displayStatus) {
			case 400:
				return <div className="item__status">{config?.sold}</div>;

			case 500:
				return <div className="item__status">{config?.passed}</div>;

			default:
				let bidTime = new Date(leadBidTimeStamp);
				return (
					<div className="item__stopwatch border-secondary-color-dark">
						<FontAwesomeIcon
							className="item__stopwatch__icon regular-18-font-size"
							icon={faStopwatch}
						/>
						<span className="item__stopwatch__tooltip">
							{`${bidTime.getHours()}:${bidTime.getMinutes()}`}
						</span>
					</div>
				);
		}
	};

	if (!isValidOfferPrice(offerPrice)) {
		return (
			<div className={getClass()}>
				<div className="item__col-1">
					<div
						className={`item__sourcetype border-secondary-color-dark`}
					>
						{getIcon()}
					</div>
					<div className="item__description border-secondary-color-dark regular-16-font-size">
						{config?.noBidsPassed}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={getClass()} data-testid="bid">
			<div className="item__col-1">
				<div
					className={`item__sourcetype border-secondary-color-dark + ${
						platformIconNumber === 0 ? 'bg-accent-color-2' : ''
					}`}
				>
					{getIcon()}
					{platformIconNumber === 0 && (
						<div className="item__sourcetypebids">{bidCount}</div>
					)}
				</div>
				<div className="item__description border-secondary-color-dark regular-16-font-size">
					{getText()}
				</div>
			</div>
			<div className="item__col-2">{getStatus()}</div>
		</div>
	);
};

export default Bid;
