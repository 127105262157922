import * as Yup from 'yup';

export const getFormData = props => {
	const { config } = props;
	return [
		{
			label: config?.lotNumber,
			id: 'lotNumber',
			value: props?.lotNumber || '',
			placeholder: '',
			type: 'text',
			inputType: 'input',
			disabled: true,
		},
		{
			label: config?.title,
			id: 'title',
			value: props?.title || '',
			placeholder: '',
			type: 'text',
			inputType: 'textarea',
		},
		{
			label: config?.description,
			id: 'description',
			value: props?.description || '',
			placeholder: '',
			type: 'text',
			inputType: 'textarea',
		},
		{
			label: config?.lowEstimate,
			id: 'lowEst',
			value: props?.lowEst || '',
			placeholder: '',
			type: 'text',
			inputType: 'input',
			disabled: true,
		},
		{
			label: config?.highEstimate,
			id: 'highEst',
			value: props?.highEst || '',
			placeholder: 'High estimate',
			type: 'text',
			inputType: 'input',
			disabled: true,
		},
		{
			label: config?.startingPrice,
			id: 'startingPrice',
			value: props?.startingPrice || '',
			placeholder: '',
			type: 'text',
			inputType: 'input',
			disabled: true,
		},
	];
};

export const getValidationSchema = () => {
	const schema = {
		lotNumber: Yup.string().required('Required'),
		title: Yup.string().required('Required'),
		description: Yup.string().required('Required'),
		lowEst: Yup.number().required('Required').typeError('Must be a number'),
		highEst: Yup.number()
			.required('Required')
			.typeError('Must be a number'),
		startingPrice: Yup.number()
			.required('Required')
			.typeError('Must be a number'),
	};

	return Yup.object().shape(schema);
};
