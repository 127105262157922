const Row = props => {
	const { config } = props;

	const getPlatform = platform => {
		switch (platform) {
			case 'FB':
				return config?.floorBid;
			case 'PB':
				return config?.phoneBid;
			default:
				return config?.platformName;
		}
	};

	const date =
		props?.sbWinningBidder?.timestamp &&
		new Date(props?.sbWinningBidder?.timestamp);
	const formattedDate = date
		? `${`${date.getHours()}`.padStart(
				2,
				0
		  )}:${`${date.getMinutes()}`.padStart(
				2,
				0
		  )}:${`${date.getSeconds()}`.padStart(2, 0)}`
		: 'n/a';

	return (
		<tr>
			<td>{props?.lotNumber}</td>
			<td>{props?.sbWinningBidder?.bidderLogin || config?.na}</td>
			<td>
				{props?.sbWinningBidder?.isClerk ? config?.yes : config?.no}
			</td>
			<td>{getPlatform(props?.winningPlatform)}</td>
			<td>{`${formattedDate}`}</td>
			<td>{props?.salePrice || config?.na}</td>
			<td>{props?.saleOutcome}</td>
			<td>
				<button
					className="auctionresults__showall"
					onClick={() => props.showAllBids(props.lotNumber)}
				>
					{config?.showAll}
				</button>
			</td>
		</tr>
	);
};

export default Row;
