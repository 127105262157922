import { useEffect, useState } from 'react';
import useBidsFeed from 'hooks/useBidsFeed';
import useConfig from 'hooks/useConfig.js';
import Bid from './Bid/Bid';
import Message from './Message/Message';
import classNames from 'classnames';
import styles from './BidsFeed.module.scss';

const BidsFeed = ({ showAutobid }) => {
	const slideTimeout = 500;
	const [animateTopBid, setAnimateTopBid] = useState(false);
	const config = useConfig();
	const { displayList, animateSlide } = useBidsFeed();

	useEffect(() => {
		setAnimateTopBid(true);
		setTimeout(() => setAnimateTopBid(false), slideTimeout);
	}, [displayList?.length]);

	const renderItem = (data, index) => {
		const itemProps = {
			config,
			data,
			index,
			showAutobid,
			animate: index === 0 && animateTopBid,
			visibilityHidden: index === 2,
			displayNone: index > 2,
		};
		if (data.offerID) {
			return <Bid {...itemProps} key={index} />;
		}
		return <Message {...itemProps} key={index} />;
	};

	return (
		<div
			className={classNames(styles.bidsFeed, {
				[styles.autobidOpen]: showAutobid,
				[styles.slideDown]: animateSlide,
			})}
		>
			{displayList &&
				displayList.map((data, index) => renderItem(data, index))}
			{!displayList.length && (
				<div className={styles.noBids}>{config?.noBidsYet}</div>
			)}
		</div>
	);
};

export default BidsFeed;
