import Papa from 'papaparse';

export default function dataToCsvDownload(data, filename) {
	let json = JSON.stringify(data);
	let csv = Papa.unparse(json);
	let downloader = document.createElement('a');

	downloader.href = 'data:attachment/text,' + encodeURI(csv);
	downloader.target = '_blank';
	downloader.download = `${filename}.csv`;
	downloader.click();
}
