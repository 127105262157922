import { useState, useEffect } from 'react';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencil, faUndo } from '@fortawesome/pro-solid-svg-icons';
import styles from './Inputs.module.scss';

const Inputs = ({
	limit,
	resetLimit,
	onInputChange,
	onIncrementClick,
	autoBidOpt,
	onDelete,
	isEditing,
	setIsEditing,
	lowEst,
	onSubmit,
	info,
}) => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const [hasInteracted, setHasInteracted] = useState(false);
	const [value, setValue] = useState(
		autoBidOpt ? getFormattedCurrency(autoBidOpt) : ''
	);

	useEffect(() => {
		if (hasInteracted) {
			setValue(getFormattedCurrency(limit));
			return;
		}

		if (limit === '' || limit === lowEst) return;

		setValue(getFormattedCurrency(limit));
		setHasInteracted(true);
		// eslint-disable-next-line
	}, [limit]);

	const onKeyPress = event => {
		if (event.charCode !== 13) return;
		event.preventDefault();
		event.target.blur();
		onSubmit(event);
	};

	const isDisabled = autoBidOpt && !isEditing;
	const showReset = isEditing;

	const onResetClick = () => {
		if (limit !== autoBidOpt) resetLimit();
		setIsEditing(false);
	};

	const renderDeleteEdit = () => (
		<div className={styles.controls}>
			<button
				className={styles.button}
				onClick={event => onDelete(event)}
			>
				<FontAwesomeIcon icon={faTimes} />
			</button>
			<button
				className={classNames(styles.button, styles.edit)}
				onClick={() => setIsEditing(true)}
			>
				<FontAwesomeIcon icon={faPencil} />
			</button>
		</div>
	);

	const renderReset = () => (
		<div className={styles.controls}>
			<button className={styles.button} onClick={onResetClick}>
				<FontAwesomeIcon icon={faUndo} />
			</button>
		</div>
	);

	return (
		<div className={styles.container}>
			{isDisabled && renderDeleteEdit()}
			{showReset && renderReset()}
			<input
				className={classNames(styles.input, {
					[styles.info]: info,
				})}
				value={value}
				placeholder={config?.autobidPlaceholder}
				onClick={event => event.stopPropagation()}
				onChange={event => {
					setHasInteracted(true);
					onInputChange(event);
				}}
				onKeyPress={onKeyPress}
				inputMode="numeric"
				disabled={isDisabled}
			/>
		</div>
	);
};

export default Inputs;
