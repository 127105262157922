import { pretifyDate } from 'utils/utils';
import 'styles/viewerinterface/viewerpanel.scss';

const ViewerPanel = props => {
	let date = props?.bidder?.auctionDate
		? pretifyDate(props?.bidder?.auctionDate)
		: 'No date set';
	let specialist = props?.bidder?.specialistOpt
		? props?.bidder?.specialistOpt
		: 'No contact set';
	let specialistEmail = props?.bidder?.specialistEmailOpt
		? props?.bidder?.specialistEmailOpt
		: 'No email set';

	return (
		<section className="viewerpanel bg-primary-color-light border-secondary-color-dark primary-color-dark">
			<div className="viewerpanel__title large-24-font-size">
				{props?.bidder?.auctionTitle || 'No title set'}
			</div>
			<div className="viewerpanel__details">
				<div className="viewerpanel__date">
					<span className="viewerpanel__heading">Date:</span>
					<span className="viewerpanel__value">{date}</span>
				</div>
				<div className="viewerpanel__number">
					<span className="viewerpanel__heading">Sale number:</span>
					<span className="viewerpanel__value">
						{props?.bidder?.auctionNumber ||
							'No auction number set'}
					</span>
				</div>
				<div className="viewerpanel__contact">
					<span className="viewerpanel__heading">Sale contact:</span>
					<span className="viewerpanel__value">
						{`${specialist} - ${specialistEmail}`}
					</span>
				</div>
			</div>
		</section>
	);
};

export default ViewerPanel;
