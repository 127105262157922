import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_UPDATEINC: 'Req-UpdateInc'
export default function requestUpdateInc(incTypeOpt, custIncOpt, custPriceOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-UpdateInc',
		userTokenID: window.API?.user?.userToken,
		incTypeOpt,
		custIncOpt,
		custPriceOpt,
	});
}
