import sendRequest from '..';
import { getClerkEndpoint, getClerkEndpointStrict } from '../utils';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import ClerkActions from 'store/actions/clerk';

//REQ_AUCTION_STATE: 'Req-State'
export default function requestState() {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-State',
		userTokenID: window.API?.user?.userToken,
	})
		.then(response => {
			const { data } = response;
			console.debug('Req-State success', data);
			store.dispatch(AuctionActions.updateAuctionState(data));
			return data;
		})
		.catch(error => {
			console.debug('Req-State failure', error);
			return error;
		});
}

export function requestTestClerkPort() {
	return sendRequest(getClerkEndpointStrict(), {
		Action: 'Req-State',
		userTokenID: window.API?.user?.userToken,
	})
		.then(response => {
			const { data } = response;
			console.log(`[CLERK PORT ${window.API.clerkPort} OK]`);
			store.dispatch(
				ClerkActions.setPortMessage({
					showMessage: true,
					success: true,
					clerkPort: window.API.clerkPort,
				})
			);
			console.debug('Req-State success', data);
			store.dispatch(AuctionActions.updateAuctionState(data));
		})
		.catch(error => {
			console.log(
				`[CLERK PORT ${window.API.clerkPort} FAILED, REVERTING TO DEFAULT]`
			);
			store.dispatch(
				ClerkActions.setPortMessage({
					showMessage: true,
					success: false,
					clerkPort: window.API.clerkPort,
				})
			);
			window.API.clerkPort = null;
			requestState();
		});
}
