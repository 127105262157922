import { UPDATE_CONFIG, RESET_CONFIG } from 'store/actions/config';

const initialState = {};

export default function config(state = initialState, action) {
	switch (action.type) {
		case UPDATE_CONFIG:
			const { data } = action;
			if (!data) return;
			if (!Object.keys(state).length) return data;

			let newState = Object.assign({}, state);

			function merge(result, newProp) {
				Object.keys(newProp).forEach(key => {
					if (!result[key] || typeof newProp[key] !== 'object') {
						result[key] = newProp[key];
						return;
					}
					merge(result[key], newProp[key]);
				});

				return result;
			}

			return merge(newState, data);

		case RESET_CONFIG:
			return initialState;

		default:
			return state;
	}
}
