import { useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSlidersH,
	faTimes,
	faSearch,
	faUndo,
} from '@fortawesome/pro-light-svg-icons';

import styles from './Filter.module.scss';

export const Filter = props => {
	const [isOpen, setIsOpen] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const { searchValue, setSearchValue, filterValue, setFilterValue } = props;

	const onCloseClick = () => {
		if (showSearch) setShowSearch(false);
		else setIsOpen(false);
	};

	return (
		<div
			className={classNames(styles.filter, {
				[styles.closed]: true,
				[styles.open]: false,
			})}
		>
			{!isOpen && (
				<button className={styles.openbutton} onClick={() => setIsOpen(true)}>
					<FontAwesomeIcon icon={faSlidersH} />
				</button>
			)}
			{isOpen && (
				<div className={styles.open}>
					{!showSearch && (
						<>
							<button
								className={classNames(styles.filterbutton, {
									[styles.active]: filterValue === 'all',
								})}
								onClick={() => setFilterValue('all')}
							>
								All
							</button>
							<button
								className={classNames(styles.filterbutton, {
									[styles.active]: filterValue === 'wishlist',
								})}
								onClick={() => setFilterValue('wishlist')}
							>
								Wishlist
							</button>
							<button
								className={classNames(styles.filterbutton, {
									[styles.active]: filterValue === 'autobid',
								})}
								onClick={() => setFilterValue('autobid')}
							>
								Autobid
							</button>
							<button
								className={styles.searchbutton}
								onClick={() => setShowSearch(true)}
							>
								<FontAwesomeIcon icon={faSearch} />
							</button>
						</>
					)}
					{showSearch && (
						<div>
							<input
								className={styles.search}
								placeholder="Search..."
								value={searchValue}
								onChange={event => setSearchValue(event.target.value)}
							/>
						</div>
					)}
					{searchValue && (
						<button
							className={styles.closebutton}
							onClick={() => setSearchValue('')}
						>
							<FontAwesomeIcon icon={faUndo} />
						</button>
					)}
					<button className={styles.closebutton} onClick={() => onCloseClick()}>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</div>
			)}
		</div>
	);
};
