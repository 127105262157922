import { useState } from 'react';
import { connect } from 'react-redux';
import { getLocaleDate } from 'utils/utils';
import GoLink from './goLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendar,
	faTag,
	faWallet,
	faLaptopCode,
	faLink,
	faUser,
	faEdit,
} from '@fortawesome/pro-solid-svg-icons';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';

const AuctionInfo = props => {
	const [copyAlertTimeout, setCopyAlertTimeout] = useState(null);
	const [showCopyAlert, setShowCopyAlert] = useState(null);

	const copyToClipboard = (string, index) => {
		clearTimeout(copyAlertTimeout);
		setShowCopyAlert(null);
		navigator.clipboard.writeText(string);
		setShowCopyAlert(index);
		setCopyAlertTimeout(setTimeout(() => setShowCopyAlert(null), 2000));
	};

	const date = props?.auctionDate ? new Date(props?.auctionDate) : '';
	const endDate = props?.auctionEndDate
		? new Date(props?.auctionEndDate)
		: '';

	const goAuctionID = props?.goID;
	const iFrameCode = `<div style="margin-top: 10%; padding-bottom: 40%; position: relative; height: 0; overflow: hidden; max-width: 100%;"><iframe src="https://fmlivesb.stream.bid/ant/playfmdemo_offset.html?streamID=${props.streamID}" width="100%" height="400" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>`;
	const uploadTime =
		props?.adminData?.lotUPTS &&
		new Date(props?.adminData?.lotUPTS).toLocaleTimeString();
	const uploadDate =
		props?.adminData?.lotUPTS &&
		new Date(props?.adminData?.lotUPTS).toLocaleDateString();

	return (
		<div className="addauction__complete__row-2 regular-16-font-size">
			<section>
				<FontAwesomeIcon
					className="icon border-accent-color-1 accent-color-1"
					icon={faUser}
				/>
				<div>
					<p className="title">
						{props?.specialistOpt
							? props?.specialistOpt
							: props?.config?.noSpecialist}
					</p>
					<p>{props?.specialistEmailOpt}</p>
				</div>
			</section>
			<section>
				<FontAwesomeIcon
					className="icon border-accent-color-1 accent-color-1"
					icon={faCalendar}
				/>
				{!endDate && (
					<div>
						<p className="title">{props?.config?.date}</p>
						<p>{getLocaleDate(date, props?.timeZone)}</p>
					</div>
				)}
				{endDate && (
					<div>
						<p className="title">{props?.config?.date}</p>
						<p>{getLocaleDate(date, props?.timeZone)} to</p>
						<p>{getLocaleDate(endDate, props?.timeZone)}</p>
					</div>
				)}
			</section>
			<section>
				<FontAwesomeIcon
					className="icon border-accent-color-1 accent-color-1"
					icon={faTag}
				/>
				<div>
					<p className="title">{props?.config?.numberOfLots}</p>
					<p>{props?.lotCount}</p>
				</div>
			</section>
			<section>
				<FontAwesomeIcon
					className="icon border-accent-color-1 accent-color-1"
					icon={faWallet}
				/>
				<div>
					<p className="title">{props?.config?.currency}</p>
					<p>{props?.currency?.toUpperCase()}</p>
				</div>
			</section>
			{!goAuctionID && <GoLink />}

			{goAuctionID && (
				<section>
					<FontAwesomeIcon
						className="icon border-accent-color-1 accent-color-1"
						icon={faLaptopCode}
					/>
					<div>
						<p className="title">{props?.config?.auctionID}</p>
						<p>
							{goAuctionID}
							<FontAwesomeIcon
								className="icon accent-color-1"
								onClick={() => copyToClipboard(goAuctionID, 1)}
								icon={faCopy}
							/>
						</p>
						{showCopyAlert === 1 && (
							<div className="copy-alert small-12-font-size">
								{props?.config?.copiedToClipboard}
							</div>
						)}
					</div>
				</section>
			)}
			{goAuctionID && (
				<section>
					<FontAwesomeIcon
						className="icon border-accent-color-1 accent-color-1"
						icon={faLink}
					/>
					<div>
						<p className="title">{props?.config?.iframeCode}</p>
						<p>
							{`<div style="margin-top: 10%; padding-bottom: 40%; posi...`}
							<FontAwesomeIcon
								className="icon accent-color-1"
								onClick={() => copyToClipboard(iFrameCode, 2)}
								icon={faCopy}
							/>
						</p>
					</div>
					{showCopyAlert === 2 && (
						<div className="copy-alert small-12-font-size">
							{props?.config?.copiedToClipboard}
						</div>
					)}
				</section>
			)}
			{goAuctionID && (
				<section>
					<FontAwesomeIcon
						className="icon border-accent-color-1 accent-color-1"
						icon={faLink}
					/>
					<div>
						<p className="title">{props?.config?.streamID}</p>
						<p>
							{props.streamID}
							<FontAwesomeIcon
								className="icon accent-color-1"
								onClick={() =>
									copyToClipboard(props.streamID, 3)
								}
								icon={faCopy}
							/>
						</p>
					</div>
					{showCopyAlert === 3 && (
						<div className="copy-alert small-12-font-size">
							{props?.config?.copiedToClipboard}
						</div>
					)}
				</section>
			)}
			{props?.adminData && (
				<section>
					<FontAwesomeIcon
						className="icon border-accent-color-1 accent-color-1"
						icon={faEdit}
					/>
					<ul>
						<li>
							{props?.config?.lastEditDate} -{' '}
							{props?.adminData?.lotUPTS
								? `${uploadTime} ${uploadDate}`
								: props?.config?.notFound}
						</li>
						<li>
							{props?.config?.lastEditBy} -{' '}
							{props?.adminData?.lotUploadBy ||
								props?.config?.notFound}
						</li>
						<li>
							{props?.config?.fileName} -{' '}
							{props?.adminData?.lotFileName ||
								props?.config?.notFound}
						</li>
					</ul>
				</section>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		lotCount: state.adminpages?.auctionDetail?.lotCount,
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		auctionDate: state.adminpages?.auctionDetail?.auctionDate,
		auctionEndDate: state.adminpages?.auctionDetail?.auctionEndDate,
		adminData: state.adminpages?.auctionDetail?.extended?.adminData,
		goID: state.adminpages?.auctionDetail?.goID,
		location: state.adminpages?.auctionDetail?.location,
		currency: state.adminpages?.auctionDetail?.localisedData?.currency,
		specialistOpt: state.adminpages?.auctionDetail?.specialistOpt,
		specialistEmailOpt: state.adminpages?.auctionDetail?.specialistEmailOpt,
		timeZone: state.adminpages?.auctionDetail?.localisedData?.timeZone,
		streamID: state?.auction?.clerkDataOpt?.videoStream?.streamID,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(AuctionInfo);
