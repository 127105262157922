import { useContext } from 'react';

import { TableContext } from './Wrapper';
import styles from './Table.module.scss';

const Row = ({ item }) => {
	const { schema } = useContext(TableContext);
	const columnProps = Object.keys(schema);
	return (
		<tr className={styles.row}>
			{columnProps.map((column, index) => (
				<td key={`${item[column]}-${index}`}>{item[column]}</td>
			))}
		</tr>
	);
};

export default Row;
