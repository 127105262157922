import React, { Component } from 'react';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import 'styles/debugmodal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

class DebugModal extends Component {
	closeModal() {
		store.dispatch(AuctionActions.debugModalClose());
	}

	render() {
		let auction = this.props.clerk || this.props.bidder;

		return (
			<div className="debug__modal">
				<div className="shadow" onClick={this.closeModal}></div>
				<div className="modal">
					<button
						className="modal__closebutton"
						onClick={this.closeModal}
					>
						<FontAwesomeIcon
							className="modal__closebutton__icon"
							icon={faTimes}
						/>
					</button>

					<div className="modal__debug__output">
						<h2>Auction Debug</h2>
						<div className="modal__debug__output__item">
							<div className="title">Auction is Paused</div>
							<div>{auction.isPaused.toString()}</div>
						</div>
						<div className="modal__debug__output__item">
							<div className="title">Auction ID</div>
							<div>{auction.auctionID}</div>
						</div>
						<div className="modal__debug__output__item">
							<div className="title">Current Lot ID</div>
							<div className="title">
								{auction.currentLot.lotID}
							</div>
						</div>
						<div className="modal__debug__output__item">
							<div className="title">User Token</div>
							<div>{window.API.user.userToken}</div>
						</div>
						<div className="modal__debug__output__item">
							<div className="title">Bidder ID</div>
							<div>{window.API.user?.loginName}</div>
						</div>
						<div className="modal__debug__output__item">
							<div className="title">Current Offer</div>
							<div>
								{auction.currentLotOfferPriceOpt ||
									'No current offer'}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DebugModal;
