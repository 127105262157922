import { useState } from 'react';
import { connect } from 'react-redux';
import lotUpload from 'api/requests/fileTransfer/lotUpload';
import { capitaliseFirst } from 'utils/utils';
import DragDropInput from 'components/common/dragdropinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/pro-solid-svg-icons';
import {
	faFolderOpen,
	faTimes,
	faSpinner,
	faFilePlus,
	faFileEdit,
	faExclamationTriangle,
	faCheck,
} from '@fortawesome/pro-light-svg-icons';
import 'styles/adminpages/addauction/csv/csv.scss';

const Csv = props => {
	const [notification, setNotification] = useState('');
	const [file, setFile] = useState(null);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [uploadError, setUploadError] = useState(null);
	const [showSpinner, setShowSpinner] = useState(false);

	const { markAsComplete, auctionID, state, lotCount, auctionTitle } = props;

	const resetWarning = () => {
		setShowSpinner(false);
		setTimeout(() => {
			setUploadError(null);
		}, 2000);
	};

	const onActionClick = () => {
		if (!auctionID || !file) {
			setNotification(props?.config?.noFile);
			return;
		}

		if (!file.name.includes('.csv')) {
			setNotification(props?.config?.notCsv);
			return;
		}

		if (state !== 'draft') {
			setNotification(props?.config?.auctionRunningCsv);
			return;
		}
		setShowConfirmation(true);
	};

	const sendData = () => {
		const formData = new FormData();
		setShowSpinner(true);
		formData.append('csv', file);

		lotUpload(auctionID, formData)
			.then(data => {
				if (!data.length) {
					setUploadError(props?.config?.uploadFailed);
					resetWarning();
					return;
				}
				if (data[0].result === -70) {
					setUploadError(data[0].detail);
					setShowSpinner(false);
					return;
				}
				markAsComplete();
			})
			.catch(error => {
				setUploadError(props?.config?.uploadFailed);
				resetWarning();
			});
	};

	const getActionButton = () => {
		let content = (
			<div>
				{props?.config?.upload}{' '}
				<FontAwesomeIcon
					className="addauction__csv__row-1__action-icon"
					icon={faFileUpload}
				/>
			</div>
		);
		let className =
			!file || state !== 'draft'
				? 'addauction__csv__row-1__action bg-lost-color '
				: 'addauction__csv__row-1__action bg-accent-color-1';
		className = className + ' primary-color-light';

		return (
			<button className={className} onClick={onActionClick}>
				{content}{' '}
			</button>
		);
	};

	return (
		<div className="addauction__csv">
			{showConfirmation && (
				<div className="confirmation" data-testid="confirmation-modal">
					<div className="confirmation__popup bg-primary-color-light">
						<div
							className={`confirmation__header primary-color-light ${
								lotCount
									? 'bg-admin-accent-2'
									: 'bg-admin-accent-1'
							}`}
						>
							<div className="confirmation__header-title large-24-font-size">
								{lotCount
									? 'Overwrite Existing CSV'
									: 'Add New CSV'}
							</div>
							<div className="confirmation__header-icon">
								<FontAwesomeIcon
									className="icon extra-large-34-font-size"
									icon={lotCount ? faFileEdit : faFilePlus}
								/>
							</div>
						</div>

						<div className="confirmation__body large-20-font-size">
							{lotCount > 0 &&
								`${capitaliseFirst(
									props?.config?.overwriteCsvConfirm
								)} ${auctionTitle}?`}
							{!lotCount &&
								`${capitaliseFirst(
									props?.config?.addNewCsvConfirm
								)} ${auctionTitle}?`}

							<div
								className="confirmation__error warning-color large-20-font-size"
								style={{
									visibility: `${
										uploadError ? 'visible' : 'hidden'
									}`,
								}}
							>
								<FontAwesomeIcon
									className="icon large-24-font-size"
									icon={faExclamationTriangle}
								/>{' '}
								{uploadError}
							</div>
						</div>

						<div className="confirmation__footer">
							<button
								className="confirmation__control primary-color-light bg-admin-accent-1 border-secondary-color-dark"
								onClick={() => sendData()}
								data-testid="conrifmation-action"
							>
								{showSpinner && (
									<FontAwesomeIcon
										className="icon fa-spin large-24-font-size"
										icon={faSpinner}
									/>
								)}
								{!showSpinner && (
									<FontAwesomeIcon
										className="icon large-24-font-size"
										icon={faCheck}
									/>
								)}
								{props?.config?.confirm}
							</button>

							<button
								className="confirmation__control primary-color-light bg-warning-color border-secondary-color-dark"
								onClick={() => {
									setUploadError(null);
									setShowConfirmation(false);
								}}
							>
								<FontAwesomeIcon
									className="icon large-24-font-size"
									icon={faTimes}
								/>{' '}
								{props?.config?.cancel}
							</button>
						</div>
					</div>
				</div>
			)}

			<div className="addauction__csv__row-1">
				<div className="addauction__csv__row-1__header">
					<div className="addauction__csv__row-1__header__title">
						{props?.config?.addCsvDescription}
					</div>
				</div>

				{notification}
				{getActionButton()}
			</div>

			{file ? (
				<div className="addauction__csv__row-3 bg-primary-color-light">
					<div className="file">
						<FontAwesomeIcon
							className="file-icon"
							icon={faFolderOpen}
						/>
						{file.name}
					</div>
					<div className="spacer" />

					<button
						className="file__remove"
						onClick={() => setFile(null)}
					>
						<FontAwesomeIcon
							className="file__remove__icon"
							icon={faTimes}
						/>
					</button>
				</div>
			) : (
				<div className="addauction__csv__row-2 bg-primary-color-light">
					<DragDropInput
						onFileUpload={setFile}
						instruction={props?.config?.dragSelectInstruction}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		csvUpload: state.adminpages?.csvUpload, //check
		state: state.adminpages?.auctionDetail?.state,
		lotCount: state.adminpages?.auctionDetail?.lotCount,
		auctionTitle: state.adminpages?.auctionDetail?.auctionTitle,
		descriptionOpt: state.adminpages?.auctionDetail?.descriptionOpt,
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Csv);
