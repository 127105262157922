import useConfig from 'hooks/useConfig';
import classNames from 'classnames';
import styles from './lotcard.module.scss';

const Withdrawn = ({ lotNumber, title }) => {
	const config = useConfig();

	return (
		<div
			className={classNames(
				styles.container,
				styles.borderBottom,
				styles.withdrawn
			)}
			data-testid="lotcard"
		>
			<div className={styles.lotCard}>
				<div className={classNames(styles.image, styles.opacity)}>
					{config?.withdrawn}
				</div>
				<div className={styles.details}>
					<div className={styles.lotNumber}>
						{config?.lot} {lotNumber}
					</div>
				</div>
				<div className={styles.title}>{title}</div>
				<div className={styles.estimate}>{config?.lotWithdrawn}</div>
			</div>
		</div>
	);
};

export default Withdrawn;
