import { useState } from 'react';
import { connect } from 'react-redux';
import requestPlayVideo from 'api/requests/clerk/Req-PlayVideo';
import 'styles/clerkconsole/header/streaminfo.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faSpinner } from '@fortawesome/pro-regular-svg-icons';

const StreamInfo = props => {
	const streamEndpoint = props?.videoStream?.streamEndpoint;
	const streamCode = props?.streamCode;
	const [showCopySuccess, setShowCopySuccess] = useState(null);
	const { config } = props;

	const copyToClipboard = (string, id) => {
		navigator.clipboard.writeText(string);
		setShowCopySuccess(id);
		setTimeout(() => setShowCopySuccess(null), 1000);
	};

	return (
		<div className="header__streaminfo bg-primary-color-dark">
			<div className="streaminfo__header large-20-font-size primary-color-light">
				{config?.streamPanel}
			</div>
			<div className="streaminfo__items primary-color-dark">
				<div className="streaminfo__item bg-secondary-color-light">
					<div className="title">{config?.setVideoPlay}</div>
					<div className="start-stop">
						<button
							className="bg-accent-color-2"
							onClick={() => requestPlayVideo(true)}
						>
							{config?.play}
						</button>
						<button
							className="bg-warning-color primary-color-light"
							onClick={() => requestPlayVideo(false)}
						>
							{config?.stop}
						</button>
					</div>
				</div>
				{/* <div className="streaminfo__item  streampin bg-secondary-color-light">
					<div className="streampin-inner">
						<div className="title">Stream PIN: </div>
						<div className="value">
							{streamPin ? streamPin : 'Retrieving data'}
						</div>
						{streamPin && (
							<button
								className="request-pin bg-primary-color-dark primary-color-light"
								onClick={() =>
									requestNewStreamPin(props.auctionID)
								}
							>
								{streamPin ? (
									'New PIN'
								) : (
									<FontAwesomeIcon
										className="icon fa-spin"
										icon={faSpinner}
									/>
								)}
							</button>
						)}
						{!streamPin && (
							<FontAwesomeIcon
								className="icon fa-spin"
								icon={faSpinner}
							/>
						)}
					</div>
					<div className="streampin-link">
						This PIN can be used at{' '}
						<a
							href={`${
								window.location.origin
							}${'/ant/broadcast.html'}`}
							target="_blank"
						>{`${
							window.location.origin
						}${'/ant/broadcast.html'}`}</a>
					</div>
				</div> */}
				<div className="streaminfo__item bg-secondary-color-light">
					<div className="title">{config?.streamEndpoint}: </div>
					<div className="value" >
						{streamEndpoint ? streamEndpoint : 'Retrieving data'}
					</div>
					<div className="copy">
						{streamEndpoint && (
							<FontAwesomeIcon
								className="copy__icon large-20-font-size"
								onClick={() =>
									copyToClipboard(
										streamEndpoint,
										'streamEndpoint'
									)
								}
								icon={faCopy}
							/>
						)}
						{!streamEndpoint && (
							<FontAwesomeIcon
								className="icon fa-spin"
								icon={faSpinner}
							/>
						)}
						<div
							className={`copy__success small-12-font-size primary-color-dark bg-primary-color-light border-primary-color-dark ${showCopySuccess === 'streamEndpoint'
									? 'show'
									: ''
								}`}
						>
							{config?.copiedToClipboard}
						</div>
					</div>
				</div>
				<div className="streaminfo__item bg-secondary-color-light" >
					<div className="title">{config?.streamCode}: </div>
					<div className="value">
						{streamCode ? streamCode : 'Retrieving data'}
					</div>
					<div className="copy">
						{streamCode && (
							<FontAwesomeIcon
								className="copy__icon large-20-font-size"
								onClick={() =>
									copyToClipboard(streamCode, 'streamCode')
								}
								icon={faCopy}
							/>
						)}
						{!streamCode && (
							<FontAwesomeIcon
								className="icon fa-spin"
								icon={faSpinner}
							/>
						)}
						<div
							className={`copy__success small-12-font-size primary-color-dark bg-primary-color-light border-primary-color-dark ${showCopySuccess === 'streamCode' ? 'show' : ''
								}`}
						>
							{config?.copiedToClipboard}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		videoStream: state?.auction?.clerkDataOpt?.videoStream,
		config: state?.config,
		auctionID: state.auction.auctionID,
		streamCode: state?.auction?.clerkDataOpt?.videoStream?.streamCode,
	};
};

export default connect(mapStateToProps)(StreamInfo);
