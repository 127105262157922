import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_PASSBIDS: 'Req-PassBids'
export default function requestPassBids() {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-PassBids',
		userTokenID: window.API?.user?.userToken,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AdminPageActions.setPassBids(data));
	console.debug('Req-PassBids success', data);
};

const handleFailure = data => {
	console.debug('Req-PassBids failure', data);
};
