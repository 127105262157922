export const REQ_SENT = 'REQ_SENT';
export const REQ_REPLY = 'REQ_REPLY';
export const REQ_REMOVE = 'REQ_REMOVE';

export default class RequestActions {
	static requestSent(protocol, requestID, resetTimeout = 250) {
		return function (dispatch) {
			return dispatch({
				type: REQ_SENT,
				protocol,
				requestID,
				resetTimeout,
			});
		};
	}

	static requestReply(protocol, success) {
		return function (dispatch) {
			return dispatch({
				type: REQ_REPLY,
				protocol,
				success,
			});
		};
	}

	static requestRemove(requestID) {
		return function (dispatch) {
			return dispatch({
				type: REQ_REMOVE,
				requestID,
			});
		};
	}
}
