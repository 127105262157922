import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

import { getAuctionAutobids } from 'api/auction/autobids';

import Export from './export';

import { AccordionTable } from 'components/common/AccordionTable';

export default function Autobids() {
	const { showAutobids } = useSelector(state => state.showFlags);
	const { auctionTitle, auctionID } = useSelector(
		state => state.adminpages?.auctionDetail
	);

	const [initialLoadDone, setInitialLoadDone] = useState(false);
	const [list, setList] = useState([]);

	useEffect(() => {
		if (!auctionID) return;

		const fetchData = async () => {
			const data = await getAuctionAutobids(auctionID);
			if (!data.autoBidLots) return;
			const transformedData = data.autoBidLots.map(autoBidLot => {
				autoBidLot.autobidTotal = autoBidLot.autoBids.length;
				return autoBidLot;
			});
			setList(transformedData);
			setInitialLoadDone(true);
		};

		fetchData();
	}, [auctionID]);

	const schema = {
		lotNum: 'Lot number',
		lotID: 'Lot ID',
		title: 'Lot title',
		autobidTotal: 'Autobids',
	};

	const nestedProp = 'autoBids';

	const nestedSchema = {
		bidLimit: 'Bid limit',
		firstName: 'First name',
		secondName: 'Second name',
		primaryEmail: 'Email',
		paddleNumber: 'Paddle num',
		spendLimitApproved: 'Spend limit',
		updatedAt: 'Updated',
		userID: 'User ID',
	};

	if (!showAutobids && initialLoadDone)
		return (
			<div className="adminpages__home">
				<h3>Feature disabled</h3>
				<Link to={`/adminpages/edit`}>Back</Link>
			</div>
		);

	if (!list?.length && !initialLoadDone)
		return (
			<div className="auctionlist__loading">
				<FontAwesomeIcon className="fa-spin" icon={faSpinner} />
				<Link to={`/adminpages/edit`}>Back</Link>
			</div>
		);

	if (!list?.length && initialLoadDone)
		return (
			<div className="adminpages__home">
				<h3>No autobids yet for {auctionTitle}</h3>
				<Link to={`/adminpages/edit`}>Back</Link>
			</div>
		);

	return (
		<div className="adminpages__home">
			<h1>Autobids for {auctionTitle}</h1>
			<Link to={`/adminpages/edit`}>Back</Link>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					width: '100%',
					marginBottom: '1rem',
					paddingRight: '1rem',
				}}
			>
				{/* <Export registrations={list} auctionTitle={auctionTitle} /> */}
			</div>
			<AccordionTable
				data={list}
				schema={schema}
				nestedProp={nestedProp}
				nestedSchema={nestedSchema}
				defaultSortProp={'lotNum'}
			/>
		</div>
	);
}
