import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faCheck,
	faSpinner,
} from '@fortawesome/pro-regular-svg-icons';

import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import styles from './AutobidToggle.module.scss';

export const AutobidToggle = ({
	showAutobid,
	onAutobidToggle,
	autoBidOpt,
	onDelete,
}) => {
	const { setAutobid, autobid, manualBidding, stopAutobid } = useConfig();
	const featureEnabled = useSelector(state => state.showFlags?.showAutobid);
	const spinner = <FontAwesomeIcon className="fa-spin" icon={faSpinner} />;
	const check = <FontAwesomeIcon icon={faCheck} />;
	const times = <FontAwesomeIcon icon={faTimes} />;

	const { getFormattedCurrency } = useCurrency();
	const [deleteState, setDeleteState] = useState(stopAutobid);

	useEffect(() => setDeleteState(stopAutobid), [stopAutobid]);

	const resetDelete = () => setTimeout(() => setDeleteState(stopAutobid), 1500);

	const onDeleteClick = event => {
		setDeleteState(spinner);
		onDelete(event)
			.then(() => {
				setDeleteState(check);
				resetDelete();
			})
			.catch(() => {
				setDeleteState(times);
				resetDelete();
			});
	};

	if (!featureEnabled)
		return (
			<div className={styles.container}>
				<div className={styles.title}>{manualBidding}</div>
			</div>
		);

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				{autoBidOpt && (
					<span>
						{autobid}:{' '}
						<span className={styles.autobidvalue}>
							{getFormattedCurrency(autoBidOpt)}
						</span>
					</span>
				)}
				{!autoBidOpt && manualBidding}
			</div>
			{autoBidOpt && (
				<button
					className={styles.control}
					onClick={event => onDeleteClick(event)}
				>
					{deleteState}
				</button>
			)}
			{!autoBidOpt && (
				<button
					className={styles.control}
					onClick={event => onAutobidToggle(event)}
				>
					{setAutobid}
				</button>
			)}
		</div>
	);
};
