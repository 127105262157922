import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import BidderActions from 'store/actions/bidder';

//REQ_BIDINCREMENTS: 'Req-BidIncrements'
export default function requestBidIncrements(auctionIDOpt, bidIncOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-BidIncrements',
		userTokenID: window.API?.user?.userToken,
		auctionIDOpt,
		bidIncOpt,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	console.debug('Req-BidIncrements success', data);
	if (
		window.API.user?.userPerm?.indexOf('root') !== -1 ||
		window.API.user?.userPerm?.indexOf('admin') !== -1
	) {
		store.dispatch(AdminPageActions.updateAuctionIncrements(data));
	}
	if (window.API.user?.userPerm?.indexOf('bidder') !== -1) {
		store.dispatch(BidderActions.updateAuctionIncrements(data));
	}
};

const handleFailure = data => {
	console.debug('Req-BidIncrements failure', data);
};
