import Currentlot from './currentlot';
import './currentlot.scss';
import BidHistory from '../bidhistory/bidhistory';
import './.bidhistory.scss';
import SBButton from '../managementpanel/bidselector/sbbutton';
import './sbbutton.scss';
import CurrentBid from '../managementpanel/currentbid';
import './currentbid.scss';
import AskingPrice from '../managementpanel/askingprice/askingprice';
import askingPriceStyles from './askingprice.module.scss';
import './auctioneer.scss';

const Auctioneer = () => {
	return (
		<div className="auctioneer">
			<div className="quadrant quadrant-1">
				<Currentlot />
			</div>
			<div className="quadrant quadrant-2">
				<BidHistory />
			</div>
			<div className="quadrant quadrant-3">
				<SBButton />
			</div>
			<div className="quadrant quadrant-4">
				<CurrentBid />
				<AskingPrice styles={askingPriceStyles} />
			</div>
		</div>
	);
};

export default Auctioneer;
