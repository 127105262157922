import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import BidButton from 'components/bidderinterface/biddingpanel/bidbutton/bidbutton';
import Flipdater from 'components/common/flipdater';
import styles from './biddingpanel.module.scss';
import { isValidOfferPrice } from 'utils/utils';

export const ManualMode = () => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const askingPrice = useSelector(
		state => state.auction?.currentOfferOpt?.offerPrice
	);
	const isLocked = useSelector(
		state => state.auction?.currentLotDetailOpt?.isLocked
	);

	const validOffer = isValidOfferPrice(askingPrice);

	return (
		<div className={styles.manual}>
			<div className={styles.askingprice}>
				<div className={styles.askingpricetitle}>{config?.askingPrice}</div>
				<div className={styles.askingpricevalue}>
					{validOffer && (
						<Flipdater value={getFormattedCurrency(askingPrice)} right />
					)}
					{!validOffer && !isLocked && (
						<FontAwesomeIcon
							className="icon fa-spin large-24-font-size"
							icon={faSpinner}
						/>
					)}
				</div>
			</div>
			<BidButton />
		</div>
	);
};
