import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import VideoActions from 'store/actions/video';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import 'styles/common/AntMediaSB.scss';

const AntMediaSB = props => {
	const [isPlaying] = useState(true);
	const videoURL = props?.videoURLOpt && props?.videoURLOpt[0];
	let videoRef = null;
	useEffect(() => {
		if (!videoURL) return;

		const startIndex = videoURL?.lastIndexOf('id=');
		const lastIndex = videoURL?.lastIndexOf('&playOrder');
		if (!startIndex || !lastIndex) return;

		const streamId = videoURL.slice(startIndex + 3, lastIndex);
		initializeWebRTCPlayer(streamId);
		store.dispatch(VideoActions.updateIsMuted(videoRef.muted));
		// eslint-disable-next-line
	}, [videoURL]);

	useEffect(() => {
		if (props?.isPaused) videoRef.pause();
		else videoRef?.play().catch(error => console.debug(error));
		// eslint-disable-next-line
	}, [props?.isPaused]);

	useEffect(() => {
		videoRef.muted = props?.isMuted;
		// eslint-disable-next-line
	}, [props?.isMuted]);

	const initializeWebRTCPlayer = (
		streamId,
		token,
		subscriberId,
		subscriberCode,
		noStreamCallback
	) => {
		const pc_config = {
			iceServers: [
				{
					urls: 'turn:18.200.164.64:3478',
					username: 'username',
					credential: 'password',
				},
			],
		};
		const sdpConstraints = {
			OfferToReceiveAudio: true,
			OfferToReceiveVideo: true,
		};
		const mediaConstraints = {
			video: false,
			audio: false,
		};

		const websocketURL =
			'wss://streamedge.stream.bid:5443/WebRTCAppEE/websocket';

		let webRTCAdaptor = new WebRTCAdaptor({
			websocket_url: websocketURL,
			mediaConstraints: mediaConstraints,
			peerconnection_config: pc_config,
			sdp_constraints: sdpConstraints,
			remoteVideoId: 'webrtc__player',
			debug: false,
			isPlayMode: true,
			bandwidth: 900,
			callback: (info, data) => {
				if (info === 'pong') return;
				if (info === 'browser_screen_share_supported') return;
				if (info === 'initialized') {
					console.debug('[WEBRTC] initialized');
					webRTCAdaptor.play(
						streamId,
						token,
						subscriberId,
						subscriberCode
					);
					webRTCAdaptor.getStreamInfo(streamId);
				} else if (info === 'streamInformation') {
					console.debug('[WEBRTC] stream information');
				} else if (info === 'play_started') {
					console.debug('[WEBRTC] play_started');
					//const streamRes = window.innerWidth <= 768 ? 360 : 480;
					//webRTCAdaptor.forceStreamQuality(streamId, streamRes);
				} else {
					console.debug('[WEBRTC]', info, data);
				}
			},
			callbackError: (error, data) => {
				console.debug('[WEBRTC] webRTCAdaptor error:', error, data);
				if (error === 'no_stream_exist') {
					webRTCAdaptor.closeWebSocket();
					webRTCAdaptor = null;
					store.dispatch(
						AuctionActions.updateAuctionState({
							sourceOpt: null,
							videoURLOpt: [],
						})
					);
				}
			},
		});
	};

	// const onToggleMute = () => {
	// 	store.dispatch(VideoActions.updateIsMuted(!props?.isMuted));
	// };

	const onPlayPauseEvent = () => {
		store.dispatch(VideoActions.updateIsPaused(videoRef.paused));
	};

	// const togglePlayPause = () => {
	// 	store.dispatch(VideoActions.updateIsPaused(!props?.isPaused));
	// };

	const onVolumeChange = () => {
		if (videoRef.muted !== props?.isMuted) {
			store.dispatch(VideoActions.updateIsMuted(videoRef.muted));
		}
	};

	return (
		<div className={`${props.classes} webrtc__container`}>
			{props?.overlay && (
				<div
					className={props.overlay}
					onClick={event =>
						props?.showMainVideo
							? videoRef
									.play()
									.catch(error => console.debug(error))
							: props?.overlayClick(event)
					}
				>
					{!isPlaying && (
						<FontAwesomeIcon
							className={`${props.overlay} icon`}
							icon={faPlayCircle}
						/>
					)}
				</div>
			)}

			<video
				id="webrtc__player"
				ref={video => (videoRef = video)}
				controls
				controlsList="nofullscreen"
				autoPlay
				playsInline
				muted={props?.isMuted}
				onPlay={() => onPlayPauseEvent()}
				onPause={() => onPlayPauseEvent()}
				onVolumeChange={() => onVolumeChange()}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		isMuted: state.video?.isMuted,
		isPaused: state.video?.isPaused,
		videoURLOpt: state?.auction?.videoStream?.videoURLOpt,
		tickerMessageOpt: state?.auction?.tickerMessageOpt,
		showMainVideo: state?.bidder?.showMainVideo,
	};
};

export default connect(mapStateToProps)(AntMediaSB);
