import { useSelector } from 'react-redux';
import requestDefaultsProtocol from 'api/requests/common/Req-DefaultsProtocol';
import Toggle from 'components/common/Toggle/Toggle';
import styles from './AutoAccept.module.scss';

const AutoAccept = () => {
	const autoAcceptSB = useSelector(
		state => state.adminpages?.defaultsProtocol?.autoAcceptSB
	);
	const showAutoAccept = useSelector(
		state => state.showFlags?.showAutoAccept
	);

	const config = useSelector(state => state?.config);
	const valueOpt = autoAcceptSB?.valueOpt;
	const onToggle = event => {
		event.preventDefault();

		const newValue = !valueOpt;

		const data = {
			autoAcceptSB: { valueOpt: newValue, update: true },
		};

		requestDefaultsProtocol(data);
	};

	if (!showAutoAccept) return null;

	return (
		<div className={styles.container}>
			<div className={styles.title}>{config?.autoAccept}</div>
			<Toggle isActive={valueOpt} onClick={event => onToggle(event)} />
		</div>
	);
};

export default AutoAccept;
