import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import Flipdater from 'components/common/flipdater';
import styles from './Info.module.scss';

const Info = ({
	showIncrements,
	currentIncrement,
	showAutobid,
	autoBidOpt,
	limit,
}) => {
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	if (showIncrements)
		return (
			<div className={styles.incrementInfo}>
				{' '}
				{config?.incrementsOf}
				{': '}
				<span className={styles.flipdaterContainer}>
					<Flipdater
						value={getFormattedCurrency(currentIncrement)}
						left={true}
					/>
				</span>
			</div>
		);

	if (showAutobid && autoBidOpt)
		return (
			<div className={styles.incrementInfo}>
				{config?.autobidSet}:{' '}
				<span className={styles.flipdaterContainer}>
					<Flipdater
						value={getFormattedCurrency(limit)}
						left={true}
					/>
				</span>
			</div>
		);

	return false;
};

export default Info;
