import requestUpdateInc from 'api/requests/clerk/Req-UpdateInc';
import { useDispatch } from 'react-redux';
import AuctionActions from 'store/actions/auction';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSpinner,
	faCheck,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const useSetButton = (input, setActiveButton) => {
	const [buttonSetIcon, setButtonSetIcon] = useState(null);
	const [showSetSuccess, setShowSetSuccess] = useState(false);
	const [showSetError, setShowSetError] = useState(false);
	const dispatch = useDispatch();
	const { setBidIncrement } = AuctionActions;

	const onSetClick = () => {
		if (!input) return;
		const incTypeOpt = null;
		const custIncOpt = input;
		dispatch(setBidIncrement({ incTypeOpt, custIncOpt }));
		setButtonSetIcon(
			<FontAwesomeIcon className="fa-spin" icon={faSpinner} />
		);
		requestUpdateInc(incTypeOpt, custIncOpt, null)
			.then(response => {
				const { data } = response;
				if (data.result > 100) {
					onError();
					return;
				}
				onSuccess();
				return data;
			})
			.catch(() => onError());
	};

	const onSuccess = () => {
		setShowSetSuccess(true);
		setButtonSetIcon(<FontAwesomeIcon icon={faCheck} />);
		setActiveButton(null);
		resetButton();
	};

	const onError = () => {
		setShowSetError(true);
		setButtonSetIcon(<FontAwesomeIcon icon={faTimes} />);
		resetButton();
	};

	const resetButton = () => {
		setTimeout(() => {
			setShowSetSuccess(false);
			setShowSetError(false);
			setButtonSetIcon(null);
		}, 800);
	};

	return { onSetClick, buttonSetIcon, showSetSuccess, showSetError };
};

export default useSetButton;
