import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { useSearchFilterLots } from '../useSearchFilterLots';
import { useVirtualList } from '../useVirtualList';
import useConfig from 'hooks/useConfig';
import BidderActions from 'store/actions/bidder';
import { Filters } from './Filters';
import { SearchPanel } from './SearchPanel';
import { Lots } from './Lots';
import styles from './Catalogue.module.scss';

export const Catalogue = () => {
	const dispatch = useDispatch();
	const { exploreCatalogue } = useConfig();

	const searchFilterLots = useSearchFilterLots();
	const { searchedFilteredList } = searchFilterLots;
	const virtualList = useVirtualList(searchedFilteredList, 9, false);

	return (
		<div className={styles.catalogue}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div className={styles.spacer}></div>
					<div className={styles.explore}>{exploreCatalogue}</div>
					<div className={styles.spacer}></div>
					<button
						className={styles.closebutton}
						onClick={() => dispatch(BidderActions.updateBidderLayout(1))}
					>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</div>
				<div className={styles.filters}>
					<Filters
						virtualList={virtualList}
						searchFilterLots={searchFilterLots}
					/>
				</div>
			</div>
			<div className={styles.sidebar}>
				<SearchPanel searchFilterLots={searchFilterLots} />
			</div>
			<div className={styles.lots}>
				<Lots virtualList={virtualList} />
			</div>
		</div>
	);
};
