import { useSelector } from 'react-redux';
import { useState } from 'react';
import requestDefaultsProtocol from 'api/requests/common/Req-DefaultsProtocol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faSpinner,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const SaleNote = props => {
	const saleNotePrompt = useSelector(
		state => state.adminpages?.defaultsProtocol?.saleNotePrompt
	);
	const config = useSelector(state => state?.config);
	const loadingIcon = (
		<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
	);
	const successIcon = <FontAwesomeIcon className="icon" icon={faCheck} />;
	const failureIcon = <FontAwesomeIcon className="icon" icon={faTimes} />;
	const valueOpt = saleNotePrompt?.valueOpt;
	const [icon, setIcon] = useState(null);

	const onToggleClick = event => {
		event.preventDefault();
		showLoading();

		const data = {
			saleNotePrompt: { valueOpt: !valueOpt, update: true },
		};

		requestDefaultsProtocol(data)
			.then(data => {
				if (data.result > 100 || data?.isAxiosError) {
					showFailure();
					return;
				}
				showSuccess();
			})
			.catch(() => showFailure());
	};

	const showLoading = () => setIcon(loadingIcon);

	const showSuccess = () => {
		setIcon(successIcon);
		reset();
	};

	const showFailure = () => {
		setIcon(failureIcon);
		reset();
	};

	const reset = () => {
		setTimeout(() => setIcon(null), 750);
	};

	const buttonText = valueOpt ? config?.enabled : config?.disabled;
	return (
		<div className="row">
			<div className="salenote">
				<div className="large-20-font-size">
					{config?.saleNoteRequired}
				</div>
				<button
					className={`salenote-toggle ${
						valueOpt ? 'enabled' : 'disabled'
					}`}
					onClick={event => onToggleClick(event)}
				>
					{icon ? icon : buttonText}
				</button>
			</div>
		</div>
	);
};

export default SaleNote;
