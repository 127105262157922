import sendRequest from '..';
import { getCommonEndpoint } from '../utils';
import { storeLatency, getLatency } from '../latency';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import RequestActions from 'store/actions/requests';

//REQ_ONBID: 'Req-OnBid'
export default function requestOnBid(offerID) {
	return sendRequest(getCommonEndpoint(), {
		Action: 'Req-OnBid',
		userTokenID: window.API?.user?.userToken,
		offerID,
		tS: Date.now(),
		lastLatency: getLatency(),
	})
		.then(response => {
			const { data } = response;
			if (data.tS) storeLatency(data.tS);
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess();
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = () => {
	store.dispatch(RequestActions.requestReply('REQ_ONBID', true));
	console.debug('Req-OnBid success');
};

const handleFailure = data => {
	store.dispatch(AuctionActions.infoMessage(data.detail));
	console.debug('Req-OnBid failure', data);
	setTimeout(() => {
		store.dispatch(AuctionActions.infoMessage(null));
	}, 4000);
};
