import { useContext } from 'react';

import { AccordionContext } from './Wrapper';
import Row from './Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-regular-svg-icons';
import styles from './AccordionTable.module.scss';

export function AccordionTable() {
	const { list, schema, onSortColumn } = useContext(AccordionContext);
	const columnProps = Object.keys(schema);

	const renderRows = () => {
		if (list.length === 0) {
			return (
				<tr>
					<td>No matches</td>
				</tr>
			);
		}
		return list.map(item => {
			return <Row item={item} key={item.lotID} />;
		});
	};

	return (
		<div className={styles.tableWrapper}>
			<table className={styles.table} cellSpacing="0">
				<thead className="bg-secondary-color-dark">
					<tr>
						<td></td>
						{columnProps.map(column => (
							<th key={column} onClick={() => onSortColumn(column)}>
								{schema[column]}
								<FontAwesomeIcon className={styles.sortIcon} icon={faSort} />
							</th>
						))}
					</tr>
				</thead>
				<tbody>{renderRows()}</tbody>
			</table>
		</div>
	);
}
