import { useSelector } from 'react-redux';
import styles from './AskingPrice.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import Flipdater from 'components/common/flipdater';
import { isValidOfferPrice } from 'utils/utils';

const AskingPrice = ({ showAutobid }) => {
	const askingPrice = useSelector(
		state => state.auction?.currentOfferOpt?.offerPrice
	);
	const config = useConfig();
	const { getFormattedCurrency } = useCurrency();
	const validOffer = isValidOfferPrice(askingPrice);

	return (
		<div className={styles.askingPrice}>
			<div className={styles.title}>{config?.askingPrice}</div>
			<div className={styles.value}>
				{validOffer && (
					<Flipdater
						value={getFormattedCurrency(askingPrice)}
						right={true}
					/>
				)}
				{!validOffer && (
					<FontAwesomeIcon
						className="icon fa-spin regular-16-font-size"
						icon={faSpinner}
					/>
				)}
			</div>
		</div>
	);
};

export default AskingPrice;
