import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

import { getAuctionRegistrations } from 'api/auction/registration';
import Export from './export';

import { Table } from 'components/common/Table';

export default function Registrations() {
	const showRegistrations = useSelector(
		state => state.showFlags?.showRegistrations
	);
	const { auctionTitle, auctionID } = useSelector(
		state => state.adminpages?.auctionDetail
	);

	const [initialLoadDone, setInitialLoadDone] = useState(false);
	const [list, setList] = useState([]);

	useEffect(() => {
		if (!auctionID) return;

		const fetchData = async () => {
			const data = await getAuctionRegistrations(auctionID);
			setList(data);
			setInitialLoadDone(true);
		};

		fetchData();
	}, [auctionID]);

	const schema = {
		approved: 'Approved',
		email: 'Email',
		firstName: 'First name',
		secondName: 'Second name',
		paddleNum: 'Paddle num',
		currency: 'Currency',
		spendLimit: 'Spend limit',
		userID: 'User Id',
	};

	if (!showRegistrations && initialLoadDone)
		return (
			<div className="adminpages__home">
				<h3>Feature disabled</h3>
				<Link to={`/adminpages/edit`}>Back</Link>
			</div>
		);

	if (!list?.length && !initialLoadDone)
		return (
			<div className="auctionlist__loading">
				<FontAwesomeIcon className="fa-spin" icon={faSpinner} />
				<Link to={`/adminpages/edit`}>Back</Link>
			</div>
		);

	if (!list?.length && initialLoadDone)
		return (
			<div className="adminpages__home">
				<h3>No users registered yet for {auctionTitle}</h3>
				<Link to={`/adminpages/edit`}>Back</Link>
			</div>
		);

	return (
		<div className="adminpages__home">
			<h1>Registrations for {auctionTitle}</h1>
			<Link to={`/adminpages/edit`}>Back</Link>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					width: '100%',
					marginBottom: '1rem',
					paddingRight: '1rem',
				}}
			>
				<Export registrations={list} auctionTitle={auctionTitle} />
			</div>
			<Table data={list} schema={schema} defaultSortProp={'email'} />
		</div>
	);
}
