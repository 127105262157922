import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Nav from './nav/nav';
import Header from './header/header';
import Controls from './controls/controls';
import Search from './search/search';
import Lots from './lots/lots';
import ItemDetail from './itemdetail/itemdetail';
import './catalogue.scss';

const Catalogue = props => {
	const [allLotsLoaded, setAllLotsLoaded] = useState(false);
	const [showPageForIndex, setShowPageForIndex] = useState(null);
	const [paginatedLotList, setPaginatedLotList] = useState([]);
	const [unpaginatedLotList, setUnpaginatedLotList] = useState([]);
	const [availableCategories, setAvailableCategories] = useState([]);
	const [activeCategories, setActiveCategories] = useState([]);
	const [statusFilter, setStatusFilter] = useState('all');
	const [detailSort, setDetailSort] = useState('lotNumber');
	const [lotsPerPage, setLotsPerPage] = useState(18);
	const [keywordSearch, setKeywordSearch] = useState('');
	const [priceBoundLow, setPriceBoundLow] = useState('');
	const [priceBoundHigh, setPriceBoundHigh] = useState('');
	const [page, setPage] = useState(1);
	const currentLotIndex = props.currentLot?.lotIndex;

	useEffect(() => {
		// if (allLotsLoaded) return;
		if (props?.lotList?.length === props?.lotCount) {
			setupAvailableCategories();
			setNewLotList();
			setAllLotsLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allLotsLoaded, props?.lotList, props?.lotCount]);

	useEffect(() => {
		// if (!allLotsLoaded) return;
		setNewLotList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		statusFilter,
		detailSort,
		keywordSearch,
		priceBoundLow,
		priceBoundHigh,
		page,
		activeCategories,
	]);

	useEffect(() => {
		if (!allLotsLoaded) return;
		setPage(1);
		setNewLotList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lotsPerPage]);

	const setupAvailableCategories = () => {
		let categories = [];
		props.lotList.forEach(lot => {
			const lotCategory = lot.catalogueLot.category;
			if (categories.indexOf(lotCategory) !== -1) return;
			categories.push(lotCategory);
		});
		setActiveCategories(categories);
		setAvailableCategories(categories);
	};

	const setNewLotList = () => {
		let newLotList = [...props.lotList];

		newLotList = applyCategoryFilter(newLotList);
		newLotList = applyKeywordSearch(newLotList);
		newLotList = applyLowPriceBoundFilter(newLotList);
		newLotList = applyHighPriceBoundFilter(newLotList);
		newLotList = applyStatusFilter(newLotList);
		newLotList = applyDetailSort(newLotList);

		setUnpaginatedLotList(newLotList);

		newLotList = applyPagination(newLotList);

		setPaginatedLotList(newLotList);
	};

	const applyCategoryFilter = newLotList => {
		return [...newLotList].filter(lot => {
			return activeCategories.indexOf(lot.catalogueLot.category) !== -1;
		});
	};

	const applyPagination = newLotList => {
		let lowBound = page * lotsPerPage - lotsPerPage + 1;
		let highBound = lowBound + lotsPerPage - 1;
		const currentLotIndex = props?.currentLot?.lotIndex;

		// at startup make sure the active page has the current lot on it
		if (!allLotsLoaded && currentLotIndex > highBound) {
			let page = 1;
			while (currentLotIndex > highBound) {
				highBound += lotsPerPage;
				page++;
				setPage(page);
			}
		}

		return [...newLotList].filter(
			(lot, index) => index + 1 >= lowBound && index + 1 <= highBound
		);
	};

	const applyStatusFilter = newLotList => {
		if (statusFilter === 'all') return newLotList;

		return [...newLotList].filter(lot => {
			const lotState = lot?.saleOutcomeOpt?.lotState;
			if (statusFilter === 'current' && lotState === 0) return true;
			if (statusFilter === 'sold' && lotState === 200) return true;
			if (statusFilter === 'passed' && lotState === 300) return true;
			return false;
		});
	};

	const applyDetailSort = newLotList => {
		switch (detailSort) {
			case 'lotNumber':
				return [...newLotList].sort((a, b) =>
					Number(a.catalogueLot.lotNumber) >
					Number(b.catalogueLot.lotNumber)
						? 1
						: -1
				);
			case 'lowEst':
				return [...newLotList].sort((a, b) =>
					a.catalogueLot.lowEst > b.catalogueLot.lowEst ? 1 : -1
				);
			case 'highEst':
				return [...newLotList].sort((a, b) =>
					a.catalogueLot.highEst < b.catalogueLot.highEst ? 1 : -1
				);
			case 'title':
				return [...newLotList].sort((a, b) =>
					a.catalogueLot.title > b.catalogueLot.title ? 1 : -1
				);
			default:
				return newLotList;
		}
	};

	const applyKeywordSearch = newLotList => {
		if (!keywordSearch) return newLotList;

		return [...newLotList].filter(lot => {
			const title = lot.catalogueLot.title.toLowerCase();
			if (title.indexOf(keywordSearch) !== -1) return true;

			const description = lot.catalogueLot.description.toLowerCase();
			if (description.indexOf(keywordSearch) !== -1) return true;

			const lotNum = lot.catalogueLot.lotNumber.toLowerCase();
			if (lotNum.indexOf(keywordSearch) !== -1) return true;

			return false;
		});
	};

	const applyLowPriceBoundFilter = newLotList => {
		if (!priceBoundLow) return newLotList;
		return [...newLotList].filter(
			lot => lot.catalogueLot.lowEst >= priceBoundLow
		);
	};

	const applyHighPriceBoundFilter = newLotList => {
		if (!priceBoundHigh) return newLotList;
		return [...newLotList].filter(
			lot => lot.catalogueLot.highEst <= priceBoundHigh
		);
	};

	const changePage = change => {
		if (change === -1) {
			if (page === 1) return;
			setPage(page - 1);
			return;
		}

		const lowBound = (page + 1) * lotsPerPage - lotsPerPage + 1;
		if (lowBound > unpaginatedLotList.length) return;
		setPage(page + 1);
	};

	const onLotCardClick = lotIndex => {
		setShowPageForIndex(lotIndex);
	};

	const onItemDetailChangePage = change => {
		const newLotIndex = showPageForIndex + change;
		const newLot = unpaginatedLotList?.find(
			lot => lot.catalogueLot.lotIndex === newLotIndex
		);
		if (!newLot) return;
		setShowPageForIndex(newLotIndex);
	};

	const config = props?.config;

	const navProps = {
		showPageForIndex,
		setShowPageForIndex,
		config,
	};
	const headerProps = { title: props.config?.exploreCatalogue };
	const controlProps = {
		showPageForIndex,
		statusFilter,
		setStatusFilter,
		detailSort,
		setDetailSort,
		lotsPerPage,
		setLotsPerPage,
		changePage,
		onItemDetailChangePage,
		config,
	};
	const searchProps = {
		showPageForIndex,
		keywordSearch,
		setKeywordSearch,
		priceBoundLow,
		setPriceBoundLow,
		priceBoundHigh,
		setPriceBoundHigh,
		availableCategories,
		activeCategories,
		setActiveCategories,
		config,
	};
	const lotsProps = {
		showPageForIndex,
		paginatedLotList,
		currentLotIndex,
		onLotCardClick,
		allLotsLoaded,
	};
	const itemDetailProps = {
		showPageForIndex,
	};

	return (
		<div className="catalogue">
			<Nav {...navProps} />
			<Header {...headerProps} />
			<Controls {...controlProps} />

			<div className="catalogue__row">
				<Search {...searchProps} />
				<Lots {...lotsProps} />
				<ItemDetail {...itemDetailProps} />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		lotList: state.auction.lotList,
		config: state?.config,
		currentLot: state.auction.currentLot,
		lotCount: state.auction.lotCount,
		currency: state.auction?.localisedData?.currency,
	};
};

export default connect(mapStateToProps)(Catalogue);
