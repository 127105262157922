import store from 'store/store';
import AuctionActions from 'store/actions/auction';
import ClerkActions from 'store/actions/clerk';

export const auctionState = msg => {
	console.debug(`[WSS] AuctionState`, msg);
	const size = new TextEncoder().encode(JSON.stringify(msg)).length;
	const kiloBytes = size / 1024;
	msg.packetSizeKB = kiloBytes;
	msg.receivedTimestamp = Date.now();
	store.dispatch(AuctionActions.updateAuctionState(msg));
};

export const bidUpdate = msg => {
	store.dispatch(AuctionActions.updateBid(msg));
};

export const bidNotify = msg => {
	store.dispatch(AuctionActions.bidNotify(msg));
};

export const bidActions = msg => {
	store.dispatch(ClerkActions.updateBidActions(msg));
};
