import { useSelector } from 'react-redux';
import styles from './CurrentLot.module.scss';

const CurrentLot = () => {
	const config = useSelector(state => state.config);

	return (
		<div className={styles.container}>
			<div className={styles.card}>{config?.currentLot}</div>
		</div>
	);
};

export default CurrentLot;
