import React, { useState } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import BidderActions from 'store/actions/bidder';
import VideoActions from 'store/actions/video';
import BidderAudioController from 'components/bidderinterface/bidderaudiocontroller/bidderaudiocontroller';
import ActionBar from 'components/bidderinterface/mobile/ActionBar/actionBar';
import MainPanel from 'components/bidderinterface/mobile/mainPanel/mainPanel';
import BiddingPanelMobile from 'components/bidderinterface/mobile/BiddingPanel/biddingPanel';
import Catalogue from 'components/bidderinterface/mobile/Catalogue/Catalogue';
import LotDetail from 'components/bidderinterface/mobile/lotDetail/lotDetail';
import WelcomeModal from 'components/bidderinterface/mobile/welcomeModal/welcomeModal';
import 'styles/bidderinterface/biddermobile/biddermobile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSignOut,
	faVolumeSlash,
	faVolume,
} from '@fortawesome/pro-light-svg-icons';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const BidderMobile = props => {
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const onLogoutClick = () => setShowLogoutModal(true);
	const onLogoutCancel = () => setShowLogoutModal(false);
	const onLogoutConfirm = () => window.API.onLogout();
	const toggleMute = () =>
		store.dispatch(VideoActions.updateIsMuted(!props.video.isMuted));
	const location = useLocation();
	const [lotdetailClass] = useState('open');

	useEffect(() => {
		store.dispatch(BidderActions.bidderOpenModal('welcome'));
	}, []);

	const onDetailPanelClick = () => {
		if (props.futureAutobidPanel === 'closed') return;
		store.dispatch(BidderActions.setFutureAutobidPanel('closed'));
	};

	useEffect(() => {
		let page = '';
		switch (location.pathname) {
			case '/bidderinterface/mobilecatalogue':
				page = 'catalogue';
				break;
			case '/bidderinterface/lotdetail':
				page = 'lotdetail';
				break;
			default:
				page = 'bidding';
		}
		store.dispatch(BidderActions.updateMobilePage(page));
	}, [location]);

	return (
		<div className="biddermobile">
			{props.modalOpen && props.modalOpen === 'welcome' && (
				<WelcomeModal />
			)}
			{showLogoutModal && (
				<div className="biddermobile__logoutcontainer bg-primary-color-light">
					<div className="biddermobile__logouttitle">
						{props?.config?.logoutConfirm}
					</div>
					<div className="biddermobile__logoutcontrols">
						<button
							className="biddermobile__logoutbutton cancel border-primary-color-dark bg-primary-color-light"
							onClick={() => onLogoutCancel()}
						>
							{props?.config?.back}
						</button>
						<button
							className="biddermobile__logoutbutton confirm border-primary-color-dark bg-primary-color-dark primary-color-light"
							onClick={() => onLogoutConfirm()}
						>
							{props?.config?.logout}
						</button>
					</div>
				</div>
			)}

			<BidderAudioController />
			<section className="biddermobile__header bg-primary-color-light">
				<div className="biddermobile__logo">
					<img
						src={`https://${window.location.hostname}/styling/ahlogo.png`}
						alt=""
					/>
				</div>

				<div className="biddermobile__topcontrols">
					<div
						className="biddermobile__mute"
						onClick={() => toggleMute()}
					>
						<FontAwesomeIcon
							className="icon"
							icon={
								props.video.isMuted ? faVolumeSlash : faVolume
							}
						/>
					</div>
					<div
						className="biddermobile__logout"
						onClick={() => onLogoutClick()}
					>
						<FontAwesomeIcon className="icon" icon={faSignOut} />
					</div>
				</div>
			</section>

			<ActionBar />
			<MainPanel />
			<Routes>
				<Route
					exact
					path=""
					element={
						<BiddingPanelMobile
							{...props}
							currentLot={props?.currentLot}
						/>
					}
				/>
				<Route path="mobilecatalogue" element={<Catalogue />} />

				<Route
					path="lotdetail"
					element={
						<div onClick={onDetailPanelClick}>
							<LotDetail classes={lotdetailClass} />
						</div>
					}
				/>
			</Routes>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		video: state.video,
		config: state.config,
		currentLot: state.auction?.currentLot,
		futureAutobidPanel: state.bidder?.futureAutobidPanel,
		autobidState: state.bidder?.autobidState,
		modalOpen: state.bidder?.modalOpen,
	};
};

export default connect(mapStateToProps)(BidderMobile);
