import ModalRow from './modalrow';

const Modal = props => {
	const target = props.saleOutcome.lots.find(
		outcome => outcome.lotNumber === props.showModalForLotNumber
	);

	const config = props?.config;
	const winningBidder = target?.sbWinningBidder;

	if (!target)
		return (
			<div className="auctionresults__modal__wrapper">
				<div
					className="auctionresults__shadow"
					onClick={() => props.modalClose()}
				></div>
				<div className="auctionresults__modal">{config?.noData}</div>
			</div>
		);

	return (
		<div className="auctionresults__modal__wrapper">
			<div
				className="auctionresults__shadow"
				onClick={() => props.modalClose()}
			></div>
			<div className="auctionresults__modal">
				<div className="auctionresults__modal__title">
					{config?.bidHistoryForLot} {target.lotNumber}
				</div>

				<div className="auctionresults__table__wrapper">
					<table className="auctionresults__table" cellSpacing="0">
						<thead>
							<tr>
								<th>{config?.name}</th>
								<th>{config?.bidderID}</th>
								<th>{config?.paddleNumber}</th>
								<th>{config?.bidPrice}</th>
								<th>{config?.timestamp}</th>
								<th>{config?.isClerkBid}</th>
								<th>{config?.isVoid}</th>
							</tr>
						</thead>
						<tbody>
							{target.sbAllBidders.map((row, index) => {
								console.log(winningBidder);
								const isWinner =
									winningBidder?.bidderID === row?.bidderID &&
									winningBidder?.bidPrice === row?.bidPrice &&
									winningBidder?.timestamp === row?.timestamp;
								return (
									<ModalRow
										{...row}
										key={index}
										isWinner={isWinner}
										config={config}
									/>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Modal;
