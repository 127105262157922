import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import ClerkActions from 'store/actions/clerk';

//REQ_UNDO: 'Req-Undo'
export default function requestUndo() {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-Undo',
		userTokenID: window.API?.user?.userToken,
	})
		.then(response => {
			store.dispatch(ClerkActions.setSaleControlReqInFlight(false));
		})
		.catch(error => console.debug(error));
}
