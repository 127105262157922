import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import requestAuctions from 'api/requests/common/Req-Auctions';
import requestBidIncrements from 'api/requests/common/Req-BidIncrements';
import Sidebar from 'components/adminpages/sidebar/sidebar';
import AddAuction from 'components/adminpages/addauction/addauction';
import Settings from 'components/adminpages/settings/settings';
import Users from 'components/adminpages/users/users';
import AddEditUser from 'components/adminpages/users/addedituser/addedituser';
import 'styles/adminpages/adminpages.scss';
import AuctionList from './home/auctionList';
import AuctionResults from 'components/common/auctionresults/auctionresults';
import Registrations from './registrations/registrations';
import Autobids from './autobids/autobids';
import PassBids from 'components/common/passbids';

const AdminPages = props => {
	useEffect(() => {
		if (!window?.API?.user) return;
		requestAuctions();
		store.dispatch(AdminPageActions.updateAuctionDetail(null));
	}, []);

	// useEffect(() => {
	// 	console.log('LOGIN AUCTION ID', props?.auctionID);
	// 	if (window?.API?.user) requestAuctions();
	// }, props?.auctionHouseID);

	useEffect(() => {
		if (!props?.auctionID) {
			store.dispatch(AdminPageActions.updateAuctionDetail(null));
			return;
		}

		requestBidIncrements(props?.auctionID, null);
	}, [props?.auctionID]);

	return (
		<div className="adminpages bg-secondary-color-light">
			<Sidebar />
			<div className="adminpages__main bg-primary-color-light">
				<Routes>
					<Route path="" element={<AuctionList />} />
					<Route path="add" element={<AddAuction editMode={false} />} />
					<Route path="edit" element={<AddAuction editMode={true} />} />
					<Route path="users" element={<Users />} />
					<Route path="registrations" element={<Registrations />} />
					<Route path="autobids" element={<Autobids />} />
					<Route path="adduser" element={<AddEditUser />} />

					<Route path="edituser" element={<AddEditUser />} />

					<Route
						path="housesettings"
						element={<Settings houseSettings={true} />}
					/>

					<Route
						path="auctionsettings"
						element={<Settings houseSettings={false} />}
					/>

					<Route
						path="results"
						element={<AuctionResults origin="/adminpages/edit" />}
					/>

					<Route
						path="passbids"
						element={<PassBids origin="/adminpages/edit" />}
					/>
				</Routes>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auctionHouseID:
			state.adminpages?.loginDetails?.auctionHouse?.auctionHouseID,
		auctionID: state.adminpages?.loginDetails?.auction?.auctionID,
	};
};

export default connect(mapStateToProps)(AdminPages);
