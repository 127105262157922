import { getEndPointAPI2 } from 'api/getEndpoint';
import axios from 'axios';

export default async function getLoginUrl(userID, auctionID) {
	const url = `${getEndPointAPI2()}/auction/state?auctionID=${encodeURIComponent(
		auctionID
	)}&clientID=${encodeURIComponent(userID)}`;

	// const url = `${getEndPointAPI2()}/auction/state?auctionID=${auctionID}clientID=${userID}`;

	//const url = `${getEndPointAPI2()}/auction/state?auctionID=${auctionID}&clientID=${userID}`;
	// const url =
	// 	'https://demoreact.stream.bid/api2/auction/state?auctionID=uQFduyEDTj2v3PMyMlR9oQ==&clientID=VqYgJAMpT16/DArUySFYYw==';

	// const url = `${getEndPointAPI2()}/auction/state?auctionID=${auctionID}&clientID=${userID}`;

	// const url = 'https://demoreact.stream.bid/version';

	//URL: https://demoreact.stream.bid/api2/auction/state?auctionID=kMwdbvMFTjK30LdJN5lQEg==&clientID=lyDKlPFpRcWbP8kk2gXHRQ==

	// curl -s --header "Authorization: Bearer t/BRSrjISqGS81+Il9ywog==" https://demoreact.stream.bid/api2/user/send-reg-mail?auctionID=kMwdbvMFTjK30LdJN5lQEg==&clientID=lyDKlPFpRcWbP8kk2gXHRQ==
	// curl https://demoreact.stream.bid/api2/user/send-reg-mail?auctionID=kMwdbvMFTjK30LdJN5lQEg%3D%3D&clientID=lyDKlPFpRcWbP8kk2gXHRQ%3D%3D

	// curl -X PUT -s --header "Authorization: Bearer t/BRSrjISqGS81+Il9ywog==" "https://demoreact.stream.bid/api2/user/send-reg-mail?auctionID=kMwdbvMFTjK30LdJN5lQEg%3D%3D&clientID=a2Mf0fkLTPiD4gTF7DeeTQ%3D%3D"

	// curl -s --header "Authorization: Bearer t/BRSrjISqGS81+Il9ywog==" "https://demoreact.stream.bid/api2/auction/state?auctionID=kMwdbvMFTjK30LdJN5lQEg==&clientID=lyDKlPFpRcWbP8kk2gXHRQ=="

	// const url =
	// 	'https://demoreact.stream.bid/api2/auction/state?auctionID=kMwdbvMFTjK30LdJN5lQEg==&clientID=lyDKlPFpRcWbP8kk2gXHRQ==';

	// const url = `https://demoreact.stream.bid/api2/auction/state?auctionID=${encodeURIComponent(
	// 	'kMwdbvMFTjK30LdJN5lQEg=='
	// )}&clientID=${encodeURIComponent('lyDKlPFpRcWbP8kk2gXHRQ==')}`;

	console.log('URL:', url);
	return axios({
		headers: {
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
			// Origin: window.location.host,
			// crossdomain: true,
			Authorization: `Bearer ${window.API.user.userToken}`,
		},
		method: 'GET',
		url,
	})
		.then(response => response)
		.catch(error => {
			console.log(error);
			return error;
		});

	// try {
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		mode: 'cors',
	// 		headers: {
	// 			Accept: 'application/json',
	// 			'Content-Type': 'text/plain',
	// 			Authorization: `Bearer ${window.API.user.userToken}`,
	// 		},
	// 	});

	// 	try {
	// 		// const data = await response.json();
	// 		console.log(response);
	// 		return response;
	// 	} catch {
	// 		return {
	// 			result: 400,
	// 			detail: 'not valid json',
	// 		};
	// 	}
	// } catch (error) {
	// 	console.log('OH LAWD', error);
	// }

	// return await response.json();
}

// curl -s --header "Authorization: Bearer 2cmZXeORTSiABg825vvIwA==" "https://demoreact.stream.bid:4443/api2/auction/state?auctionID=kMwdbvMFTjK30LdJN5lQEg==&clientID=a2Mf0fkLTPiD4gTF7DeeTQ=="
// curl -s --header "Authorization: Bearer 2cmZXeORTSiABg825vvIwA==" "https://demoreact.stream.bid/api2/auction/state?auctionID=kMwdbvMFTjK30LdJN5lQEg%3D%3D&clientID=lyDKlPFpRcWbP8kk2gXHRQ%3D%3D"

// 'https://demoreact.stream.bid/api2/auction/state?auctionID=kMwdbvMFTjK30LdJN5lQEg%3D%3D&clientID=lyDKlPFpRcWbP8kk2gXHRQ%3D%3D'; NOT WORKING

// "https://demoreact.stream.bid/api2/user/send-reg-mail?auctionID=kMwdbvMFTjK30LdJN5lQEg%3D%3D&clientID=a2Mf0fkLTPiD4gTF7DeeTQ%3D%3D" WORKING
