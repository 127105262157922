import Slider from 'react-slick';
import { NextArrow, PrevArrow } from './Arrows';
import 'styles/bidderinterface/biddermobile/mobilebidding/biddermobilemainimage.scss';

const ImageSlider = props => {
	const renderImage = (image, index) => {
		return (
			<div className="biddermobile__mainimage__slide" key={index}>
				<div className="biddermobile__mainimage__inner">
					<div className="biddermobile__mainimage__pagination primary-color-light bg-primary-color-dark">
						{index + 1}/{props?.absImg.length}
					</div>
					<img src={props?.absImg[index]} alt="" />
				</div>
			</div>
		);
	};

	const settings = {
		dots: false,
		lazyLoad: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	if (!props?.lotID) {
		return <div className="biddermobile__mainimage"></div>;
	}

	let sliderData = [...props.absImg];

	return (
		<Slider className={`biddermobile__mainimage`} {...settings}>
			{sliderData.map((image, index) => renderImage(image, index))}
		</Slider>
	);
};

export default ImageSlider;
