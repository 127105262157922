export const BIDDER_OPEN_MODAL = 'BIDDER_OPEN_MODAL';
export const BIDDER_CLOSE_MODAL = 'BIDDER_CLOSE_MODAL';
export const UPDATE_BIDDER_LAYOUT = 'UPDATE_BIDDER_LAYOUT';
export const CATALOGUE_NAV_FORWARD = 'CATALOGUE_NAV_FORWARD';
export const CATALOGUE_NAV_BACK = 'CATALOGUE_NAV_BACK';
export const BID_ID_ASSIGNED = 'BID_ID_ASSIGNED';
export const UPDATE_AUTOBIDS = 'UPDATE_AUTOBIDS';
export const UPDATE_AUCTION_INCREMENTS = 'UPDATE_AUCTION_INCREMENTS';
export const RESET = 'RESET';
export const SET_VIEWERINTERFACE = 'SET_VIEWERINTERFACE';
export const UPDATE_WELCOMEMODAL = 'UPDATE_WELCOMEMODAL';
export const UPDATE_MOBILE_PAGE = 'UPDATE_MOBILE_PAGE';
export const UPDATE_MOBILE_CAT_INDEX = 'UPDATE_MOBILE_CAT_INDEX';
export const SET_SHOW_MAIN_VIDEO = 'SET_SHOW_MAIN_VIDEO';
export const SET_USEMOBILELAYOUT = 'SET_USEMOBILELAYOUT';
export const SET_FUTURE_AUTOBID_PANEL = 'SET_FUTURE_AUTOBID_PANEL';
export const SET_SWIPE_PANEL = 'SET_SWIPE_PANEL';
export const AUTOBID_MESSAGE = 'AUTOBID_MESSAGE';
export const SET_AUTOBID_STATE = 'SET_AUTOBID_STATE';
export const SET_MOBILE_CAT_SCROLL = 'SET_MOBILE_CAT_SCROLL';
export const RESET_AUTOBID_STATE = 'RESET_AUTOBID_STATE';
export const SET_AUTOBID_TARGET = 'SET_AUTOBID_TARGET';
export const UPDATE_POST_PASS = 'UPDATE_POST_PASS';
export const TRIGGER_WIN_ANIMATION = 'TRIGGER_WIN_ANIMATION';

export default class BidderActions {
	static reset() {
		return function (dispatch) {
			return dispatch({
				type: RESET,
			});
		};
	}

	static bidderOpenModal(modalType, data) {
		return function (dispatch) {
			return dispatch({
				type: BIDDER_OPEN_MODAL,
				modalType,
				data,
			});
		};
	}

	static bidderCloseModal() {
		return function (dispatch) {
			return dispatch({
				type: BIDDER_CLOSE_MODAL,
			});
		};
	}

	static updateBidderLayout(code, activePanel) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_BIDDER_LAYOUT,
				code,
			});
		};
	}

	static catalogueNavForward(newLocation) {
		return function (dispatch) {
			return dispatch({
				type: CATALOGUE_NAV_FORWARD,
				newLocation,
			});
		};
	}

	static catalogueNavBack() {
		return function (dispatch) {
			return dispatch({
				type: CATALOGUE_NAV_BACK,
			});
		};
	}

	static bidIdAssigned(id) {
		return function (dispatch) {
			return dispatch({
				type: BID_ID_ASSIGNED,
				id,
			});
		};
	}

	static updateAutoBids(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUTOBIDS,
				data,
			});
		};
	}

	static updateAuctionIncrements(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_AUCTION_INCREMENTS,
				data,
			});
		};
	}

	static setViewerInterface(bool) {
		return function (dispatch) {
			return dispatch({
				type: SET_VIEWERINTERFACE,
				bool,
			});
		};
	}

	static updateWelcomeModal(data) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_WELCOMEMODAL,
				data,
			});
		};
	}

	static updateMobilePage(string) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_MOBILE_PAGE,
				string,
			});
		};
	}

	static updateMobileCatIndex(index) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_MOBILE_CAT_INDEX,
				index,
			});
		};
	}

	static setShowMainVideo(bool) {
		return function (dispatch) {
			return dispatch({
				type: SET_SHOW_MAIN_VIDEO,
				bool,
			});
		};
	}

	static setUseMobileLayout(bool) {
		return function (dispatch) {
			return dispatch({
				type: SET_USEMOBILELAYOUT,
				bool,
			});
		};
	}

	static setSwipePanel(string) {
		return function (dispatch) {
			return dispatch({
				type: SET_SWIPE_PANEL,
				string,
			});
		};
	}

	// deprecated
	static setFutureAutobidPanel(string) {
		return function (dispatch) {
			return dispatch({
				type: SET_FUTURE_AUTOBID_PANEL,
				string,
			});
		};
	}

	static setAutobidMessage(string) {
		return function (dispatch) {
			return dispatch({
				type: AUTOBID_MESSAGE,
				string,
			});
		};
	}

	static setAutobidState(num) {
		return function (dispatch) {
			return dispatch({
				type: SET_AUTOBID_STATE,
				num,
			});
		};
	}

	static setMobileCatScroll(num) {
		return function (dispatch) {
			return dispatch({
				type: SET_MOBILE_CAT_SCROLL,
				num,
			});
		};
	}

	static resetAutobidState() {
		return function (dispatch) {
			return dispatch({
				type: RESET_AUTOBID_STATE,
			});
		};
	}

	static setAutobidTarget(target) {
		return function (dispatch) {
			return dispatch({
				type: SET_AUTOBID_TARGET,
				target,
			});
		};
	}

	static updatePostPass(bool) {
		return function (dispatch) {
			return dispatch({
				type: UPDATE_POST_PASS,
				bool,
			});
		};
	}

	static triggerWinAnimation(num, lotID) {
		return function (dispatch) {
			return dispatch({
				type: TRIGGER_WIN_ANIMATION,
				num,
				lotID,
			});
		};
	}
}
