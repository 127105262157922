import useAutobid from 'hooks/autobid/useAutobid';
import { useState, useEffect } from 'react';
import Inputs from './Inputs/Inputs';
import Buttons from './Buttons/Buttons';
import styles from './AutobidFuture.module.scss';

const AutobidFuture = ({ lotID }) => {
	const autobidProps = useAutobid(lotID);
	const { info, autoBidOpt } = autobidProps || {};
	const [isEditing, setIsEditing] = useState(false);
	useEffect(() => setIsEditing(false), [autoBidOpt]);

	const childProps = { ...autobidProps, isEditing, setIsEditing };

	if (!lotID) return null;
	return (
		<div className={styles.container}>
			<Inputs {...childProps} />
			<div className={styles.info}>{info}</div>
			<Buttons {...childProps} />
		</div>
	);
};

export default AutobidFuture;
