import sendRequest from '..';
import { getCommonEndpoint } from '../utils';
import store from 'store/store';
import AuctionActions from 'store/actions/auction';

//REQ_INTERACTION: 'Req-Interaction'
export default function requestInteraction(lotID, likeOpt) {
	return sendRequest(getCommonEndpoint(), {
		Action: 'Req-Interaction',
		userTokenID: window.API?.user?.userToken,
		lotID,
		likeOpt,
		wishOpt: null,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				handleFailure(data);
				return;
			}
			handleSuccess(data);
			return data;
		})
		.catch(error => console.debug(error));
}

const handleSuccess = data => {
	store.dispatch(AuctionActions.updateInteractions(data));
	console.debug('Req-Interaction success', data);
};

const handleFailure = data => {
	console.debug('Req-Interaction failure', data);
};
