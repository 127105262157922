import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import Archive from './archive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/pro-regular-svg-icons';
import { faChartSimple } from '@fortawesome/pro-light-svg-icons';

const AuctionRow = props => {
	const onRowClick = () => {
		let reissueOpt = {
			auctionHouseIDOpt: props.auctionHouseID,
			auctionIDOpt: props.auctionID,
		};

		window.API.validationCallBack = function (newAuctionHouseID) {
			if (newAuctionHouseID === props?.auctionHouseID) {
				window.API.validationCallBack = null;
				this.pushHistory('/adminpages/edit');
			}
		};
		store.dispatch(AdminPageActions.updateAddAuctionLocation(4));
		window.API.tokenValidation(window?.API?.user?.userToken, reissueOpt);
	};

	const onSettingsClick = event => {
		event.stopPropagation();
		let reissueOpt = {
			auctionHouseIDOpt: props.auctionHouseID,
			auctionIDOpt: props.auctionID,
		};

		window.API.validationCallBack = function (newAuctionHouseID) {
			if (newAuctionHouseID === props?.auctionHouseID) {
				window.API.validationCallBack = null;
				this.pushHistory('/adminpages/auctionsettings');
			}
		};

		window.API.tokenValidation(window.API.user.userToken, reissueOpt);
	};

	const onStatisticsClick = event => {
		event.stopPropagation();
		let reissueOpt = {
			auctionHouseIDOpt: props.auctionHouseID,
			auctionIDOpt: props.auctionID,
		};

		window.API.validationCallBack = function (newAuctionHouseID) {
			if (newAuctionHouseID === props?.auctionHouseID) {
				window.API.validationCallBack = null;
				this.pushHistory('/adminpages/results');
			}
		};

		window.API.tokenValidation(window?.API?.user?.userToken, reissueOpt);
	};

	let date = new Date(props.auctionDate).toLocaleString('en-GB');
	const { auctionID, isArchived } = props;
	return (
		<tr
			className="auctionlist__table__row bg-secondary-color-light"
			onClick={onRowClick}
			data-testid="auctions-row"
		>
			<td>{props?.saleNumOpt}</td>
			<td>{props.auctionTitle}</td>
			<td>{props.currency + ' (' + props.currencySymbol + ')'}</td>
			<td>{date}</td>
			<td>{props.lotCount}</td>
			<td>{props.specialistEmailOpt}</td>
			<td>{props.goIDOpt}</td>
			<td>
				<Archive auctionID={auctionID} isArchived={isArchived} />
			</td>
			<td>
				<button
					className="auctionlist__settings"
					onClick={event => onSettingsClick(event)}
				>
					<FontAwesomeIcon className="icon" icon={faCogs} />
				</button>
			</td>
			<td>
				<button
					className="auctionlist__settings"
					onClick={event => onStatisticsClick(event)}
				>
					<FontAwesomeIcon className="icon" icon={faChartSimple} />
				</button>
			</td>
		</tr>
	);
};

export default AuctionRow;
