import { getFile } from '..';
import { getConfigEndpoint } from '../utils';
import store from 'store/store';
import ConfigActions from 'store/actions/config';
import LoginActions from 'store/actions/login';
import defaultConfig from 'l10n/config.json';
import defaultL10n from 'l10n/l10n.json';

export default function getConfigs() {
	setupFallbacks();

	getFile(getConfigEndpoint())
		.then(response => {
			const { data } = response;
			const { defConfigURL, locales } = data || {};

			if (!defConfigURL || !locales?.length) {
				console.debug('getConfigs error - data not found', response);
			}
			console.debug(response);
			store.dispatch(LoginActions.setLocales({ defConfigURL }));
			setActiveConfig(defConfigURL, locales);
		})
		.catch(error => {
			console.debug(error?.message);
		});
}

export function setActiveConfig(defConfigURL, locales) {
	if (!window.API.locale) return;

	setConfig(defConfigURL, window.API.locale);
}

export function setConfig(defConfigURL, code) {
	if (!code) return;

	const url = `${defConfigURL}lang/${code}/language.json`;
	const customUrl = `${defConfigURL}lang/${code}/custom_language.json`;

	return getFile(url)
		.then(response => {
			const { data } = response;
			store.dispatch(ConfigActions.updateConfig(data));
			setCustomConfig(customUrl);
			return code;
		})
		.catch(error => {
			console.debug(error?.message);
			return null;
		});
}

function setCustomConfig(customUrl) {
	getFile(customUrl)
		.then(response => {
			const { data } = response;
			store.dispatch(ConfigActions.updateConfig(data));
		})
		.catch(error => console.debug(error?.message));
}

function setupFallbacks() {
	let tabName = defaultL10n?.tabName;
	if (tabName) document.title = tabName;
	store.dispatch(ConfigActions.updateConfig(defaultConfig));
	store.dispatch(ConfigActions.updateConfig(defaultL10n));
}
