import {
    PLAY_AUDIO
} from 'store/actions/audio';


const initialState = {
    effect: null,
    loop: false,
    timestamp: null
}

export default function audio(state = initialState, action) {
    let returnState = Object.assign({}, state);

    switch (action.type) {
        case PLAY_AUDIO:
            returnState.effect = action.data.effect;
            returnState.loop = action.data.loop;
            returnState.timestamp = action.data.timestamp;
            return returnState;

        default:
            return state
    }
}
