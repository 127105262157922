export const PLAY_AUDIO = 'PLAY_AUDIO';

export default class AudioActions {

    static playAudio(data) {
        return function(dispatch) {
            data.timestamp = Date.now();
            return dispatch({
                type: PLAY_AUDIO,
                data
            })
        }
    }

}
