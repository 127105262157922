import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Editor from 'react-simple-wysiwyg';
import requestDefaultsProtocol from 'api/requests/common/Req-DefaultsProtocol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faArrowUp,
	faSpinner,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import 'styles/adminpages/settings/settings.scss';

const TandC = props => {
	const uploadIcon = <FontAwesomeIcon className="icon" icon={faArrowUp} />;
	const loadingIcon = (
		<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
	);
	const successIcon = <FontAwesomeIcon className="icon" icon={faCheck} />;
	const failureIcon = <FontAwesomeIcon className="icon" icon={faTimes} />;

	const [buttonIcon, setButtonIcon] = useState(uploadIcon);
	const [buttonClass, setButtonClass] = useState(
		'bg-primary-color-light admin-accent-1'
	);
	const exisitingValue = props?.defaultsProtocol?.tAndC?.valueOpt;
	const [html, setHtml] = useState(exisitingValue || '');

	useEffect(() => {
		const incomingValue = props?.defaultsProtocol?.tAndC?.valueOpt;
		setHtml(incomingValue || '');
		// eslint-disable-next-line
	}, [props?.defaultsProtocol?.tAndC]);

	const onSubmit = () => {
		setButtonIcon(loadingIcon);
		requestDefaultsProtocol({ tAndC: { valueOpt: html, update: true } })
			.then(data => {
				if (data.result > 100 || data?.isAxiosError) {
					showFailure();
					return;
				}
				showSuccess();
			})
			.catch(() => showFailure());
	};

	const showSuccess = () => {
		setButtonIcon(successIcon);
		setButtonClass('primary-color-light bg-admin-accent-1');
		setTimeout(() => {
			setButtonIcon(uploadIcon);
			setButtonClass('bg-primary-color-light admin-accent-1');
		}, 1000);
	};

	const showFailure = () => {
		setButtonIcon(failureIcon);
		setButtonClass('bg-warning-color primary-color-light');

		setTimeout(() => {
			setButtonIcon(uploadIcon);
			setButtonClass('bg-primary-color-light admin-accent-1');
		}, 1000);
	};

	return (
		<div className={props.className}>
			<div className="tandc__container">
				<div className="row__title large-20-font-size">
					{props?.config?.defaultTandC}
				</div>
				<Editor
					value={html}
					onChange={event => setHtml(event.target.value)}
				/>
				<div className="tandc__actioncontainer">
					<button
						className={`tandc__action border-secondary-color-dark ${buttonClass}`}
						onClick={onSubmit}
					>
						{props?.config?.upload} {buttonIcon}
					</button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		defaultsProtocol: state.adminpages?.defaultsProtocol,
		config: state?.config,
	};
};
export default connect(mapStateToProps)(TandC);
