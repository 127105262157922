import 'components/bidderinterface/catalogue/controls/controls.scss';
import { capitaliseFirst } from 'utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

const Controls = props => {
	return (
		<div className={'catalogue__controls'}>
			<Filters {...props} />
			<Pagination {...props} />
			<ItemControls {...props} />
		</div>
	);
};

export default Controls;

const Filters = props => {
	return (
		<div className={`filters ${props.showPageForIndex ? 'hidden' : ''}`}>
			<button
				className={`regular-18-font-size ${
					props.statusFilter === 'all' ? 'active' : ''
				}`}
				onClick={() => props.setStatusFilter('all')}
			>
				{props?.config?.all}
			</button>
			<button
				className={`regular-18-font-size ${
					props.statusFilter === 'current' ? 'active' : ''
				}`}
				onClick={() => props.setStatusFilter('current')}
			>
				{props?.config?.currentLots}
			</button>
			<button
				className={`regular-18-font-size ${
					props.statusFilter === 'sold' ? 'active' : ''
				}`}
				onClick={() => props.setStatusFilter('sold')}
			>
				{props?.config?.soldLots}
			</button>
			<button
				className={`regular-18-font-size ${
					props.statusFilter === 'passed' ? 'active' : ''
				}`}
				onClick={() => props.setStatusFilter('passed')}
			>
				{props?.config?.passedLots}
			</button>
		</div>
	);
};

const Pagination = props => {
	const lotsPerPage = capitaliseFirst(props?.config?.lotsPerPage);
	return (
		<div
			className={`pagination ${
				props.showPageForIndex ? 'displaynone' : ''
			}`}
		>
			<select
				className="regular-18-font-size"
				value={props.detailSort}
				onChange={event => props.setDetailSort(event.target.value)}
			>
				<option value={'lotNumber'}>
					{capitaliseFirst(props?.config?.lotNumber)}
				</option>
				<option value={'lowEst'}>
					{capitaliseFirst(props?.config?.lowEstimate)}
				</option>
				<option value={'highEst'}>
					{capitaliseFirst(props?.config?.highEstimate)}
				</option>
				<option value={'title'}>
					{capitaliseFirst(props?.config?.title)}
				</option>
			</select>
			<select
				className="regular-18-font-size"
				value={props.lotsPerPage}
				onChange={event =>
					props.setLotsPerPage(Number(event.target.value))
				}
			>
				<option value={3}>{`3 ${lotsPerPage}`}</option>
				<option value={18}>{`18 ${lotsPerPage}`}</option>
				<option value={36}>{`36 ${lotsPerPage}`}</option>
			</select>
			<button onClick={() => props.changePage(-1)}>
				<FontAwesomeIcon
					className="icon large-20-font-size"
					icon={faChevronLeft}
				/>
			</button>
			<button onClick={() => props.changePage(1)}>
				<FontAwesomeIcon
					className="icon large-20-font-size"
					icon={faChevronRight}
				/>
			</button>
		</div>
	);
};

const ItemControls = props => {
	return (
		<div
			className={`itemcontrols ${
				props.showPageForIndex ? '' : 'displaynone'
			}`}
		>
			<button
				className="regular-18-font-size"
				onClick={() => props.onItemDetailChangePage(-1)}
			>
				<FontAwesomeIcon
					className="icon regular-18-font-size"
					icon={faChevronLeft}
				/>
				{props?.config?.previous}
			</button>
			<button
				className="regular-18-font-size"
				onClick={() => props.onItemDetailChangePage(1)}
			>
				{props?.config?.next}
				<FontAwesomeIcon
					className="icon regular-18-font-size"
					icon={faChevronRight}
				/>
			</button>
		</div>
	);
};
