import React, { Component } from 'react';
import { connect } from 'react-redux';
import LazyImage from 'components/common/lazyimage';
import 'styles/clerkconsole/currentlot/currentlot.scss';

class CurrentLot extends Component {
	getImageURL(lot) {
		if (!lot) return '';
		const currentIndex = this.props?.currentLot?.lotIndex;
		const currentLot = this.props?.lotList?.find(
			lot => lot?.catalogueLot?.lotIndex === currentIndex
		);
		if (!currentLot?.catalogueLot?.absImg?.length) return '';
		return currentLot?.catalogueLot?.absImg[0];
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps?.currentLot?.lotID !== this.props?.currentLot?.lotID)
			return true;

		return false;
	}

	render() {
		let currentLot = this.props?.currentLot;

		if (currentLot) currentLot.imageURL = this.props?.imageURL;
		const isWithdrawn = currentLot?.isWithdrawn;
		const lotNumber = currentLot?.lotNumber
			? `${this.props?.config?.lot} ${currentLot?.lotNumber}`
			: null;

		if (isWithdrawn)
			return (
				<div className="currentlot">
					<div className="currentlot__header large-24-font-size">
						{this.props?.config?.currentLot}
					</div>
					<div className="currentlot__image regular-18-font-size withdrawn">
						{this.props?.config?.lotWithdrawn}
					</div>
				</div>
			);

		return (
			<div className="currentlot">
				<div className="currentlot__title regular-16-font-size">
					{currentLot
						? `${lotNumber} - ${currentLot?.artist || ''} ${
								currentLot.title
						  }`
						: this.props?.config?.noCurrentLotSet}
				</div>
				<LazyImage
					className={'currentlot__image'}
					url={this.getImageURL(currentLot)}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currency: state.auction?.localisedData?.currency,
		config: state.config,
		currentLot: state.auction?.currentLot,
		lotList: state.auction?.lotList,
		lotCount: state.auction?.lotCount,
		imageURL: state.auction?.imageURL,
	};
};

export default connect(mapStateToProps)(CurrentLot);
