import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'styles/adminpages/addauction/review/review.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoWithdraw from '../review/autowithdraw/autowithdraw';
import LotRow from './lotRow';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

const LotTable = props => {
	const [lots, setLots] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const [filterInput, setFilterInput] = useState('');
	const [totalFilteredLots, setTotalFilteredLots] = useState(null);
	const [firstRowIndex, setFirstRowIndex] = useState(1);
	const [lastRowIndex, setLastRowIndex] = useState(10);
	const [initialLoadDone, setInitialLoadDone] = useState(false);

	const prepareLots = () => {
		if (!props?.lotList?.length) return [];
		if (!initialLoadDone) setInitialLoadDone(true);
		let newLots = props?.lotList;

		newLots = applyFilter(newLots);
		newLots = applyPagination(newLots);

		setLots(newLots);
	};

	const applyFilter = newLots => {
		if (!filterInput) {
			setTotalFilteredLots(newLots?.length);
			return newLots;
		}

		const target = filterInput.toLocaleLowerCase();
		const filteredLots = [...newLots].filter(
			lot =>
				lot?.catalogueLot?.title
					?.toLocaleLowerCase()
					.indexOf(target) !== -1
		);
		setTotalFilteredLots(filteredLots?.length);
		return filteredLots;
	};

	const applyPagination = newLots => {
		let firstRowIndex = currentPage * pageSize;
		let lastRowIndex = firstRowIndex + pageSize;
		let totalLots = newLots?.length;

		if (lastRowIndex > totalLots) lastRowIndex = totalLots;
		if (firstRowIndex < 0) firstRowIndex = 0;

		setFirstRowIndex(firstRowIndex);
		setLastRowIndex(lastRowIndex);

		return [...newLots].filter((lot, index) => {
			return index >= firstRowIndex && index < lastRowIndex;
		});
	};

	useEffect(() => {
		prepareLots();
		// eslint-disable-next-line
	}, [
		props?.lotUPTS,
		props?.lotList?.length,
		pageSize,
		currentPage,
		filterInput,
	]);

	const onPaginationChange = bool => {
		if (bool) {
			let lastPage =
				(currentPage + 1) * pageSize >= props?.lotList?.length;
			if (lastPage) return;
			setCurrentPage(currentPage + 1);
			return;
		}
		if (currentPage - 1 < 0) return;
		setCurrentPage(currentPage - 1);
	};

	const renderRows = () => {
		if (lots && lots.length === 0) {
			return (
				<tr>
					<td></td>
					<td></td>
					<td>{props?.config?.noMatches}</td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
			);
		}

		return lots.map((lot, index) => {
			let uploadStatus = props?.csvUpload?.find(
				status => lot?.catalogueLot?.lotID === status?.itemID
			);
			return (
				<LotRow
					key={index}
					lotID={lot?.catalogueLot?.lotID}
					onEditClick={props?.onEditClick}
					uploadStatus={uploadStatus}
				/>
			);
		});
	};

	if (props.lotCount === 0)
		return (
			<div className="addauction__lottable">
				{props?.config?.numberOfLots}: 0
			</div>
		);

	if (!initialLoadDone)
		return (
			<div className="addauction__lottable">{props?.config?.loading}</div>
		);

	return (
		<div className="addauction__lottable" data-testid="lots-table">
			<div className="addauction__review__row-2 bg-primary-color-light">
				<div className="addauction__review__row-2__left-panel">
					<div className="addauction__review__row-2__left-panel__page-toggle">
						<p>
							{firstRowIndex + 1} - {lastRowIndex}{' '}
							{props?.config?.of} {totalFilteredLots}
						</p>
						<div
							className="round-button"
							onClick={() => onPaginationChange(false)}
						>
							<FontAwesomeIcon
								className="addauction__review__row-2__icon"
								icon={faChevronLeft}
							/>
						</div>
						<div
							className="round-button"
							onClick={() => onPaginationChange(true)}
						>
							<FontAwesomeIcon
								className="addauction__review__row-2__icon"
								icon={faChevronRight}
							/>
						</div>
						<select
							name="display-count"
							id="display-count"
							className="round-button"
							onChange={event => {
								setCurrentPage(0);
								setPageSize(Number(event.target.value));
							}}
						>
							<option value="10">
								10 {props?.config?.lotsPerPage}
							</option>
							<option value="20">
								20 {props?.config?.lotsPerPage}
							</option>
							<option value="50">
								50 {props?.config?.lotsPerPage}
							</option>
							<option value="100">
								100 {props?.config?.lotsPerPage}
							</option>
							<option value="150">
								150 {props?.config?.lotsPerPage}
							</option>
							<option value="200">
								200 {props?.config?.lotsPerPage}
							</option>
						</select>
						<input
							placeholder={props?.config?.search}
							onChange={event =>
								setFilterInput(event.target.value)
							}
							data-testid="lots-search"
						></input>
					</div>
				</div>
			</div>
			{props?.addAuctionLocation === 3 && (
				<AutoWithdraw auctionID={props?.auctionID} />
			)}
			<div className="addauction__review__row-3 bg-primary-color-light">
				<table>
					<thead className="regular-16-font-size">
						<tr>
							<th>{props?.config?.withdrawn}</th>
							<th>{props?.config?.lot}</th>
							<th>{props?.config?.images}</th>
							<th>{props?.config?.artist}</th>
							<th>{props?.config?.title}</th>
							<th>{props?.config?.startingPrice}</th>
							<th>{props?.config?.reserve}</th>
							<th>{props?.config?.lowEstimate}</th>
							<th>{props?.config?.highEstimate}</th>
							{props?.csvUpload?.length && (
								<th>{props?.config?.uploadStatus}</th>
							)}
						</tr>
					</thead>
					<tbody>{renderRows()}</tbody>
				</table>
			</div>
		</div>
	);
};

export { LotTable };
const mapStateToProps = state => {
	return {
		lotCount: state.adminpages?.auctionDetail?.lotCount,
		lotList: state.adminpages?.auctionDetail?.lotList,
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		lotUPTS: state.adminpages?.auctionDetail?.lotUPTS,
		reviewSelectedLot: state.adminpages?.reviewSelectedLot, //check
		csvUpload: state.adminpages?.csvUpload, //check,
		addAuctionLocation: state.adminpages?.addAuctionLocation,
		config: state.config,
	};
};

export default connect(mapStateToProps)(LotTable);
