import useConfig from 'hooks/useConfig';
import dataToCsvDownload from 'utils/dataToCsvDownload';

export default function Export({ registrations, auctionTitle }) {
	const { exportCsv } = useConfig();
	const buildRow = item => {
		return {
			approved: item?.approved,
			email: item?.email,
			firstName: item?.firstName,
			secondName: item?.secondName,
			paddleNum: item?.paddleNum,
			currency: item?.currency,
			spendLimit: item?.spendLimit,
			userID: item?.userID,
		};
	};

	const onExportClick = () => {
		let data = [];

		registrations.forEach(item => {
			data.push(buildRow(item));
		});

		dataToCsvDownload(data, `${auctionTitle}_registrations`);
	};

	return (
		<button
			className="auctionresults__export bg-accent-color-1 primary-color-light"
			onClick={() => onExportClick()}
		>
			{exportCsv}
		</button>
	);
}
