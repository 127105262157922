import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import respondToBid from 'api/requests/clerk/Req-RespondToBid';
import ClerkActions from 'store/actions/clerk';
import RequestActions from 'store/actions/requests';
import 'styles/clerkconsole/managementpanel/bidselector.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel, faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

export class SBButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			requestInFlight: null,
			bidSentTimestamp: null,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState?.requestInFlight !== this.state?.requestInFlight)
			return true;

		if (nextProps?.requests?.length !== this.props?.requests?.length)
			return true;
		if (nextProps?.offerID !== this.props?.offerID) return true;

		if (nextProps?.offersWithBidsLen !== this.props?.offersWithBidsLen)
			return true;
		if (nextProps?.sbBidCount !== this.props?.sbBidCount) return true;
		if (nextProps?.sbLeadBidderOpt !== this.props?.sbLeadBidderOpt)
			return true;
		if (nextProps?.curHover !== this.props?.curHover) return true;
		if (nextProps?.autoAcceptSB !== this.props?.autoAcceptSB) return true;

		if (this.state.requestInFlight) return true;

		return false;
	}

	componentDidUpdate(prevProps) {
		if (this.state.requestInFlight) {
			const request = this.props.requests?.find(
				req => req.requestID === this.state.requestInFlight
			);
			if (request && request.reply)
				this.setState({
					requestInFlight: false,
				});
		}

		if (this.props?.offersWithBidsLen > prevProps?.offersWithBidsLen) {
			if (!this.state.bidSentTimestamp || !this.state.bidType) return;
		}

		if (this.props?.sbLeadBidderOpt !== prevProps?.sbLeadBidderOpt) {
			if (this.props?.autoAcceptSB) this.onOnBidClick();
		}

		if (this.props?.autoAcceptSB !== prevProps?.autoAcceptSB) {
			if (this.props?.autoAcceptSB && this.props?.sbLeadBidderOpt)
				this.onOnBidClick();
		}
	}

	componentWillUnmount() {
		if (this.showCheckTimeout) clearTimeout(this.showCheckTimeout);
		if (this.inputErrorTimeout) clearTimeout(this.inputErrorTimeout); //temporarily removed due to bug ie i can't offbid
	}

	onOnBidClick() {
		let platformSN = 'SB';
		let priceOpt = this.props?.offerPrice;
		let incTypeOpt = this.props?.bidIncrement?.incTypeOpt;
		let custIncOpt = this.props?.bidIncrement?.custIncOpt;

		this.setState({
			requestInFlight: 'SB',
		});

		store.dispatch(RequestActions.requestSent('REQ_RESPONDTOBID', 'SB'));

		respondToBid(
			this.props?.offerID,
			platformSN,
			priceOpt,
			incTypeOpt,
			custIncOpt
		);

		this.setState({
			bidSentTimestamp: Date.now(),
			bidType: 'ON BID',
		});
	}

	renderIcon() {
		let icon = faGavel;
		let classes = 'bidselector__floor__container__icon large-24-font-size';

		const request = this.props.requests?.find(
			req => req.requestID === 'SB'
		);
		if (!request || !request.reply)
			return <FontAwesomeIcon className={classes} icon={icon} />;

		if (request.success) {
			icon = faCheck;
			classes =
				'bidselector__floor__container__icon large-20-font-size admin-accent-1';
		} else {
			icon = faTimes;
			classes =
				'bidselector__floor__container__icon large-20-font-size warning-color';
		}

		setTimeout(() => {
			store.dispatch(RequestActions.requestRemove(request.requestID));
		}, 500);

		return <FontAwesomeIcon className={classes} icon={icon} />;
	}

	showInputError = () => {
		store.dispatch(ClerkActions.setShowAskingPriceError(true));

		this.inputErrorTimeout = setTimeout(() => {
			//temporarily removed due to bug ie i can't offbid
			store.dispatch(ClerkActions.setShowAskingPriceError(false));
		}, 800);
	};

	render() {
		let sbActive = this.props?.sbBidCount > 0;
		let bidCount = sbActive && this.props?.sbBidCount;

		const curHover = this.props?.curHover;

		return (
			<button
				className={`bidselector__streambid border-secondary-color-dark ${
					sbActive ? 'active' : ''
				}`}
				onClick={() => this.onOnBidClick()}
				data-testid="onbid-sb"
			>
				<div className="highlighter__container">
					{!sbActive && (
						<div className="highlighter__mask one">
							<div
								className={`highlighter__inner ${
									curHover > 0 && curHover < 5 ? 'pulse' : ''
								}`}
							></div>
						</div>
					)}
					{!sbActive && (
						<div className="highlighter__mask two">
							<div
								className={`highlighter__inner ${
									curHover > 1 && curHover < 5 ? 'pulse' : ''
								}`}
							></div>
						</div>
					)}
					{!sbActive && (
						<div className="highlighter__mask three">
							<div
								className={`highlighter__inner ${
									curHover > 2 && curHover < 5 ? 'pulse' : ''
								}`}
							></div>
						</div>
					)}
					{!sbActive && (
						<div className="highlighter__mask four">
							<div
								className={`highlighter__inner ${
									curHover > 3 && curHover < 5 ? 'pulse' : ''
								}`}
							></div>
						</div>
					)}
					<div
						className={`bidselector__streambid__container bg-primary-color-light border-2-accent-color-2 ${
							curHover > 4 ? 'wiggle' : ''
						}`}
					>
						{this.renderIcon('SB')}
						{sbActive && (
							<div className="bidselector__streambidnumber">
								{bidCount}
							</div>
						)}
					</div>
				</div>
				<div className="bidselector__streambid__title large-20-font-size">
					{sbActive ? 'SB -' : this.props?.config?.platformName}
					{` ${this.props?.sbLeadBidderOpt || ''}`}
				</div>
			</button>
		);
	}
}

const mapStateToProps = state => {
	return {
		config: state?.config,
		curHover: state.clerk?.bidActions?.curHover,
		...state.requests,
		offerID: state.auction?.currentOfferOpt?.offerID,
		offerPrice: state.auction?.currentOfferOpt?.offerPrice,
		offersWithBidsLen: state.auction?.offersWithBids?.length,
		clerkAskingPrice: state.auction?.clerkAskingPrice,
		sbBidCount: state.auction?.clerkDataOpt?.sbBidCount,
		sbLeadBidderOpt: state.auction?.clerkDataOpt?.sbLeadBidderOpt,
		bidIncrement: state.auction?.bidIncrement,
		packetSizeKB: state.auction?.packetSizeKB,
		serverTS: state.auction?.serverTS,
		receivedTimestamp: state.auction?.receivedTimestamp,
		autoAcceptSB:
			state.adminpages?.defaultsProtocol?.autoAcceptSB?.valueOpt,
	};
};

export default connect(mapStateToProps)(SBButton);
