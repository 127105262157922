import { useSelector } from 'react-redux';
import { useState } from 'react';

export const useSearchFilterLots = (defaultFilter = 'all') => {
	const auction = useSelector(state => state.auction);
	const { lotList } = auction || {};
	const [searchValue, setSearchValue] = useState('');
	const [filterValue, setFilterValue] = useState(defaultFilter);
	const [priceValue, setPriceValue] = useState({ low: '', high: '' });
	const [categoryValue, setCategoryValue] = useState('');
	const [sortKey, setSortKey] = useState('');
	const categories = [
		...new Set(lotList.map(lot => lot.catalogueLot.category)),
	];

	const sortList = (a, b) => {
		if (!sortKey) return 0;
		if (sortKey === 'lotNumber') {
			return Number(a.catalogueLot.lotNumber) > Number(b.catalogueLot.lotNumber)
				? 1
				: -1;
		}
		if (sortKey === 'lowEst') {
			return a.catalogueLot.lowEst > b.catalogueLot.lowEst ? 1 : -1;
		}
		if (sortKey === 'highEst') {
			return a.catalogueLot.highEst < b.catalogueLot.highEst ? 1 : -1;
		}
		if (sortKey === 'title') {
			return a.catalogueLot.title > b.catalogueLot.title ? 1 : -1;
		}
	};

	const searchList = lot => {
		if (!searchValue) return true;
		const title = lot.catalogueLot.title.toLowerCase();
		const lotNo = lot.catalogueLot.lotNumber;

		if (title.indexOf(searchValue) !== -1 || lotNo.indexOf(searchValue) !== -1)
			return true;
		return false;
	};

	const filterList = lot => {
		if (filterValue === 'all') return true;
		if (filterValue === 'wishlist') return lot?.interaction?.like;
		if (filterValue === 'autobid') return lot?.autoBidOpt;
		if (filterValue === 'current') return lot?.saleOutcomeOpt?.lotState === 0;
		if (filterValue === 'sold') return lot?.saleOutcomeOpt?.lotState === 200;
		if (filterValue === 'passed') return lot?.saleOutcomeOpt?.lotState === 300;
	};

	const lowPriceFilter = lot => {
		if (!priceValue.low) return true;
		return lot.catalogueLot.lowEst >= priceValue.low;
	};

	const highPriceFilter = lot => {
		if (!priceValue.high) return true;
		return lot.catalogueLot.highEst <= priceValue.high;
	};

	const categoryFilter = lot => {
		if (!categoryValue) return true;
		return lot.catalogueLot.category === categoryValue;
	};

	const searchedFilteredList = lotList
		.sort(sortList)
		.filter(searchList)
		.filter(filterList)
		.filter(lowPriceFilter)
		.filter(highPriceFilter)
		.filter(categoryFilter);

	return {
		searchedFilteredList,
		searchValue,
		setSearchValue,
		filterValue,
		setFilterValue,
		sortKey,
		setSortKey,
		priceValue,
		setPriceValue,
		categories,
		categoryValue,
		setCategoryValue,
	};
};
