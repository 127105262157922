import classNames from 'classnames';
import AskingPrice from '../AskingPrice/AskingPrice';
import AutobidInput from '../AutobidInput/AutobidInput';
import Info from './Info/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';
import styles from './PriceControl.module.scss';

const PriceControl = props => {
	const { showAutobid, onIncrementClick, autoBidOpt } = props;
	const showIncrements = showAutobid && !autoBidOpt;
	const smallInput = showAutobid && !autoBidOpt;

	return (
		<div
			className={classNames(styles.container, {
				[styles.autobidOpen]: showAutobid,
			})}
		>
			<div
				className={classNames(styles.value, {
					[styles.smallInput]: smallInput,
				})}
			>
				{showAutobid ? <AutobidInput {...props} /> : <AskingPrice />}
			</div>
			<Info {...props} showIncrements={showIncrements} />
			<button
				className={classNames(styles.increment, styles.left, {
					[styles.autobidOpen]: showIncrements,
				})}
				onClick={event => onIncrementClick(event, false)}
			>
				<FontAwesomeIcon className={styles.icon} icon={faMinus} />
			</button>
			<button
				className={classNames(styles.increment, styles.right, {
					[styles.autobidOpen]: showIncrements,
				})}
				onClick={event => onIncrementClick(event, true)}
			>
				<FontAwesomeIcon className={styles.icon} icon={faPlus} />
			</button>
		</div>
	);
};

export default PriceControl;
