import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_UPDATECLERK: 'Req-UpdateClerk'
export default function requestUpdateClerk(data) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-UpdateClerk',
		userTokenID: window.API?.user?.userToken,
		...data,
	})
		.then(response => {
			const { data } = response;
			if (data.result > 100) {
				return;
			}
			store.dispatch(AdminPageActions.resetStaff());
			return data;
		})
		.catch(error => console.debug(error));
}
