import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { useSearchFilterLots } from '../useSearchFilterLots';
import { useVirtualList } from '../useVirtualList';
import BidderActions from 'store/actions/bidder';
import useConfig from 'hooks/useConfig';
import { Nav } from './Nav';
import { Lots } from './Lots';
import styles from './Wishlist.module.scss';

export const Wishlist = () => {
	const dispatch = useDispatch();
	const { exploreWishlist } = useConfig();
	const searchFilterLots = useSearchFilterLots('wishlist');
	const { searchedFilteredList } = searchFilterLots;
	const virtualList = useVirtualList(searchedFilteredList, 4, false);

	return (
		<div className={styles.wishlist}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div className={styles.spacer}></div>
					<div className={styles.explore}>{exploreWishlist}</div>
					<div className={styles.spacer}></div>
					<button
						className={styles.closebutton}
						onClick={() => dispatch(BidderActions.updateBidderLayout(1))}
					>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</div>
			</div>
			<div className={styles.nav}>
				<Nav virtualList={virtualList} />
			</div>
			<div className={styles.lots}>
				<Lots virtualList={virtualList} />
			</div>
		</div>
	);
};
