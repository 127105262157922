import React, { Component } from 'react';
import { connect } from 'react-redux';
import requestUndo from 'api/requests/clerk/Req-Undo';
import requestSale from 'api/requests/clerk/Req-Sale';
import { requestMessage } from 'api/requests/clerk/Req-Message';
import store from 'store/store';
import requestCatUpdate from 'api/requests/common/Req-CatUpdate';
import ClerkActions from 'store/actions/clerk';
import 'styles/clerkconsole/managementpanel/salecontrols.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { faCoins, faSpinner, faCheck } from '@fortawesome/pro-solid-svg-icons';

export class SaleControls extends Component {
	constructor(props) {
		super(props);

		this.state = {
			requestInFlight: null,
			showCheck: null,
			saleNote: this.props.saleNoteInput || this.props.saleNote || '',
		};

		this.onVoidClick = this.onVoidClick.bind(this);
		this.onFairWarningClick = this.onFairWarningClick.bind(this);
		this.onSellItemClick = this.onSellItemClick.bind(this);
		this.onPassItemClick = this.onPassItemClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.saleControlReqInFlight &&
			!this.props?.saleControlReqInFlight
		) {
			this.setState({
				showCheck: this.state.requestInFlight,
				requestInFlight: null,
			});

			const lotState =
				this.props.clerk?.currentLot?.lotSaleDetail?.lotState;
			const lotLocked = lotState === 200 || lotState === 300;
			if (lotLocked) {
				requestCatUpdate(this.props?.currentLotIndex, 1);

				this.showCheckTimeout = setTimeout(() => {
					this.setState({ showCheck: null });
				}, 800);
			}
		}
		if (
			(prevProps?.saleNoteInput !== this.props.saleNoteInput ||
				prevProps?.saleNote) !== this.props.saleNote
		) {
			this.setState({
				saleNote: this.props.saleNoteInput || this.props.saleNote || '',
			});
		}
	}

	onVoidClick() {
		this.setState({ requestInFlight: 'undo' });
		store.dispatch(ClerkActions.setSaleControlReqInFlight(true));
		requestUndo();
	}

	onFairWarningClick() {
		const fairWarning = this.props?.messages?.find(
			msg => msg.messageID === 60
		);
		if (!fairWarning) return;
		requestMessage(fairWarning?.messageID, fairWarning?.text);
	}

	onPassItemClick() {
		this.setState({ requestInFlight: 'pass' });
		store.dispatch(ClerkActions.setSaleControlReqInFlight(true));
		requestSale(this.props?.offerID, false);
	}

	onSellItemClick() {
		const saleNote = this.state.saleNote || null;

		if (this.props?.saleNoteRequired && !saleNote) {
			store.dispatch(ClerkActions.setSaleNoteWarning(true));
			return;
		}

		this.setState({ requestInFlight: 'sell' });
		store.dispatch(ClerkActions.setSaleControlReqInFlight(true));
		requestSale(this.props?.offerID, true, saleNote);
		this.setState({ saleNote: '' });
	}

	renderIcon(type) {
		let icon = null;
		switch (type) {
			case 'undo':
				icon = <FontAwesomeIcon className="icon" icon={faUndo} />;
				break;
			case 'pass':
				icon = <FontAwesomeIcon className="icon" icon={faTimes} />;
				break;
			case 'sell':
				icon = <FontAwesomeIcon className="icon" icon={faCoins} />;
				break;
			default:
		}

		if (this.state.showCheck === type) {
			return <FontAwesomeIcon className="icon" icon={faCheck} />;
		} else if (this.state.requestInFlight === type) {
			return (
				<FontAwesomeIcon className="icon fa-spin " icon={faSpinner} />
			);
		} else return icon;
	}

	render() {
		return (
			<div className="salecontrols">
				<button
					className="salecontrols__void regular-16-font-size warning-color bg-warning-color-light border-warning-color hover-bg-warning-color-light-offset"
					onClick={this.onVoidClick}
				>
					{this.renderIcon('undo')}
					{this.props?.config?.voidLastBid}
				</button>
				<button
					className="salecontrols__fairwarning regular-16-font-size warning-color bg-primary-color-light border-secondary-color-dark hover-bg-secondary-color-dark"
					onClick={this.onFairWarningClick}
				>
					{this.props?.config?.fairWarning}
				</button>
				<button
					className="salecontrols__passitem regular-16-font-size border-2-secondary-color-dark accent-color-1 bg-primary-color-light hover-bg-secondary-color-dark"
					onClick={this.onPassItemClick}
				>
					{this.renderIcon('pass')}
					{this.props?.config?.passItem}
				</button>
				<button
					className="salecontrols__sellitem regular-16-font-size bg-accent-color-1 primary-color-light border-accent-color-1 hover-bg-accent-offset-1"
					onClick={this.onSellItemClick}
				>
					{this.renderIcon('sell')}
					{this.props?.config?.sellItem}
				</button>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const currentLotIndex = state.auction?.currentLot?.lotIndex;
	const currentLot = state.auction.lotList.find(
		lot => lot.catalogueLot.lotIndex === currentLotIndex
	);
	const saleNote = currentLot?.clerkDataOpt?.saleNote;
	const saleNoteRequired =
		state.adminpages?.defaultsProtocol?.saleNotePrompt?.valueOpt;

	return {
		config: state?.config,
		saleControlReqInFlight: state.clerk?.saleControlReqInFlight,
		offerID: state.auction?.currentOfferOpt?.offerID,
		currentLotIndex,
		saleNote,
		saleNoteInput: state.clerk?.saleNoteInput,
		saleNoteRequired,
		messages: state.config?.clerkMessages,
	};
};

export default connect(mapStateToProps)(SaleControls);
