import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faCheck,
	faSpinner,
} from '@fortawesome/pro-regular-svg-icons';

import useAutobid from 'hooks/autobid/useAutobid';
import useCurrency from 'hooks/useCurrency';
import useConfig from 'hooks/useConfig';
import styles from './AutobidPanel.module.scss';

export const AutobidPanel = ({ showAutobid, setShowAutobid }) => {
	const featureEnabled = useSelector(state => state.showFlags?.showAutobid);
	const currentLotID = useSelector(
		state => state.auction?.currentLotDetailOpt?.currentLotID
	);
	const { limit, onInputChange, onSubmit, info } = useAutobid(currentLotID);
	const { currencySymbol } = useCurrency();
	const { confirm, cancel, autobidPlaceholder } = useConfig();

	const onKeyPress = event => {
		if (event.charCode !== 13) return;
		onSubmit(event);
	};

	const spinner = <FontAwesomeIcon className="fa-spin" icon={faSpinner} />;

	const [confirmState, setConfirmState] = useState(confirm);

	useEffect(() => {
		setConfirmState(confirm);
	}, [confirm]);

	const onConfirmClick = event => {
		setConfirmState(spinner);
		onSubmit(event);
		resetConfirm();
	};

	const resetConfirm = () => setTimeout(() => setConfirmState(confirm), 1500);

	if (!featureEnabled) return null;

	return (
		<div
			className={classNames(styles.autobidpanel, {
				[styles.active]: showAutobid,
			})}
		>
			<div className={styles.instruction}>{autobidPlaceholder}</div>
			<div className={styles.container}>
				<div className={styles.symbol}>{currencySymbol}</div>
				<input
					className={styles.input}
					value={limit || ''}
					onChange={event => onInputChange(event)}
					onKeyPress={onKeyPress}
					inputMode="numeric"
				/>
			</div>
			{info && <div className={styles.info}>{info}</div>}
			{!info && (
				<div className={styles.controls}>
					<button
						className={styles.deleteButton}
						onClick={() => setShowAutobid(false)}
					>
						{cancel}
					</button>
					<button className={styles.confirmButton} onClick={onConfirmClick}>
						{info || confirmState}
					</button>
				</div>
			)}
		</div>
	);
};
