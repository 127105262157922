import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';

import requestPlayVideo from 'api/requests/clerk/Req-PlayVideo';
import AntMediaSB from 'components/common/AntMediaSB';
import ReactPlayerSB from 'components/common/ReactPlayerSB';
import Controls from './controls/controls';
import FocusMessage from './focusMessage/focusMessage';
import { getResFromWidth, getAdaptorProps, getDefaultDeviceId } from './utils';
import './broadcast3.scss';

const BroadcastInterface = props => {
	const localVideoRef = useRef();
	const [streamId, setStreamId] = useState('');
	const [wssConnected, setWssConnected] = useState(false);
	const [webRTCAdaptor, setWebRTCAdaptor] = useState(null);
	const [setupComplete, setSetupComplete] = useState(false);
	const [camResolution, setCamResolution] = useState(null);
	const [videoDevices, setVideoDevices] = useState([]);
	const [audioDevices, setAudioDevices] = useState([]);
	const [errorMsg, setErrorMsg] = useState(null);
	const [publishingStatus, setPublishingStatus] = useState('ready');
	const [activeCameraId, setActiveCameraId] = useState(null);
	const [activeMicId, setActiveMicId] = useState(null);
	const videoWidth = webRTCAdaptor?.mediaConstraints?.video?.width;
	const webRTCVideoId =
		webRTCAdaptor?.mediaConstraints?.video?.deviceId?.exact;
	const webRTCAudioId = webRTCAdaptor?.mediaConstraints?.audio?.deviceId;
	const [infoMsg, setInfoMsg] = useState();

	useEffect(() => {
		if (activeCameraId === webRTCVideoId) return;
		setActiveCameraId(webRTCVideoId);
		localVideoRef?.current?.play().catch(error => console.log(error));
	}, [webRTCVideoId, activeCameraId]);

	useEffect(() => {
		if (activeMicId === webRTCAudioId) return;
		setActiveMicId(webRTCAudioId);
	}, [webRTCAudioId, activeMicId]);

	useEffect(() => {
		if (!videoDevices.length || !audioDevices.length) return;
		if (!webRTCAdaptor || setupComplete) return;
		setSetupComplete(true);
		console.debug('webRTCAdaptor setup complete');
		switchCamera(getDefaultDeviceId(videoDevices));
		switchMic(getDefaultDeviceId(audioDevices));
		// eslint-disable-next-line
	}, [webRTCAdaptor, videoDevices, audioDevices]);

	useEffect(() => {
		setCamResolution(getResFromWidth(Number(videoWidth)));
	}, [videoWidth]);

	useEffect(() => {
		setStreamId(props?.streamId);
	}, [props?.streamId]);

	useEffect(() => {
		if (!props?.videoStreamWSS) return;
		setupAdaptor();
		// eslint-disable-next-line
	}, [props?.videoStreamWSS]);

	// eslint-disable-next-line
	useEffect(() => () => shutDownConn(), []);

	const setupAdaptor = () => {
		const adaptorProps = getAdaptorProps(
			props?.videoStreamWSS,
			onRTCMessage,
			onRTCError
		);
		const newAdaptor = new WebRTCAdaptor(adaptorProps);
		setWebRTCAdaptor(newAdaptor);
	};

	const shutDownConn = () => {
		webRTCAdaptor?.stop();
		webRTCAdaptor?.closeStream();
		webRTCAdaptor?.closeWebSocket();
	};

	const publishPreFlight = () => {
		if (publishingStatus === 'live' || publishingStatus === 'working')
			return false;
		if (!streamId) {
			setInfoMsg('Please enter a Stream ID');
			return false;
		}
		// if (!activeCameraId) {
		// 	setInfoMsg('Please choose a camera');
		// 	return false;
		// }
		if (!webRTCVideoId) {
			setInfoMsg('Please choose a camera');
			return false;
		}
		// if (!activeMicId) {
		// 	setInfoMsg('Please choose a microphone');
		// 	return false;
		// }
		if (!webRTCAudioId) {
			setInfoMsg('Please choose a microphone');
			return false;
		}

		setPublishingStatus('working');
		requestPlayVideo(true);
		return true;
	};

	const publish = () => {
		if (!publishPreFlight()) return;

		const token = props?.jwtToken;
		const subscriberId = null;
		const subscriberCode = null;
		const streamName = props?.streamName;
		const mainTrack = '';
		const metaData = '';

		webRTCAdaptor.publish(
			streamId,
			token,
			subscriberId,
			subscriberCode,
			streamName,
			mainTrack,
			metaData
		);
		webRTCAdaptor.setVolumeLevel(0.5);
		// webRTCAdaptor.setVolumeLevel(1);
	};

	const stopPublishing = () => webRTCAdaptor.stop(streamId);

	const onRTCMessage = (info, obj) => {
		if (info === 'pong') return;
		if (info === 'data_received') return;

		console.log('*** onRTCMessage', info, obj);
		const currentDate = new Date();
		const timestamp = currentDate.toLocaleTimeString();
		setInfoMsg(`${timestamp} - ${info}`);

		switch (info) {
			case 'initialized':
				setWssConnected(true);
				return;
			case 'available_devices':
				setAvailableDevices(obj);
				return;
			case 'publish_started':
				setPublishingStatus('live');
				return;
			case 'publish_finished':
				setPublishingStatus('ready');
				return;
			case 'closed':
				setWssConnected(false);
				return;
			default:
		}

		console.debug('[WEBRTC]', info, obj);
	};

	const onRTCError = (errorMsg, obj) => {
		console.debug('WEBRTC', errorMsg, obj);

		if (errorMsg === 'TypeError') {
			showError('Please check if your devices are already in use');
			return;
		}
		showError(errorMsg);
	};

	const showError = msg => {
		setErrorMsg(msg);
		setTimeout(() => setErrorMsg(null), 2000);
	};

	const setAvailableDevices = devices => {
		setVideoDevices(
			devices.filter(device => device?.kind === 'videoinput')
		);
		setAudioDevices(
			devices.filter(device => device?.kind === 'audioinput')
		);
	};

	const switchCamera = deviceId => {
		webRTCAdaptor
			?.switchVideoCameraCapture(streamId, deviceId)
			.then(response => {
				if (response) setActiveCameraId(deviceId);
				else goToNextCamera(deviceId);
			})
			.catch(() => goToNextCamera(deviceId));
	};

	const goToNextCamera = deviceId => {
		setErrorMsg('Unable to start camera. It may already be in use.');
		if (!activeCameraId) {
			const nextCam = videoDevices?.find(
				cam => cam?.deviceId !== deviceId
			);
			if (!nextCam) return;
			switchCamera(nextCam?.deviceId);
			return;
		}
		if (deviceId !== activeCameraId) switchCamera(activeCameraId);
	};

	const switchMic = deviceId => {
		webRTCAdaptor?.switchAudioInputSource(streamId, deviceId);
		// .then(response => {
		// 	if (response) setActiveMicId(deviceId);
		// 	else setErrorMsg('Unable to switch microphone');
		// })
		// .catch(() => setErrorMsg('Error switching microphone'));
	};

	const changeVideoSize = newResolution => {
		const constraints = {
			audio: true,
			video: newResolution,
		};
		webRTCAdaptor.applyConstraints(streamId, constraints);
		// .then(response => {
		// 	if (response) setCamResolution(newResolution);
		// 	else setErrorMsg('Unable to switch resolution');
		// })
		// .catch(error => setErrorMsg('Error switching resolution'));
	};

	const controlsProps = {
		streamId,
		setStreamId,
		videoDevices,
		audioDevices,
		activeCameraId: webRTCVideoId,
		activeMicId: webRTCAudioId,
		switchCamera,
		switchMic,
		publish,
		stopPublishing,
		infoMsg,
		changeVideoSize,
		camResolution,
		publishingStatus,
		errorMsg,
	};

	return (
		<div className="broadcast3">
			<div className="video-panel">
				<div className="video-container">
					<div className="">
						Local Video{' '}
						{camResolution &&
							` - ${camResolution.width} x ${camResolution.height}`}
					</div>
					<video
						id="localVideo"
						className="videofeed"
						controls
						autoPlay={setupComplete}
						ref={localVideoRef}
					></video>
				</div>

				<div className="video-container">
					<div className="">Remote Video</div>
					<div className="">{props?.sourceOpt}</div>
					{/* <AntMediaSB classes={''} /> */}
					{props?.sourceOpt === 'liveStream' && (
						<AntMediaSB classes={''} />
					)}

					{props?.sourceOpt !== 'liveStream' && (
						<ReactPlayerSB classes={''} />
					)}
				</div>
			</div>
			<div className="right-panel">
				{/* <SwitchPanelButton
					showControlsPanel={showControlsPanel}
					setShowControlsPanel={setShowControlsPanel}
				/>
				{showControlsPanel && <Controls {...controlsProps} />}
				{!showControlsPanel && <Promo />} */}

				{wssConnected && (
					<div className="webrtc-connection">
						<div className="icon webrtc-connected"></div>
						<div>Connected</div>
					</div>
				)}
				{!wssConnected && (
					<div className="webrtc-connection">
						<div className="icon webrtc-notconnected"></div>
						<div>Not Connected</div>
					</div>
				)}

				<Controls {...controlsProps} />
			</div>

			<FocusMessage isLive={publishingStatus === 'live'} />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		videoStreamWSS: state?.auction?.videoStreamWSS,
		streamEndpoint:
			state?.auction?.clerkDataOpt?.videoStream?.streamEndpoint,
		streamId: state?.auction?.clerkDataOpt?.videoStream?.streamID,
		sourceOpt: state?.auction?.videoStream?.sourceOpt,
		streamPin: state?.auction?.clerkDataOpt?.streamPin,
		jwtToken: state?.auction?.clerkDataOpt?.videoStream?.jwtToken,
		streamName: state?.auction?.clerkDataOpt?.videoStream?.name,
	};
};

export default connect(mapStateToProps)(BroadcastInterface);
