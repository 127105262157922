import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useAutobid from 'hooks/autobid/useAutobid';
import { AutobidToggle } from './AutobidToggle/AutobidToggle';
import { AutobidPanel } from './AutobidPanel/AutobidPanel';
import { ManualMode } from './manualmode';
import InfoPanel from './infoPanel/infoPanel';
import styles from './biddingpanel.module.scss';

export const BiddingPanel = () => {
	const currentLotID = useSelector(
		state => state.auction?.currentLotDetailOpt?.currentLotID
	);

	const [showAutobid, setShowAutobid] = useState(false);

	const onAutobidToggle = event => {
		setShowAutobid(!showAutobid);
	};

	const { autoBidOpt, onDelete } = useAutobid(currentLotID);

	useEffect(() => {
		if (!autoBidOpt) return;
		if (showAutobid) setShowAutobid(false);
	}, [autoBidOpt]);

	return (
		<div className={styles.biddingpanel}>
			<AutobidToggle
				{...{ showAutobid, onAutobidToggle, autoBidOpt, onDelete }}
			/>
			<ManualMode />
			<AutobidPanel {...{ showAutobid, setShowAutobid }} />
			<InfoPanel />
		</div>
	);
};
