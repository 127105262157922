import sendRequest from '..';
import { getClerkEndpoint } from '../utils';

//REQ_SETCURITEM: 'Req-SetCurItem'
export default function requestSetCurrentItem(currentLotIndexOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-SetCurItem',
		userTokenID: window.API?.user?.userToken,
		currentLotIndexOpt,
	}).catch(error => console.debug(error));
}
