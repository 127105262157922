import { useSelector } from 'react-redux';

import { Table } from 'components/common/Table';
import styles from './LotAutobids.module.scss';

export default function LotAutobids({ targetAutoBidLot }) {
	const { showAutobids } = useSelector(state => state.showFlags);

	if (!targetAutoBidLot || !showAutobids)
		return <div className={styles.lotautobids} />;

	const schema = {
		primaryEmail: 'Email',
		firstName: 'First name',
		secondName: 'Second name',
		paddleNumber: 'Paddle num',
		bidLimit: 'Bid limit',
		spendLimitApproved: 'Spend limit',
		userID: 'User Id',
	};
	return (
		<div className={styles.lotautobids}>
			<h2>Autobids for lot {targetAutoBidLot.lotNum}</h2>
			<Table
				data={targetAutoBidLot.autoBids}
				schema={schema}
				defaultSortProp={'email'}
			/>
		</div>
	);
}
