const ModalRow = props => {
	const date = new Date(props?.timestamp);
	const { config } = props;
	const formattedDate = `${`${date.getHours()}`.padStart(
		2,
		0
	)}:${`${date.getMinutes()}`.padStart(
		2,
		0
	)}:${`${date.getSeconds()}`.padStart(2, 0)}`;

	return (
		<tr className={props?.isWinner ? 'winner' : ''}>
			<td>{props.bidderLogin}</td>
			<td>{props.bidderID}</td>
			<td>{props.paddleNum}</td>
			<td>{props.bidPrice}</td>
			<td>{formattedDate}</td>
			<td>{props.isClerk ? config?.yes : config?.no}</td>
			<td>{props.isVoid ? config?.yes : config?.no}</td>
		</tr>
	);
};

export default ModalRow;
