import axios from 'axios';

export default function sendRequest(url, data) {
	return axios({
		headers: {
			Accept: 'application/json',
			'Content-Type': 'text/plain',
		},
		method: 'POST',
		url,
		data: JSON.stringify(data),
	});
}

export function sendFile(url, file) {
	return axios({
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		method: 'POST',
		url,
		data: file,
	});
}

export function getFile(url) {
	return axios({
		headers: {
			'Content-Type': 'text/javascript',
			Origin: window.location.host,
			crossdomain: true,
		},
		method: 'GET',
		url,
	});
}
