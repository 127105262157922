import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';

//REQ_DEFAULTSPROTOCOL: 'Req-DeleteAuction'
export default function requestDeleteAuction(auctionID, setArchiveOpt) {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-DeleteAuction',
		userTokenID: window.API?.user?.userToken,
		auctionID,
		setArchiveOpt,
	})
		.then(response => {
			// if (data.result > 100) {
			// 	return data;
			// }
			console.log(response.data);
			store.dispatch(AdminPageActions.reset());
			return response.data;
		})
		.catch(error => {
			console.debug(error);
			return error;
		});
}
