import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUndo,
	faCheck,
	faArrowUp,
	faSpinner,
	faTimes,
} from '@fortawesome/pro-regular-svg-icons';

import requestApproveBidder from 'api/requests/admin/Req-ApproveBidder';
import getBidder from 'api/bidder/getBidder';
import useConfig from 'hooks/useConfig';
import styles from './paddleNum.module.scss';

const PaddleNum = ({
	userID,
	auctionID,
	paddleNum,
	paddleLimitOpt,
	userUUID,
}) => {
	const config = useConfig();
	const [showInput, setShowInput] = useState(false);
	const [value, setValue] = useState(paddleNum);
	const [isLoading, setIsLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [error, setError] = useState('');

	const showEditPaddleNumber = useSelector(
		state => state.showFlags?.showEditPaddleNumber
	);

	const onKeyPress = event => {
		if (event.charCode !== 13) return;
		event.preventDefault();
		onConfirm();
	};

	const onConfirm = () => {
		setIsLoading(true);
		requestApproveBidder(userID, auctionID, paddleLimitOpt, Number(value))
			.then(response => {
				setIsLoading(false);
				if (response.detail) {
					showError(response.detail);
					return;
				}
				console.log(response);
				setShowInput(false);
				onSuccess();
			})
			.catch(error => {
				setIsLoading(false);
				showError(error);
				console.log(error);
			});
	};

	const showError = detail => {
		setError(detail);
		setTimeout(() => setError(''), 1500);
	};

	const onSuccess = () => {
		getBidder(userUUID);
		setShowSuccess(true);
		setTimeout(() => setShowSuccess(false), 1500);
	};

	const onCancel = () => {
		setShowInput(false);
		setValue(paddleNum);
	};

	if (!paddleNum) return <td />;
	if (!showEditPaddleNumber) return <td>{paddleNum}</td>;
	if (error) return <td className={styles.error}>{error}</td>;

	if (showInput)
		return (
			<td>
				<div className={styles.paddleNum}>
					<input
						value={value}
						onChange={event => setValue(event.target.value)}
						onKeyPress={onKeyPress}
					/>
					<button className={styles.confirm} onClick={onConfirm}>
						{isLoading ? (
							<FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
						) : (
							<FontAwesomeIcon icon={faArrowUp} />
						)}
					</button>
					<button className={styles.cancel} onClick={onCancel}>
						<FontAwesomeIcon className="icon" icon={faTimes} />
					</button>
				</div>
			</td>
		);

	return (
		<td>
			<div className={styles.paddleNum}>
				{paddleNum}
				<button
					className={classNames(styles.change, {
						[styles.success]: showSuccess,
					})}
					onClick={() => setShowInput(true)}
				>
					{showSuccess ? (
						<FontAwesomeIcon className="icon" icon={faCheck} />
					) : (
						config.change
					)}
				</button>
			</div>
		</td>
	);
};

export default PaddleNum;
