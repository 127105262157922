import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import store from 'store/store';
import AdminPageActions from 'store/actions/adminpages';
import 'styles/adminpages/sidebar/sidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPlus,
	faLandmark,
	faSignOut,
	faUser,
	faUsers,
	faSquareRoot,
	faCog,
	faInfo,
} from '@fortawesome/pro-light-svg-icons';

const Sidebar = props => {
	const [active, setActive] = useState(1);

	useEffect(() => {
		setActive(0);
	}, [props?.auctionID]);

	const onHomeClick = () => {
		window.API.pushHistory('/adminpages');
		store.dispatch(AdminPageActions.reset());
	};

	const onUsersClick = () => {
		setActive(2);
		window.API.pushHistory('/adminpages/users');
		store.dispatch(AdminPageActions.reset());
	};

	const onHouseSettingsClick = () => {
		setActive(3);
		window.API.pushHistory('/adminpages/housesettings');
		store.dispatch(AdminPageActions.reset());
	};

	const onRootClick = () => {
		let reissueOpt = {
			auctionHouseIDOpt: props?.auctionHouseID || null,
			auctionIDOpt: props?.auctionID || null,
		};

		window.API.validationCallBack = function () {
			window.API.validationCallBack = null;
			this.pushHistory('/rootadmin');
		};

		window.API.tokenValidation(window.API.user.userToken, reissueOpt);
		store.dispatch(AdminPageActions.reset());
	};

	const currentAuction = props?.auctionID;
	const rootUser = props?.permissions?.indexOf('root') !== -1;

	return (
		<div className="adminpages__sidebar bg-primary-color-dark primary-color-light large-20-font-size">
			<div className="adminpages__sidebar__title">
				{props?.auctionHouseName}
			</div>
			<div className="adminpages__sidebar__buttons">
				<div className="adminpages__sidebar__buttons__1">
					{!currentAuction ? (
						<Link to={`/adminpages/add`} style={{ textDecoration: 'none' }}>
							<button
								className={`adminpages__sidebar__buttons__1__button bg-primary-color-dark primary-color-light regular-16-font-size ${
									active === 0 ? 'activated' : ''
								}`}
								onClick={() => {
									store.dispatch(AdminPageActions.updateAddAuctionLocation(0));
									setActive(0);
								}}
							>
								<div className="adminpages__sidebar__buttons__1__button-icon-wrapper">
									<FontAwesomeIcon
										className="adminpages__sidebar__buttons__1__button-icon large-24-font-size"
										icon={faPlus}
									/>
								</div>
								<span>{props?.config?.createAuction}</span>
							</button>
						</Link>
					) : (
						<button
							className={`adminpages__sidebar__buttons__1__button disabled bg-primary-color-dark primary-color-light regular-16-font-size`}
						>
							<div className="adminpages__sidebar__buttons__1__button-icon-wrapper">
								<FontAwesomeIcon
									className="adminpages__sidebar__buttons__1__button-icon large-24-font-size"
									icon={faPlus}
								/>
							</div>
							<span>{props?.config?.editingAuction}</span>
						</button>
					)}

					<button
						className={`adminpages__sidebar__buttons__1__button bg-primary-color-dark primary-color-light regular-16-font-size ${
							active === 1 ? 'activated' : ''
						}`}
						onClick={() => {
							onHomeClick();
							setActive(1);
						}}
					>
						<div className="adminpages__sidebar__buttons__1__button-icon-wrapper">
							<FontAwesomeIcon
								className="adminpages__sidebar__buttons__1__button-icon large-24-font-size"
								icon={faLandmark}
							/>
						</div>
						<span>{props?.config?.home}</span>
					</button>

					<button
						className={`adminpages__sidebar__buttons__1__button bg-primary-color-dark primary-color-light regular-16-font-size ${
							active === 2 ? 'activated' : ''
						}`}
						onClick={() => onUsersClick()}
					>
						<div className="adminpages__sidebar__buttons__1__button-icon-wrapper">
							<FontAwesomeIcon
								className="adminpages__sidebar__buttons__1__button-icon large-24-font-size"
								icon={faUsers}
							/>
						</div>
						<span>{props?.config?.users}</span>
					</button>

					<button
						className={`adminpages__sidebar__buttons__1__button bg-primary-color-dark primary-color-light regular-16-font-size ${
							active === 3 ? 'activated' : ''
						}`}
						onClick={() => onHouseSettingsClick()}
					>
						<div className="adminpages__sidebar__buttons__1__button-icon-wrapper">
							<FontAwesomeIcon
								className="adminpages__sidebar__buttons__1__button-icon large-24-font-size"
								icon={faCog}
							/>
						</div>
						<span>{props?.config?.settings}</span>
					</button>

					<a
						className="icon__container animated"
						href={'https://wiki.stream.bid/'}
						target="_blank"
						rel="noreferrer"
					>
						<button
							className={`adminpages__sidebar__buttons__1__button bg-primary-color-dark primary-color-light regular-16-font-size`}
						>
							<div className="adminpages__sidebar__buttons__1__button-icon-wrapper">
								<FontAwesomeIcon
									className="adminpages__sidebar__buttons__1__button-icon large-24-font-size"
									icon={faInfo}
								/>
							</div>
							<span>{props?.config?.wiki}</span>
						</button>
					</a>
				</div>
				<div className="adminpages__sidebar__buttons__2">
					<button className="adminpages__sidebar__buttons__2__button bg-primary-color-dark primary-color-light regular-16-font-size username">
						<div className="adminpages__sidebar__buttons__2__button-icon-wrapper">
							<FontAwesomeIcon
								className="adminpages__sidebar__buttons__2__button-icon large-24-font-size"
								icon={faUser}
							/>
						</div>
						<span>{props?.loginName}</span>
					</button>

					{rootUser && (
						<button
							className="adminpages__sidebar__buttons__2__button bg-primary-color-dark primary-color-light regular-16-font-size"
							onClick={onRootClick}
						>
							<div className="adminpages__sidebar__buttons__2__button-icon-wrapper">
								<FontAwesomeIcon
									className="adminpages__sidebar__buttons__2__button-icon large-24-font-size"
									icon={faSquareRoot}
								/>
							</div>
							<span>{props?.config?.rootAdmin}</span>
						</button>
					)}

					<button
						className="adminpages__sidebar__buttons__2__button bg-primary-color-dark primary-color-light regular-16-font-size"
						onClick={() => window.API.onLogout()}
					>
						<div className="adminpages__sidebar__buttons__1__button-icon-wrapper">
							<FontAwesomeIcon
								className="adminpages__sidebar__buttons__2__button-icon large-24-font-size"
								icon={faSignOut}
							/>
						</div>
						<span>{props?.config?.logout}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auctionID: state.login?.loginDetails?.auction?.auctionID,
		auctionHouseName: state.login?.loginDetails?.auctionHouse?.name,
		permissions: state.login?.loginDetails?.permissions,
		auctionHouseID: state.login?.loginDetails?.auctionHouse?.auctionHouseID,
		addAuctionLocation: state.adminpages?.addAuctionLocation,
		loginName: state.login?.loginDetails?.loginName,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Sidebar);
