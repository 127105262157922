import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useConfig from 'hooks/useConfig';
import useCurrentLot from 'hooks/useCurrentLot';
import useCurrency from 'hooks/useCurrency';
import AuctionActions from 'store/actions/auction';
import requestUpdateInc from 'api/requests/clerk/Req-UpdateInc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faSpinner } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import defaultStyles from './askingPrice.module.scss';
import { isValidOfferPrice } from 'utils/utils';

const AskingPrice = ({ styles = defaultStyles }) => {
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const { updateClerkAskingPrice, resetClerkAskingPrice } = AuctionActions;
	const offerPrice = useSelector(
		state => state.auction?.currentOfferOpt?.offerPrice
	);
	const clerkAskingPrice = useSelector(
		state => state.auction?.clerkAskingPrice
	);

	const sbActive = useSelector(
		state => state.auction?.clerkDataOpt?.sbBidCount > 0
	);
	const currentLot = useCurrentLot();
	const lotState = currentLot?.lotSaleDetail?.lotState;
	const config = useConfig();
	const { getFormattedCurrency, sanitiseCurrency } = useCurrency();
	const [askingPrice, setAskingPrice] = useState(
		isValidOfferPrice(offerPrice) ? offerPrice : ''
	);
	const [isEditing, setIsEditing] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(null);
	useEffect(
		() => setAskingPrice(isValidOfferPrice(offerPrice) ? offerPrice : ''),
		[offerPrice]
	);

	useEffect(() => {
		if (
			clerkAskingPrice !== null &&
			clerkAskingPrice !== offerPrice &&
			clerkAskingPrice !== askingPrice
		) {
			setAskingPrice(clerkAskingPrice);
			setIsEditing(true);
		}
		// eslint-disable-next-line
	}, [clerkAskingPrice]);

	useEffect(() => {
		if (clerkAskingPrice === offerPrice && isEditing) {
			setAskingPrice(offerPrice);
			setIsEditing(false);
		}
		// eslint-disable-next-line
	}, [clerkAskingPrice, offerPrice]);

	const onUndoClick = () => {
		setAskingPrice('0');
		dispatch(resetClerkAskingPrice());
	};

	const onInputChange = event => {
		event.preventDefault();
		let sanitisedValue = sanitiseCurrency(event.currentTarget.value);
		let input = Number(sanitisedValue);

		if (isNaN(Number(input))) return;
		inputRef.current.setSelectionRange(
			event.target.selectionStart,
			event.target.selectionStart
		);
		dispatch(updateClerkAskingPrice(input));
	};

	const onInputKeyPress = event => {
		event.stopPropagation();
		if (event.charCode === 13) {
			event.preventDefault();
			if (!isValidOfferPrice(offerPrice)) return;
			setShowLoading(true);
			setIsEditing(false);
			requestUpdateInc(null, null, Number(clerkAskingPrice))
				.then(response => {
					setShowLoading(false);
					const { data } = response;
					if (data.result > 100) {
						handleFailure();
						return;
					}
					handleSuccess();
				})
				.catch(error => {
					console.debug(error);
					handleFailure();
				});
		}
	};

	const handleSuccess = () => {
		setShowSuccess(true);
		setTimeout(() => setShowSuccess(false), 800);
	};

	const handleFailure = () => {
		setShowError(true);
		setTimeout(() => setShowError(false), 800);
	};

	const onInputClick = () => {
		dispatch(updateClerkAskingPrice(0));
		inputRef.current.selectionStart = inputRef.current.value.length;
		inputRef.current.selectionEnd = inputRef.current.value.length;
	};

	const lotPassed = !isValidOfferPrice(offerPrice) && lotState === 200;
	const lotSold = !isValidOfferPrice(offerPrice) && lotState === 300;
	const lotLocked = lotPassed || lotSold;
	return (
		<div
			className={classNames(styles.askingPrice, {
				[styles.sbActive]: sbActive,
				[styles.locked]: lotLocked,
			})}
		>
			{lotLocked && (
				<div className={styles.description}>
					<div className={styles.title}>{config?.lotLocked}</div>
					<div className={styles.subtitle}>
						{config?.voidToUnlock}
					</div>
				</div>
			)}

			{!lotLocked && (
				<div className={styles.description}>
					<div className={styles.title} data-testid="title">
						{config?.askingPrice}
					</div>
					<div className={styles.subtitle}>
						{config?.editAskingPrice}
					</div>
				</div>
			)}

			<div
				className={classNames(styles.inputContainer, {
					[styles.success]: showSuccess,
					[styles.error]: showError,
				})}
			>
				{showLoading && (
					<FontAwesomeIcon className="fa-spin" icon={faSpinner} />
				)}
				{isEditing && !showLoading && (
					<button
						className={styles.undo}
						onClick={onUndoClick}
						data-testid="undo"
					>
						<FontAwesomeIcon icon={faUndo} />
					</button>
				)}
				<input
					className={classNames(styles.input, {
						[styles.success]: showSuccess,
						[styles.error]: showError,
					})}
					ref={inputRef}
					value={getFormattedCurrency(askingPrice)}
					onChange={onInputChange}
					onKeyPress={onInputKeyPress}
					onClick={onInputClick}
					data-testid="askingprice"
				/>
			</div>
		</div>
	);
};

export default AskingPrice;
