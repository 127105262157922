import useConfig from 'hooks/useConfig';
import styles from './Header.module.scss';

const Header = ({ onExportClick }) => {
	const { back, auctionResults, exportCsv } = useConfig();

	return (
		<div className={styles.header}>
			<button
				className={styles.backButton}
				onClick={window?.API?.navBack}
			>
				{back}
			</button>
			<h1 className={styles.title}>{auctionResults}</h1>
			<button
				className={styles.exportButton}
				onClick={() => onExportClick()}
			>
				{exportCsv}
			</button>
		</div>
	);
};

export default Header;
