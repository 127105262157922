import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import requestAuctionDetail from 'api/requests/common/Req-AuctionDetail/Req-AuctionDetail';
import UploadProgress from './uploadProgress';
import 'styles/adminpages/addauction/review/review.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import LotTable from 'components/adminpages/addauction/lots/lotTable';
import LotEditForm from 'components/adminpages/addauction/lots/lotEditForm';

const Review = props => {
	const [editingLot, setEditingLot] = useState(null);
	const [reqAuctionDetailSent, setReqAuctionDetailSent] = useState(false);

	const onEditClick = lotIndex => {
		if (!isNaN(lotIndex)) {
			const nextLot = props?.lotList.find(
				lot => lot.catalogueLot.lotIndex === lotIndex
			);
			if (!nextLot) return;
		}
		setEditingLot(lotIndex);
	};

	useEffect(() => {
		if (!props.lotList.length && !reqAuctionDetailSent) {
			requestAuctionDetail(true, props?.auctionID);
			setReqAuctionDetailSent(true);
		}
	}, [props.lotList, props?.auctionID, reqAuctionDetailSent]);

	return (
		<div className="addauction__review">
			<div className="addauction__review__row-1">
				<UploadProgress csvUpload={props.csvUpload} />

				<button
					className="addauction__review__row-1__action primary-color-light bg-accent-color-1"
					onClick={props.markAsComplete}
				>
					{props?.config?.next}
					<FontAwesomeIcon
						className="addauction__review__row-1__action-icon"
						icon={faArrowRight}
					/>
				</button>
			</div>

			<div className="addauction__complete__row-4 bg-primary-color-light">
				{editingLot ? (
					<LotEditForm lotIndex={editingLot} onEditClick={onEditClick} />
				) : (
					<LotTable onEditClick={onEditClick} />
				)}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		lotList: state.adminpages?.auctionDetail?.lotList,
		auctionID: state.adminpages?.auctionDetail?.auctionID,
		csvUpload: state.adminpages?.csvUpload,
		config: state?.config,
	};
};

export default connect(mapStateToProps)(Review);
