import { useEffect, useState } from 'react';

import styles from './RenderWrapper.module.scss';

export const RenderWrapper = ({ children, isVisible }) => {
	const [showComponent, setShowComponent] = useState(isVisible);

	useEffect(() => {
		if (!isVisible) setShowComponent(false);
		else setTimeout(() => setShowComponent(true), 200);
	}, [isVisible]);

	if (!showComponent) return null;
	return <div className={styles.renderwrapper}>{children}</div>;
};
