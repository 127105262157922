import useCurrentLot from 'hooks/useCurrentLot';
import useConfig from 'hooks/useConfig';
import { Interweave } from 'interweave';
import styles from './Header.module.scss';

const Header = () => {
	const { lot } = useConfig();
	const { catalogueLot } = useCurrentLot();
	const { lotNumber, title } = catalogueLot || {};

	if (!title) return <div className={styles.header} />;

	return (
		<div className={styles.header}>
			<div className={styles.lotNumber}>
				{lot} {lotNumber}:
			</div>
			<div className={styles.title}>
				<Interweave content={title} noHtml />
			</div>
		</div>
	);
};

export default Header;
