import { SET_SHOWFLAGS } from 'store/actions/showflags';

const initialState = {
	showBidButton1: false,
	showBidButton2: false,
	showBidButton3: false,
	showDetailedAuctioneer: false,
	showAutoOpen: false,
	showAutoAccept: false,
	showSaleNote: false,
	showEditPaddleNumber: false,
	showLanguagePicker: false,
	showPaddleLimit: false,
	showTandCModal: false,
	showAutobid: false,
};

export default function showFlags(state = initialState, action) {
	switch (action.type) {
		case SET_SHOWFLAGS:
			const value = action.num;

			const returnState = Object.assign(state, {
				showBidButton1: Boolean(value & 0x0001),
				showBidButton2: Boolean(value & 0x0002),
				showBidButton3: Boolean(value & 0x0004),
				showDetailedAuctioneer: Boolean(value & 0x0008),
				showAutoOpen: Boolean(value & 0x0010),
				showAutoAccept: Boolean(value & 0x0020),
				showSaleNote: Boolean(value & 0x0040),
				showEditPaddleNumber: Boolean(value & 0x0080),
				showLanguagePicker: true,
				showPaddleLimit: Boolean(value & 0x0200),
				showTandCModal: true,
				showAutobid: Boolean(value & 0x0100),
				showRegistrations: true,
				showAutobids: true,
			});

			return returnState;

		default:
			return state;
	}
}
