export function getEndPoint() {
	if (window?.API?.clerkPort) {
		return `${document.location.protocol}//${window.location.hostname}:${window.API.clerkPort}`;
	}
	return `${document.location.protocol}//${window.location.hostname}`;
}

export function getEndPointAPI2() {
	// if (window?.API?.clerkPort) {
	// 	return `${document.location.protocol}//${window.location.hostname}:${window.API.clerkPort}/api2`;
	// }
	return `${document.location.protocol}//${window.location.hostname}/api2`;
}
