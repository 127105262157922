import useLotID from 'hooks/useLotID';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';

const useLotCard = lotID => {
	const config = useConfig();
	const {
		catalogueLot,
		autoBidOpt,
		isCurrentLot,
		lotSaleDetail,
		interaction,
	} = useLotID(lotID);
	const { displayAs, lotState, saleResultOpt } = lotSaleDetail || {};
	const { salePrice } = saleResultOpt || {};
	const {
		title,
		artist,
		absImg = [],
		lotIndex,
		lotNumber,
		lowEst,
		highEst,
		isWithdrawn,
	} = catalogueLot || {};
	const { like } = interaction || {};
	const isBidder = window.API?.user?.userPerm?.includes('bidder');
	const { getFormattedCurrency } = useCurrency();
	const isClerk = window?.API?.user?.userPerm?.includes('clerk');

	const getTitle = () => {
		if (isClerk) {
			if (lotState === 200) {
				return `${config?.sold} - ${getFormattedCurrency(
					salePrice || 0
				)}`;
			}
			if (lotState === 300) return config?.itemPassed;
		}

		if (displayAs === 4200) {
			const wontext = isBidder ? config?.itemWon : config?.sold;
			return `${wontext} - ${getFormattedCurrency(salePrice)}`;
		}

		if (displayAs === 4300) {
			return `${config?.itemLost} - ${getFormattedCurrency(salePrice)}`;
		}

		switch (lotState) {
			case 200:
				return `${config?.sold} - ${getFormattedCurrency(
					salePrice || 0
				)}`;

			case 300:
				return config?.itemPassed;

			default:
				return `${artist || ''} ${title}`;
		}
	};

	const getEstimate = () => {
		switch (lotState) {
			case 200:
				return config?.explore;

			case 300:
				return config?.clickToLeaveOffer;

			default:
				return `${config?.estimate} ${getFormattedCurrency(
					lowEst
				)} - ${getFormattedCurrency(highEst)}`;
		}
	};

	return {
		autoBidOpt,
		isCurrentLot,
		displayAs,
		lotState,
		title,
		absImg,
		lotIndex,
		lotNumber,
		isWithdrawn,
		like,
		isBidder,
		getTitle,
		getEstimate,
	};
};

export default useLotCard;
