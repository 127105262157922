import sendRequest from '..';
import { getClerkEndpoint } from '../utils';
import store from 'store/store';
import LoginActions from 'store/actions/login';

//REQ_AUCTIONS: 'Req-Auctions'
export default function requestAuctions() {
	return sendRequest(getClerkEndpoint(), {
		Action: 'Req-Auctions',
		userTokenID: window.API?.user?.userToken,
	})
		.then(response => {
			const { data } = response;
			store.dispatch(LoginActions.updateAuctionHouses(data));
		})
		.catch(error => console.debug(error));
}
