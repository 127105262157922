import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import classNames from 'classnames';
import ClerkActions from 'store/actions/clerk';
import useCurrentLot from 'hooks/useCurrentLot';
import useConfig from 'hooks/useConfig';
import useCurrency from 'hooks/useCurrency';
import { capitaliseFirst } from 'utils/utils';

import styles from './InfoPanel.module.scss';

const InfoPanel = () => {
	const config = useConfig();
	const dispatch = useDispatch();
	const showSaleNote = useSelector(state => state.showFlags?.showSaleNote);
	const { setSaleNoteInput, setSaleNoteWarning } = ClerkActions || {};
	const { getFormattedCurrency } = useCurrency();
	const currentLot = useCurrentLot();
	const saleNote = currentLot?.clerkDataOpt?.saleNote;
	const lotID = currentLot?.catalogueLot?.lotID;
	const clerkState = useSelector(state => state.clerk);
	const { saleNoteInput, showSaleNoteWarning } = clerkState || {};
	const clerkDataOpt = useSelector(state => state?.auction?.clerkDataOpt);
	const saleNoteRequired = useSelector(
		state => state.adminpages?.defaultsProtocol?.saleNotePrompt?.valueOpt
	);
	const { autoBidsOpt, maxAutoBidOpt } = clerkDataOpt || {};
	const autoBids = autoBidsOpt || 0;
	const maxAutoBid = maxAutoBidOpt || 0;
	const inputValue = saleNoteInput || saleNote || '';

	useEffect(() => {
		dispatch(setSaleNoteInput(saleNote || ''));
		// eslint-disable-next-line
	}, [lotID]);

	useEffect(() => {
		if (!showSaleNoteWarning) return;
		setTimeout(() => dispatch(setSaleNoteWarning(false)), 2000);
		// eslint-disable-next-line
	}, [showSaleNoteWarning]);

	const onInputChange = event => {
		event.preventDefault();
		dispatch(setSaleNoteInput(event.target.value));
	};

	return (
		<div className={styles.infoPanel}>
			<div className={styles.autobids}>
				{config?.autobids} ({autoBids})
			</div>

			<div className={styles.starting}>
				{config?.startingAt} {getFormattedCurrency(maxAutoBid)}
			</div>
			{showSaleNote && (
				<input
					className={classNames(styles.saleNote, {
						[styles.warning]: showSaleNoteWarning,
					})}
					value={inputValue}
					onChange={event => onInputChange(event)}
					placeholder={capitaliseFirst(config?.enterSaleNote)}
				/>
			)}
		</div>
	);
};

export default InfoPanel;
